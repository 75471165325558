import React, { Component } from 'react'
import { Card } from 'reactstrap'
import { Route } from 'react-router-dom'
import { PanelHeader } from 'components'
import workerFetchUser from '../workers/workerFetchUser.js'
import WebWorker from '../workers/workerSetup.js'
// import { database } from 'firebase'
import firebase from 'firebase'
import { inject, observer } from 'mobx-react'
import UserDetail from './UserDetail'
import UsersDisplay from './UsersDisplay'
import SearchBar from 'components/SearchBar'

/* HELPERS FUNCTIONS - These are the functions which can reduce the line of codes inside the main class component and can be reused if need to. */
const date = (timestamp) => `${new Date(timestamp).getDate().toString()}/${(new Date(timestamp).getMonth() + 1).toString()}/${new Date(timestamp).getFullYear().toString()}`

const time = (timestamp) => {
  let hours, minutes, seconds
  if (new Date(timestamp).getHours() < 10) hours = `0${new Date(timestamp).getHours().toString()}`
  if (!new Date(timestamp).getHours() < 10) hours = new Date(timestamp).getHours().toString()

  if (new Date(timestamp).getMinutes() < 10) minutes = `0${new Date(timestamp).getMinutes().toString()}`
  if (!new Date(timestamp).getMinutes() < 10) minutes = new Date(timestamp).getMinutes().toString()

  if (new Date(timestamp).getSeconds() < 10) seconds = `0${new Date(timestamp).getSeconds().toString()}`
  if (!new Date(timestamp).getSeconds() < 10) seconds = new Date(timestamp).getSeconds().toString()

  return `${hours}:${minutes}:${seconds}`
}

export class Main extends Component {
    state = {
        users: [],
        users_count: 0,
        view_user_count: 24,
        load: '1',
        excelData: [],
        search: '',
        currentPage: 1,
        checked: false,
    }
    componentDidMount = () => {
        this.workerFetchUser = new WebWorker(workerFetchUser)
        this.fetchUsers()
    }
    
    fetchUsers = async () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
       
        let DB_VAL, DB_SNAP
        try {
            DB_SNAP = await firebase.app(COUNTRY_DB).database().ref('users/').once('value')
        
            if (!DB_SNAP.exists()) DB_VAL = 'none'
            if (DB_SNAP.exists()) DB_VAL = DB_SNAP.val()
        
            await this.workerFetchUser.postMessage(DB_VAL)
            await this.workerFetchUser.addEventListener('message', (event) => {
                this.setState({
                    users: event.data,
                    users_count: event.data.length,
                    load: event.data.length !== 0 ? '2' : '3'
                }, () => {
                    console.log(this.state.users);
                    this.storeUsersIntoArray()
                })
            })
        } catch (error) { console.error(error) }
    }
    
    storeUsersIntoArray = () => {
        const { users } = this.state
        let TEMP = []
    
        if (users.length !== 0) {
            for (const key in users) {
                const { name, Phone, email, register_timestamp } = users[key]
                let a = {
                    name: name,
                    phone: Phone,
                    email: email,
                    registered_date: register_timestamp !== undefined ? date(register_timestamp) : '',
                    registered_time: register_timestamp !== undefined ? time(register_timestamp) : '',
                }
                TEMP.push(a)
            }
            this.setState({ excelData: TEMP })
        }
    }
    
    chunkArray = (myArray, chunk_size) => {
        let index = 0
        let arrayLength = myArray.length
        let tempArray = []
        let TEMP = []
        let myChunk
    
        if (arrayLength !== 0) {
            for (let j = 0; j < arrayLength; j++) {
                TEMP.unshift(myArray[j])
            }
            let TEMPlength = TEMP.length
        
            for (index = 0; index < TEMPlength; index += chunk_size) {
                myChunk = TEMP.slice(index, index + chunk_size)
                tempArray.push(myChunk)
            }
        }
        return tempArray
    }
    
    
    /* EVENTS FUNCTION */
    // Handle the grid/list switch
    handleSwitch = (checked) => this.setState({ checked: checked })
    
    // Handle the search bar input
    handleSearch = (event) => this.setState({ currentPage: 1, search: event.target.value })
    
    // Handle the page view
    handlePageChange = (page) => this.setState({ currentPage: page })

    render() {
        const { excelData, checked } = this.state
        return (
            <Card body>
                <SearchBar excelData={excelData} checked={checked} searchPlaceholder='Search by name or email' parentSearch={this.handleSearch} parentSwitch={this.handleSwitch} styleContainer={{ background: 'rgb(521,125,0)', margin: '-1.25rem -1.25rem 0', borderRadius: '1rem 1rem 0 0', color: 'white', display: 'flex', justifyContent: 'space-between' }} classContainer='card-body' />

                <UsersDisplay load={this.state.load} users={this.state.users} search={this.state.search} currentPage={this.state.currentPage} view_user_count={this.state.view_user_count} chunkArray={this.chunkArray} checked={this.state.checked} handlePageChange={this.handlePageChange} />
            </Card>
        )
    }
}


export default class Users extends Component {
    render() {
        return (

            <div>
               <PanelHeader size='sm' />
               <div className='content'>
                    <Route exact path='/user/Users-List' component={Main} />
                    <Route exact path='/user/Users-List/:id' component={({ match }) => 
                    <UserDetail id={match.params.id}/>
                    }/>
                </div>
                
            </div>
        )
    }
}

Main = inject('mobx_users', 'mobx_config', 'mobx_auth')(observer(Main))
  