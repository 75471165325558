export default () => {
    // eslint-disable-next-line
    self.addEventListener('message', e => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;
        var DATA = e.data;
        let RESPONSE = e.data
        
        console.log(RESPONSE);

        postMessage(RESPONSE);
    });
};