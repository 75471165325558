import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import firebase from 'firebase';
import { inject, observer } from 'mobx-react'
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js';
import LOGO from "assets/img/logo-placeholder.jpg"
import ErrorAlert from './ErrorAlert';

class BusinessAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAdd: false,
            user_type: '',
            displayButt: true,

            PreviewLogo: LOGO,
            canUploadLogo: false,

            toggle: false,
            success: false,
            reload: false
        };
    }
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    //HANDLE THE CHOOSE FILE BUTTON
    handleLogoChange = (evt) => {
        let that = this;
        const file = document.querySelector('#upload_logo').files[0];

        if (file) {
            var checker = new Image();

            checker.src = window.URL.createObjectURL(file);

            checker.onload = function () {
                let fileSize = file.size;
                // var width = checker.naturalWidth,height = checker.naturalHeight;
                window.URL.revokeObjectURL(checker.src);
                if (fileSize < 100000) {
                    that.setState({ canUploadLogo: true, PreviewLogo: URL.createObjectURL(file) })
                } else {
                    that.setState({ canUploadLogo: false })
                }
            }
        } else {
            console.log("Image is empty");
        }

    }
    // 1. upload image into SP firebase storage
    _1uploadLogo = () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let that = this;
        let fileLogo = document.querySelector('#upload_logo').files[0];
        let TYPE_ID = firebase.app(COUNTRY_DB).database().ref('user_type/').push().getKey();

        console.log('1. upload logo', TYPE_ID);
        if (this.state.user_type === '') {
            alert('Please enter User Business Type!');
            that.setState({ displayButt: true })
            return;
        }
        // Checks that icon image is chosen and within file size limits
        if (fileLogo === undefined) {
            alert('Please upload image for tile icon!')
            that.setState({ displayButt: true })
            return;
        }
        if (fileLogo !== undefined && this.state.canUploadLogo === false) {
            alert('Icon image file size too large! Please upload images below 100kb.')
            that.setState({ displayButt: true })
            return;
        }
       

        const storageRef = firebase.app(COUNTRY_DB).storage().ref('business_logo');
        let uploadLogo = storageRef.child(TYPE_ID).child('logo').put(fileLogo);

        // UNCOMMENT THIS CODE
        uploadLogo.on('state_changed', function (snapshot) {
            that.setState({ progress: 25 })
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.app(COUNTRY_DB).storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    // that.setState({progressColor:'warning', text : 'PAUSED'})
                    break;
                case firebase.app(COUNTRY_DB).storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    // that.setState({progressColor:'success', text : 'UPLOADING'})
                    break;
            }
        }, function (error) {
            // IF IMAGE UPLOAD FAILED
            that.setState({ progress: 0 })
            alert('Upload failed, please try again!\n', error.message);
            that.setState({ displayButt: true })
        }, function () {
            // IF IMAGE UPLOAD SUCCEED
            uploadLogo.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // 2. get downloadURL
                
                that._2addUserTypeCF(downloadURL, TYPE_ID)
            })
        })
    }
    _2addUserTypeCF = (downloadURL, type_id) => {
        // 3. fetch CF addUserType

        const { browser } = this.props.mobx_config

        let URL, csrfTokenU, csrfSecretU, headerToken;
        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.addUserType
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.addUserType
        }

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        let USER_TYPE = 'user_type=' + this.state.user_type
        let DOWNLOAD_URL = '&downloadURL=' + downloadURL
        let TYPE_ID = '&type_id=' + type_id

        let FINAL_URL = URL + USER_TYPE + DOWNLOAD_URL + TYPE_ID;

        let OPTIONS = { credentials: 'include', headers: headerToken }

        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) this.setState({ toggle: true, success: false, reload: true })
            return response.json()
        }).then(() => {
            this.setState({
                user_type: '', canUploadLogo: false,
                displayButt: true, PreviewLogo: LOGO
            }, () => {
                setTimeout(() => {
                    this.props.parentRefresh();
                    this.toggleModal('modalAdd');
                }, 500);
            })
        })
    }
    _renderButton = () => {
        let display;

        if (this.state.displayButt === true) {
            display = <Button color='light' onClick={() => {
                this.setState({ displayButt: false }, () => {
                    this._1uploadLogo()
                })
            }}>Add Business Type</Button>
        }
        if (this.state.displayButt === false) {
            display = <Button disabled color='light' className="d-flex align-items-center" onClick={this._1uploadLogo}><div className="mr-2">Adding Business Type</div><div className
                ="btnloader"></div></Button>
        }

        return display;
    }
    render() {
        return (
            <div>
                <Button onClick={() => { this.toggleModal('modalAdd') }} style={{ background: '#00006c', borderRadius: '0', boxShadow: '3px -3px 0 #00004b, 6px -6px 0 #000039', width: '100%', position: 'relative', zIndex: '50' }}>Add Business</Button>

                <Modal className='modal-default' isOpen={this.state.modalAdd} toggle={() => { this.toggleModal('modalAdd') }}>
                    <ModalHeader toggle={() => { this.toggleModal('modalAdd') }}>Add User Business</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for='user_type'>Business Name</Label>
                            <Input type='text' name='user_type' id='user_type' onChange={this.handleChange} />
                        </FormGroup>

                        <Label for="upload_logo">Upload Logo</Label>
                        <Input type="file" accept="image/*" name="upload_logo" id="upload_logo" onChange={this.handleLogoChange} />
                        <FormText color="danger">
                            Logo (Make sure the the image size is less than 100kb!)
                        </FormText>
                        <div>
                            <img style={{ width: 250 }} src={this.state.PreviewLogo} />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this._renderButton()}{' '}
                        <Button className='ml-auto' color='secondary' onClick={() => { this.toggleModal('modalAdd') }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        );
    }
}

BusinessAdd.propTypes = {
    parentRefresh: PropTypes.func,
};

BusinessAdd = inject('mobx_config', 'mobx_auth')(observer(BusinessAdd));
export default BusinessAdd;