import React from "react";
import PropTypes from 'prop-types'

import { Card, CardBody, Badge, InputGroup, InputGroupText, InputGroupAddon, Input } from "reactstrap";

import { inject, observer } from 'mobx-react';
import firebase from 'firebase';

import email from 'assets/img/email.svg'
import phone from 'assets/img/phone.svg'
import avatar from 'assets/img/avatar.svg'


class ServList extends React.Component{
  static propTypes = {
    data: PropTypes.array,
    selected: PropTypes.object,
    userExist: PropTypes.string,
    fetchParent: PropTypes.func,
    parentSelect: PropTypes.func,
  }
  state = {
    search: '',
    select: {},
  }
  handleSearch = (event) => {
    this.setState({search : event.target.value})
  }

  _renderList = () => {
    let display

    if(this.props.userExist === '1') {
      display = <div style={{padding: '15px 10px'}} className="text-center">No users to display</div>
    }

    if(this.props.userExist === '3') {
      display = <div className="loader"></div>
    }

    if(this.props.userExist === '2') {
      // console.log(this.props.data);
      let filteredData = this.props.data.filter(
        (data) => {
          return data.user_data.name !== undefined ? data.user_data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 : null;
        }
      );

      display = filteredData.map((data,index) => {
        let displayUnread;
        if(data.unread !== 0) {
          displayUnread = <Badge color="danger" style={{fontSize:'16px', borderRadius:'45px'}}>{data.unread}</Badge>
        }
        if(data.user_data.approval === true){
          return (
            <Card
              key={index}
              className="provideList"
              onClick={() => {
                this.props.parentSelect(data)
              }}>
              <CardBody style={{minHeight:'10px'}}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
                  <div style={{width:'90%'}}>
                    <div style={{fontWeight:'bold', display:'flex', alignItems:'center', marginBottom:'10px', marginLeft:'5px'}}>
                      <img src={avatar} style={{width:'20px', marginRight: '15px'}}/>{data.user_data.name}
                    </div>

                    <div style={{fontWeight:'bold', display:'flex', alignItems:'center', marginBottom:'10px', marginLeft:'5px'}}>
                      <img src={email} style={{width:'20px', marginRight: '15px'}}/>{data.user_data.email}
                    </div>
                    <div style={{fontWeight:'bold', display:'flex', alignItems:'center', marginBottom:'10px', marginLeft:'5px'}}>
                      <img src={phone} style={{width:'20px', marginRight: '15px'}}/>{data.user_data.phone}
                    </div>
                  </div>

                  <div style={{width:'10%'}}>
                    {displayUnread}
                  </div>
                </div>

              </CardBody>
            </Card>
          )
        }
        if(data.user_data.approval === false){
          return (
            <Card
              key={index}
              className="provideList unassigned"
              onClick={() => {
                alert('This user has not been approved. Please approve in the SP Approve tab.')
              }}>
              <CardBody style={{minHeight:'10px'}}>
                <div style={{fontWeight:'bold', display:'flex', alignItems:'center', marginBottom:'10px', marginLeft:'5px'}}>
                  <img src={avatar} style={{width:'20px', marginRight: '15px'}}/>{data.user_data.name}
                </div>
                <div style={{fontWeight:'bold', display:'flex', alignItems:'center', marginBottom:'10px', marginLeft:'5px'}}>
                  <img src={email} style={{width:'20px', marginRight: '15px'}}/>{data.user_data.email}
                </div>
                <div style={{fontWeight:'bold', display:'flex', alignItems:'center', marginBottom:'10px', marginLeft:'5px'}}>
                  <img src={phone} style={{width:'20px', marginRight: '15px'}}/>{data.user_data.phone}
                </div>
                <div>
                  <Badge color="danger" pill>Have Not Applied</Badge>
                </div>
              </CardBody>
            </Card>
          )
        }
      })

    }

    return display;
  }
  render(){
    return(
    <div style={{width: '100%', height: 'inherit',}}>
      <form>
        <InputGroup className="no-border">
          <Input placeholder="Search..." onChange={this.handleSearch}/>
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <i className="now-ui-icons ui-1_zoom-bold" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </form>

      <div style={{height:'500px', overflow:'scroll'}}>
        {this._renderList()}
      </div>
    </div>
    )
  }
}

export default ServList;
