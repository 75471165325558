import React, { Component } from 'react'
import { PanelHeader } from "components";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row, timeoutsShape } from 'reactstrap'
import firebase from 'firebase';
import ErrorAlert from 'components/ErrorAlert.jsx'
import MarketingPopupAdd from './MarketingPopupAdd';
import { inject, observer } from 'mobx-react';
export default class MarketingPopup extends Component {
    state = { load : '1', data : {}, toggle : false, text : '' }

    componentDidMount() {
        this.fetchPopup()
    }
    toggleChild = () => { this.setState({ toggle : !this.state.toggle }) }
    fetchPopup = () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY

        firebase.app(COUNTRY_DB).database().ref(`marketing_popup/`).on('value', (snapshot) => {
            if(snapshot.exists()) {
                let DATA = snapshot.val()
                this.setState({load : '2', data : DATA})
            } else {
                this.setState({load : '3'})
            }
        })
    }
    _checkRemove = () => {
        this.setState({ toggle : true, text : <span>Are you sure you want to remove this popup? This will permanently remove the popup announcement from the app, and there will be no more Popups displayed.<br/>If you meant to replace this popup message, please click on 'Add new Popup' on the left.</span> })
    }
    _removePopup = async () => {
        let TEMP = this.state.data
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        console.log('remove', TEMP);
        TEMP.timestamp_archived = new Date().getTime()
        await this.setState({ toggle : true })

        await firebase.app(COUNTRY_DB).database().ref(`marketing_popup_archives/`).push(TEMP);
        await firebase.app(COUNTRY_DB).database().ref(`marketing_popup/`).remove();
    }
    _renderContent = () => {
        let display, { load, data } = this.state

        if(load === '1'){
            return (
                <Card body><div className="loader"></div></Card>
            )
        }
        if(load === '2'|| load === '3') {
            display = 
            <Row>
                <Col sm={5}>
                    <Card body>
                        <p className="mb-1" style={{lineHeight:'1'}}>Add new popup</p>
                        <small>* This action will overwrite the current popup</small>
                        <div className="text-right">
                            <MarketingPopupAdd />
                        </div>
                    </Card>
                </Col>
                <Col sm={7}>
                    <Card>
                        <CardHeader style={{ background: 'rgb(32, 32, 32)', borderRadius: '0.9rem 0.9rem 0px 0px', backgroundColor: '#202020', color: 'white' }} className="pb-2">
                            <h5 className="m-0 mr-auto">Current Pop-up</h5>
                        </CardHeader>
                        {load === '2' ? <>
                                <CardBody className="d-flex flex-column">
                                    <img src={data.banner_image} style={{width:'60%', margin:'0 auto'}} />
                                    <small className="text-right">Created on : {new Date(data.timestamp).toLocaleString('en-MY')}</small>
                                </CardBody>
                                <CardFooter className="text-right">
                                    <Button className="m-0" color="danger" onClick={this._checkRemove}>Remove Popup</Button>
                                </CardFooter>
                            </> : <CardBody>
                                No popup exists
                            </CardBody>}
                    </Card>
                </Col>
            </Row>
        }

        return display
    }
    render() {
        const { data, load } = this.state
        
        return (
            <div>
                <PanelHeader size='sm' />
                <div className="content">
                    {this._renderContent()}
                </div>
                <ErrorAlert toggle={this.state.toggle} text={this.state.text} parentToggle={this.toggleChild} parentAction={this._removePopup} close />
            </div>
        )
    }
}

MarketingPopup = inject('mobx_auth')(observer(MarketingPopup))