import React from 'react';
import { inject, observer } from 'mobx-react';
import PanelHeader from 'components/PanelHeader.jsx';
import Dashboard_Main from './Dashboard_Main';
import Dashboard_DirectLending from './Dashboard_DirectLending';
import Dashboard_Marketing from './Dashboard_Marketing';
import DashboardTurkey from './DashboardTurkey';
export default class SimpleDashboard extends React.Component {
  render() {
    // console.log(this.props);
    return (
      <div>
        <PanelHeader size='sm' />
        {(this.props.mobx_auth.ROLE === "DL" || this.props.match.path === "/user/Analytics-DirectLending") && <Dashboard_DirectLending />}
        {/* {(this.props.mobx_auth.ROLE === "Super" || this.props.mobx_auth.ROLE === "Marketing") && (this.props.mobx_auth.COUNTRY === "MY") && this.props.match.path === "/user/dashboard" && <Dashboard_Main />} */}
        {(this.props.mobx_auth.ROLE === "Super" || this.props.mobx_auth.ROLE === "Marketing") && this.props.match.path === "/user/dashboard" && this.props.mobx_auth.COUNTRY === 'MY'&& <Dashboard_Main />}
        {this.props.mobx_auth.ROLE === "Super"  && this.props.match.path === "/user/dashboard" && this.props.mobx_auth.COUNTRY === 'TR'&& <DashboardTurkey />}
        {/* {this.props.mobx_auth.ROLE === "Marketing" && this.props.match.path === "/user/dashboard" && <Dashboard_Marketing />} */}
      </div>
    )
  }
}

SimpleDashboard = inject('mobx_auth', 'mobx_quote', 'mobx_config')(observer(SimpleDashboard));