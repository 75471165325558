import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, CardFooter, Input, Button } from 'reactstrap';

import CollapsibleCard from 'components/CollapsibleCard'

import firebase from 'firebase';

export default class BookingRequestWage extends Component {
    static propTypes = {
        booking_id: PropTypes.string,
        data: PropTypes.object,
        parentRefresh: PropTypes.func,
    }
    state = {
        wage: false,
        masterItems: [{items:'Basic services fee', price:50}]
    }
    handleNestedChange = (evt) => {
        let PREV = Array.from(this.state.masterItems)
        let STRING = evt.target.name.split('_')
        let INDEX = STRING[0]
        let FIELD = STRING[1]

        PREV[INDEX][FIELD] = evt.target.value
        this.setState({masterItems:PREV})
    }
    componentDidMount() {
        this.checkUpah()
    }
    checkUpah = () => {
        const { data } = this.props

        if(data.upah.masterItems !== undefined){
            this.setState({wage:true, masterItems:data.upah.masterItems})
        }
    }
    addItem = () => {
        let PREV = Array.from(this.state.masterItems);
        let a = { items: '', price: 0 };

        PREV.push(a)
        this.setState({masterItems:PREV})
    }
    removeItem = (index) => {
        let PREV = Array.from(this.state.masterItems);

        PREV.splice(index, 1)
        this.setState({masterItems:PREV})
    }
    calculateTotalPrice = () => {
        let TOTAL = 0

        this.state.masterItems.forEach(item => {
            let PRICE = item.price !== "" ? item.price : 0

            TOTAL = TOTAL + parseInt(PRICE)
        });

        return TOTAL
    }
    _submitWage = () => {
        // 1. Calculate total price
        // 2. Update masterItems
        let state = this.state
        if(state.masterItems.length === 0){
            alert('Wage pricing needs to have at least ONE(1) item to submit!')
            return
        }
        let r = window.confirm("Are you sure you want to submit wage?");
        if(!r){
            return;
        }

        let TOTAL_PRICE = this.calculateTotalPrice()

        let DATA = {
            masterItems: this.state.masterItems,
            total_price: TOTAL_PRICE
        }

        firebase.database().ref('request/' + this.props.booking_id + '/upah').update(DATA).then(() => {
            this.props.parentRefresh()
        })
    }
    _renderButton = () => {
        let display

        if(!this.state.wage){
            display =
            <>
                {this.props.data.upah.masterItems !== undefined ? <Button color="secondary" className="mr-3" onClick={() => {this.props.parentRefresh()}}>Cancel</Button> : null}
                <Button color="success" onClick={this._submitWage}>Submit Wage Price</Button>
            </>
        }
        if(this.state.wage){
            display = <Button color="dark" onClick={() => {this.setState({wage:!this.state.wage})}}>Edit Wage Price</Button>
        }

        return display
    }
    _renderTableContents = () => {
        let display

        display = this.state.masterItems.map((item,index) => (
            <tr key={index}>
                <td>
                    <Input field={index + '_items'} type='text' name={index + '_items'} disabled={this.state.wage} value={item.items} onChange={this.handleNestedChange} />
                </td>
                <td width="20%">
                    <Input field={index + '_price'} type='number' name={index + '_price'} disabled={this.state.wage} value={item.price} onChange={this.handleNestedChange} />
                </td>
                <td width="5%">
                    {!this.state.wage && index !== 0 ? <Button size="sm" color='danger' onClick={() => {this.removeItem(index)}}>x</Button> : null}
                </td>
            </tr>
        ))
        
        return display
    }
    _renderComponent = () => {
        let display

        display =
        <>
            <Table className='align-items-center' responsive>
                <thead className='thead-light'>
                    <tr>
                        <th scope='col'>Item</th>
                        <th scope='col'>Price</th>
                        <th scope='col'/>
                    </tr>
                </thead>
                <tbody>
                    {this._renderTableContents()}

                    {!this.state.wage ? 
                        <tr>
                            <td colSpan="3" className="text-right">
                                <Button size="sm" color='success' onClick={this.addItem}>+</Button>
                            </td>
                        </tr>
                    : null}

                    <tr>
                        <td colSpan="1" className="text-right">TOTAL</td>
                        <td>
                            {this.calculateTotalPrice()}
                        </td>
                        <td/>
                    </tr>
                </tbody>
            </Table>

            <CardFooter className="d-flex justify-content-end mt-0">
                {this._renderButton()}
            </CardFooter>    
        </>

        return display
    }
    _renderStatus = () => {
        let display
        let color = this.state.wage ? 'text-success' : 'text-danger'
        let status = this.state.wage ? 'Submitted' : 'Not Submitted'

        display =
        <div className="mr-3 d-flex align-items-center">
            <span className="mr-1">{status}</span>
            <i className={"fas fa-circle " + color}></i>
        </div>

        return display
    }
    render() {
        return <CollapsibleCard title="Wage" collapse={false} component={this._renderComponent} status={this._renderStatus} />;
    }
}
