import { observable, action, decorate } from "mobx";
//import { computed, toJS } from "mobx";
export default class Auth {
  FUID = '';
  setFUID(FUID) {
    this.FUID = FUID;
  }

  ROLE = '';
  setROLE(ROLE) {
    this.ROLE = ROLE;
  }

  UNREAD = 0;
  setUNREAD(UNREAD) {
    this.UNREAD = UNREAD
  }
  //detect by COUNTRY
  COUNTRY = null
  setCOUNTRY(COUNTRY) {
    this.COUNTRY = COUNTRY
  }
}

decorate(Auth, {
  FUID: observable,
  setFUID: action,
  ROLE: observable,
  setROLE: action,
  UNREAD: observable,
  setUNREAD: action,

  COUNTRY:observable,
  setCOUNTRY:action
});
