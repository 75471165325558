import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap'
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js';
import { inject, observer } from 'mobx-react';

import ReactExport from "react-export-excel";
import ErrorAlert from 'components/ErrorAlert';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class TransactionsReportTurkey extends Component {
    static propTypes = {
        data: PropTypes.array,
        workshop: PropTypes.object,
    }
    state = {
        modalEdit: false,
        displayButt: true,
        from: null,
        to: null,
        data: [],
        load: '1',
        toggle: false,
        success: false,
        reload: false
    }
    getInitialState = () => {
        return {
            from: null,
            to: null,
        };
    }
    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
    }
    handleResetClick = () => {
        this.setState(this.getInitialState());
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    _selectDates = () => {
        let COUNTRY = this.props.mobx_auth.COUNTRY
        if (this.state.from === null) {
            alert('Please select start date!')
            return
        }
        if (this.state.to === null) {
            alert('Please select end date!')
            return
        }
        let URL, csrfTokenU, csrfSecretU, headerToken
        const { browser } = this.props.mobx_config
        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopMonthlyReport
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopMonthlyReport
        }
        let LOCATION = `&location=TR`
        let START = 'start=' + new Date(this.state.from).getTime()
        let END = '&end=' + new Date(this.state.to).getTime()
        let FINAL_URL = URL + START + END + LOCATION
        let OPTIONS = { credentials: 'include', headers: headerToken }
        console.log("FINAL URL", FINAL_URL)

        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            console.log('response', response.data);
            if (response.message === 'ok' && response !== 401) {
                this.setState({ data: response.data, load: '2', displayButt: true }, () => {
                    // console.log("data", this.state.data);
                    this.toggleModal('modalEdit')
                })
            } else {
                this.setState({ load: '3', displayButt: true }, () => { this.toggleModal('modalEdit') })
            }
        })
    }
    _renderContent = () => {
        let display
        const { load } = this.state
        const { workshop } = this.props
        let sort_data = this.state.data
        let payment_method
        sort_data.sort((a,b) => a.timestamp_paid - b.timestamp_paid)
  
        if (load === '1') {
            display = <div style={{ padding: '65px 25px' }} className="text-center">Select dates to generate report.</div>
        }
        if (load === '2') {
            display =
                <Table className='align-items-center' responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th scope='col'>Merchant Name</th>
                            <th scope='col'>Amount (RM)</th>
                            <th scope='col'>Payment Date</th>
                            <th scope='col'>Payment Method</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.length !== 0 ?
                            sort_data.map((data, index) => {
                                if (data.type === 'cash_transfer') { payment_method = 'online transfer'}
                                if (data.type === 'cash_terminal') { payment_method = 'credit/debit Card'}
                                // console.log('hi', data.retail_main, data.retail_id, workshop[data.retail_main], data);
                                if(workshop[data.retail_main]) {
                                    return (
                                        <tr key={index}>
                                            {/* <td>{workshop[data.retail_main].outlets[data.retail_id].name}</td> */}
                                            <td>{workshop[data.retail_main].outlets[data.retail_id].name}</td>
                                            <td>{data.amount}</td>
                                            <td>{new Date(data.timestamp_paid).toLocaleDateString('en-MY')}</td>
                                            <td>{payment_method? payment_method : data.type}</td>
                                        </tr>
                                    )
                                } else console.log('outlet doesnt exist', data.payment_id, data.retail_main, data.retail_id)
                            }) :
                            <tr>
                                <td colSpan="4" style={{ padding: '65px 25px 25px', textAlign: 'center' }}>No transactions for the dates selected</td>
                            </tr>
                        }
                    </tbody>
                </Table>
        }
        if (load === '3') {
            display = <div style={{ padding: '65px 25px' }} className="text-center">An error occured. Please try again.</div>
        }
        return display
    }
    _renderExcel = () => {
        let display

        if (this.state.load === '2') {
            display =
                <ExcelFile filename={`Report ${new Date(this.state.from).toLocaleDateString('en-MY')} - ${new Date(this.state.to).toLocaleDateString('en-MY')}`} element={<Button color="info" className="m-0">Export Report</Button>}>
                    <ExcelSheet data={this.state.data} name={`Transactions from ${new Date(this.state.from).toLocaleDateString('en-MY')} - ${new Date(this.state.to).toLocaleDateString('en-MY')}`}>
                        <ExcelColumn label="Merchant Name" value={(col) => this.props.workshop[col.retail_main] ? this.props.workshop[col.retail_main].outlets[col.retail_id].name : 'Outlet deleted'} />
                        <ExcelColumn label="Amount (RM)" value="amount" />
                        <ExcelColumn label="Payment Date" value={(col) => new Date(col.timestamp_paid).toLocaleDateString('en-MY')} />
                        <ExcelColumn label="Payment Method" value="type" />
                    </ExcelSheet>
                </ExcelFile>
        }

        return display
    }
    _renderButton = () => {
        let display

        if (this.state.displayButt === true) {
            display = <Button color='dark' onClick={() => {
                this.setState({ displayButt: false }, () => {
                    this._selectDates()
                })
            }} className="m-0">Select dates and generate report</Button>
        } else {
            display = <Button disabled className="d-flex align-items-center justify-content-center m-0" color='dark'><div>Loading...</div><div className="btnloader ml-1"></div></Button>
        }

        return display
    }
    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };

        return (
            <>
                <Card className='shadow'>
                    <CardHeader className="d-flex justify-content-between align-items-center">
                        <h5 className="m-0">Generate Report</h5>
                        <div>
                            {this._renderExcel()}
                            <Button className="m-0 ml-2" color="light" onClick={() => {
                                // this.getInitialState()
                                this.toggleModal('modalEdit')
                            }}>Select Dates</Button>
                        </div>
                    </CardHeader>

                    <CardBody className="pl-0 pr-0">
                        {this._renderContent()}
                    </CardBody>
                </Card>

                <Modal backdrop='static' isOpen={this.state.modalEdit} toggle={() => this.toggleModal('modalEdit')} className='modal-dialog-centered'>
                    <ModalHeader>Select Dates to Generate Report</ModalHeader>
                    <ModalBody className="text-center">
                        <DayPicker
                            className="Selectable"
                            numberOfMonths={2}
                            selectedDays={[from, { from, to }]}
                            modifiers={modifiers}
                            onDayClick={this.handleDayClick}
                            month={this.state.to === null ? new Date(new Date().getFullYear(), new Date().getMonth() - 1) : this.state.to}
                        />
                        {this.state.from && this.state.to && (
                            <div>
                                <Button size='sm' color="warning" className="m-0" onClick={this.handleResetClick}>
                                    Reset dates
                                </Button>
                            </div>
                        )}
                        <Helmet>
                            <style>{`
                            .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                                background-color: #f0f8ff !important;
                                color: #4a90e2;
                            }
                            .Selectable .DayPicker-Day {
                                border-radius: 0 !important;
                            }
                            .Selectable .DayPicker-Day--start {
                                border-top-left-radius: 50% !important;
                                border-bottom-left-radius: 50% !important;
                            }
                            .Selectable .DayPicker-Day--end {
                                border-top-right-radius: 50% !important;
                                border-bottom-right-radius: 50% !important;
                            }
                            `}</style>
                        </Helmet>
                    </ModalBody>
                    <ModalFooter>
                        {this._renderButton()}
                        <Button className='m-0 ml-auto' color='danger' onClick={() => this.toggleModal('modalEdit')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </>
        )
    }
}
TransactionsReportTurkey = inject('mobx_config','mobx_auth')(observer(TransactionsReportTurkey))