import Auth from './Auth.js';
import Config from './Config.js';
import Serv from './Serv.js';
import Quote from './Quote.js'
import Provider from './Provider.js';
import Users from './Users.js';

export default {
  mobx_auth: new Auth(),
  mobx_config: new Config(),
  mobx_serv: new Serv(),
  mobx_quote: new Quote(),
  mobx_provider: new Provider(),
  mobx_users: new Users(),
}
