import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Card, Button, CardBody, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledCollapse, CardHeader, Badge } from 'reactstrap';
import { QRCode } from 'react-qrcode-logo';
import BookingRequestEditServiceTime from 'pages/BookingRequestEditServiceTime.jsx'

import firebase from 'firebase';
import { serv_user_development, serv_user_production } from 'configs/firebaseCF';
import { inject, observer } from 'mobx-react'
import { createHashHistory } from 'history';

import BookingRequestQuotation from './BookingRequestQuotation';
import BookingRequestWage from './BookingRequestWage';
import CollapsibleCard from 'components/CollapsibleCard';
import BookingRequestAssignSP from './BookingRequestAssignSP';
import BookingRequestChecklist from './BookingRequestChecklist';
import ErrorAlert from '../components/ErrorAlert';

const history = createHashHistory();

/**
 * BOOKING REQUEST STATUS
 */
export class BookingRequestStatus extends Component {
    static propTypes = {
        status: PropTypes.string,
        parentRefresh: PropTypes.func,
        booking_id: PropTypes.string,
        SP_ID: PropTypes.string,
        upah_price: PropTypes.any,
    }
    _completeJob = () => {
        let r = window.confirm("Are you sure you want to complete this job? Only complete this job once you are certain the SP has performed the service and SERV has paid the SP wages.\n\nClick 'OK' to proceed.")

        if (!r) {
            return;
        }

        let BOOKING_ID = this.props.booking_id

        firebase.database().ref('request/' + BOOKING_ID + '/assign/').update({
            status: 'done',
        }).then(() => {
            this.props.parentRefresh()
        })
    }
    _archiveJob = () => {
        let r = window.confirm("Are you sure you want to archive this job? This job will be placed in the Booking Request history page.\n\nClick 'OK' to proceed.")

        if (!r) {
            return;
        }

        let BOOKING_ID = this.props.booking_id
        let SP_ID = this.props.SP_ID
        let ARCHIVE_DATA = null, ARCHIVE_JOB = null;

        firebase.database().ref('request/' + BOOKING_ID).once('value').then((snapshot) => {
            if (snapshot.exists()) {
                ARCHIVE_DATA = snapshot.val();
            } else {
                console.log('Empty')
            }
        }).then(() => {
            if (ARCHIVE_DATA !== null) {
                firebase.database().ref('history/' + BOOKING_ID).update(ARCHIVE_DATA)
            }
        }).then(() => {
            firebase.database().ref('request/' + BOOKING_ID).remove()
        })

        firebase.database().ref('servproviderUID/' + SP_ID + '/job/' + BOOKING_ID).once('value').then((snapshot2) => {
            if (snapshot2.exists()) {
                ARCHIVE_JOB = snapshot2.val();
                ARCHIVE_JOB.total_price = this.props.upah_price

            } else {
                console.log('no such job in this user');
            }
        }).then(() => {
            if (ARCHIVE_JOB !== null) {
                firebase.database().ref('servproviderUID/' + SP_ID + '/job_history/' + BOOKING_ID).update(ARCHIVE_JOB)
            }
        }).then(() => {
            firebase.database().ref('servproviderUID/' + SP_ID + '/job/' + BOOKING_ID).remove()
        })
    }
    _renderContent = () => {
        let display, header, text, button
        let STATUS = this.props.status

        if (STATUS === 'requesting') {
            header = 'Waiting for Quotation'
            text = 'A customer has made a booking and is awaiting for your response. Please issue a quotation.'
        }

        if (STATUS === 'unread') {
            header = 'Waiting for the user to review the quotation'
            text = 'The quotation has been issued to the user and the user has not read the quotation.'
        }

        if (STATUS === 'read') {
            header = 'Awaiting Payment from User'
            text = 'The quotation has been read. Waiting for the user to make payment for the service. Once payments have been made, the booking can be assigned to a SERV Partner (SP).'
        }

        if (STATUS === 'paid unassinged') {
            header = 'Payment Acknowledged'
            text = 'Payment has been recieved. Please assign a SERV Partner (SP) and update the wages for the SP.'
        }

        if (STATUS === 'paid assigned') {
            header = 'SP Assigned'
            text = 'SERV Partner has been assigned. Awaiting service execution.'
        }

        if (STATUS === 'job completed waiting for approval') {
            header = 'Awaiting Acknowledgement and Payment Approval'
            text = "Service has been completed by the SP. Please issue payment to the SP before marking the booking as 'Complete'. Click on 'Complete Job' to mark the booking as complete"
            button = <Button size="sm" color="success" className="m-0" onClick={this._completeJob}>Complete Job</Button>
        }

        if (STATUS === 'done') {
            header = 'Booking Complete'
            text = "This booking flow has been completed. Click on the 'Archive' button to archive this booking."
            button = <Button size="sm" color="success" className="m-0" onClick={this._archiveJob}>Archive Job</Button>
        }

        display =
            <Row className="align-items-center">
                <Col md={10}>
                    <h5 className="m-0 font-weight-bold">{header}</h5>
                    <div>{text}</div>
                </Col>
                <Col md={2} className="text-right">
                    {button}
                </Col>
            </Row>


        return display
    }
    render() {
        return (
            <Card body>
                {this._renderContent()}
            </Card>
        )
    }
}

/**
 * BOOKING REQUEST SELECTED
 */
export default class BookingRequestSelected extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statu: 'requesting',
            data: {},
            listen: {},
            load: '1',
            displayButt: true,
            modalEdit: false,
            modalPrev: false,
            screen: 0,
            bookHistory: {},
            toggle: false,
            success: false,
            reload: false
        };
    }
    _back = () => {
        history.push("/user/Booking-Request")
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    };
    _refresh = () => {
        this.setState({ load: '1' }, () => {
            this.fetchRequest();
        })
    }
    _delete = () => {
        let r = window.confirm('Are you sure you want to delete this booking request?')
        if (!r) {
            return;
        }

        let HISTORY = {};
        let csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        let URL;
        let USER_ID = this.state.data.customer_FUID
        let VEHICLE_ID = this.state.data.customer_vehicleID
        let BOOKING_ID = this.props.id
        let REQUEST_ID = this.state.data._requestID
        let ASSIGN_NAME = this.state.data.assign._name ? this.state.data.assign._name : ''
        let SPID = this.state.data.assign._id ? this.state.data.assign._name : ''
        let ADDRESS = this.state.data.customer_address
        let CAR_MAKE = this.state.data.customer_carmake
        let CAR_MODEL = this.state.data.customer_carmodel
        let CAR_PLATE = this.state.data.customer_carplate
        let CAR_YEAR = this.state.data.customer_caryear
        let TOTAL_PRICE = this.state.data.quotation.total_price !== '' ? this.state.data.quotation.total_price : null
        let BRAKE_PAD = this.state.data.quotation.requested_services.isBrakePad !== '' ? this.state.data.quotation.requested_services.isBrakePad : null
        let BATTERY = this.state.data.quotation.requested_services.isBattery !== '' ? this.state.data.quotation.requested_services.isBattery : null
        let AIR_FILTER = this.state.data.quotation.requested_services.isAirFilter !== '' ? this.state.data.quotation.requested_services.isAirFilter : null
        let AC = this.state.data.quotation.requested_services.isAC !== '' ? this.state.data.quotation.requested_services.isAC : null
        let SPARK_PLUG = this.state.data.quotation.requested_services.isSparkPlug !== '' ? this.state.data.quotation.requested_services.isSparkPlug : null
        let LIGHT_BULB = this.state.data.quotation.requested_services.isLightBulb !== '' ? this.state.data.quotation.requested_services.isLightBulb : null
        let WIPER = this.state.data.quotation.requested_services.isWiper !== '' ? this.state.data.quotation.requested_services.isWiper : null
        let TYRE_PUMP = this.state.data.quotation.requested_services.isTyrePump !== '' ? this.state.data.quotation.requested_services.isTyrePump : null
        let GEAR_OIL = this.state.data.quotation.requested_services.isGearOil !== '' ? this.state.data.quotation.requested_services.isGearOil : null

        let AC_PRICE, AC_BRAND, AIR_FILTER_PRICE, AIR_FILTER_BRAND, BATTERY_PRICE, BATTERY_BRAND, BRAKE_PAD_PRICE, BRAKE_PAD_BRAND, GEAR_OIL_PRICE, GEAR_OIL_BRAND, LIGHT_BULB_PRICE, LIGHT_BULB_BRAND, SPARK_PLUG_PRICE, SPARK_PLUG_BRAND, TYRE_PUMP_PRICE, TYRE_PUMP_BRAND, WIPER_PRICE, WIPER_BRAND, SERVICE_PRICE, SERVICE_BRAND
        let MASTERITEMS_QUERY = null

        if (this.state.data.quotation.masterItems) {
            AC_PRICE = this.state.data.quotation.masterItems[0].price
            AC_BRAND = this.state.data.quotation.masterItems[0].brand
            AIR_FILTER_PRICE = this.state.data.quotation.masterItems[1].price
            AIR_FILTER_BRAND = this.state.data.quotation.masterItems[1].brand
            BATTERY_PRICE = this.state.data.quotation.masterItems[2].price
            BATTERY_BRAND = this.state.data.quotation.masterItems[2].brand
            BRAKE_PAD_PRICE = this.state.data.quotation.masterItems[3].price
            BRAKE_PAD_BRAND = this.state.data.quotation.masterItems[3].brand
            GEAR_OIL_PRICE = this.state.data.quotation.masterItems[4].price
            GEAR_OIL_BRAND = this.state.data.quotation.masterItems[4].brand
            LIGHT_BULB_PRICE = this.state.data.quotation.masterItems[5].price
            LIGHT_BULB_BRAND = this.state.data.quotation.masterItems[5].brand
            SPARK_PLUG_PRICE = this.state.data.quotation.masterItems[6].price
            SPARK_PLUG_BRAND = this.state.data.quotation.masterItems[6].brand
            TYRE_PUMP_PRICE = this.state.data.quotation.masterItems[7].price
            TYRE_PUMP_BRAND = this.state.data.quotation.masterItems[7].brand
            WIPER_PRICE = this.state.data.quotation.masterItems[8].price
            WIPER_BRAND = this.state.data.quotation.masterItems[8].brand
            SERVICE_PRICE = this.state.data.quotation.masterItems[9].price
            SERVICE_BRAND = this.state.data.quotation.masterItems[9].brand

            MASTERITEMS_QUERY = `&acPrice=${AC_PRICE}&acBrand=${AC_BRAND}&airFilterPrice=${AIR_FILTER_PRICE}&airFilterBrand=${AIR_FILTER_BRAND}&batteryPrice=${BATTERY_PRICE}&batteryBrand=${BATTERY_BRAND}&brakePadPrice=${BRAKE_PAD_PRICE}&brakePadBrand=${BRAKE_PAD_BRAND}&gearOilPrice=${GEAR_OIL_PRICE}&gearOilBrand=${GEAR_OIL_BRAND}&lightBulbPrice=${LIGHT_BULB_PRICE}&lightBulbBrand=${LIGHT_BULB_BRAND}&sparkPlugPrice=${SPARK_PLUG_PRICE}&sparkPlugBrand=${SPARK_PLUG_BRAND}&tyrePumpPrice=${TYRE_PUMP_PRICE}&tyrePumpBrand=${TYRE_PUMP_BRAND}&wiperPrice=${WIPER_PRICE}&wiperBrand=${WIPER_BRAND}&servicePrice=${SERVICE_PRICE}`
        }

        let TIME = this.state.data.customer_time
        let DAY = this.state.data.customer_day
        let MONTH = this.state.data.customer_month
        let YEAR = this.state.data.customer_year
        let BASIC_PREMIUM = this.state.data.quotation.requested_services.basic_premium
        let TIMESTAMP = this.state.data.timestamp

        if (this.props.mobx_config.config === 0) {
            URL = `${serv_user_development.deleteRequests}userkey=${USER_ID}&carkey=${VEHICLE_ID}&key=${BOOKING_ID}&requestID=${REQUEST_ID}&assignName=${ASSIGN_NAME}&SPID=${SPID}&address=${ADDRESS}&carmake=${CAR_MAKE}&carmodel=${CAR_MODEL}&carplate=${CAR_PLATE}&caryear=${CAR_YEAR}&total_price=${TOTAL_PRICE}&isBrakePad=${BRAKE_PAD}&isBattery=${BATTERY}&isAirFilter=${AIR_FILTER}&isAC=${AC}&isSparkPlug=${SPARK_PLUG}&isLightBulb=${LIGHT_BULB}&isWiper=${WIPER}&isTyrePump=${TYRE_PUMP}&isGearOil=${GEAR_OIL}&serviceBrand=${SERVICE_BRAND}&customer_time=${TIME}&customer_day=${DAY}&customer_month=${MONTH}&customer_year=${YEAR}&basic_premium=${BASIC_PREMIUM}&createdOn=${TIMESTAMP}${MASTERITEMS_QUERY !== null ? MASTERITEMS_QUERY : null}`
        }
        if (this.props.mobx_config.config === 1) {
            URL = `${serv_user_production.deleteRequests}userkey=${USER_ID}&carkey=${VEHICLE_ID}&key=${BOOKING_ID}&requestID=${REQUEST_ID}&assignName=${ASSIGN_NAME}&SPID=${SPID}&address=${ADDRESS}&carmake=${CAR_MAKE}&carmodel=${CAR_MODEL}&carplate=${CAR_PLATE}&caryear=${CAR_YEAR}&total_price=${TOTAL_PRICE}&isBrakePad=${BRAKE_PAD}&isBattery=${BATTERY}&isAirFilter=${AIR_FILTER}&isAC=${AC}&isSparkPlug=${SPARK_PLUG}&isLightBulb=${LIGHT_BULB}&isWiper=${WIPER}&isTyrePump=${TYRE_PUMP}&isGearOil=${GEAR_OIL}&serviceBrand=${SERVICE_BRAND}&customer_time=${TIME}&customer_day=${DAY}&customer_month=${MONTH}&customer_year=${YEAR}&basic_premium=${BASIC_PREMIUM}&createdOn=${TIMESTAMP}${MASTERITEMS_QUERY !== null ? MASTERITEMS_QUERY : null}`
        }

        let OPTIONS = { credentials: 'include', headers: headerToken }

        fetch(URL, OPTIONS).then((response) => {
            // console.log(response.status)
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            response.json()
        }).then(response => {
            let RESPONSE = response

            if (RESPONSE !== 401) {
                firebase.database().ref('request/' + this.props.id).once('value').then((snapshot) => {
                    if (snapshot.exists()) {
                        HISTORY = snapshot.val()
                    } else {
                        console.log("error")
                    }
                }).then(() => {
                    firebase.database().ref('history/' + this.props.id).update(HISTORY)
                }).then(() => {
                    firebase.database().ref('request/' + this.props.id).remove()
                }).then(() => {
                    this._refresh();
                })
            }
        })
    }

    componentWillUnmount() {
        firebase.database().ref('request/' + this.props.id).off()
    }
    componentDidMount() {
        this.fetchRequest()
    }
    fetchRequest = () => {
        firebase.database().ref('request/' + this.props.id).on('value', (snapshot) => {
            let DATA;
            if (snapshot.exists()) {
                DATA = snapshot.val();
                DATA.booking_id = this.props.id

                let STATUS = 'requesting'
                if (DATA.quotation.masterItems !== undefined && (DATA.quotation.read === false || DATA.quotation.read === undefined)) {
                    STATUS = 'unread'
                }
                if (DATA.quotation.read === true) {
                    STATUS = 'read'
                }
                if (DATA.receipt_payment_url !== undefined && DATA.assign.status === 'none') {
                    STATUS = 'paid unassinged'
                }
                if (DATA.receipt_payment_url !== undefined && DATA.assign.status === 'assigned') {
                    STATUS = 'paid assigned'
                }
                if (DATA.assign.status === 'waiting for approval') {
                    STATUS = 'job completed waiting for approval'
                }
                if (DATA.assign.status === 'done') {
                    STATUS = 'done'
                }

                this.setState({ data: DATA, status: STATUS }, () => {
                    this.fetchReturningCustomer()
                })
            } else {
                console.log('Request somehow does not exist');
                // alert('Request does not exist!');
                history.push('/user/Booking-Request');
            }
        })
    }
    // Fetch the customer booking history to display previous quote and records
    fetchReturningCustomer = () => {
        const { data } = this.state
        const { browser } = this.props.mobx_config
        const USER_ID = data.customer_FUID
        const VEHICLE_ID = data.customer_vehicleID
        let URL, csrfTokenU, csrfSecretU, headerToken;

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            URL = `${serv_user_development.fetchReturningCustomer}userid=${USER_ID}&vehicleid=${VEHICLE_ID}`
        }
        if (this.props.mobx_config.config === 1) {
            URL = `${serv_user_production.fetchReturningCustomer}userid=${USER_ID}&vehicleid=${VEHICLE_ID}`
        }

        let OPTIONS = { credentials: 'include', headers: headerToken }

        fetch(URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                let bookHistory = {}
                let book_history = response.data.book_history
                let SORTED
                let REMOVE_DELETE
                let DRIVE_IN_HISTORY
                let SOTG_HISTORY
                let DRIVE_IN_LATEST
                let SOTG_LATEST

                if (book_history !== "") {
                    SORTED = book_history.sort((book1, book2) => book2.book_history.timestamp - book1.book_history.timestamp)
                    REMOVE_DELETE = SORTED.filter(book => book.book_history.quotation !== undefined && book.book_history.quotation.masterItems !== undefined && book.book_history.assign.status !== "deleted")
                    DRIVE_IN_HISTORY = REMOVE_DELETE.filter(book => book.book_history.type === "walkin")
                    SOTG_HISTORY = REMOVE_DELETE.filter(book => book.book_history.type !== "walkin")

                    DRIVE_IN_LATEST = DRIVE_IN_HISTORY[0]
                    SOTG_LATEST = SOTG_HISTORY[0]

                    if (!DRIVE_IN_LATEST && !SOTG_LATEST) {
                        book_history.noSuccessBook = ""
                    }
                }

                bookHistory.book = book_history
                bookHistory.prevSOTG = SOTG_LATEST
                bookHistory.prevDRIVE = DRIVE_IN_LATEST

                this.setState({ bookHistory: bookHistory, load: '2' })
            }
        })
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    _renderCustomerDetails = () => {
        let display;

        display =
            <Card style={{ height: '100%' }}>
                <CardHeader style={{ background: 'rgb(32, 32, 32)', borderRadius: '1rem 1rem 0px 0px' }} className="pb-3 text-white">
                    <h5 className="mb-0" style={{ lineHeight: '1' }}>Customer Details</h5>
                </CardHeader>
                <CardBody>
                    <h6 style={{ color: 'black', display: 'inline-block' }}>Name :</h6>
                    <span>{' '}  {this.state.data.customer_name}</span><br />

                    <h6 style={{ color: 'black', display: 'inline-block' }}>Email :</h6>
                    <span>{' '}{this.state.data.customer_email}</span><br />

                    <h6 style={{ color: 'black', display: 'inline-block' }}>Phone : </h6>
                    <span>{' '}{this.state.data.customer_phone}</span>

                    <h6 style={{ color: 'black' }}>Address :</h6>
                    <p style={{ marginBottom: '0', padding: '0 20px 8px', textAlign: 'justify' }}>{this.state.data.customer_address}</p>
                    {
                        this.state.data.app_platform !== undefined ?
                            <div>
                                <h6 style={{ color: 'black', display: 'inline-block' }}>Device Platform :</h6>
                                <span>{' '}{this.state.data.app_platform}</span>
                            </div> : null
                    }
                    {
                        this.state.data.app_version !== undefined ?
                            <div>
                                <h6 style={{ color: 'black', display: 'inline-block' }}>App Version :</h6>
                                <span>{' '}{this.state.data.app_version}</span>
                            </div> : null
                    }

                    {/* {this._renderPrevRecordButton()} */}
                </CardBody>
            </Card>

        return display;
    }
    _renderServiceDetails = () => {
        let display;

        display =
            <Card style={{ height: '100%' }}>
                <CardHeader style={{ background: 'rgb(32, 32, 32)', borderRadius: '1rem 1rem 0px 0px' }} className="pb-3 text-white">
                    <h5 className="mb-0" style={{ lineHeight: '1' }}>Service Details</h5>
                </CardHeader>
                <CardBody>
                    <h6 style={{ color: 'black', display: 'inline-block' }}>Service date : </h6>
                    <span>{' '}{this.state.data.customer_day}{' '}{this.state.data.customer_month}{' '}{this.state.data.customer_year},{' '}{this.state.data.customer_time}</span>{' '}
                    <BookingRequestEditServiceTime data={this.state.data} parentFetch={this._refresh} />
                    <br />

                    <h6 style={{ color: 'black', display: 'inline-block' }}>Car Model : </h6>
                    <span>{' '}{this.state.data.customer_carmodel}</span><br />

                    <h6 style={{ color: 'black', display: 'inline-block' }}>Car Make :</h6>
                    <span>{' '}{this.state.data.customer_carmake}</span><br />

                    <h6 style={{ color: 'black', display: 'inline-block' }}>Car Year :</h6>
                    <span>{' '}{this.state.data.customer_caryear}</span><br />

                    <h6 style={{ color: 'black', display: 'inline-block' }}>Car CC :</h6>
                    <span>{' '}{this.state.data.customer_carcc}</span><br />

                    <h6 style={{ color: 'black', display: 'inline-block' }}>Transmission :</h6>
                    <span>{' '}{this.state.data.customer_cartransmission}</span><br />

                    <h6 style={{ color: 'black', display: 'inline-block' }}>Plate Number :</h6>
                    <span>{' '}{this.state.data.customer_carplate} {this._renderPrevRecordButton()}</span><br />

                    <h6 style={{ color: 'black' }}>Extra Notes :</h6>
                    <p style={{ marginBottom: '0', padding: '15px', textAlign: 'justify', background: 'rgba(255,255,255,0.1)' }}>{
                        this.state.data.customer_notes !== '' ? this.state.data.customer_notes : 'No extra notes to display'
                    }</p>

                    <br />
                    <div className="text-center" style={{ marginTop: '15px' }}>
                        <div>{this.state.data.customer_vehicleID}</div>
                        <QRCode style={{ width: '250px', height: '250px', padding: '5px' }} value={this.state.data.customer_vehicleID} />
                    </div>
                </CardBody>
            </Card>

        return display;
    }
    // Rendering the Previous Record Btn
    _renderPrevRecordButton = () => {
        let display

        display = <Button color="info" className="m-0 ml-1" style={{ padding: '3px 10px', borderRadius: '25px' }} onClick={() => {
            this.toggleModal('modalPrev')
        }}>Maintenance Records</Button>

        return display
    }
    _renderPrevRecordModal = () => {
        let display

        const { bookHistory } = this.state
        let item

        if (Object.keys(bookHistory).length !== 0 && bookHistory.book !== "") {

            let FILTERED = bookHistory.book.filter(book => {
                if (!book.book_history.quotation) {
                    return
                }
                return (book.book_history.checklist)
            })
            let LAST_FIVE = FILTERED.slice(0, 5)

            if (LAST_FIVE.length !== 0) {
                item = LAST_FIVE.map((book, index) => {
                    return (
                        <div key={book.book_history._requestID} style={{ borderBottom: index !== LAST_FIVE.length - 1 ? '1px solid rgba(0,0,0,0.3)' : null }} className="pt-3 pl-3 pr-3">
                            <div className
                                ="mb-2">
                                <div className="d-flex align-items-center justify-content-start">
                                    <h5 className="m-0 mr-2 font-weight-bold">{book.book_history._requestID}</h5>
                                    <Badge pill color="info">{book.book_history.type === "walkin" ? "Drive In" : "Service On The Go"}</Badge>
                                </div>
                                <small>Booking made on {new Date(parseInt(book.book_history.timestamp)).toLocaleString('en-MY')}</small>
                            </div>

                            <BookingRequestChecklist data={book.book_history} noStatus={true} />
                        </div>
                    )
                })
            } else {
                item = <div className="text-center" style={{ padding: '65px 25px' }}>No maintenance records to show.<br />This is the first time this user is using the SERV App to make a service booking!</div>
            }
        } else {
            item = <div className="text-center" style={{ padding: '65px 25px' }}>No maintenance records to show.<br />This is the first time this user is using the SERV App to make a service booking!</div>
        }

        display =
            <Modal isOpen={this.state.modalPrev} toggle={() => this.toggleModal('modalPrev')} className='modal-dialog-centered'>
                <ModalHeader toggle={() => this.toggleModal('modalPrev')}>Previous Maintenance Record</ModalHeader>
                <ModalBody className="p-0">
                    {item}
                </ModalBody>
                <ModalFooter>
                    <Button className='m-0 ml-auto' color='danger' onClick={() => this.toggleModal('modalPrev')}>Close</Button>
                </ModalFooter>
            </Modal>

        return display
    }
    render() {
        if (this.state.load === '1') {
            return (
                <Card body>
                    <div className="loader"></div>
                    <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
                </Card>
            )
        }
        return (
            <>
                <div className="d-flex justify-content-between align-items-center position-relative mb-3">
                    <div>
                        <Button color="dark" onClick={this._back} style={{ margin: '0', display: 'inline-flex', alignItems: 'center' }}>
                            <i style={{ marginRight: '3px', lineHeight: '1' }} className="now-ui-icons arrows-1_minimal-left"></i>
                            <div>Back</div>
                        </Button>{' '}
                        <Button style={{ margin: '0', display: 'inline-flex', alignItems: 'center' }} color="dark" onClick={this._refresh}>
                            <i style={{ marginRight: '3px', lineHeight: '1' }} className="now-ui-icons arrows-1_refresh-69"></i>
                            <div>Refresh</div>
                        </Button>
                    </div>

                    {this.state.data.receipt_payment_url === undefined ? <Button color="danger" className="m-0" onClick={this._delete}>Delete</Button> : null}
                    {/* <Button color="danger" className="m-0" onClick={this._delete}>Delete</Button> */}
                </div>

                <BookingRequestStatus booking_id={this.props.id} status={this.state.status} parentRefresh={this._refresh} SP_ID={this.state.data.assign._id} upah_price={this.state.data.upah.total_price} />

                <Row className="mb-4">
                    <Col>
                        {this._renderCustomerDetails()}
                    </Col>
                    <Col>
                        {this._renderServiceDetails()}
                    </Col>
                </Row>

                <BookingRequestQuotation data={this.state.data} booking_id={this.props.id} parentRefresh={this._refresh} bookHistory={this.state.bookHistory} />

                <BookingRequestWage data={this.state.data} booking_id={this.props.id} parentRefresh={this._refresh} />

                <BookingRequestAssignSP data={this.state.data} booking_id={this.props.id} parentRefresh={this._refresh} />

                <BookingRequestChecklist data={this.state.data} booking_id={this.props.id} parentRefresh={this._refresh} />

                {this._renderPrevRecordModal()}
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />

            </>
        )
    }
}
BookingRequestSelected = inject('mobx_config')(observer(BookingRequestSelected));
