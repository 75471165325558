import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Input, Button, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap'
import classnames from 'classnames';

import CollapsibleCard from 'components/CollapsibleCard'
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'
import { CONFIG } from 'configs/config.js'
import firebase from 'firebase';
import BookingRequestChecklist from './BookingRequestChecklist'
import ErrorAlert from 'components/ErrorAlert';
import { inject, observer } from 'mobx-react';

const MASTER_LIST = ['isAC', 'isAirFilter', 'isBattery', 'isBrakePad', 'isGearOil', 'isLightBulb', 'isSparkPlug', 'isTyrePump', 'isWiper', 'basic_premium']

/**
 * BOOKINGREQUESTQUOTATIONBENCHMARK
 */
export class BookingRequestQuotationBenchmark extends Component {
    static propTypes = {
        bookHistory: PropTypes.object
    }
    state = {
        toggleService: "1",
        serviceTab: "1",
        modalQuote: false,
    }
    toggleTab = (tab) => {
        if (this.state.serviceTab !== tab) {
            this.setState({
                serviceTab: tab
            });
        }
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    };
    _renderSOTG = () => {
        let display;
        const { bookHistory } = this.props
        let SOTG_LATEST = bookHistory.prevSOTG
        let TEMP_SOTG = []
        let REQUESTED_DATE_SOTG

        if (bookHistory !== undefined) {

            // For SOTG Quote Price
            if (SOTG_LATEST !== undefined) {
                let REQUESTED = SOTG_LATEST.book_history.quotation.requested_services
                let MASTER_ITEMS = SOTG_LATEST.book_history.quotation.masterItems

                for (const services in REQUESTED) {
                    let index = MASTER_LIST.indexOf(services)
                    let item = MASTER_ITEMS[index]

                    if (item !== undefined) {
                        let a =
                            <tr key={index}>
                                <td colSpan="2">{item.item}</td>
                                <td colSpan="1">{item.brand !== "" ? item.brand : 'No description available'}</td>
                                <td colSpan="1">{item.price}</td>
                            </tr>

                        if (index !== 9 && REQUESTED[services] === "Yes") {
                            TEMP_SOTG.push(a)
                        }

                        if (index === 9) {
                            TEMP_SOTG.unshift(a)
                        }
                    }
                }
                REQUESTED_DATE_SOTG = new Date(parseInt(SOTG_LATEST.book_history.timestamp)).toLocaleDateString('en-MY')
            }
            display =
                <div style={{ fontSize: "0.9rem" }}>
                    {SOTG_LATEST ?
                        <div>
                            <h5 className="m-0 font-weight-bold">{SOTG_LATEST.book_history._requestID}</h5>
                            <small className="d-block">Requested On: {REQUESTED_DATE_SOTG}</small>
                            <small className="mb-2 d-block">Serviced On: {new Date(SOTG_LATEST.book_history.checklist.timestamp).toLocaleString('en-MY')}</small>
                        </div> :
                        <div className="text-center" style={{ padding: '65px 25px' }}>This vehicle has never performed any On The Go services prior to this booking.</div>
                    }

                    {SOTG_LATEST ? <Table className='text-left' style={{ fontSize: "0.9rem" }} responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th scope='col' colSpan="2">Item</th>
                                <th scope='col' colSpan="1">Brand / Description</th>
                                <th scope='col' colSpan="1">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {TEMP_SOTG}
                            <tr>
                                <td colSpan="3" className="text-right">Total</td>
                                <td colSpan="2">{SOTG_LATEST.book_history.quotation.total_price}</td>
                            </tr>
                        </tbody>
                    </Table> : null}

                </div>
        }

        return display;
    }
    _renderDriveIn = () => {
        let display

        const { bookHistory } = this.props
        let DRIVE_IN_LATEST = bookHistory.prevDRIVE
        let TEMP_DRIVE = []
        let REQUESTED_DATE_DRIVE

        if (bookHistory !== undefined) {

            // For DRIVE IN Quote Price
            if (DRIVE_IN_LATEST !== undefined) {
                let MASTER_ITEMS = DRIVE_IN_LATEST.book_history.quotation.masterItems
                TEMP_DRIVE = MASTER_ITEMS.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td colSpan="2">{item.item}</td>
                            <td colSpan="3">{item.description}</td>
                            <td colSpan="1">{item.quantity}</td>
                            <td colSpan="1">{item.unit_cost}</td>
                            <td colSpan="1">{item.price}</td>
                        </tr>
                    )
                })
                REQUESTED_DATE_DRIVE = new Date(parseInt(DRIVE_IN_LATEST.book_history.timestamp)).toLocaleDateString()
            }

            display =
                <div style={{ fontSize: "0.9rem" }}>
                    {DRIVE_IN_LATEST ?
                        <div className="d-flex flex-row">
                            <p style={{ margin: "0px", padding: "5px 10px 0px 10px" }}>Requested On: {REQUESTED_DATE_DRIVE}</p>

                            {DRIVE_IN_LATEST ? <p className="ml-auto" style={{ padding: "5px 10px 0px 10px", margin: "0px" }}>Request ID: {DRIVE_IN_LATEST.book_history._requestID}</p> : null}
                        </div> :
                        <div className="text-center" style={{ padding: '65px 25px' }}>This vehicle has never performed any Drive In services prior to this booking.</div>
                    }

                    {DRIVE_IN_LATEST ? <Table className='text-left' style={{ fontSize: "0.8rem" }} responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th scope='col' colSpan="2">Item</th>
                                <th scope='col' colSpan="3">Description</th>
                                <th scope='col' colSpan="1">Quantity</th>
                                <th scope='col' colSpan="1">Unit Cost</th>
                                <th scope='col' colSpan="1">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {TEMP_DRIVE}
                            <tr>
                                <td colSpan="7" style={{ paddingBottom: "0px" }} className="text-right">Tax</td>
                                <td colSpan="2" style={{ paddingBottom: "0px" }}>{DRIVE_IN_LATEST.book_history.quotation.tax}</td>
                            </tr>
                            <tr>
                                <td colSpan="7" className="text-right" style={{ border: "none", paddingTop: "0px" }}>Total</td>
                                <td colSpan="2" style={{ border: "none", paddingTop: "0px" }}>{DRIVE_IN_LATEST.book_history.quotation.total_price}</td>
                            </tr>
                        </tbody>
                    </Table> : null}

                </div>
        }

        return display
    }
    _renderContent = () => {
        let display

        display =
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.serviceTab === '1' })} onClick={() => { this.toggleTab('1'); }} >
                            Service On The Go
                    </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.serviceTab === '2' })} onClick={() => { this.toggleTab('2'); }} >
                            Drive In
                    </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.serviceTab} className="p-0 pt-1">
                    <TabPane tabId="1">
                        {this._renderSOTG()}
                    </TabPane>
                    <TabPane tabId="2">
                        {this._renderDriveIn()}
                    </TabPane>
                </TabContent>
            </div>

        return display
    }
    render() {
        return (
            <>
                <Button color="dark" className="m-0 mr-2" onClick={() => this.toggleModal('modalQuote')}>Previous Quotation</Button>

                <Modal isOpen={this.state.modalQuote} toggle={() => this.toggleModal('modalQuote')} className='modal-dialog-centered'>
                    <ModalHeader toggle={() => this.toggleModal('modalQuote')}>Previous Quotation Price</ModalHeader>
                    <ModalBody>
                        {this._renderContent()}
                    </ModalBody>
                    <ModalFooter>
                        <Button className='m-0 ml-auto' color='secondary' onClick={() => this.toggleModal('modalQuote')}>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

/**
 * BOOKINGREQUESTQUOTATION
 */
export default class BookingRequestQuotation extends Component {
    static propTypes = {
        data: PropTypes.object,
        booking_id: PropTypes.string,
        parentRefresh: PropTypes.func,
        bookHistory: PropTypes.object
    }
    state = {
        quotation: false,
        masterItems: [],
        load: false,
        collapse: true,
        buttonDisplay: true,
        modalPrev: false,
        toggleService: "1",
        toggle: false,
        success: false,
        reload: false
    }
    handleChange = (evt) => {
        let PREV = Array.from(this.state.masterItems)
        let STRING = evt.target.name.split('_')
        let INDEX = STRING[0], KEY = STRING[1]
        PREV[INDEX][KEY] = evt.target.value

        this.setState({ masterItems: PREV });
    }
    componentDidMount() {
        this.checkQuotation()
    }

    checkQuotation = () => {
        // CHECKS IF A QUOTATION HAS BEEN SUBMITTED OR NOT
        let DATA = this.props.data, TEMP = [], SERVICE_TYPE = {}
        const { data } = this.props
        let REQUESTED_SERVICES = data.quotation.requested_services

        if (DATA.quotation.masterItems !== undefined) {
            this.setState({ quotation: true, masterItems: DATA.quotation.masterItems, load: true })
        } else {
            for (const service in REQUESTED_SERVICES) {
                if (service === 'basic_premium') {
                    let a = {
                        item: REQUESTED_SERVICES[service],
                        brand: '',
                        price: 0
                    }
                    SERVICE_TYPE = a
                } else {
                    let a = {
                        item: this.getServiceName(service),
                        brand: '',
                        price: 0
                    }
                    TEMP.push(a)
                }
            }

            // PUSHING THE SERVICE TYPE TO THE END OF THE ARRAY
            TEMP.push(SERVICE_TYPE)
            this.setState({ masterItems: TEMP, load: true })
        }
    }
    getServiceName = (service) => {
        // GO THROUGH EACH REQUESTED SERVICE AND EXTRACT THE SERVICE NAME
        const regex = /[A-Z][a-z]+|[0-9]+/g;
        const found = service.match(regex);
        let STRING

        if (found !== null) {
            STRING = found.join(" ")
        } else {
            if (service === 'isAC') {
                STRING = 'AC / Cabin Filter'
            }
        }

        return STRING
    }
    calculateTotalPrice = () => {
        let TOTAL = 0

        this.state.masterItems.forEach(item => {
            let PRICE = item.price !== "" ? item.price : 0

            TOTAL = TOTAL + parseFloat(PRICE)
        });

        return TOTAL
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })


    _submitQuotation = async () => {
        // 1. Calculate total price
        // 2. Update masterItems
        // 3. run CF; i) updateQuotationStatus and ii) updatePaymentToUnpaid
        let state = this.state
        let csrfTokenU, csrfSecretU, headerToken

        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (state.masterItems[9].price === 0) {
            // alert('Price for Basic/Premium/Inspection item cannot be empty!')

            return
        }
        let r = window.confirm("ARE YOU SURE YOU MEANT TO SUBMIT THAT QUOTATION?\n\nQuotation can only be submitted once for Service On The Go Requests. Click on cancel if you still need to fill in the form.");
        if (!r) {
            return;
        }

        let TOTAL_PRICE = this.calculateTotalPrice()

        let DATA = {
            masterItems: this.state.masterItems,
            total_price: TOTAL_PRICE
        }

        // RUNNING CLOUD FUNCTIONS THAT SEND NOTIFICATION OF THE UPDATED QUOTATION STATUS AND UPDATES THE PAYMENT STATUS TO UNPAID ON USER SIDE
        let updatequoteURL, updatePaymentURL;
        let userkey = 'userkey=' + this.props.data.customer_FUID
        let carkey = '&carkey=' + this.props.data.customer_vehicleID
        let boost;

        if (this.props.data.app_platform === "Boost") {
            boost = '&boost=' + 'true'
        }
        if (this.props.data.app_platform !== "Boost") {
            boost = '&boost=' + 'false'
        }

        if (CONFIG === 0) {
            updatequoteURL = serv_user_development.updateQuotationStatus

            updatePaymentURL = serv_user_development.updatePaymentToUnpaid
        }
        if (CONFIG === 1) {
            updatequoteURL = serv_user_production.updateQuotationStatus

            updatePaymentURL = serv_user_production.updatePaymentToUnpaid
        }

        let FINAL_updatePaymentURL = updatePaymentURL + userkey + carkey
        let FINAL_updatequoteURL = updatequoteURL + userkey + carkey + boost
        let OPTIONS = { credentials: 'include', headers: headerToken }

        const response = await fetch(FINAL_updatequoteURL, OPTIONS).then((response) => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            response.json()
        })
        const response1 = await fetch(FINAL_updatePaymentURL, OPTIONS).then((response) => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            response.json()
        })

        if (response !== 401 && response1 !== 401) {
            firebase.database().ref('request/' + this.props.booking_id + '/quotation').update(DATA).then(async () => {
                alert('SUCCESSFULLY SENT QUOTATION!\nQuotation can only be sent once.')
                this.props.parentRefresh()
            })
        }
    }
    _renderTableContents = () => {
        let display, TEMP = []
        const { data } = this.props
        const { load } = this.state
        let REQUESTED_SERVICES = data.quotation.requested_services

        if (load === true) {
            for (const service in REQUESTED_SERVICES) {
                let index = MASTER_LIST.indexOf(service)
                let item = this.state.masterItems[index]

                let a = <tr key={index}>
                    <td width="25%">
                        {item.item}
                    </td>
                    <td>
                        <Input type='text' name={index + '_brand'} id={index + '_' + item.brand} onChange={this.handleChange} value={item.brand} disabled={this.state.quotation} />
                    </td>
                    <td width="15%">
                        <Input type='number' name={index + '_price'} id={index + '_' + item.price} onChange={this.handleChange} value={item.price} disabled={this.state.quotation} />
                    </td>
                </tr>

                if (index !== 9 && REQUESTED_SERVICES[service] === 'Yes') {
                    TEMP.push(a)
                }
                if (index === 9) {
                    TEMP.unshift(a)
                }
            }
        }

        display = TEMP

        return display
    }
    _renderButton = () => {
        let display

        if (this.state.buttonDisplay && !this.state.quotation) {
            return display = <Button color="success" className="m-0" onClick={this._submitQuotation}>Submit Quotation</Button>
        }
        if (!this.state.buttonDisplay && !this.state.quotation) {
            return display = <Button disabled color="success" className="m-0" className="d-flex align-content-center justify-content-center"><div className="mr-1">Submitting Quotation</div><div className="btnloader"></div></Button>
        }

        return display
    }

    _renderStatus = () => {
        let display
        let color = this.state.quotation ? 'text-success' : 'text-danger'
        let status = this.state.quotation ? 'Submitted' : 'Not Submitted'

        display =
            <div className="mr-3 d-flex align-items-center">
                <span className="mr-1">{status}</span>
                <i className={"fas fa-circle " + color}></i>
            </div>

        return display
    }
    _renderComponent = () => {
        const { bookHistory } = this.props
        let display

        display =
            <>
                <Table className='align-items-center' responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th scope='col'>Item</th>
                            <th scope='col'>Brand / Description</th>
                            <th scope='col'>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderTableContents()}
                        <tr >
                            <td colSpan="2" className="text-right" >TOTAL</td>
                            <td>
                                {this.calculateTotalPrice()}
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <CardFooter className="d-flex justify-content-end mt-0">
                    <BookingRequestQuotationBenchmark bookHistory={this.props.bookHistory} />
                    {this._renderButton()}
                </CardFooter>
            </>

        return display
    }
    render() {
        return (
            <>
                <CollapsibleCard title="Quotation" component={this._renderComponent} status={this._renderStatus} />
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </>
        )
    }
}
BookingRequestQuotation = inject('mobx_config')(observer(BookingRequestQuotation));

