import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { database } from 'firebase'
import firebase from 'firebase'
import workerUserAnalytics from '../workers/workerUserAnalytics.js'
import WebWorker from '../workers/workerSetup.js'
import { createHashHistory } from 'history'
import { Content, Profile } from './UserDetailDisplay'
import { Card, Row, Button, Col } from 'reactstrap'
import { inject, observer } from 'mobx-react'
const history = createHashHistory()

export default class UserDetail extends Component {
  static propTypes = {
    FUID: PropTypes.number, // get the params id from Users route component
  }

  state = {
    data: {},
    analytics: {},
    selectedVehicle: {},
    activeTab: '1',
    load: '1',
    view: 'main',
  }

  componentDidMount = () => {
    this.workerUserAnalytics = new WebWorker(workerUserAnalytics)
    this.fetchUser()
  }

  fetchUser = async () => {
    const { id: FUID } = this.props
    let DB_VAL, DB_SNAP
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    try {
      DB_SNAP = await firebase.app(COUNTRY_DB).database().ref(`users/${FUID}`).once('value')

      if (!DB_SNAP.exists()) this.back()
      if (DB_SNAP.exists()) DB_VAL = DB_SNAP.val()

      await this.workerUserAnalytics.postMessage(DB_VAL)
      await this.workerUserAnalytics.addEventListener('message', (event) => {
        this.setState({ data: DB_VAL, load: '2', analytics: event.data })
      })
    } catch (error) { console.error(error) }
  }

  /* EVENT TRIGGER FUNCTIONALITY */
  toggleTab = (tab) => (this.state.activeTab !== tab) && this.setState({ activeTab: tab })
  parentSelect = (data, view) => this.setState({ selectedVehicle: data, view: view })
  back = () => history.push('/user/Users-List/')

  /* RENDER THE COMPONENT CONTENT */
  _renderContent = () => {
    const { load, view, data, analytics, activeTab, selectedVehicle } = this.state
    if (load === '1') {
      return (
        <div>
          <Card style={{ padding: '65px 25px' }}>
            <div className='loader'></div>
          </Card>
        </div>
      )
    }
    if (load !== '1') {
      return (
        <div>
          <div className='mb-3 d-flex justify-content-between align-items-center position-relative'>
            <Button color='dark' className='m-0' onClick={this.back}>Back</Button>
          </div>
          <Row>
            <Col md={8}>
              <Card body>
                <Content toggleTab={this.toggleTab} parentSelect={this.parentSelect} selectedVehicle={selectedVehicle} activeTab={activeTab} view={view} data={data} analytics={analytics} load={load} />
              </Card>
            </Col>
            <Col md={4}>
              <Card body>
                <Profile data={data} analytics={analytics} />
              </Card>
            </Col>
          </Row>
        </div>
      )
    }
  }

  render() {
    return this._renderContent()
  }
}

UserDetail = inject('mobx_auth')(observer(UserDetail))
