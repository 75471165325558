import { observable, action, decorate } from "mobx";
//import { computed, toJS } from "mobx";
export default class Users {
  users = undefined;
  setUsers(users) {
    this.users = users;
  }
}

decorate(Users, {
  users : observable,
  setUsers : action,
});
