export default () => {
    // eslint-disable-next-line
    self.addEventListener('message', e => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;
        var DATA = e.data;
        let RESPONSE = []
        // console.log(DATA)
        
        let USERS = DATA.users
        let CALLS = DATA.calls

        for (const key in CALLS) {
            if(USERS[key] !== undefined){
                let LENGTH = Object.keys(CALLS[key]).length
                let a = USERS[key]
                a.FUID = key
                a.last_call = Object.values(CALLS[key])[LENGTH - 1].opened
                a.call_count = LENGTH

                RESPONSE.push(a)
            }
        }

        RESPONSE.sort((a,b) =>b.last_call - a.last_call)

        postMessage(RESPONSE);
    });
};