import React, { Component } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase';
import { Button, Col, Row } from 'reactstrap'
import CarListingUpdate from './CarListingUpdate';
import CarListingVariantCC from './CarListingVariantCC';
import { inject, observer } from 'mobx-react';

export class CarListingDisplay extends Component {
  static propTypes = {
    forType: PropTypes.string,      // To specify the data comes from which type from parent
    selectId: PropTypes.string,     // The id for vehicle type
    selectType: PropTypes.string,   // The selected type name
    selectedType: PropTypes.func,   // The selected type function that will trigger when user pick the vehicle type
    selectedMake: PropTypes.func,   // The selected make function that will trigger when user choose which vehicle make
    parentRefresh: PropTypes.func,  // To refetch all the data
    data: PropTypes.object,         // The main data transfer from parent
    model: PropTypes.object,        // The model data
    make: PropTypes.object          // The make data
  }

  state = { variant: [] }

  componentDidMount = () => {
    const { forType } = this.props
    if (forType === 'model') return this.fetchVariantLoad()
  }

  fetchVariantLoad = async () => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    const { model } = this.props, db = firebase.app(COUNTRY_DB).database()
    let MODEL_ID = model.key, VARIANT = [], DB_SNAP, DB_VAL

    try {
      DB_SNAP = await db.ref(`vehicle_variant/${MODEL_ID}`).once('value')
      DB_VAL = await DB_SNAP.val()

      if (!DB_SNAP.exists()) return this.setState({ variant: VARIANT })
      if (DB_SNAP.exists()) {
        for (const key in DB_VAL) {
          let a = DB_VAL[key]
          a.key = key
          VARIANT.push(a)
        }
        this.setState({ variant: VARIANT }, () => console.log('VARIANT', this.state.variant))
      }
    } catch (error) { return console.error('Error Occured', error) }
  }

  _renderMake = () => {
    const { data, selectedMake, selectType, id, parentRefresh } = this.props
    return (
      <Col md={12} style={{ padding: '10px 15px' }} onClick={() => console.log(data)}>
        <Row className="align-items-center justify-content-center">
          <Col md={1} className='text-center'>
            {data.logo !== '' ? <img src={data.logo} style={{ width: '85%', margin: '0 auto' }} /> : null}
          </Col>
          <Col md={5}>{data.make}</Col>
          <Col md={6} className='d-flex justify-content-end'>
            <Button color='light' className='m-0 mr-3' onClick={() => selectedMake(data)}>View Models</Button>
            <CarListingUpdate forType='logo' type='edit' make={data} selectedType={selectType} id={id} parentRefresh={parentRefresh} />
            <CarListingUpdate forType='make' type='edit' make={data} selectedType={selectType} id={id} parentRefresh={parentRefresh} />
          </Col>
        </Row>
      </Col>
    )
  }

  _refreshVariant = () => {
    this.fetchVariantLoad()
    this.props.parentRefresh()
  }

  _renderModel = () => {
    const { model, make, id, parentRefresh } = this.props, { variant } = this.state

    return <Row className='align-items-center mt-3'>
      <Col md={3}>{model.model}</Col>
      <Col md={6}>{variant.length}</Col>
      <Col md={3} className='text-right'>
        <CarListingUpdate forType='model' type='edit' model={model} make={make} id={id} variant={variant} parentRefresh={parentRefresh} />
        <CarListingVariantCC model={model} make={make} id={id} variant={variant} parentRefresh={this._refreshVariant} />
      </Col>
    </Row>
  }

  _renderContent = () => {
    const { forType, data, selectId, selectedType } = this.props
    let display
    if (forType === 'make') return display = this._renderMake()
    if (forType === 'model') return display = this._renderModel()
    return (
      <>
        <Col md={3} style={{ background: selectId === data.id ? 'rgba(255, 255, 255, 0.2)' : null, borderRadius: '0.1875rem', border: '1px solid rgba(255,255,255,0.2)', padding: '15px', margin: '0 5px' }} onClick={() => selectedType(data)}>{data.type}<br />{data.id}</Col>
        {display}
      </>
    )
  }

  render() {
    // console.log(this.props)
    return this._renderContent()
  }
}

CarListingDisplay = inject('mobx_auth')(observer(CarListingDisplay))