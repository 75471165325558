import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Progress } from 'reactstrap'
import firebase from 'firebase'
import { inject, observer } from 'mobx-react'

export default class MarketingBannerEdit extends Component {
    static propTypes = {
        data: PropTypes.object,
        modal: PropTypes.bool,
        banners: PropTypes.array
    }
    state = {
        modal : false, displayButt : true, data : null,
    }
    // componentDidMount() {
    //     this.setState({ data:this.props.data })
    // }
    componentDidUpdate(prevProps, prevState) {
        if(this.props.data !== null && prevProps.data === null){
            this.setState({data:this.props.data})
        }
    }
    
    handleChange = (evt) => {
        let value = evt.target.value, name = evt.target.name
        this.setState({[name] : value})
        this.setState({ data : {...this.state.data, [name] : value }})
    }
    errorHandler = () => {
        // const file = document.querySelector('#upload_banner').files[0]
        const { name, link } = this.state
        if(name === ""){
            this.setState({ toggle:true, text: <span>Please fill in the name of the campaign.</span> })
            return;
        }
        if(link === ""){
            this.setState({ toggle:true, text: <span>Please fill in the link to open the webview for the campaign.</span> })
            return;
        }
        // if(!file || !canUpload){
        //     this.setState({ toggle:true, text: <span>Please select an image to upload!</span> })
        //     return;
        // }
        this._saveBannerCampaign();
    }
    _saveBannerCampaign = () => {
        const { data } = this.state
        const { banners } = this.props
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // let blob = croppedImage, that = this
        // let progress = { text:'saving data', percentage:50 }
        
        // this.setState({ progress })
        let DATA = {
            name : data.name,
            link : data.link,
            id : data.id,
            // croppedImage : croppedImage
        }

        firebase.app(COUNTRY_DB).database().ref(`marketing_banner/${data.banner_id}`).update({
            name : data.name,
            link : data.link,
            id : data.id,
        }).then(() => {
            const index = banners.findIndex(banner => banner.banner_id === data.banner_id)

            if (index !== -1) {
                firebase.app(COUNTRY_DB).database().ref(`marketing_banner_schema/${index}`).update({
                    name : data.name,
                    link : data.link,
                    id : data.id,
                })
            }
        }).then(() => {
            this.setState({
                displayButt:true, data: null
            }, () => {
                this.props.toggleModal('modalEdit','close')
            })
        })
        // console.log(DATA);
        // let KEY = firebase.database().ref('marketing_banner/').push().getKey();
        // const storageRef = firebase.storage().ref('marketing_banner');
        // let uploadBanner = storageRef.child(KEY).put(blob);
        
        // uploadBanner.on('state_changed', (snapshot) => {
        //     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //     console.log('Upload is ' + progress + '% done');
        //     switch (snapshot.state) {
        //         case firebase.storage.TaskState.PAUSED: // or 'paused'
        //         console.log('Upload is paused');
        //         // that.setState({progressColor:'warning', text : 'PAUSED'})
        //         break;
        //         case firebase.storage.TaskState.RUNNING: // or 'running'
        //         console.log('Upload is running');
        //         // that.setState({progressColor:'success', text : 'UPLOADING'})
        //         break;
        //     }
        // }, (error) => {
        //     // IF IMAGE UPLOAD FAILED
        //     alert('Upload failed, please try again!\n', error.message);
        //     that.setState({
        //         displayButt: true, modalError:true,
        //         text: <span>Error uploading image!<br/>Check your internet connection and try again.</span>,
        //         text:'saving data', percentage:70 
        //     })
        // }, () => {
        //     // IF IMAGE UPLOAD SUCCEED
        //     uploadBanner.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //         console.log('File available at', downloadURL);
        //         firebase.database().ref(`marketing_banner/${KEY}`).update({
        //             name : name,
        //             link : link,
        //             id : id,
        //             banner_image: downloadURL,
        //             timestamp: new Date().getTime()
        //         }).then(() => {
        //             that.setState({
        //                 text:'saving data', percentage:100,
        //                 displayButt:true,
        //                 canUpload: false, previewUpload: LOGO, text: '',
        //             }, () => {
        //                 alert('Succesfully uploaded banner!')
        //                 // this.props.parentRefresh()
        //                 that.setState({
        //                     text:'', percentage:0,
        //                     displayButt:true, modal: false,
        //                     canUpload: false, previewUpload: LOGO,
        //                     name : '', link : '', compressedFile : null
        //                 })
        //             })
        //         })
        //     })
        // })
    }
    render() {
        const { data, displayButt } = this.state

        if(data === null) return null;
        else return (
            <Modal isOpen={this.props.modal} toggle={() => {this.props.toggleModal('modalEdit','close')}} className="modal-dialog-centered modal-sm">
                <ModalHeader toggle={() => {this.props.toggleModal('modalEdit', 'close')}}>Edit banner campaign</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">Campaign Name</Label>
                                <Input type="text" name="name" id="name" onChange={this.handleChange} placeholder="Name of campaign associated with the banner" value={data.name} />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for="id">Campaign ID</Label>
                                <Input type="text" name="id" id="id" onChange={this.handleChange} placeholder="ID for developers to refer to" value={data.id} />
                                <FormText color="danger">* This ID is for any hardcoded banners in the app, please check with the developers first if you want anything specific hardcoded, otherwise, you may put whatever.</FormText>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="link">Campaign Link</Label>
                        <Input type="text" name="link" id="link" onChange={this.handleChange} placeholder="Webview link" value={data.link} />
                        <FormText color="danger">* This is the link for the webview.</FormText>
                    </FormGroup>

                    {/* <FormGroup>
                        <Label>Upload Banner</Label><br />
                        <button id='upload_banner_button' onClick={this.attachFileInputToButton} className='mb-1 m-0' style={{ fontSize: '0.7rem', borderRadius: '5px', display: 'block' }}>Upload Banner</button>
                        <Input type="file" accept="image/*" name="upload_banner" id="upload_banner" style={{marginBottom:'5px'}} onChange={this.handleImage}/>
                        <FormText color="danger">* Image upload guide : Recommended file size less than 150kb. Image dimensions do not matter as long as it has an aspect ratio of 3:1!</FormText>
                    </FormGroup>
                    <Card style={{ height:'270px'}}>
                        <Cropper
                            minZoom={minZoom}
                            image={this.state.previewUpload}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.aspect}
                            restrictPosition={true}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                            zoomSpeed={0.2}
                            objectFit="contain"
                        />
                    </Card>
                    {progress.percentage !== 0 && <Progress bar animated color="success" value={progress.percentage}>{progress.text}</Progress>} */}
                </ModalBody>
                <ModalFooter>
                    {displayButt ? <Button color="dark" onClick={this.errorHandler} className="m-0">Save</Button> : <Button color="dark" className="m-0 d-flex align-items-center justify-content-center" disabled><div>Saving</div><div className="btnloader"></div></Button>}
                </ModalFooter>
            </Modal>
        )
    }
}
MarketingBannerEdit = inject('mobx_auth')(observer(MarketingBannerEdit))
