import React, { Component } from 'react';
import { Card, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import firebase from 'firebase';
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js';
import { inject, observer } from 'mobx-react'

import DisplayRequestCard from 'components/DisplayRequestCard'

import { createHashHistory } from 'history';
import ErrorAlert from '../components/ErrorAlert';
const history = createHashHistory();

class BookingRequest extends Component {
  state = {
    load: '1',
    data: [],
    type_data: {},
    delete: [],
    search: '',
    screen: 0,
    toggle: false,
    success: false,
    reload: false
  };

  updateSearch = (event) => {
    this.setState({ search: event.target.value })
  }
  componentWillUnmount() {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    firebase.app(COUNTRY_DB).database().ref('request/').off();
    firebase.app(COUNTRY_DB).database().ref('delete/').off();
  }
  componentWillMount() {
    this.fetchTypeTiers()
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.fetchRequests();
    this.fetchDeleteRequest();
  }
  resize = () => {
    this.setState({ screen: window.innerWidth });
  }
  handleSearchBar = (evt) => {
    this.setState({ search: evt.target.value });
  }
  fetchRequests() {
    let that = this;
    let COUNTRY_DB = that.props.mobx_auth.COUNTRY
    // let BASIC;
    firebase.app(COUNTRY_DB).database().ref('request/').on('value', function (snapshot) {
      let REQUEST = [];
      if (snapshot.exists()) {
        let keys = Object.keys(snapshot.val())
        keys.forEach((key) => {
          let x = snapshot.val()[key];
          x.timestampString = new Date(snapshot.val()[key].timestamp).toLocaleString();

          x.delete_key = key;
          REQUEST.unshift(x)
        })
        // console.log("REQUEST", REQUEST);
        that.setState({ data: REQUEST, load: '2' }, () => {
          // console.log(that.state.data)
        });
      } else {
        console.log('Snapshot does not exist');
        that.setState({ load: '3' })
      }
    })
  }
  fetchDeleteRequest = () => {
    let that = this;
    let COUNTRY_DB = that.props.mobx_auth.COUNTRY
    firebase.app(COUNTRY_DB).database().ref('delete/').on('value', function (snapshot) {
      let DELETE = [];
      if (snapshot.exists()) {
        let keys = Object.keys(snapshot.val())
        keys.forEach((booking_id) => {
          let x = snapshot.val()[booking_id];
          x.booking_id = booking_id

          DELETE.push(x);
        })
        that.setState({ delete: DELETE }, () => {
          // console.log(that.state.delete)
        })
      } else {
        console.log('No delete requests');
      }
    })
  }
  fetchTypeTiers = () => {
    let URL, csrfTokenU, csrfSecretU, headerToken;
    const { browser } = this.props.mobx_config
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    if (browser === 'safari') {
      csrfTokenU = sessionStorage.getItem('CSRFTokenU')
      csrfSecretU = sessionStorage.getItem('CSRFSecretU')
      headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
    }
    if (this.props.mobx_config.config === 0) {
      URL = serv_user_development.fetchUserType
    }
    if (this.props.mobx_config.config === 1) {
      URL = serv_user_production.fetchUserType
    }

    const LOCATION = `location=${COUNTRY_DB}`
    let OPTIONS = { credentials: 'include', headers: headerToken }
    const  SENT_LOCATION = URL + LOCATION
    fetch(SENT_LOCATION, OPTIONS).then(response => {
      if (response.status !== 200) {
        this.setState({ toggle: true, success: false, reload: true })
        return response.status
      }
      return response.json()
    }).then(response => {
      // console.log('type & tiers', response.data)
      if (response !== 401) {
        this.setState({ type_data: response.data })
      }
    }).catch(err => {
      console.log(err)
    })
  }
  childToggle = () => this.setState({ toggle: false }, () => {
    if (this.state.reload === true) {
      sessionStorage.removeItem('CT')
      document.location.reload()
    }
  })
  _renderContent = () => {
    let display, { load } = this.state, PAYMENT, ASSIGNMENT;
    let DATA = this.state.data;
    let filteredData = DATA.filter(
      (data) => {
        return data._requestID.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
          data.customer_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
          data.customer_carplate.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
          data.customer_carmake.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
          data.customer_phone.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
      }
    )

    if (load === '1') {
      display = <Card body><div className="loader"></div></Card>
    }
    if (load === '2') {
      display = filteredData.map((data, index) => (
        <DisplayRequestCard key={index} data={data} business={this.state.type_data}
          deleteData={
            this.state.delete.findIndex(i => i.booking_id === data.delete_key) !== -1 ?
              this.state.delete[this.state.delete.findIndex(i => i.booking_id === data.delete_key)] : null
          }
        />
      ))
    }
    if (load === '3') {
      display = <Card style={{ padding: '45px 25px', textAlign: 'center' }}>There are no requests</Card>
    }

    return display;
  }

  render() {
    return (
      <div>
        <div className="d-flex align-items-center justify-content-between position-relative">
          <InputGroup className="no-border" style={{ width: this.state.screen < 768 ? '100%' : '50%' }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ background: 'rgba(255,255,255,1)' }}>
                <i className="now-ui-icons ui-1_zoom-bold" />
              </InputGroupText>
            </InputGroupAddon>
            <Input style={{ background: 'rgba(255,255,255,1)' }} placeholder="Search by Name, Car make or Plate no." onChange={this.handleSearchBar} />
          </InputGroup>
        </div>

        {this._renderContent()}
        <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
      </div>
    );
  }
}
BookingRequest = inject('mobx_config', 'mobx_auth')(observer(BookingRequest));
export default BookingRequest;