import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, CardFooter, Button, Collapse, CardBody } from 'reactstrap'

export default class CollapsibleCard extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        component: PropTypes.func.isRequired,
        status: PropTypes.func,
        headerSneak: PropTypes.func,
        collapse: PropTypes.bool,
    }
    state = { collapse: this.props.collapse !== undefined ? this.props.collapse : false }
    toggleCollapse = () => {
        this.setState({ collapse: !this.state.collapse })
    }
    render() {
        let props = this.props
        return (
            <Card>
                <CardHeader onClick={this.toggleCollapse} style={{ background: 'rgb(32, 32, 32)', borderRadius: this.state.collapse || props.headerSneak !== undefined ? '1rem 1rem 0px 0px' : '1rem', ...this.props.style }} className={this.props.className ? this.props.className : "pb-3"}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-0 font-weight-bold text-white">{props.title}</h5>
                        <div className="d-flex align-items-center text-white">
                            {props.status !== undefined ? props.status() : null}

                            {/* {this.state.collapse ? <div className="d-flex align-items-center">Collapse<i className="fas fa-chevron-right ml-1"/></div> : <div className="d-flex align-items-center">Expand<i className="fas fa-chevron-down ml-1"/></div>} */}
                            {this.state.collapse ? <i className="fas fa-chevron-down ml-1" /> : <i className="fas fa-chevron-right ml-1" />}
                        </div>
                    </div>
                </CardHeader>
                {props.headerSneak !== undefined ?
                    <CardBody>
                        {props.headerSneak()}
                    </CardBody>
                    : null}

                <Collapse isOpen={this.state.collapse}>
                    {props.component()}
                </Collapse>
            </Card>
        )
    }
}
