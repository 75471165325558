import BookingRequest from "pages/BookingRequest.jsx";
import Dashboard from "pages/Dashboard.jsx";
import SPApprove from "pages/SPApprove.jsx";
import BookingHistory from "pages/BookingHistory.jsx";
import Promotions from "pages/Promotions.jsx";
import Announcements from "pages/Announcements.jsx";
import UserListing from "pages/ListingBusiness.jsx";
import ServProvider from "pages/ServiceProvider.jsx";
import Workshop from "pages/Merchant.jsx";
import WorkshopTurkey from "pages/MerchantTurkey.jsx";
import Transactions from "pages/Transactions.jsx";
import TransactionsTurkey from "../pages/TransactionsTurkey";
import Analytics from "pages/AnalyticsRoutes";
import Wip from "pages/Wip.jsx";
import RoadAssistance from "pages/AnalyticsRoadAssistance";
import BugReport from "pages/BugReport";
import FeatureSuggestion from "pages/FeatureSuggestion";
import ListingWorkshopAmenitites from "pages/ListingWorkshopAmenitites";
import BusinessListing from "pages/BusinessListing";
import CarListing from "pages/CarListing";
import ServiceListing from "pages/ServiceListing";
import TireListing from "pages/TireListing";
import VehicleVerification from "pages/VehicleVerification";
import Marketplace from "pages/Marketplace";
import Users from "pages/Users";
import IAM from "pages/IAM";
import MarketingBanner from "pages/MarketingBanner";
import MarketingPopup from "pages/MarketingPopup";
import MarketingCategory from "pages/MarketingCategory";

var dashRoutes = [
  {
    // 0
    path: '/user/dashboard', name: 'Dashboard', icon: 'tachometer-alt', component: Dashboard, roles: ["Super", "DL", "Marketing"], country: ["MY", "TR"],
  },

  {
    path: '/user/Users', name: 'SERV App', icon: 'users', id:'users', collapse: true, roles: ["Super"], country: ["MY"],
    pages: [
      {
        path: '/user/Users-List', name: 'Users', component: Users, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Users-Ownership-Verification', name: 'Ownership Verification', component: VehicleVerification, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Users-Primo-Rewards-Verification', name: 'Primo Rewards', component: Wip, roles: ["Super"], country: ["MY"]
      },
    ]
  },

  {
    // 1
    path: '/user/Partner', name: 'SERV Partner', icon: 'handshake', component: ServProvider, id:'partner', collapse: true, roles: ["Super"], country: ["MY"],
    pages: [
      {
        path: '/user/Partner-Provider', name: 'Service Provider', component: ServProvider, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Partner-Approve', name: 'SP Approve', component: SPApprove, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Partner-Supplier', name: 'Suppliers', component: Wip, roles: ["Super"], country: ["MY"]
      },
    ]
  },

  {
    // 1
    path: '/user/Booking', name: 'Bookings', icon: 'wrench', component: Wip, id:'booking', collapse: true, roles: ["Super"], country: ["MY"],
    pages: [
      {
        path: '/user/Booking-Stats', name: 'Statistics', component: Wip, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Booking-Request', name: 'Booking Request', component: BookingRequest, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Booking-History', name: 'Booking History', component: BookingHistory, roles: ["Super"], country: ["MY"]
      },
    ]
  },

  {
    // 2
    path: '/user/Marketing', name: 'Marketing', icon: 'bullhorn', component: Wip, id:'marketing', collapse: true, roles: ["Super", "Marketing"], country: ["MY"],
    pages: [
      {
        path: '/user/Marketing-Category', name: 'Category', component: MarketingCategory, roles: ["Super", "Marketing"], country: ["MY"]
      },
      {
        path: '/user/Marketing-Banner', name: 'Banner', component: MarketingBanner, roles: ["Super", "Marketing"], country: ["MY"]
      },
      {
        path: '/user/Marketing-Popup', name: 'Pop up', component: MarketingPopup, roles: ["Super", "Marketing"], country: ["MY"]
      },
      {
        path: '/user/Marketing-Promo', name: 'Promotions', component: Promotions, roles: ["Super", "Marketing"], country: ["MY"]
      },
      {
        path: '/user/Marketing-Newsletter', name: 'Newsletter', component: Wip, roles: ["Super", "Marketing"], country: ["MY"]
      },
      {
        path: '/user/Marketing-Announcement', name: 'Announcements', component: Announcements, roles: ["Super", "Marketing"], country: ["MY"]
      },
    ]
  },
  {
    path: '/user/Marketplace', name: 'Marketplace', icon: 'shopping-basket', component: Marketplace, roles: ["Super"], country: ["MY"],
  },
  {
    // 3
    path: '/user/Merchant', name: 'Merchants', icon: 'cogs', component: Wip, id:'merchant', collapse: true, roles: ["Super"], country: ["MY", "TR"],
    pages: [
      {
        path: '/user/Merchant-Malaysia', name: 'Malaysia', component: Workshop, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Merchant-Turkey', name: 'Turkey', component: WorkshopTurkey, roles: ["Super"], country: ["MY", "TR"]
      },
      {
        path: '/user/Transactions-Malaysia', name: 'Transaction (Malaysia)', component: Transactions, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Transactions-Turkey', name: 'Transaction (Turkey)', component: TransactionsTurkey, roles: ["Super"], country: ["MY", "TR"]
      }
    ]
  },

  {
    // 4
    path: '/user/Listing', name: 'Listing', icon: 'list', component: Wip, id:'listing', collapse: true, roles: ["Super"],country: ["MY"],
    pages: [
      {
        path: "/user/Listing-Business", name: "Business Listing", component: BusinessListing, roles: ["Super"], country: ["MY"]
      },
      {
        path: "/user/Listing-Car", name: "Car Listing", component: CarListing, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Listing-User', name: 'User Listing', component: Wip, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Listing-Service', name: 'Service Listing', component: ServiceListing, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Listing-Tire', name: 'Tire Listing', component: TireListing, roles: ["Super"], country: ["MY"]
      },
      {
        path: "/user/Listing-Workshop-Amenities", name: "Workshop Amenities Listing", component: ListingWorkshopAmenitites, roles: ["Super"], country: ["MY"]
      },
    ]
  },
  {
    // 4
    path: '/user/Analytics', name: 'Analytics', icon: 'chart-area', component: RoadAssistance, id:'analytics', collapse: true, roles: ["Super"],country: ["MY"],
    pages: [
      {
        path: '/user/Analytics-Assistance', name: 'Road Assistance', component: RoadAssistance, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Analytics-DirectLending', name: 'Direct Lending', component: Dashboard, roles: ["Super"], country: ["MY"]
      },
    ]
  },

  {
    // 5
    path: '/user/IAM', name: 'Access Management', icon: 'id-card', component: IAM, roles: ["Super"], country: ["MY"],
  },
  {
    // 6
    path: '/user/Feedback', name: 'User Feedback', icon: 'comment', component: BugReport, id:'feedback', collapse: true, roles: ["Super"], country: ["MY"],
    pages: [
      {
        path: '/user/Feedback-Bug', name: 'Bug Reports', component: BugReport, roles: ["Super"], country: ["MY"]
      },
      {
        path: '/user/Feature-Suggestion', name: 'Feature Suggestions', component: FeatureSuggestion, roles: ["Super"], country: ["MY"]
      },
    ]
  },

  { redirect: true, path: '/', pathTo: '/user/dashboard', name: 'Dashboard' }
];

export default dashRoutes;