import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';

class ServiceListingDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            disabled: true,
            data: this.props.data,
        };
    }
    _renderContent = () => {
        let display;
        const { edit } = this.state;
        const { data } = this.props;
        let displayButt = edit === false ?
            <Button className="m-0" color="light" onClick={() => {
                console.log('EDIT', data)
                this.setState({ edit: true, disabled: false, data: this.props.data })
            }}>Edit</Button> :
            <Button className="m-0" color="success" onClick={() => {
                console.log('SAVE', data)
                this.setState({ edit: false, disabled: true })
            }}>Save</Button>

        let displayTop =
            <div className="d-flex justify-content-between align-items-center">
                {displayButt}

                <div>
                    <Button color="danger">Delete</Button>
                </div>
            </div>

        let displayLink = this.state.data.link !== '' ?
            <FormGroup row className="align-items-center">
                <Col md={2}>
                    Link :
            </Col>
                <Col>
                    <Input className="form-single" disabled={this.state.disabled} type='text' name='link' id='link' value={this.state.data.link} onChange={this.handleChange} />
                </Col>
            </FormGroup> : null;

        display =
            <div>
                {displayTop}

                <div className="mt-3">
                    <FormGroup row className="align-items-center">
                        <Col md={2}>
                            Tile Title :
                    </Col>
                        <Col>
                            <Input className="form-single" disabled={this.state.disabled} type='text' name='title' id='title' value={this.state.data.title} onChange={this.handleChange} />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="align-items-center">
                        <Col md={2}>
                            Type :
                    </Col>
                        <Col>
                            <Input className="form-single" disabled={this.state.disabled} type='select' name='type' id='type' value={this.state.data.type} onChange={this.handleChange}>
                                <option value="webview">Webview</option>
                                <option value="hardcoded">Hardcoded</option>
                            </Input>
                        </Col>
                    </FormGroup>

                    {displayLink}
                </div>
            </div>

        return display;
    }
    render() {

        return (
            <div>
                {this._renderContent()}
            </div>
        );
    }
}

ServiceListingDisplay.propTypes = {
    data: PropTypes.object,
    parentRefresh: PropTypes.func,
    parentUnselect: PropTypes.func,
};

export default ServiceListingDisplay;