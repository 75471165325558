import React, { Component } from 'react'

import { Button, Card } from 'reactstrap'
import firebase from 'firebase';

import PanelHeader from 'components/PanelHeader.jsx';

import MarketingCategoryManagement from 'pages/MarketingCategoryManagement.jsx'
import MarketingCategoryCard from 'pages/MarketingCategoryCard'
import { inject, observer } from 'mobx-react';

export default class MarketingCategory extends Component {
   state = {
      load: '1',   
      collapseCategory: false,   // toggle card for category
      
      categoriesStore: [],    // stores all categories
      sectionsStore: []       // stores all sections in each category
   }

   componentDidMount = () => this.fetchCategories()

   /* fetch all categories from db */
   fetchCategories = () => {
      let storeCategory = []
      let COUNTRY_DB = this.props.mobx_auth.COUNTRY
      firebase.app(COUNTRY_DB).database().ref('marketing_categories/').once('value').then(snapshot => {
         if(snapshot.exists()){
            let categoriesData = snapshot.val()

            for (const categoryID in categoriesData) {
               const currentCategory = categoriesData[categoryID];
               
               currentCategory.category_id = categoryID
               storeCategory.push(currentCategory)
            }

            this.setState({ categoriesStore:storeCategory, load:'1' })
            this.fetchSections()
         }
         else {
            this.setState({ load:'3' })
         }
      })
   }

   /* fetch all sections data in each category from db */
   fetchSections = () => {
      const { categoriesStore } = this.state
      let tempStore = []

      let COUNTRY_DB = this.props.mobx_auth.COUNTRY
      categoriesStore.forEach(category => {
         firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${category.category_id}`).once('value').then(snapshot => {
            if(snapshot.exists()){
               let categoriesData = snapshot.val()
               let storeSections = []

               for (const sectionID in categoriesData) {
                  const currentSection = categoriesData[sectionID];
                  
                  currentSection.section_id = sectionID
                  storeSections.push(currentSection)
               }

               category.sections = storeSections
               console.log("category", category)
            }
         })

         
         tempStore.push(category)
      })
      
      this.setState({ sectionsStore:tempStore, load:'2' })
   }

   /* refresh updated data */
   handleRefreshPage = () => {
      this.setState({ load:'1' })
      
      this.fetchCategories()
   }

   /* displays list of categories */
   _renderCategoryCollapse = () => {
      const { sectionsStore } = this.state

      return sectionsStore.map((data, index) => (
         <MarketingCategoryCard key={index} data={data} fetchCategory={this.handleRefreshPage} />
      ))
   }

   /* displays refresh and add category buttons */
   _renderHeader = () => {
      return (
         <Card body className='d-flex justify-content-between flex-row align-items-center'>
            <MarketingCategoryManagement type='add' fetchCategory={this.fetchCategories} />
            
            <div>
               <Button 
                  className='mt-0 mb-0' onClick={() => this.handleRefreshPage()}  color='light' 
                  style={{ borderRadius:'50%', padding:'8px 10px', fontWeight:'bold' }}>
               <i className='now-ui-icons arrows-1_refresh-69' style={{ fontSize: '20px', fontWeight: 'bold' }} />
               </Button>
            </div>
         </Card>
      )
   }

   /* renders load page checker */
   _renderLoadContent = () => {
      const { load } = this.state
      let display

      switch (load) {
         case '1': display = <Card body><div className='loader'></div></Card>
            break;
         case '2': display = this._renderCategoryCollapse()
            break;
         case '3': display = <Card className='text-center p-5' body><div>No categories added.<br />Click on 'Add Category' to add a new category.</div> </Card>
            break;
      }

      return display
   }
   
   render() {
      return(
         <div>
            <PanelHeader size='sm' />

            <div className='content'>
               {this._renderHeader()}

               {this._renderLoadContent()}
            </div>
         </div>
      )
   }
}
MarketingCategory = inject('mobx_auth')(observer(MarketingCategory))