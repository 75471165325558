import React, { Component } from 'react';
import PanelHeader from '../components/PanelHeader'
import { Card, Row, Col } from 'reactstrap';
import firebase from 'firebase';
import ServiceListingDisplay from './ServiceListingDisplay';
import ServiceListingUpdate from './ServiceListingUpdate'
import { inject, observer } from 'mobx-react';


class ServiceListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load: '1',
            data: [],
            select: {},
        };
       
    }
    
    componentDidMount() {
        this.fetchTiles();
    }
    fetchTiles = () => {
        const COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // console.log("COUNTRY ",COUNTRY_DB)
        firebase.app(COUNTRY_DB).database().ref('service_tiles/').once('value').then((snapshot) => {
            if (snapshot.exists()) {
                let DATA = []
                for (const tile_id in snapshot.val()) {
                    let a = snapshot.val()[tile_id];
                    a.id = tile_id;
                    DATA.push(a)
                }

                this.setState({ load: '2', data: DATA })
            } else {
                this.setState({ load: '3' })
            }
        })
    }
    refresh = () => {
        this.fetchTiles();
    }
    _unselect = () => {
        this.setState({ select: {} })
    }
    _renderSelectedTile = () => {
        let display;

        if (this.state.select.title !== undefined) {
            display = <ServiceListingDisplay data={this.state.select} parentRefresh={this.refresh} parentUnselect={this._unselect} />
        }
        if (this.state.select.title === undefined) {
            display = <div className="text-center" style={{ padding: '65px 25px' }}>Select Service Tile on the left.</div>
        }

        return display;
    }
    _renderServiceTiles = () => {
        let display;

        if (this.state.load === '1') {
            display =
                <div style={{ padding: '65px 25px' }}>
                    <div className="loader"></div>
                </div>
        }
        if (this.state.load === '2') {
            let tiles = this.state.data !== undefined && this.state.data.length !== 0 ?
                this.state.data.map((data, index) => (
                    <div className="p-3"
                        style={{ border: '1px solid rgba(255,255,255,0.3)', borderRadius: '10px', marginBottom: this.state.data.length !== index ? '15px' : null, marginTop: index !== 0 ? '15px' : null }} key={index}
                        onClick={() => {
                            console.log(data)
                            this.setState({ select: data })
                        }}
                    >
                        {data.title}<br />{data.id}
                    </div>
                )) : <div className="mt-3">No Service Tiles to display.</div>

            display =
                <div className="custom-scrollbar pr-2 mt-4" style={{ position: 'relative', overflowY: 'scroll', maxHeight: '300px' }}>
                    {tiles}
                </div>
        }
        if (this.state.load === '3') {
            display =
                <div style={{ padding: '65px 25px' }}>
                    No Service Tiles to display.
            </div>
        }

        return display;
    }
    render() {
        return (
            <div>
                <PanelHeader size="sm" />
                <div className='content'>
                    <Row>
                        <Col md={3}>
                            <Card body>
                                <ServiceListingUpdate />

                                {this._renderServiceTiles()}
                            </Card>
                        </Col>
                        <Col md={9}>
                            <Card body>
                                {this._renderSelectedTile()}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

ServiceListing = inject('mobx_auth')(observer(ServiceListing))
export default ServiceListing