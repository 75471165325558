import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, Collapse, Button, Row, Col, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import PLACEHOLDER from 'assets/img/logo-placeholder.png'
import VERIFIED from 'assets/img/verified.svg'
import VerifyWorkshop from 'pages/MerchantVerify.jsx';
import PreferredWorkshop from 'pages/MerchantPreferred'

import { createHashHistory } from 'history';
const history = createHashHistory();

/**
 * DISPLAY BANK
 */
// export class DisplayBankTurkey extends Component {
//     static propTypes = {
//         bank: PropTypes.array,
//         workshop: PropTypes.object,
//     }
//     state = { modalView : false }
    
//     toggleModal = state => {
//         this.setState({ [state]: !this.state[state] });
//     };
//     render() {
//         let name, acc_no, bank, id
//         let primaryIndex = 0
//         if (this.props.bank instanceof Array && this.props.bank[primaryIndex] !== undefined){
//             this.props.bank.map((bank, index) => {
//                 if (bank.primary === true) primaryIndex = index 
//             })
//         }
        
//         name = this.props.bank[primaryIndex] && this.props.bank[primaryIndex].name? this.props.bank[primaryIndex].name : this.props.bank.name
//         id = this.props.bank[primaryIndex] && this.props.bank[primaryIndex].id? this.props.bank[primaryIndex].id : this.props.bank.id
//         acc_no = this.props.bank[primaryIndex] && this.props.bank[primaryIndex].acc_no? this.props.bank[primaryIndex].acc_no : this.props.bank.acc_no
//         bank = this.props.bank[primaryIndex] && this.props.bank[primaryIndex].bank? this.props.bank[primaryIndex].bank : this.props.bank.bank
//         return (
//             <>
//                 <Button style={{fontSize:'0.65rem', padding:'5px 10px'}} size="sm" color="light" className="m-0 mt-auto mb-auto ml-2" onClick={() => this.toggleModal('modalView')}>Bank Details</Button>

//                 <Modal isOpen={this.state.modalView} toggle={() => this.toggleModal('modalView')} className='modal-dialog-centered'>
//                     <ModalHeader toggle={() => this.toggleModal('modalView')}>Bank Details</ModalHeader>
//                     <ModalBody>
//                         <Table className='align-items-center' dark bordered responsive>
//                             {/* <thead className='thead-light'>
//                                 <tr>
//                                     <th>Title</th>
//                                     <th>Data</th>
//                                 </tr>
//                             </thead> */}
//                             <tbody>
//                                 <tr>
//                                     <th width="40%" style={{whiteSpace:'pre-wrap'}}>Merchant Name :</th>
//                                     <td>{this.props.workshop.name}</td>
//                                 </tr>          
//                                 <tr>
//                                     <th width="40%" style={{whiteSpace:'pre-wrap'}}>Account Holder Name :</th>
//                                     <td>{name}</td>
//                                 </tr>
//                                 <tr>
//                                     <th width="40%" style={{whiteSpace:'pre-wrap'}}>Account Holder IC or Company Registration :</th>
//                                     <td>{id}</td>
//                                 </tr>
//                                 <tr>
//                                     <th width="40%" style={{whiteSpace:'pre-wrap'}}>Account Number :</th>
//                                     <td>{acc_no}</td>
//                                 </tr>
//                                 <tr>
//                                     <th width="40%" style={{whiteSpace:'pre-wrap'}}>Bank : </th>
//                                     <td>{bank}</td>
//                                 </tr>
//                             </tbody>
//                         </Table>
//                     </ModalBody>
//                     <ModalFooter>
//                         {/* <Button color='primary'>Save changes</Button> */}
//                         <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalView')}>Close</Button>
//                     </ModalFooter>
//                 </Modal>
//             </>
//         )
//     }
// }


/**
 * MAIN COMPONENT
 */
export default class DisplayWorkshopTurkey extends Component {
    static propTypes = {
        data: PropTypes.object,
        parentFetch: PropTypes.func,
    }
    state = { isOpen:false }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }
    _view = () => {
        history.push('/user/Merchant-Turkey/' + this.props.data.group_id)
        // history.push('/user/Merchant-Listing-Turkey/' + this.props.data.group_id)
    }
    render() {
        const { data } = this.props
        // let preferredLogo = <i className="fas fa-solid fa-check-circle mr-2" style={{ fontSize:'19px', color:'#1e1950' }} />
        let preferredLogo = <Badge pill className='mr-1' style={{ background:'#1e1950' }}>Preferred</Badge>

        return (
            <Card>
                <CardBody
                    className="d-flex align-items-center justify-content-between"
                    style={{ background: 'rgb(30, 25, 80)', borderRadius:'0.9rem 0.9rem 0 0', color:'white' }}
                >
                    <div>
                        <img src={data.logo !== '' ? data.logo : PLACEHOLDER} style={{width:'30px', marginRight:'15px', borderRadius: data.logo !== '' ? '0' : '50%'}} />
                        {data.name}
                    </div>

                    <div>
                        {/* <Button color="danger" className="m-0 mr-2" style={{borderRadius:'10px', padding:'5px 10px',textAlign:'center', fontWeight:'bold'}}>Delete</Button> */}
                        <Button color="light" className="m-0" style={{borderRadius:'10px', padding:'5px 10px',textAlign:'center', fontWeight:'bold'}} onClick={this._view}>View</Button>
                    </div>
                </CardBody>
                <CardBody onClick={this.toggle}
                    style={{borderBottom:this.state.isOpen === false ? 'none' : '1px solid'}}
                >
                    <div className="mb-3">
                        HQ Address: {data.address}<br/>
                        HQ Phone Number: {data.phone}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <Badge pill color="info">{data.OUTLETS.length} outlets</Badge>
                        {console.log("hahahha", data.OUTLETS.length)}
                        {
                           
                            this.state.isOpen === false ?
                            <i className='fas fa-chevron-right'/> :
                            <i className='fas fa-chevron-down'/>
                        }
                    </div>
                </CardBody>
                <Collapse isOpen={this.state.isOpen} style={{padding:'10px 15px 15px'}}>
                    <h5 className="m-0 mb-2" style={{lineHeight:'1'}}>Outlets</h5>
                    <Row>
                        {data.OUTLETS!== undefined ? data.OUTLETS.map((workshop,index) => (
                            <Col key={index} md={4}>
                                <Card body className="d-flex flex-column justify-content-between align-items-start" style={{boxShadow:'none', border:'1px solid', height:'calc(100% - 15px)',}}>
                                    <div className="m-0 mb-2 " style={{width:'100%'}}>
                                        {workshop.display !== undefined && workshop.display === '3' ?
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {workshop.preferred !== undefined && workshop.preferred === true ? preferredLogo : null}
                                                    <img src={VERIFIED} style={{width:'20px', marginRight:'10px'}} />
                                                </div>

                                                <VerifyWorkshop id={this.props.data.group_id} data={workshop} parentRefresh={this.props.parentFetch} />
                                            </div> :
                                            workshop.display !== '3' && workshop.display === '1' ?
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {workshop.preferred !== undefined && workshop.preferred === true ? preferredLogo : null}
                                                    <Badge pill color="danger" style={{marginRight:'10px'}}>Unlisted</Badge>
                                                </div>

                                                <VerifyWorkshop id={this.props.data.group_id} data={workshop} parentRefresh={this.props.parentFetch} />
                                            </div> :
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {workshop.preferred !== undefined && workshop.preferred === true ? preferredLogo : null}
                                                    <Badge pill color="info" style={{marginRight:'10px'}}>Unverified</Badge>
                                                </div>
                                                <VerifyWorkshop id={this.props.data.group_id} data={workshop} parentRefresh={this.props.parentFetch} />
                                            </div>
                                        }
                                    </div>

                                    <div className="mb-4">
                                        <div>Name : {workshop.name}</div>
                                        <div>Address : {workshop.address}</div>
                                        <div>Phone : {workshop.phone}</div>
                                    </div>

                                    {workshop.referral_programme ? 
                                        <div className="mb-4" style={{width:'100%'}}> 
                                            <hr />
                                            <h6>Referral Programme</h6>
                                            <div>Referral Workshop : {workshop.referral_programme.referral_workshop}</div>
                                            <div>Referral Contact : {workshop.referral_programme.referral_phone}</div>
                                        </div>
                                    : null}

                                    <div className='d-flex justify-content-between align-items-center' style={{ width:'100%' }}>
                                        <Badge color="warning">{workshop.role}</Badge>

                                        <div className='d-flex justify-content-end'>
                                            <PreferredWorkshop  preferred={workshop.preferred} retailID={this.props.data.group_id} workshop={workshop} parentRefresh={this.props.parentFetch} />

                                            {/* {workshop.bank ?
                                                <DisplayBank bank={workshop.bank} workshop={workshop} /> : null
                                            } */}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        )) : null}
                    </Row>
                </Collapse>
            </Card>
        )
    }
}
