export default () => {
	self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
		if (!e) return;

		let DATA = e.data
        
        // console.log('worker thread', DATA)
        let MONTH_LIST = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let thirty = [3, 5, 8, 10]
        let thirtyone = [0, 2, 4, 6, 7, 9, 11]

        let label = []
        let value = []

        let TODAY = new Date()
        let MONTH = TODAY.getMonth()
        let YEAR = TODAY.getFullYear()

        let ANALYTICS = {}
        let STRING = ''

        for (let i = 0; i < 11; i++) {
            if(MONTH < 0){
            MONTH = 12 + MONTH
            YEAR = YEAR - 1
            }

            STRING = MONTH_LIST[MONTH] + ' ' + YEAR

            if(thirty.indexOf(MONTH) !== -1){
            label = new Array(30).fill(0)
            value = new Array(30).fill(0)
            }
            if(thirtyone.indexOf(MONTH) !== -1){
                label = new Array(31).fill(0)
                value = new Array(31).fill(0)
            }
            if(YEAR % 4 === 0 && thirty.indexOf(MONTH) === -1 && 
            thirtyone.indexOf(MONTH) === -1){
                label = new Array(29).fill(0)
                value = new Array(29).fill(0)
            }
            if(YEAR % 4 !== 0 && thirty.indexOf(MONTH) === -1 && 
            thirtyone.indexOf(MONTH) === -1){
                label = new Array(28).fill(0)
                value = new Array(28).fill(0)
            }

            ANALYTICS[STRING] = {
                labels :  label,
                values :  value,
                counts : 0
            }

            for (let i = 0; i < ANALYTICS[STRING].labels.length; i++) {
                ANALYTICS[STRING].labels[i] = i + 1
            }

            MONTH = MONTH - 1
        }

        // console.log(ANALYTICS[STRING]);

        for (const booking_id in DATA) {
            let CURRENT = DATA[booking_id]
            let CURRENT_DATE = new Date(CURRENT.timestamp)
            let DATE = CURRENT_DATE.getDate()
            let CURRENT_MONTH = CURRENT_DATE.getMonth()
            let CURRENT_YEAR = CURRENT_DATE.getFullYear()
            let CURRENT_STRING = MONTH_LIST[CURRENT_MONTH] + ' ' + CURRENT_YEAR

            if(ANALYTICS[CURRENT_STRING] !== undefined){
                let INDEX = ANALYTICS[CURRENT_STRING].labels.indexOf(DATE)

                if(INDEX !== -1){
                    ANALYTICS[CURRENT_STRING].values[INDEX] = ANALYTICS[CURRENT_STRING].values[INDEX] + 1
                    ANALYTICS[CURRENT_STRING].counts = ANALYTICS[CURRENT_STRING].counts + 1
                }
            }
        }

        console.log(ANALYTICS)

		postMessage(ANALYTICS); 
	}) 
}
