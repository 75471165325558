import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import firebase from 'firebase';
import { inject, observer,  } from 'mobx-react';
// import { observe } from 'mobx';

export default class VehicleVerificationDisplayUserEmail extends Component {
    static propTypes = {
        toggleModal: PropTypes.func,
        modalDisplay: PropTypes.bool,
        FUID: PropTypes.string,
    }
    state = { data : {} }
    componentWillUnmount() {
        console.log('UNMOUNTED');
        this.setState({data : {}})
    }
    componentDidMount = async () => {
        const { FUID } = this.props
        console.log('MOUNTED', FUID);
        let user_branch = FUID.length === 20 ? 'users_non_serv' : 'users'
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let SNAPSHOT = await firebase.app(COUNTRY_DB).database().ref(`${user_branch}/${FUID}`).once('value')
        if(SNAPSHOT.exists()) this.setState({data : SNAPSHOT.val()})
    }
    
    
    render() {
        const { data } = this.state
        return (
            <Modal isOpen={this.props.modalDisplay} toggle={() => this.props.toggleModal('modalDisplay')} className='modal-dialog-centered'>
                <ModalHeader toggle={() => this.props.toggleModal('modalDisplay')}>User contact details</ModalHeader>
                <ModalBody>
                    <div>Name : {data.name}</div>
                    <div>Phone : {data.Phone}</div>
                    <div>Email : {data.email}</div>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color='primary'>Save changes</Button> */}
                    <Button className='m-0 ml-auto' color='link' onClick={() => this.props.toggleModal('modalDisplay')}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}


VehicleVerificationDisplayUserEmail = inject('mobx_auth')(observer(VehicleVerificationDisplayUserEmail))