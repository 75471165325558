import React, { Component } from 'react'
import { Card, Row, Col, CardBody, CardHeader, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Table, } from "reactstrap";
import DownloadLink from "react-download-link";
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'

import { inject, observer } from 'mobx-react';
import AddAdmin from 'components/AddAdmin';
import PrintMerchantQRCode from 'components/PrintMerchantQRCode';
import VerifyWorkshop from 'pages/MerchantVerify';
import firebase from 'firebase';
import RETAIL from 'static_data/retail.json'
import RETAIL_MAIN from 'static_data/retail_main.json'

import STAMP_PLACEHOLDER from 'assets/img/car.svg'
import PLACEHOLDER from 'assets/img/logo-placeholder.png'
import BANNER_PLACEHOLDER from 'assets/img/bg5.jpg'
import VERIFIED from 'assets/img/verified.svg'
import UNVERIFIED from 'assets/img/unverified.svg'

import { createHashHistory } from 'history';
import ErrorAlert from 'components/ErrorAlert';

const history = createHashHistory();

/* BTN LOADER HELPER FUNCTION - NO NEED TO CHANGE AND INTERACT DIRECTLY WITH ELEMENT IN CLASS */
const btnLoader = (place, id) => {
    const currentBtn = document.getElementById(id)
    const btnView = currentBtn.firstChild
    const btnViewing = btnView.nextElementSibling
    const btnLoader = btnViewing.nextElementSibling

    if (place === 'start') {
        currentBtn.setAttribute('disabled', true)
        btnView.classList.add('d-none')
        btnViewing.classList.add('mr-2')
        btnViewing.classList.remove('d-none')
        btnLoader.classList.remove('d-none')
    }
    if (place === 'end') {
        currentBtn.removeAttribute('disabled')
        btnView.classList.remove('d-none')
        btnViewing.classList.add('d-none')
        btnLoader.classList.add('d-none')
    }

    /* MUST HAVE THE HTML ELEMENT BELOW TO WORK PERFECTLY */
    // <Button id={group_id} color="danger" className="d-flex align-content-center justify-content-center ml-4" style={{ opacity: '100%' }} onClick={() => this.deleteMerchant(group_id)}>
    //     <div className="">Remove Merchant</div>
    //     <div className="d-none">Removing...</div>
    //     <div className="btnloader d-none"></div>
    // </Button>
}

export default class WorkshopIndividual extends Component {
    state = {
        data: {},
        load: '1',
        outletAdmin: [],
        screen: 0,
        modalMDR: false,
        modalAdmin: false,
        modalAdminSelect: '',
        mdr: 1.5,
        displayButt: true,
        toggle: false,
        success: false,
        reload: false,
        text: ''
    }
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
        this.fetchWorkshopIndividual()
        // this.fetchStatic()
    }
    resize = () => {
        this.setState({ screen: window.innerWidth });
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    };
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
        if (this.state.success) {
            return this._back()
        }
    })

    
    // fetchWorkshopIndividual = () => {
    //     let URL, csrfTokenU, csrfSecretU, headerToken;
    //     const { browser } = this.props.mobx_config

    //     if (browser === 'safari') {
    //         csrfTokenU = sessionStorage.getItem('CSRFTokenU')
    //         csrfSecretU = sessionStorage.getItem('CSRFSecretU')
    //         headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
    //     }

    //     if (this.props.mobx_config.config === 0) {
    //         URL = serv_user_development.workshopFetchIndividual
    //     }
    //     if (this.props.mobx_config.config === 1) {
    //         URL = serv_user_production.workshopFetchIndividual
    //     }
    //     let GROUP = 'group=' + this.props.id
    //     let FINAL_URL = URL + GROUP
    //     let OPTIONS = { credentials: 'include', headers: headerToken }

    //     // console.log(GROUP)

    //     fetch(FINAL_URL, OPTIONS).then(response => {
    //         if (response.status !== 200) {
    //             this.setState({ toggle: true, success: false, reload: true })
    //             return response.status
    //         }
    //         return response.json()
    //     }).then(response => {
    //         if (response !== 401) {
    //             let WORKSHOPS = []
    //             let retail = response.retail
    //             let retail_main = response.retail_main
    //             // console.log(response)
    //             // console.log(response.retail_main)

    //             if (response.retail_main !== null && response.retail !== null) {
    //                 let DATA = retail_main
    //                 console.log("RETAIL MAIN", DATA);
    //                 DATA.group_id = this.props.id
                    
    
    //              console.log("this.props.id", this.props.id)
    //                 // 1. iterate through each outlet
    //                 for (const retail_id in retail) {
    //                     let a = retail[retail_id]
    //                     a.retail_id = retail_id

    //                     WORKSHOPS.push(a)
    //                     // console.log("workshop", WORKSHOPS)
    //                 }

    //                 DATA.OUTLETS = WORKSHOPS
    //                 // console.log("DATA", DATA)
    //                 this.setState({ load: '2', data: DATA })
    //             } else {
    //                 this.setState({ load: '3' })
    //             }
    //         }
    //     })
    // }
   
    
    
    
    fetchWorkshopIndividual =  () => {
        let retail_main = this.props.id
        firebase.app('MY').database().ref(`retail_main/`).once('value', (snap) => {
                if(snap.exists()) {
                    let db_rtm = Object.keys(snap.val())
                    db_rtm.forEach((item) => {
                        if(retail_main === item){
                          let DATA  = snap.val()[item]
                          DATA.group_id = item
                          let WORKSHOP = []
                          firebase.app("MY").database().ref(`retail/`).once('value',(snpt) => {
                              if(snpt.exists()) {
                                let db_rt = snpt.val()
                              
                                for(const retail in db_rt[retail_main]){
                                    let DATA_RETAIL = db_rt[retail_main][retail]
                                    DATA_RETAIL.retail_id = retail
                                    WORKSHOP.push(DATA_RETAIL)
                                }  
                                // console.log("workshop", WORKSHOP)
                                DATA.OUTLETS = WORKSHOP
                                this.setState({load:'2', data:DATA}) 
                                // console.log("retail main merchant selected", this.state.data) 
                              }
                             
                          })
                         
                        }  
                      
                    })
                } else {
                    this.setState({load:'3'})    
                }
                
            })
    }



    // fetchWorkshopAdmin = async ({ retail_admin, retail_id, master_admin }, roles) => {
    //     console.log("retail main", retail_admin)
    //     console.log("master admin", master_admin)
    //     const { config } = this.props.mobx_config
    //     const { browser } = this.props.mobx_config

    //     let URL, csrfTokenU, csrfSecretU, headerToken;

    //     if (browser === 'safari') {
    //         csrfTokenU = sessionStorage.getItem('CSRFTokenU')
    //         csrfSecretU = sessionStorage.getItem('CSRFSecretU')
    //         headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
    //     }

    //     if (config === 0) URL = serv_user_development.workshopFetchAdmin
    //     if (config === 1) URL = serv_user_production.workshopFetchAdmin

    //     if (roles === 'outlets') {
    //         const ADMIN_ARRAY = []
    //         for (const key in retail_admin) {
               
    //             const FINAL_URL = `${URL}admin_id=${key}`
    //             const OPTIONS = { credentials: 'include', headers: headerToken }
    
    //             const res = await fetch(FINAL_URL, OPTIONS).then(res => res.json())
    //             res.data.retail_admin = key
    //             ADMIN_ARRAY.push(res.data)
    //             console.log("keyyyy", ADMIN_ARRAY)
    //         }
           
    //         await this.setState({ outletAdmin: ADMIN_ARRAY })
    //         console.log("outletAdmin", this.state.outletAdmin)
    //     }
    //     if (roles === 'master') {
    //         const MASTER_ADMIN_ARRAY = []
    //         for (const key in master_admin) {

    //             const FINAL_URL = `${URL}admin_id=${key}`
    //             const OPTIONS = { credentials: 'include', headers: headerToken }

    //             const res = await fetch(FINAL_URL, OPTIONS).then(res => res.json())
    //             res.data.retail_admin = key
    //             MASTER_ADMIN_ARRAY.push(res.data)
    //         }
    //         await this.setState({ masterAdmin: MASTER_ADMIN_ARRAY })
    //         console.log("MASTER ADMIN", this.state.masterAdmin)
    //     }
    // }
 
    fetchWorkshopAdmin  = async ({ retail_admin, retail_id, master_admin },roles) => {
        const ADMIN_ARRAY = [],  MASTER_ADMIN_ARRAY = []
        // roles for "master"
        // if(roles === 'master') {
        //     let KEY_MASTER = Object.keys(master_admin)

        //     for (const item of KEY_MASTER) {
        //         const DB_RETAIL_ADMIN = await firebase.app('MY').database().ref(`retail_admin/${item}`).once('value')

        //         if(DB_RETAIL_ADMIN.exists()) {
        //             let db = DB_RETAIL_ADMIN.val()
        //             db.retail_admin = item
        //             MASTER_ADMIN_ARRAY.push(db)
        //         } 
        //         await this.setState({masterAdmin:MASTER_ADMIN_ARRAY})
               
        //     }
        // }

        if(roles === 'outlets') {
            let KEY_OUTLET = Object.keys(retail_admin)

            for (const item of KEY_OUTLET) {
                const DB_RETAIL_ADMIN = await firebase.app('MY').database().ref(`retail_admin/${item}`).once('value')

                if(DB_RETAIL_ADMIN.exists()) {
                    let dbOT = DB_RETAIL_ADMIN.val()
                    dbOT.retail_admin = item
                    ADMIN_ARRAY.push(dbOT)
                } 
                await this.setState({outletAdmin:ADMIN_ARRAY})
               
            }
        }
   
    }




    viewWorkshopAdmin = async ({ retail_id, group_id, ...data }, roles) => {
        const btnTarget = retail_id || group_id
        btnLoader('start', btnTarget)
        await this.fetchWorkshopAdmin(data, roles)
        await this.setState({ modalAdminSelect: roles })
        await this.toggleModal('modalAdmin')
        btnLoader('end', btnTarget)
    }


    //  // Remove single outlet
    // deleteOutlet = async (outlet_id) => {
    //     const { group_id, OUTLETS } = this.state.data
    //     const { config } = this.props.mobx_config
    //     const { retail_admin, role, name } = OUTLETS.filter(outlet => outlet.retail_id === outlet_id)[0]
    //     btnLoader('start', outlet_id)

    //     let URL, csrfTokenU, csrfSecretU, headerToken;
    //     const { browser } = this.props.mobx_config

    //     if (browser === 'safari') {
    //         csrfTokenU = sessionStorage.getItem('CSRFTokenU')
    //         csrfSecretU = sessionStorage.getItem('CSRFSecretU')
    //         headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
    //     }
    //     if (config === 0) URL = serv_user_development.workshopRemoveIndividual
    //     if (config === 1) URL = serv_user_production.workshopRemoveIndividual

    //     if (retail_admin && Object.keys(retail_admin).length !== 0) return this.setState({ text: 'This is outlet has admin, cannot remove.', toggle: true })
    //     if (role === 'HQ') return this.setState({ text: 'This is HQ outlet, cannot remove.', toggle: true })

    //     if (role === 'Outlet') {
    //         const confirmed = window.confirm('Are you sure to remove this outlet?')

    //         if (confirmed) {
    //             const FINAL_URL = `${URL}group=${group_id}&retail=${outlet_id}&role=${role}`
    //             const OPTIONS = { credentials: 'include', headers: headerToken }
    //             await fetch(FINAL_URL, OPTIONS).then((response) => response.json())

    //             btnLoader('end', outlet_id)
    //             this.setState({ text: `Outlet ${name} has been removed.`, toggle: true })
    //             this.fetchWorkshopIndividual()
    //         }
    //         if (!confirmed) {
    //             btnLoader('end', outlet_id)
    //         }
    //     }
    // }


    // Remove single outlet
    deleteOutlet = async (outlet_id) => {
        console.log("outlet id", outlet_id)
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        const {group_id, OUTLETS} = this.state.data
        console.log("group id", group_id)
        const { retail_admin, role, name } = OUTLETS.filter(outlet => outlet.retail_id === outlet_id)[0]
        // console.log("retail admin, role, name, group_id, OUTLETS")
        // console.log(retail_admin, role, name, group_id, OUTLETS)
        btnLoader('start', group_id)
       
        if (retail_admin && Object.keys(retail_admin).length !== 0) return this.setState({ text: 'This is outlet has admin, cannot remove.', toggle: true })
        if (role === 'HQ') return this.setState({ text: 'This is HQ outlet, cannot remove.', toggle: true })
        if (role === 'Outlet') {
            const confirmed = window.confirm('Are you sure to remove this outlet?')
            if(confirmed) {
                if ((role).toLowerCase() === 'outlet') {
                    const archiveOutlet = await firebase.app('MY').database().ref(`history_walkin/${group_id}/${outlet_id}`).once('value').then((snapshot) => snapshot.exists() && snapshot.val())
                    await (archiveOutlet && firebase.app('MY').database().ref(`request_walkin_archive/${group_id}/${outlet_id}`).update(archiveOutlet))
          
                    await (archiveOutlet && firebase.app('MY').database().ref(`history_walkin/${group_id}/${outlet_id}`).remove())
                    await firebase.app('MY').database().ref(`retail/${group_id}/${outlet_id}`).remove()
                    await firebase.app('MY').database().ref(`retail_main/${group_id}/outlets/${outlet_id}`).remove()
                    await this.setState({ text:"Outlet Removed", toggle: true, success: true })
                }
               
                btnLoader('end', group_id)
        }
        if (!confirmed) {
            btnLoader('end', group_id)
        }
        }
     

    }

    // // Remove all outlet with the HQ
    // deleteMerchant = async (group_id) => {
    //         // console.log("group id ", group_id)
    //         const { OUTLETS } = this.state.data
    //         const { config } = this.props.mobx_config
    //         const { retail_id } = OUTLETS.filter(outlet => outlet.role === 'HQ')[0]
    //         const checkAdminForEachOutlet = OUTLETS.filter(outlet => outlet.retail_admin !== undefined)
    //         btnLoader('start', group_id)
    
    //         let URL, csrfTokenU, csrfSecretU, headerToken;
    //         const { browser } = this.props.mobx_config
    
    //         if (browser === 'safari') {
    //             csrfTokenU = sessionStorage.getItem('CSRFTokenU')
    //             csrfSecretU = sessionStorage.getItem('CSRFSecretU')
    //             headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
    //         }
    
    //         if (config === 0) URL = serv_user_development.workshopRemoveIndividual
    //         if (config === 1) URL = serv_user_production.workshopRemoveIndividual
    
    //         if (checkAdminForEachOutlet.length === 0) {
    //             const confirmed = window.confirm('By removing Merchant, you\'ll remove all the outlets include HQ?')
    
    //             if (confirmed) {
    //                 const FINAL_URL = `${URL}group=${group_id}&retail=${retail_id}&role=hq`
    //                 const OPTIONS = { credentials: 'include', headers: headerToken }
    //                 const response = await fetch(FINAL_URL, OPTIONS).then((response) => response.json())
    
    //                 btnLoader('end', group_id)
    //                 await this.setState({ text: response.message, toggle: true, success: true })
    //             }
    //             if (!confirmed) {
    //                 btnLoader('end', group_id)
    //             }
    //         }
    // }


    // Remove all outlet with the HQ
    deleteMerchant = async (group_id) => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        const {OUTLETS} = this.state.data
        const { role } = OUTLETS.filter(outlet => outlet.role === 'HQ')[0]
        const checkAdminForEachOutlet = OUTLETS.filter(outlet => outlet.retail_admin !== undefined)
       
        btnLoader('start', group_id)
        const confirmed = window.confirm('By removing Merchant, you\'ll remove all the outlets include HQ?')
        
        if(confirmed) {
            if(checkAdminForEachOutlet.length === 0) {
                for(const item of OUTLETS) {
                    if ((role).toLowerCase() === 'hq') {
                        console.log("retail id", item.retail_id)
                        const archiveOutlet = await firebase.app('MY').database().ref(`history_walkin/${group_id}/${item.retail_id}`).once('value').then((snapshot) => snapshot.exists() && snapshot.val())
                        await (archiveOutlet && firebase.app('MY').database().ref(`request_walkin_archive/${group_id}/${item.retail_id}`).update(archiveOutlet))
                
                        await (archiveOutlet && firebase.app('MY').database().ref(`history_walkin/${group_id}`).remove())
                        await firebase.app('MY').database().ref(`retail/${group_id}`).remove()
                        await firebase.app('MY').database().ref(`retail_main/${group_id}`).remove()
                        await this.setState({ text:"Outlet and HQ Removed", toggle: true, success: true })
                       
                    }
                    
                    
                }
                btnLoader('end', group_id)
               
            }
           
        }
        if (!confirmed) {
            btnLoader('end', group_id)
        }
    }



    //done add location 
    deleteAdmin = async ({ retail_admin: admin_id, name, retail_id, retail_main }) => {
        const { modalAdminSelect } = this.state
        const confirmed = window.confirm(`Are you sure to remove this admin?\nThis workshop admin (${name}) cannot login to the system after being removed.`)
        const { config } = this.props.mobx_config
        btnLoader('start', admin_id)

        let URL, csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (config === 0) URL = serv_user_development.workshopRemoveAdmin
        if (config === 1) URL = serv_user_production.workshopRemoveAdmin

        let LOCATION = this.props.mobx_auth.COUNTRY
        if (confirmed) {
            const FINAL_URL = `${URL}admin_id=${admin_id}&retail_main=${retail_main}&retail_id=${retail_id}&role=${modalAdminSelect}&=location${LOCATION}`
            const OPTIONS = { credentials: 'include', headers: headerToken }
            const response = await fetch(FINAL_URL, OPTIONS).then((res) => res.json())

            btnLoader('end', admin_id)
            await this.setState({ text: response.message, toggle: true })
            this.toggleModal('modalAdmin')
            this.fetchWorkshopIndividual()
        }
        if (!confirmed) {
            btnLoader('end', admin_id)
            this.toggleModal('modalAdmin')
            this.fetchWorkshopIndividual()
        }
    }

    fetchStatic = () => {
        let WORKSHOPS = []
        let retail = RETAIL
        let retail_main = RETAIL_MAIN

        let DATA = retail_main[this.props.id]
        DATA.group_id = this.props.id
        // 1. iterate through each outlet
        for (const retail_id in retail[this.props.id]) {
            let a = retail[this.props.id][retail_id]
            a.retail_id = retail_id

            WORKSHOPS.push(a)
        }

        DATA.OUTLETS = WORKSHOPS

        this.setState({ load: '2', data: DATA }, () => { console.log(this.state.data) })
    }

    _back = () => {
        history.push('/user/Merchant-Malaysia/')
    }
    _saveMDR = () => {
        let retail_main = this.props.id
        let mdr = this.state.mdr
        // console.log(this.props, this.state);
        console.log(retail_main, mdr,);

        let URL, csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopMDR
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopMDR
        }
        let RETAIL_MAIN = 'retail_main=' + retail_main
        let MDR = '&mdr=' + mdr
        let FINAL = URL + RETAIL_MAIN + MDR
        let OPTIONS = { credentials: 'include', headers: headerToken }

        console.log(FINAL);

        fetch(FINAL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response.message === 'OK' && response !== 401) {
                this.setState({ displayButt: true, mdr: 1.5 }, () => {
                    this.fetchWorkshopIndividual()
                    this.toggleModal('modalMDR')
                })
            }
        })
    }
    _renderInfo = () => {
        let display
        const { data } = this.state
        // console.log("data render info", data.master_admin)
        display =
            <Card>
                <CardHeader>
                    <h5 className="m-0 font-weight-bold">Retail Info</h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={2} style={{ display: this.state.screen <= 425 ? 'none' : null }}>
                            <img src={data.logo !== '' ? data.logo : PLACEHOLDER} style={{ width: '100%' }} />
                        </Col>
                        <Col md={10}>
                            <div>
                                <div className="font-weight-bold" style={{ fontSize: '1.2em' }}>{data.name}</div>
                                <small>{data.address}</small>



                                <div>
                                    MDR % {data.mdr !== undefined ? data.mdr : 1.5} <Button size="sm" color="dark" onClick={() => {
                                        this.setState({ mdr: this.state.data.mdr !== undefined ? Number(this.state.data.mdr) : 1.5 }, () => {
                                            this.toggleModal('modalMDR')
                                        })
                                    }}>Edit</Button>
                                </div>

                            {!!data.master_admin ?
                                <div className='d-flex flex-row align-items-center'>
                                    <div className='mr-2 mt-0 pt-0'>{Object.keys(data.master_admin).length} Master admin(s)</div>

                                    <Button id={data.group_id} color="light" size="sm" className="d-flex align-content-center justify-content-center m-0" style={{ fontSize: '0.7rem', padding: '5px 10px' }} onClick={() => this.viewWorkshopAdmin(data, 'master')}>
                                        <div className="">View</div>
                                        <div className="d-none">Viewing...</div>
                                        <div className="btnloader d-none"></div>
                                    </Button>
                                </div> : <div>No Master Admin</div>
                            }


                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        return display
    }
    _renderRate = (rate, justify_content) => {
        let display
        let RATE = rate

        if (RATE === 0) {
            display =
                <div className={"d-flex align-items-center " + justify_content}>
                    <i
                        className="fas fa-star mr-2"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                    <i
                        className="fas fa-star mr-2"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                    <i
                        className="fas fa-star mr-2"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                    <i
                        className="fas fa-star mr-2"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                    <i
                        className="fas fa-star"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                </div>

            return display
        }

        display =
            <div className={"d-flex align-items-center " + justify_content}>
                <i
                    className={RATE < 0 || RATE >= 1 ? "fas fa-star mr-2" : "fas fa-star-half mr-2"}
                    style={{ color: RATE === 1 || RATE > 0 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
                <i
                    className={RATE < 1 || RATE >= 2 ? "fas fa-star mr-2" : "fas fa-star-half mr-2"}
                    style={{ color: RATE === 2 || RATE > 1 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
                <i
                    className={RATE < 2 || RATE >= 3 ? "fas fa-star mr-2" : "fas fa-star-half mr-2"}
                    style={{ color: RATE === 3 || RATE > 2 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
                <i
                    className={RATE < 3 || RATE >= 4 ? "fas fa-star mr-2" : "fas fa-star-half mr-2"}
                    style={{ color: RATE === 4 || RATE > 3 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
                <i
                    className={RATE < 4 || RATE >= 5 ? "fas fa-star" : "fas fa-star-half"}
                    style={{ color: RATE === 5 || RATE > 4 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
            </div>

        return display
    }
    _renderLoyalty = () => {
        let display
        const { data } = this.state

        if (this.state.data.loyalty !== undefined && this.state.loyalty !== '') {
            let STAMPS = []

            for (let i = 1; i <= data.loyalty.max; i++) {
                let a = i
                STAMPS.push(a)
            }

            display =
                <Card>
                    <CardHeader
                        style={{
                            background: `linear-gradient( rgba(255,255,255, 0.2),  rgba(255,255,255, 0.2) ), url(${data.loyalty !== undefined ? data.loyalty.banner : BANNER_PLACEHOLDER})`,
                            backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100px', borderRadius: '1rem 1rem 0 0'
                        }}
                    >
                        <h5 className="m-0 font-weight-bold">Loyalty Info</h5>
                    </CardHeader>
                    <CardBody>
                        <h6 className="m-0 mb-2">{data.loyalty.title}</h6>
                        <div>
                            {data.loyalty.description}
                        </div>

                        <h6 className="m-0 mt-4">Stamps ({data.loyalty.max})</h6>
                        <div className="d-flex justify-content-between align-self-center flex-wrap" style={{ margin: '0 -5px' }}>
                            {STAMPS.map((data, i) => (
                                <div style={{ fontSize: '1.1em', fontWeight: 'bold', width: 'calc(20% - 10px)', paddingTop: 'calc(20% - 10px)', margin: '5px', background: `url(${STAMP_PLACEHOLDER})`, borderRadius: '50%', backgroundPosition: 'center', backgroundSize: 'contain' }} key={i}></div>
                            ))}
                        </div>
                    </CardBody>
                </Card>
        } else {
            display =
                <Card body style={{ padding: '45px 15px' }} className="text-center">
                    No data available
            </Card>
        }

        return display
    }

    _renderAdminList = () => {
        const { outletAdmin, masterAdmin, data, modalAdmin, modalAdminSelect } = this.state
        console.log("OUTLET ADMIN", outletAdmin)
        if (modalAdminSelect === 'outlets' && outletAdmin.length === 0 || !outletAdmin || !data.OUTLETS) return null
        if (modalAdminSelect === 'outlets' && outletAdmin.length !== 0) {

            let selectedAdmin = outletAdmin.filter(outlet => outlet.retail_id)[0]
            let outletId = outletAdmin.filter(outlet => outlet.retail_id)[0].retail_id
            // if (selectedAdmin.role.toLowerCase() === 'master') outletId = outletAdmin.filter(outlet => outlet.retail_id)[0].retail_id[0]

            const { name } = data.OUTLETS.filter(outlet => outlet.retail_id === outletId)[0]

            const adminList = outletAdmin.map((admin) => (
                <tr key={admin.retail_admin}>
                    <td>{admin.name}</td>
                    <td>{admin.email}</td>
                    <td>{admin.role}</td>
                    <td className='d-flex'>
                        <Button id={admin.retail_admin} color="danger" className="d-flex align-content-center justify-content-center ml-auto" style={{ opacity: '100%', fontSize: '0.75rem', padding: '5px 10px' }} onClick={() => this.deleteAdmin(admin)}>
                            <div className="">Remove Admin</div>
                            <div className="d-none">Removing...</div>
                            <div className="btnloader d-none"></div>
                        </Button>
                    </td>
                </tr>

            ))

            return (
                <Modal isOpen={this.state.modalAdmin} toggle={() => this.toggleModal('modalAdmin')} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger' >
                    <ModalHeader toggle={() => this.toggleModal('modalAdmin')}>Staff List for {name.toUpperCase()}</ModalHeader>
                    <ModalBody className=''>
                        <Table>
                            <thead>
                                <tr scop>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {adminList}

                            </tbody>
                        </Table>
                        <div className='d-flex flex-column'>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalAdmin')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            )
        }

        // if (modalAdminSelect === 'master') {
        //     const masterAdminList = masterAdmin.map((admin) => (
        //         <tr key={admin.retail_admin}>
        //             <td>{admin.name}</td>
        //             <td>{admin.email}</td>
        //             <td>{admin.role}</td>
        //             <td className='d-flex'>
        //                 <Button id={admin.retail_admin} color="danger" className="d-flex align-content-center justify-content-center ml-auto" style={{ opacity: '100%', fontSize: '0.75rem', padding: '5px 10px' }} onClick={() => this.deleteAdmin(admin)}>
        //                     <div className="">Remove Admin</div>
        //                     <div className="d-none">Removing...</div>
        //                     <div className="btnloader d-none"></div>
        //                 </Button>
        //             </td>
        //         </tr>
        //     ))

        //     return (
        //         <Modal isOpen={modalAdmin} toggle={() => this.toggleModal('modalAdmin')} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger' >
        //             <ModalHeader toggle={() => this.toggleModal('modalAdmin')}>Master Admin List</ModalHeader>
        //             <ModalBody className=''>
        //                 <Table>
        //                     <thead>
        //                         <tr scop>
        //                             <th>Name</th>
        //                             <th>Email</th>
        //                             <th>Role</th>
        //                             <th></th>
        //                         </tr>
        //                     </thead>
        //                     <tbody>
        //                         {masterAdminList}
        //                     </tbody>
        //                 </Table>
        //                 <div className='d-flex flex-column'>
        //                 </div>
        //             </ModalBody>
        //             <ModalFooter>
        //                 <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalAdmin')}>Cancel</Button>
        //             </ModalFooter>
        //         </Modal>
        //     )
        // }


    }

    _renderRemoveButton = ({ group_id, retail_admin, retail_id, role, OUTLETS }) => {
        // For HQ
        if (group_id) {
            const checkAdminForEachOutlet = OUTLETS.filter(outlet => outlet.retail_admin !== undefined)
            if (checkAdminForEachOutlet.length !== 0) return null

            return (
                <Button id={group_id} color="danger" className="d-flex align-content-center justify-content-center ml-4" style={{ opacity: '100%' }} onClick={() => this.deleteMerchant(group_id)}>
                    <div className="">Remove Merchant</div>
                    <div className="d-none">Removing...</div>
                    <div className="btnloader d-none"></div>
                </Button>
            )

        }
        // For Single Outlet
        if (!group_id) {
            if ((retail_admin && Object.keys(retail_admin).length !== 0) || role === 'HQ') return null

            if ((retail_admin && Object.keys(retail_admin).length === 0) || role === 'Outlet') {
                return (
                    <Button id={retail_id} color="danger" className="d-flex align-content-center justify-content-center ml-4" style={{ opacity: '100%', fontSize: '0.75rem', padding: '5px 10px' }} onClick={() => this.deleteOutlet(retail_id)}>
                        <div className="">Remove Outlet</div>
                        <div className="d-none">Removing...</div>
                        <div className="btnloader d-none"></div>
                    </Button>
                )
            }
            return null

            // return <Button color="danger" size="sm" className="m-0" style={{ fontSize: '0.75rem', padding: '5px 10px' }} onClick={() => this.deleteOutlet(retail_id)}>Remove Outlet</Button>

        }
    }

    _renderOutlets = () => {
        let display
        const { data } = this.state
        const hqExists = !!(data.OUTLETS.find((outlet) => outlet.role === 'HQ'))
        display =
            <Card>
                <CardHeader>
                    <div className='d-flex align-items-center'>
                        <h5 className="m-0 font-weight-bold">Outlets</h5>
                        {!hqExists && <Button color='dark' className='ml-auto'>Add HQ</Button>}
                    </div>
                </CardHeader>
                <CardBody style={{ paddingRight: '10px' }}>
                    <div className="custom-scrollbar" style={{ maxHeight: '600px', overflowY: 'scroll', paddingRight: '5px' }}>
                        {data.OUTLETS !== undefined ? data.OUTLETS.map((data, index) => (
                           
                            <Card body key={index} style={{ boxShadow: 'none', border: '1px solid' }} className="d-flex flex-column justify-content-between">
                                <div>
                                    <div className="m-0 mb-2 d-flex flex-column justify-content-end">
                                        <div className='m-0 mb-2 d-flex justify-content-end'>

                                            {data.display !== undefined && data.display === '3' ?
                                                <div>
                                                    <img src={VERIFIED} style={{ width: '20px', marginRight: '10px' }} />
                                                    <VerifyWorkshop id={this.props.id} data={data} parentRefresh={this.fetchWorkshopIndividual} />
                                                </div> :
                                                data.display !== '3' && data.display === '1' ?
                                                    <div>
                                                        <Badge pill color="danger">Unlisted</Badge> <VerifyWorkshop id={this.props.id} data={data} parentRefresh={this.fetchWorkshopIndividual} />
                                                    </div> :
                                                    <div>
                                                        <Badge pill color="info">Unverified</Badge> <VerifyWorkshop id={this.props.id} data={data} parentRefresh={this.fetchWorkshopIndividual} />
                                                    </div>
                                            }
                                        </div>
                                        <div className='ml-auto'>
                                            {this._renderRemoveButton(data)}
                                        </div>
                                    </div>

                                    <h6 className="m-0 mb-2">
                                        {data.name}
                                        <Badge pill className="ml-1" color="warning">{data.role}</Badge>
                                    </h6>

                                    <div>Address : {data.address}</div>
                                    <div>Description : {data.description}</div>
                                    <div>Phone : {data.phone}</div>

                                    {data.retail_admin !== undefined ?
                                        <div className='d-flex flex-row align-items-center'>
                                            <div className='mr-2 mt-0 pt-0'>{Object.keys(data.retail_admin).length} staff(s)</div>

                                            <Button id={data.retail_id} color="light" size="sm" className="d-flex align-content-center justify-content-center m-0" style={{ fontSize: '0.7rem', padding: '5px 10px' }} onClick={() => this.viewWorkshopAdmin(data, 'outlets')}>
                                                <div className="">View</div>
                                                <div className="d-none">Viewing...</div>
                                                <div className="btnloader d-none"></div>
                                            </Button>
                                        </div> : <div>No Admin</div>
                                    }

                                    <Badge pill color="light">{data.type}</Badge><br />
                                    <div className="d-flex justify-content-end align-items-center mt-1">
                                        {data.banner !== '' && data.banner !== undefined ?
                                            <Button size="sm" color="light" className="m-0 mr-3" onClick={() => {
                                                // console.log(data.banner);
                                                window.open(data.banner, '_blank')
                                            }}>Download Banner</Button> : null
                                        }

                                        <PrintMerchantQRCode retail_main={this.props.id} retail_id={data.retail_id} workshop_name={data.name} />
                                    </div>
                                </div>

                                {data.reviews_overall !== undefined ?
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div style={{ fontSize: '2em', fontWeight: 'bold' }} >{data.reviews_overall.rate.toFixed(1)}</div>

                                            <div className="d-flex justify-content-between align-items-center">
                                                {this._renderRate(data.reviews_overall.rate, 'justify-content-start')}
                                                <span className="ml-1">({data.reviews_overall.count})</span>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div style={{ fontSize: '2em', fontWeight: 'bold' }}>0</div>
                                            <div className="d-flex justify-content-start align-items-center">
                                                {this._renderRate(0, 'justify-content-start')}
                                                <span className="ml-1">(0)</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Card>
                        )) :
                            <div style={{ padding: '45px 25px' }}>
                                No data available
                        </div>
                        }
                    </div>
                </CardBody>
            </Card >

        return display
    }
    _renderContent = () => {
        let display
        const { load, data } = this.state

        if (load == '1') {
            display = <Card body><div className="loader"></div></Card>
        }
        if (load == '2') {
            display =
                <>
                    {this._renderInfo()}
                    <Row>
                        <Col md={8}>
                            {this._renderOutlets()}
                        </Col>
                        <Col md={4}>
                            {this._renderLoyalty()}
                        </Col>
                    </Row>
                </>
        }
        if (load == '3') {
            display = <Card body className="text-center">Nothing to display here.</Card>
        }

        return display
    }
    download = (link) => {
        fetch(link).then(response => {
            console.log(response);
        })
        // var element = document.createElement("a");
        // var file = new Blob(
        //   [
        //     "https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg"
        //   ],
        //   { type: "image/*" }
        // );
        // element.href = URL.createObjectURL(file);
        // element.download = "image.jpg";
        // element.click();
    };
    render() {
        return (
            <>
                <div className="position-relative d-flex justify-content-between">
                    <Button color="dark" onClick={this._back}>Back</Button>

                    <div className="d-flex justify-content-between">
                        <Button color="light" className="mr-4" onClick={() => {
                            window.open(this.state.data.logo,)
                        }}>Download Logo</Button>

                        <AddAdmin data={this.state.data} id={this.props.id} parentRefresh={this.fetchWorkshopIndividual} />
                        {this._renderRemoveButton(this.state.data)}
                    </div>
                </div>

                {this._renderContent()}

                <Modal isOpen={this.state.modalMDR} toggle={() => this.toggleModal('modalMDR')} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger'>
                    <ModalHeader toggle={() => this.toggleModal('modalMDR')}>Edit MDR for merchant</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for='mdr'>MDR %</Label>
                            <Input type='number' name='mdr' id='mdr' value={this.state.mdr} onChange={this.handleChange} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.displayButt === true ?
                            <Button color='dark' onClick={() => {
                                this.setState({ displayButt: false }, () => {
                                    this._saveMDR()
                                })
                            }}>Update MDR</Button> :
                            <Button color='dark' disabled className="d-flex align-content-center justify-content-center"><div className="mr-2">Updating...</div><div className="btnloader"></div></Button>
                        }
                        <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalMDR')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                {this.state.modalAdmin && this._renderAdminList()}
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} text={this.state.text} />
            </>
        )
    }
}

WorkshopIndividual = inject('mobx_config', 'mobx_auth')(observer(WorkshopIndividual))