import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Label, Input, FormGroup, Row, Col, FormText, Card, Progress } from 'reactstrap'
import firebase from 'firebase'
import Cropper from 'react-easy-crop';
import Compressor from 'compressorjs'

import LOGO from 'assets/img/placeholder.png'

import ErrorAlert from 'components/ErrorAlert'
import getCroppedImg from 'components/cropImage';
import ImageUpload from 'components/ImageUpload';
import { inject, observer } from 'mobx-react';

export default class MarketingSectionManagement extends Component {
   static propTypes = {
      type: PropTypes.string,          // type of modal
      data: PropTypes.object,          // consists of all sections in selected category
      fetchCategory: PropTypes.func,
      categoryID: PropTypes.string
   }

   state = {
      actionBtn: 'next',
      isModalOpen: false,              // handles category modal
      isSaveTrigger: false,            // handles save button behavior

      errorText: '',                   // handles error text
      errorToggle: false,              // handles error alert modal

      title: '',                       // title of section
      description: '',                 // description of section
      contentType: '',                 // type of content
      link: '',                        // link of section
      header: '',                      // content header
      body: '',                        // content body
      link_content: '',                // content link

      previewUpload: LOGO,
      previewContent: LOGO,
      canUpload: false,  
      canUploadContent: false,  
      croppedImage: null,              
      croppedImageContent: null,              

      crop: { x:0, y:0 },
      croppedAreaPixels: { x:0, y:0 },
      croppedAreaContentPixels: { x:0, y:0 },
      zoom: 1, 

      progress: { 
         text : '', 
         percentage : 0 
      },
   }

   /* to handle input change then, update state function */
   handleChange = evt => this.setState({ [evt.target.name]:evt.target.value })

   handleImage = (preview, upload, id) => {
      if (id === 'main') {
         this.setState({ previewUpload:preview, canUpload:upload })
      }
      if (id === 'content') {
         this.setState({ previewContent:preview, canUploadContent:upload })
      }
   }

   /* toggle error alert */
   childToggle = () => this.setState({ errorToggle:!this.state.errorToggle })

   /* toggle section modal */
   toggleModal = (e, state) => {
      const { data, type } = this.props
      e.stopPropagation()
      type === 'add' && this.setState({ [state]:!this.state[state], actionBtn:'next', contentType:'' })

      type === 'edit' && this.setState({
         isModalOpen: !this.state.isModalOpen,
         title: data.name,      
         description: data.description,
         contentType: data.content !== undefined ? 'content' : 'link',
         link: data.link !== undefined ? data.link : null,
         header: data.content !== undefined ? data.content.header : null,
         body: data.content !== undefined ? data.content.body : null,
         link_content: data.content !== undefined ? data.content.link : null,
      })
   }

   onCropChange = crop => this.setState({ crop })

   onCropComplete = (croppedArea, croppedAreaPixels) => this.setState({ croppedAreaPixels })

   onCropCompleteContent = (croppedArea, croppedAreaContentPixels) => this.setState({ croppedAreaContentPixels })

   onZoomChange = zoom => this.setState({ zoom })

   /* check missing input */
   errorHandler = async () => {
      const { title, description, contentType, canUpload, canUploadContent, previewUpload, previewContent, croppedAreaContentPixels, croppedAreaPixels, header, body, link, link_content } = this.state
      const { type } = this.props

      if (title === ''){
         return this.setState({ isSaveTrigger:false, errorToggle:true, errorText:<span>Please fill in the title of the tile.</span> })
      }
      
      if (description === ''){
         return this.setState({ isSaveTrigger:false, errorToggle:true, errorText:<span>Please fill in the description of the tile.</span> })
      }
      
      if (contentType === '') {
         return this.setState({ isSaveTrigger:false, errorToggle:true, errorText:<span>Please select the content type of the tile.</span> })
      }

      if (type === 'add' && !canUpload) {
         return this.setState({ isSaveTrigger:false, previewUpload:LOGO, canUpload:false, errorToggle:true, errorText:<span>Please select an image to upload!</span> })
      }

      if (link === '' && contentType === 'link'){
         return this.setState({ isSaveTrigger:false, errorToggle:true, errorText:<span>Please fill in the link to open the webview for the campaign.</span> })
      }

      if (contentType === 'content') {
         if (header === ''){
            return this.setState({ isSaveTrigger:false, errorToggle:true, errorText:<span>Please fill in the header of the content.</span> })
         }

         if (body === ''){
            return this.setState({ isSaveTrigger:false, errorToggle:true, errorText:<span>Please fill in the body of the content.</span> })
         }

         if (link_content === ''){
            return this.setState({ isSaveTrigger:false, errorToggle:true, errorText:<span>Please fill in the link to open the webview for the content.</span> })
         }

         if (type === 'add' && !canUploadContent) {
            return this.setState({ isSaveTrigger:false, previewContent:LOGO, canUploadContent:false, errorToggle:true, errorText:<span>Please select an image to upload!</span> })
         }
      }

      if (type === 'add') {
         contentType === 'content' && await this.handleCroppedImage(previewContent, 'croppedImageContent', croppedAreaContentPixels)
         await this.handleCroppedImage(previewUpload, 'croppedImage', croppedAreaPixels) 
      }
      if (type === 'edit') {
         this.updateSection()
      }
   }

   handleCroppedImage = async (preview, state, croppedAreaPixels) => {
      try {
         let progress = { text:'compressing image', percentage:15 }
         this.setState({ progress })

         const croppedImage = await getCroppedImg(preview, croppedAreaPixels)

         let compressedImage = await new Compressor(croppedImage, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: compressedResult => {
               this.setState({ [state]:compressedResult })
               progress = { text:'saving data', percentage:35 }
               
               this.setState({ progress }, () => {
                  if (state === 'croppedImage') {
                     this.saveImageStorage()
                  }
               })
            },
         })
      } catch (e) {
         console.error(e)
         console.log('Woops!')
      }
   }

   /* save data in db and storage */
   saveImageStorage = () => {
      const { title , description, croppedImage, croppedImageContent, link, header, body, link_content, contentType} = this.state
      const { categoryID, data, type} = this.props
      let that = this
      let progress = { text:'saving data', percentage:50 }

      this.setState({ progress })
      let COUNTRY_DB = this.props.mobx_auth.COUNTRY
      let sectionID = type === 'add' ? firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${categoryID}/`).push().getKey() : data.section_id

      const storageRef = firebase.storage().ref(`marketing_sections/${categoryID}/${sectionID}`)
      let uploadImage = storageRef.child(sectionID).put(croppedImage)

      if (contentType === 'content') {
         const storageRefContent = firebase.storage().ref(`marketing_sections_content/${categoryID}/${sectionID}`)
         let uploadImageContent = storageRefContent.child(sectionID).put(croppedImageContent);
   
         uploadImageContent.on('state_changed', (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
   
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
               case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
               case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
            }
         }, (error) => {
             // IF IMAGE UPLOAD FAILED
            alert('Upload failed, please try again!\n', error.message);
   
            that.setState({
               isSaveTrigger:true, modalError:true,
               text:<span>Error uploading image!<br/>Check your internet connection and try again.</span>,
               text:'saving data', 
               percentage:70 
             })
         }, () => {
             // IF IMAGE UPLOAD SUCCEED
            uploadImageContent.snapshot.ref.getDownloadURL().then((downloadURL) => {
               firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${categoryID}/${sectionID}`).update({
                  content: contentType === 'content' ? {
                     header: header,
                     body: body,
                     link: link_content,
                     image: downloadURL
                  } : null
               })
            })
         })
      }

      uploadImage.on('state_changed', (snapshot) => {
         var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

         console.log('Upload is ' + progress + '% done');
         switch (snapshot.state) {
            case firebase.app(COUNTRY_DB).storage.TaskState.PAUSED: // or 'paused'
               console.log('Upload is paused');
               break;
            case firebase.app(COUNTRY_DB).storage.TaskState.RUNNING: // or 'running'
               console.log('Upload is running');
               break;
         }
      }, (error) => {
         // IF IMAGE UPLOAD FAILED
         alert('Upload failed, please try again!\n', error.message);

         that.setState({
            isSaveTrigger:true, modalError:true,
            text:<span>Error uploading image!<br/>Check your internet connection and try again.</span>,
            text:'saving data', 
            percentage:70 
         })
      }, () => {
         // IF IMAGE UPLOAD SUCCEED
         uploadImage.snapshot.ref.getDownloadURL().then((downloadURL) => {
            firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${categoryID}/${sectionID}`).update({
               name: title,
               description: description,
               image: downloadURL,
               timestamp: new Date().getTime(),
               link: contentType === 'link' ? link : null,
            }).then(() => {
               that.setState({
                     text: 'saving data', 
                     percentage: 100,
                     isSaveTrigger: true,
                     canUpload: false, 
                     previewUpload: LOGO, 
                     text: '',
               }, () => {
                     that.setState({
                        text:'', 
                        percentage:0,
                        isSaveTrigger:true, 
                        isModalOpen: false,
                        canUpload: false, 
                        previewUpload: LOGO,
                        title : '', 
                        description: '',
                        contentType: '',
                        header: '',
                        body: '',
                        link: '',
                     })
               })
            })
            .then(() => {
               alert(`Successfully uploaded section!`)
               this.setState({ isModalOpen:false , isSaveTrigger:false })
               this.props.fetchCategory()
            })
         })
      })
   }

   updateSection = () => {
      const { title , description, link, header, body, link_content, contentType} = this.state
      const { categoryID, data } = this.props
      let COUNTRY_DB = this.props.mobx_auth.COUNTRY
      firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${categoryID}/${data.section_id}`).update({
         name: title,
         description: description,
         link: contentType === 'link' ? link : null,
         content: contentType === 'content' ? {
            header: header,
            body: body,
            link: link_content,
         } : null
      }).then(() => {
         this.setState({
               text: 'saving data', 
               percentage: 100,
         }, () => {
               this.setState({
                  text:'', 
                  percentage:0,
                  isSaveTrigger:true, 
                  isModalOpen: false,
                  canUploadContent: false,
                  canUpload: false, 
                  previewUpload: LOGO,
                  previewContent: LOGO,
                  title : '', 
                  description: '',
                  contentType: '',
                  header: '',
                  body: '',
                  link: '',
                  link_content: '',
               })
         })
      })
      .then(() => {
         alert(`Successfully updated section!`)
         this.setState({ isModalOpen:false , isSaveTrigger:false })
         this.props.fetchCategory()
      })
   }

   /* to trigger save button */
   _renderSaveButton = () => {
      const { isSaveTrigger } = this.state
      let button

      if (!isSaveTrigger) {
         button = <Button className='m-0' color='dark' onClick={() => this.setState({ isSaveTrigger:true }, () => this.errorHandler())}>Save</Button>
      }

      if (!!isSaveTrigger) {
         button = <Button className='m-0 d-flex align-items-center justify-content-center' color='dark' disabled><div>Saving</div><div className='ml-1 btnloader'></div></Button>
      }

      return button
   }

   /* display input fields for content type */
   _renderContent = () => {
      const { header, body, link_content, previewContent, crop, zoom } = this.state
      const { type } = this.props

      return (
         <>
         <FormGroup>
            <Label>Header</Label>
            <Input type='text' name='header' value={header} onChange={this.handleChange} placeholder='Header' />
         </FormGroup>

         <FormGroup className='mt-4'>
            <Label>Body</Label>
            <Input type='textarea' name='body' value={body} onChange={this.handleChange} placeholder='Body' />
         </FormGroup>

         <FormGroup className='mt-4'>
            <Label>Link</Label>
            <Input type='text' name='link_content' value={link_content} onChange={this.handleChange} placeholder='Webview link' />
            <FormText color='danger'>* This is the link for the webview.</FormText>
         </FormGroup>

         {
            type === 'add' &&
            <>
            <ImageUpload id='content' onImageUpload={this.handleImage} />

            <Card style={{ height:'270px'}}>
               <Cropper
                  image={previewContent}
                  crop={crop}
                  zoom={zoom}
                  aspect={2/1}
                  minZoom={0.5}
                  zoomSpeed={0.2}
                  objectFit='contain'
                  restrictPosition={true}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropCompleteContent}
                  onZoomChange={this.onZoomChange}
               />
            </Card>
            </>
         }
         </>
      )
   }

   /* display input field for link type */
   _renderLink = () => {
      const { link, title } = this.state

      return (
         <FormGroup>
            <Label>Link</Label>
            <Input type='text' name='link' value={link} onChange={this.handleChange} placeholder='Webview link' />
            <FormText color='danger'>* This is the link for the webview.</FormText>
         </FormGroup>
      )
   }
   
   _renderSubMain = () => {
      const { contentType } = this.state
      const { type } = this.props
      let display

      switch (contentType) {
         case 'link': display = this._renderLink()
            break;
         case 'content': display = this._renderContent()
            break;
         default: display = null
            break;
      }

      return (
         <>
         <div className='row'>
            {type === 'add' &&
               <>
               <Label className='mr-3 d-flex justify-content-center'>Content Type : </Label>
               <div className='container-block mb-2'>
                  <div className='float-block'>
                     <Label className='label-block' >
                        <Input style={{ width:'auto' }} name='contentType' type='radio' checked={contentType === 'link'} value={contentType} onClick={() => this.setState({ contentType:'link' })} />  
                        Link
                     </Label>
                  </div>

                  <div className='float-block'>
                     <Label className='label-block'> 
                        <Input style={{ width:'auto' }} name='contentType' type='radio' checked={contentType === 'content'} value={contentType} onClick={() => this.setState({ contentType:'content' })} /> 
                        Content 
                     </Label>
                  </div>
               </div>

               { contentType !== '' && <hr/> }
               </>
            }
         </div>

         {display}
         </>
      )
   }

   _renderMain = () => {
      const { title, description, previewUpload, crop, zoom } = this.state
      const { type } = this.props

      return (
         <>
         <FormGroup>
            <Label>Title</Label>
            <Input type='text' name='title' value={title} onChange={this.handleChange} placeholder='Title of tile' />
         </FormGroup>

         <FormGroup className='mt-4'>
            <Label>Description</Label>
            <Input type='text' name='description' value={description} onChange={this.handleChange} placeholder='Description of tile' />
         </FormGroup>

         {
            type === 'add' && 
            <>
            <ImageUpload id='main' onImageUpload={this.handleImage} />

            <Card style={{ height:'270px'}}>
               <Cropper
                  image={previewUpload}
                  crop={crop}
                  zoom={zoom}
                  aspect={1/1}
                  minZoom={0.5}
                  zoomSpeed={0.2}
                  objectFit='contain'
                  restrictPosition={true}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
               />
            </Card>
            </>
         }
         </>
      )
   }

   /* displays add/edit section modal content */
   _renderModalSection = () => {
      const { isModalOpen, progress, actionBtn } = this.state
      const { type } = this.props
      let text
      
      text = type === 'add' ? 'Add new' : 'Edit'

      return (
         <Modal isOpen={isModalOpen} toggle={e => this.toggleModal(e, 'isModalOpen')} className='modal-dialog-centered'>
            <ModalHeader toggle={e => this.toggleModal(e, 'isModalOpen')}>{`${text} tile`}</ModalHeader>
            <ModalBody>
               <Row>
                  <Col className='m-3'>{actionBtn === 'next' ? this._renderMain() : this._renderSubMain()}</Col>
               </Row>

               <Row>
                  {progress.percentage !== 0 && <Progress className='ml-4' bar animated color='success' value={progress.percentage}>{progress.text}</Progress>}
               </Row>
            </ModalBody>
            <ModalFooter className={actionBtn === 'save' ? 'd-flex justify-content-between' : 'd-flex justify-content-end'}>
               {actionBtn === 'save' && <Button className='m-0' color='dark' onClick={() => this.setState({ actionBtn:'next' })}>Back</Button>}
               
               <div>
                  {actionBtn === 'next' && <Button className='m-0' color='dark' onClick={() => this.setState({ actionBtn:'save' })}>Next</Button>}
                  {actionBtn === 'save' && this._renderSaveButton()}
               </div>
            </ModalFooter>
         </Modal>
      )
   }

   render() {
      const { errorText, errorToggle } = this.state
      const { type } = this.props
      let text, btnColor, className, padding

      text = type === 'add' ? 'Add Tile' : 'View details'
      btnColor = type === 'add' ? 'info' : 'dark'
      className = type === 'add' ? 'm-0 mr-2'  : '' 
      padding = type === 'add' ? '7px 15px' : ''

      return (
         <>
         <Button className={className} style={{ padding:padding }} color={btnColor} onClick={e => this.toggleModal(e, 'isModalOpen')}>{text}</Button>

         {this._renderModalSection()}

         <ErrorAlert text={errorText} toggle={errorToggle} parentToggle={this.childToggle} />
         </>
      )
   }
}

MarketingSectionManagement = inject('mobx_auth')(observer(MarketingSectionManagement))
