import React, { Component } from 'react';

import { Card, Modal, ModalHeader, ModalBody, CardBody, CardHeader, CardFooter, Button, Table } from 'reactstrap'
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react'

class FeedbackDisplay extends Component {
    constructor(props){
        super(props);
        this.state = {
          modal: false,
        }
      }
    
    static propTypes = {
        data: PropTypes.object.isRequired, 
    }

    _toggle = () => {
        this.setState({
        modal: !this.state.modal,
        })
    }

    _renderPlatform = () => {
        let display

        if (this.props.data.platform === 'ios' || this.props.data.platform === 'android') {
            display = <li className="li-bug"><b className="b-bug">Platform</b>: Serv User App ( {this.props.data.platform} )</li>
        } 
        if (this.props.data.platform === 'dashboard') {
            display = <li className="li-bug"><b className="b-bug">Platform</b>: Merchant Dashboard</li>
        }
        if (this.props.data.platform === 'user app' || this.props.data.platform === undefined) {
            display = <li className="li-bug"><b className="b-bug">Platform</b>: Serv User App</li>
        }

        return display
    }

    render() {
        const { data } = this.props

        let date = new Date(data.timestamp).getDate()
        let month = new Date(data.timestamp).getMonth()
        let year = new Date(data.timestamp).getFullYear()

        let complete_date = date.toString() + '/' + (month+1).toString() + '/' + year.toString()
        
        return (
            <>
                <Card className="text-left">
                    <CardHeader className="d-flex align-items-center justify-content-between" style={{color: 'white', background: 'rgb(30, 25, 80)', borderRadius:'0.9rem 0.9rem 0 0', padding:'1rem'}}>
                        <div>
                            {data.bug_id !== undefined ? data.bug_id.substr(0, 7) : data.suggestion_id.substr(0, 7)}
                        </div>
                        <div>{complete_date}</div>
                    </CardHeader>
                    <CardBody>
                        <ul className="ul-bug" style={{paddingLeft:'0px'}}>
                            <li className="li-bug"><b className="b-bug">Name</b>: {data.name}</li>
                            <li className="li-bug"><b className="b-bug">Email</b>: {data.email}</li>
                            {data.phone !== "" && data.phone !== undefined ? <li className="li-bug"><b className="b-bug">Phone</b>: {data.phone}</li> : null}

                            {this._renderPlatform()}
                            {data.version !== undefined ? <li className="li-bug"><b className="b-bug">Version</b>: {data.version}</li> : null}
                        </ul>
                        <hr />
                        <div className="max-lines" style={{fontSize: '0.9rem'}}>
                            {data.suggestion}
                        </div>
                    </CardBody>
                    <CardFooter className="text-center">
                        <Button color="dark" onClick={() => {
                            this.setState({
                                modal: !this.state.modal,
                            })
                        }}>View Details</Button>
                    </CardFooter>
                </Card>

                <Modal isOpen={this.state.modal} toggle={this._toggle} className="modal-dialog modal-dialog-centered modal-sections" style={{maxWidth:'55%'}}>
                    <Card body>
                        <ModalHeader className="p-0" toggle={this._toggle}>Report Details</ModalHeader>
                        <ModalBody className="p-0">
                            <ul className="ul-bug mb-0 pt-2" style={{fontSize: '1rem', paddingLeft:'0px'}}>
                                <li className="li-bug"><b className="b-bug">Name</b>: {data.name}</li>
                                <li className="li-bug"><b className="b-bug">Email</b>: {data.email}</li>
                                {data.phone !== "" && data.phone !== undefined ? <li className="li-bug"><b className="b-bug">Phone</b>: {data.phone}</li> : null}

                                {this._renderPlatform()}
                                {data.version !== undefined ? <li className="li-bug"><b className="b-bug">Version</b>: {data.version}</li> : null}
                            </ul>
                        </ModalBody>
                    </Card>
                    <Card body>
                        <div className="pb-3" style={{fontSize: '1rem'}}>
                            <b>Description :</b>
                        </div>
                        <div style={{fontSize:'1rem'}}>
                            {data.suggestion}

                            {data.screenshot !== undefined ? <div className="text-center"><img src={data.screenshot} style={{paddingTop:'10px', width:'75%'}} /></div> : null}
                        </div>
                    </Card>
                </Modal>
            </>

        );
    }
}

FeedbackDisplay = inject('mobx_config')(observer(FeedbackDisplay))

export default FeedbackDisplay;