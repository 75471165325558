import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, FormText, Progress } from 'reactstrap'
import LOGO from "assets/img/logo-placeholder.jpg"

import firebase from 'firebase';
import { inject, observer } from 'mobx-react';

export default class AddMarketplace extends Component {
    static propTypes = {
        parentRefresh: PropTypes.func,
    }
    state = {
        displayButt: true,
        modalAdd: false,
        name: '',
        company_name: '',
        link: '',
        category: 'Grocery Delivery',
        description: '',

        progress: 0,
        PreviewLogo: LOGO,
        canUploadLogo: false,
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    }
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    //HANDLE THE CHOOSE FILE BUTTON
    handleLogoChange = (evt) => {
        let that = this;
        const file = document.querySelector('#upload_logo').files[0];

        if(file){
            var checker = new Image();

            checker.src = window.URL.createObjectURL(file);

            checker.onload = function() {
                let fileSize = file.size;
                // var width = checker.naturalWidth,height = checker.naturalHeight;
                window.URL.revokeObjectURL(checker.src);
                if(fileSize < 100000){
                    that.setState({canUploadLogo:true, PreviewLogo: URL.createObjectURL(file)})
                } else {
                    alert('Image size has to be less than 100KB')
                    document.querySelector('#upload_logo').value = ""
                    that.setState({canUploadLogo:false})
                }
            }
        } else {
            console.log("Image is empty");
        }

    }
    _save = () => {
        // 1. error handler
        // 2. upload logo
        // 3. save data
        let ErrorHandler = this.state
        let state = this.state

        if(ErrorHandler.company_name === ''){
            alert('Please fill in company name!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.link === ''){
            alert('Please fill in the link for the listing!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.name === ''){
            alert('Please fill in the name of the site as it should appear on the SERV User App!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.description === ''){
            alert('Please fill in the description of the site as it should appear on the SERV User App!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.category === ''){
            alert('Please select the category of the listing!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.canUploadLogo === false){
            alert('Please upload a valid image!')
            this.setState({displayButt:true, progress:0})
            return
        }

        this.setState({progress:25}, () => {
            console.log('UPLOAD LOGO TIME');
            this._uploadLogo()
        })
    }
    _uploadLogo = () => {
        // 2. upload logo
        let that = this;

        let fileLogo = document.querySelector('#upload_logo').files[0];
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let MARKETPLACE_ID = firebase.app(COUNTRY_DB).database().ref('marketplace/').push().getKey();

        const storageRef = firebase.storage().ref('marketplace_logo');
        let uploadLogo = storageRef.child(MARKETPLACE_ID).put(fileLogo);

        // UNCOMMENT THIS CODE
        uploadLogo.on('state_changed', (snapshot) => {
            that.setState({progress: 35})
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                // that.setState({progressColor:'warning', text : 'PAUSED'})
                break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                // that.setState({progressColor:'success', text : 'UPLOADING'})
                break;
            }
        }, (error) => {
            // IF IMAGE UPLOAD FAILED
            that.setState({progress: 0})
            alert('Upload failed, please try again!\n', error.message);
            that.setState({displayButt: true})
        }, () => {
            // IF IMAGE UPLOAD SUCCEED
            uploadLogo.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // 2. get downloadURL
                console.log('File available at', downloadURL);
                that.setState({progress: 65}, () => {
                    that._uploadData(downloadURL, MARKETPLACE_ID)
                })
            })
        })
    }
    _uploadData = (url, marketplace_id) => {
        // 3. save data
        let state = this.state
        let downloadURL = url
        let MARKETPLACE_ID = marketplace_id

        let DATA = {
            company_name: state.company_name,
            link: state.link,
            name: state.name,
            category: state.category,
            description: state.description,
            logo: downloadURL,
        }

        // console.log(DATA);
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        firebase.app(COUNTRY_DB).database().ref('marketplace/' + MARKETPLACE_ID).update(DATA).then(() => {
            this.setState({progress:100}, () => {
                this.props.parentRefresh()
            })
        }).then(() => {
            setTimeout(() => {
                this.setState({
                    modalAdd: false,
                    displayButt: true,
                    name: '',
                    company_name: '',
                    link: '',
                    category: 'Grocery Delivery',
                    description: '',
                    progress: 0,
                    PreviewLogo: LOGO,
                    canUploadLogo: false,
                })
            }, 500)
        })
    }
    _renderButton = () => {
        let display

        if(this.state.displayButt === true){
            display =
            <Button color='dark' onClick={() => {
                this.setState({displayButt:false, progress: 10}, () => {
                    this._save()
                })
            }}>Save changes</Button>
        }
        if(this.state.displayButt === false){
            display =
            <Button color='dark' disabled className="d-flex align-items-center justify-content-center"><div className="mr-2">Saving...</div><div className="btnloader"></div></Button>
        }

        return display
    }
    render() {
        return (
            <div className="position-relative">
                <Button color="light" className="m-0" onClick={() => this.toggleModal('modalAdd')}>Add Listing</Button>

                <Modal isOpen={this.state.modalAdd} toggle={() => this.toggleModal('modalAdd')} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger'>
                    <ModalHeader toggle={() => this.toggleModal('modalAdd')}>Add new marketplace merchant</ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-3">
                            <Label for='company_name'>Company Name</Label>
                            <Input type='text' name='company_name' id='company_name' onChange={this.handleChange} value={this.state.company_name}/>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for='link'>Link</Label>
                            <Input type='text' name='link' id='link' onChange={this.handleChange} value={this.state.link}/>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for='name'>Name in SERV User App</Label>
                            <Input type='text' name='name' id='name' onChange={this.handleChange} value={this.state.name}/>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for='description'>Description in SERV User App</Label>
                            <Input type='text' name='description' id='description' onChange={this.handleChange} value={this.state.description}/>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for='category'>Category</Label>
                            <Input type='select' name='category' id='category' onChange={this.handleChange} value={this.state.category}>
                                <option value="Grocery Delivery">Grocery Delivery</option>
                                <option value="eBazaar">eBazaar</option>
                                <option value="Online Services">Online Services</option>
                            </Input>
                        </FormGroup>

                        <Label for="upload_logo">Upload Company Logo</Label>
                        <Input type="file" accept="image/*" name="upload_logo" id="upload_logo" onChange={this.handleLogoChange}/>
                        <FormText color="danger">
                            Logo (Make sure the the image size is less than 100kb!)
                        </FormText>
                        <div>
                            <img style={{width:250}} src={this.state.PreviewLogo}/>
                        </div>

                        {
                            this.state.progress !== 0 ? <div className="mt-3"><Progress animated color="success" value={this.state.progress} /></div> : null
                        }
                    </ModalBody>
                    <ModalFooter>
                        {this._renderButton()}
                        <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalAdd')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

AddMarketplace = inject('mobx_auth')(observer(AddMarketplace))
