import { observable, action, decorate } from "mobx";
//import { computed, toJS } from "mobx";
export default class Serv {
  servproviderUID = '';
  setSERVID(servproviderUID) {
    this.servproviderUID = servproviderUID;
  }

  chat_loading = false;
  setChatLoad(chat_loading) {
    this.chat_loading = chat_loading;
  }

  providerName = 'false';
  setProviderName(providerName) {
    this.providerName = providerName;
  }

  selectData = {};
  setSelectData(selectData) {
    this.selectData = selectData;
  }
}

decorate(Serv, {
  servproviderUID: observable,
  setSERVID: action,
  chat_loading: observable,
  setChatLoad: action,
  providerName: observable,
  setProviderName: action,
  selectData: observable,
  setSelectData: action,
});
