export default () => {
    // eslint-disable-next-line
    self.addEventListener('message', e => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;
        var DATA = e.data.DATA;
        var VERIFICATION = e.data.VERIFICATION
        let RESPONSE = []
        let TEMP = []

        for (const plate in DATA) {
            let CURRENT = DATA[plate]
            let CONFLICT = CURRENT.user && Object.values(CURRENT.user).find(a => a.relationship === 'not owner') ? true : false
            // console.log(CURRENT)

            if((CURRENT.user && CONFLICT) || VERIFICATION[plate]){
                let TEMP_VERIFICATION = VERIFICATION[plate] ? VERIFICATION[plate] : null
                let a = CURRENT
                a.plate = plate
                a.verification = TEMP_VERIFICATION

                TEMP.push(a)
            }
        }

        RESPONSE = TEMP

        postMessage(RESPONSE);
    });
};