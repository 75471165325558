import React from "react";
import PropTypes from 'prop-types'

import ServChatSection from 'components/ChatSection.jsx';

class ServChat extends React.Component{
  static propTypes = {
    servproviderID: PropTypes.string,
  }
  
  render(){
    if(this.props.servproviderID === null){
      return <div className="d-flex align-items-center justify-content-center" style={{height:'100%'}}>Select SP to chat or assign job.</div>
    }
    return (
      <ServChatSection servproviderID={this.props.servproviderID} />
    );
  }
}

export default ServChat;
