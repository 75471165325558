import React, { Component } from 'react';
import { Card } from 'reactstrap'
import { PanelHeader } from 'components'
import { Route } from 'react-router-dom';

import BookingHistory from 'pages/BookingHistoryMain.jsx';
import BookingHistorySelect from 'pages/BookingHistorySelected.jsx';

class BookingHistoryRoutes extends Component {
  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className='content'>
          {/* "/user/Booking-Request" */}
          <Route exact path="/user/Booking-History" component={BookingHistory} />
          <Route exact path="/user/Booking-History/:id" component={History} />
        </div>
      </div>
    );
  }
}

function History({ match }) {
  return <BookingHistorySelect id={match.params.id}/>;
}

export default BookingHistoryRoutes;