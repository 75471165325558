import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChecklistDisplay from 'components/ChecklistDisplay';
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';

import { Card, Button } from 'reactstrap';
import firebase from 'firebase';
import { createHashHistory } from 'history';
const history = createHashHistory();

class BookingHistorySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load: '1',
            data: {},
            deleted: {},
        };
    }
    componentDidMount() {
        this._fetchRequest()
        this._fetchDeletedReasons();
    }

    // _fetchRequest = () => {
    //     let that = this;
    //     let DATA;
    //     firebase.database().ref(`history/${this.props.id}`).once('value').then((snapshot) => {
    //         if(snapshot.exists()) {
    //             DATA = snapshot.val();
    //             DATA.booking_id = this.props.id;

    //             this.setState({data: DATA, load:'2'}, () => {console.log(DATA)})
    //         } else {

    //             this.setState({load:'3'})
    //         }
    //     })
    // }

    _fetchRequest = () => {
        let URL, csrfTokenP, csrfSecretP, headerToken;
        const { browser } = this.props.mobx_config
        let OPTIONS = { credentials: 'include' }
        let DATA
        let id = 'id=' + this.props.id;
        if (browser === 'safari') {
            csrfTokenP = sessionStorage.getItem('CSRFTokenP')
            csrfSecretP = sessionStorage.getItem('CSRFSecretP')
            headerToken = { csrftoken: csrfTokenP, csrfsecret: csrfSecretP }
        }
        if (this.props.mobx_config.config === 0) {
            URL = serv_provider_development.bookingHistorySelected
            // URL = 'http://localhost:5001/servprovideradmin/us-central1/bookingHistorySelected?'
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_provider_production.bookingHistorySelected
        }
        let finalURL = URL + id
        fetch(finalURL, OPTIONS).then(res => res.json().then(res => {
            if (res.history) {
                DATA = res.history
                DATA.booking_id = this.props.id;

                this.setState({ data: DATA, load: '2' }, () => { console.log(DATA) })
            } else {

                this.setState({ load: '3' })
            }
        }))
    }

    // _fetchDeletedReasons = () => {
    //     let that = this;
    //     let DELETED = {};
    //     firebase.database().ref('deleted_reasons/' + this.props.id).once('value').then((snapshot) => {
    //         if (snapshot.exists()) {
    //             DELETED = snapshot.val()
    //             this.setState({ deleted: DELETED })
    //         } else {
    //             this.setState({ deleted: 'none' })
    //         }
    //     })
    // }

    _fetchDeletedReasons = () => {
        let URL, csrfTokenP, csrfSecretP, headerToken;
        const { browser } = this.props.mobx_config
        let OPTIONS = { credentials: 'include' }
        let DELETED
        let id = 'id=' + this.props.id;
        if (browser === 'safari') {
            csrfTokenP = sessionStorage.getItem('CSRFTokenP')
            csrfSecretP = sessionStorage.getItem('CSRFSecretP')
            headerToken = { csrftoken: csrfTokenP, csrfsecret: csrfSecretP }
        }
        if (this.props.mobx_config.config === 0) {
            URL = serv_provider_development.bookingHistorySelected
            // URL = 'http://localhost:5001/servprovideradmin/us-central1/bookingHistorySelected?'
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_provider_production.bookingHistorySelected
        }
        let finalURL = URL + id
        fetch(finalURL, OPTIONS).then(res => res.json().then(res => {
            if (res.deleted) {
                DELETED = res.deleted

                this.setState({ deleted: DELETED })
            } else {
                this.setState({ deleted: 'none' })
            }
        }))
    }
    _refresh = () => {
        this.setState({ load: '1' }, () => {
            this._fetchRequest();
        })
    }

    _back = () => {
        history.push("/user/Booking-History")
    }
    _renderDeleted = () => {
        let display

        if (this.state.deleted !== 'none') {
            display =
                <Card body className="bg-danger text-white shadow-none" style={{ padding: '3rem 5rem', marginTop: '1.5rem', marginBottom: '1.5rem', marginRight: 'auto', marginLeft: 'auto' }}>
                    This booking request was deleted before a service was performed.<br />
                    Reason for cancellation:
                    <div style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
                        {this.state.deleted.reason}
                    </div>
                </Card>

        } else {
            display = <div>This user has deleted this booking request before any services can be performed.</div>
        }

        return display;
    }
    _renderChecklistItems = () => {
        let display;
        const { data } = this.state
        console.log(data.checklist)
        if (data.checklist !== undefined) {
            display = <ChecklistDisplay data={this.state.data} parentFetch={this._refresh} />
        } else {
            display = this._renderDeleted();
        }


        return display;
    }

    render() {
        if (this.state.load === '1') {
            return (
                <Card body>
                    <div className="loader"></div>
                </Card>
            )
        }


        return (
            // <Card body className={this.state.deleted !== 'none' ? 'bg-danger' : null}>
            <Card body className={this.state.deleted !== 'none' ? 'bg-white' : null}>
                <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: "15px" }}>
                    <Button color="dark" onClick={this._back} style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
                        <i style={{ marginRight: '3px', lineHeight: '1' }} className="now-ui-icons arrows-1_minimal-left"></i>
                        <div>Back</div>
                    </Button>
                </div>

                {this._renderChecklistItems()}
            </Card>
        );
    }
}

BookingHistorySelect.propTypes = {

};
BookingHistorySelect = inject('mobx_config')(observer(BookingHistorySelect));
export default BookingHistorySelect;