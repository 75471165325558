import React, { Component } from 'react'
import { Card, Button } from "reactstrap";
import { PanelHeader } from "components";
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';
import { Route } from 'react-router-dom'
// import MerchantMain from 'pages/MerchantMain.jsx';
import MerchantSelectedTR from 'pages/MerchantSelectedTurkey.jsx';
import MerchantMainTR from 'pages/MerchantMainTR.jsx';

export default class MerchantTurkey extends Component {
    render() {
        return (
            <div>
                <PanelHeader size="sm" />
                <div className="content">
                    <Route exact path="/user/Merchant-Turkey" component={MerchantMainTR} />
                    <Route exact path="/user/Merchant-Turkey/:id" component={Individual} />
                </div>
            </div>
        )
    }
}

function Individual({ match }) {
    return <MerchantSelectedTR id={match.params.id} />;
}

MerchantTurkey = inject('mobx_config')(observer( MerchantTurkey))