import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button, Card, Row, Col } from 'reactstrap'
import firebase from 'firebase'

import MarketingSectionManagement from 'pages/MarketingSectionManagement'
import { inject, observer } from 'mobx-react'

export default class MarketingSectionTile extends Component {
   static propTypes = {
      tile: PropTypes.object,          // section data
      categoryID: PropTypes.string,    // category ID
      fetchCategory: PropTypes.func    // trigger and fetch updated data from 'marketing_services'
   }

   state = {
      isDeleteTrigger: false
   }

   /* delete selected category from db */
   deleteCategory = async (data) => {
      const { categoryID } = this.props
      let SECTION
      let COUNTRY_DB = this.props.mobx_auth.COUNTRY
      this.setState({ isDeleteTrigger:true })

      let sectionDB = await firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${categoryID}/${data.section_id}`).once('value')
      if(sectionDB.exists()) SECTION = sectionDB.val()

      firebase.app(COUNTRY_DB).database().ref(`marketing_categories_archive/${categoryID}/sections/${data.section_id}`).update(SECTION)
      firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${categoryID}/${data.section_id}`).remove().then(() => {
         alert('Successfully deleted section!')
         this.setState({ isDeleteTrigger:false })
         this.props.fetchCategory()
      })
   }

   /* to trigger delete button */
   _renderDeleteButton = () => {
      const { tile } = this.props
      const { isDeleteTrigger } = this.state

      if (!isDeleteTrigger) return <Button color='danger' className='m-0 ml-2 mt-auto mb-auto' onClick={() => this.deleteCategory(tile)}>Delete</Button>

      if (!!isDeleteTrigger) return <Button color='danger' className='m-0 ml-2 mt-auto mb-auto d-flex justify-content-between'><div>Deleting</div><div className='ml-1 btnloader'></div></Button>
   }

   _renderContent = () => {
      const { tile, categoryID } = this.props
      
      return (
         <>
         <Col md={2}> 
            <div className='m-2'><img src={tile.image} /></div>
         </Col>
         <Col md={6} className='pr-3'>
            <div className='m-2 mb-3' style={{ fontSize:'18px', fontWeight:'bold' }}>{tile.name}</div>
            <p className='m-2'>{tile.description}</p>
         </Col>
         <Col md={4}>
            <div style={{ position:'absolute', bottom:0, right:'1.5rem' }} className='d-flex justify-content-between' >
               <MarketingSectionManagement type='edit' categoryID={categoryID} data={tile} fetchCategory={this.props.fetchCategory}/>
               {this._renderDeleteButton()}
            </div>
         </Col>
         </>
      )
   }

   render() {
      return (
         <Card className='p-2' style={{ borderRadius:'0.5rem' }}>
            <Row>
               {this._renderContent()}
            </Row>
         </Card>
      )
   }
}


MarketingSectionTile = inject('mobx_auth')(observer(MarketingSectionTile))