import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, } from "reactstrap";
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'
import CryptoJS from 'crypto-js'

import { inject, observer } from 'mobx-react';
import ErrorAlert from 'components/ErrorAlert';
const API_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

export default class AddWorkshop extends Component {
    static propTypes = {
        retail_main: PropTypes.array,
        parentFetch: PropTypes.func,
    }
    state = {
        modalAdd: false,
        displayButt: true,
        name: '',
        address: '',
        phone: '',
        role: 'HQ',
        hq: '',
        type: 'workshop',
        mdr: 1.5,
        toggle: false,
        success: false,
        reload: false
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    };
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    encrypt = (plainText) => {
        var b64 = CryptoJS.AES.encrypt(plainText, API_KEY).toString();
        var e64 = CryptoJS.enc.Base64.parse(b64);
        var eHex = e64.toString(CryptoJS.enc.Hex);
        return eHex
    }
    decrypt = (cipherText) => {
        var reb64 = CryptoJS.enc.Hex.parse(cipherText);
        var bytes = reb64.toString(CryptoJS.enc.Base64);
        var decrypt = CryptoJS.AES.decrypt(bytes, API_KEY);
        var plain = decrypt.toString(CryptoJS.enc.Utf8);

        return plain
    }
    // componentDidMount() {
    //     console.log(this.props.mobx_config.config)
    // }
    _errorHandlers = () => {
        let errorHandler = this.state

        if (errorHandler.name === '') {
            alert('Please fill in name of workshop');
            this.setState({ displayButt: true })
            return
        }
        if (errorHandler.address === '') {
            alert('Please fill in address of workshop');
            this.setState({ displayButt: true })
            return
        }
        if (errorHandler.role === '') {
            alert('Please select type of outlet');
            this.setState({ displayButt: true })
            return
        }

        this._save()
    }
    _save = () => {
        let state = this.state
        let URL
        let DATA = {
            name: state.name,
            address: state.address,
            role: state.role,
            hq: state.hq,
            type: state.type
        }
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let NAME = 'name=' + this.encrypt(state.name)
        let ADDRESS = '&address=' + this.encrypt(state.address)
        let ROLE = '&role=' + this.encrypt(state.role)
        let TYPE = '&type=' + this.encrypt(state.type)
        let PHONE = '&phone=' + state.phone
        let HQ = '&hq=' + this.encrypt(state.hq)
        let MDR = '&mdr=' + state.mdr
        let LOCATION = '&location=' + COUNTRY_DB
        let csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        // console.log('save', NAME, MDR)
        // console.log('DECRYPT', this.decrypt(NAME));

        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopAdd
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopAdd
        }
        let FINAL_URL = URL + NAME + ADDRESS + ROLE + TYPE + PHONE + HQ + MDR + LOCATION
        let OPTIONS = { credentials: 'include', headers: headerToken }

        console.log(FINAL_URL)

        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            console.log(response)
            if (response.response === 'OK' && response !== 401) {
                this.setState({
                    name: '',
                    address: '',
                    phone: '',
                    role: 'HQ',
                    hq: '',
                    type: 'workshop',
                    mdr: 1.6,
                    displayButt: true,
                }, () => {
                    setTimeout(() => {
                        this.props.parentFetch()
                        this.toggleModal('modalAdd')
                    }, 500);
                })
            }
        })
    }
    _renderButton = () => {
        let display

        if (this.state.displayButt === true) {
            display = <Button color='dark' onClick={() => {
                this.setState({ displayButt: false }, () => { this._errorHandlers() })
            }}>Save changes</Button>
        }
        if (this.state.displayButt === false) {
            display = <Button disabled color='dark' className="d-flex align-itens-center"><div className="mr-2">Saving...</div><div className="btnloader"></div></Button>
        }

        return display
    }
    render() {
        return (
            <div className="position-relative">
                <Button className="m-0 mr-3" onClick={() => this.toggleModal('modalAdd')} color="light">Add Workshop</Button>

                <Modal isOpen={this.state.modalAdd} toggle={() => this.toggleModal('modalAdd')} className='modal-dialog-centered' style={{ maxWidth: '65%' }}>
                    <ModalHeader toggle={() => this.toggleModal('modalAdd')}>Add Workshop</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for='name'>Retail Name</Label>
                            <Input type='text' name='name' id='name' onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup>
                            <Label for='address'>Retail Address</Label>
                            <Input type='text' name='address' id='address' onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup>
                            <Label for='phone'>Retail Phone Number</Label>
                            <Input type='text' name='phone' id='phone' onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup>
                            <Label for='type'>Retail Type</Label>
                            <Input type='select' name='type' id='type' value={this.state.type} onChange={this.handleChange}>
                                <option value="workshop">workshop</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for='role'>Branch</Label>
                            <Input type='select' name='role' id='role' onChange={this.handleChange} value={this.state.role}>
                                <option value="">--Select--</option>
                                <option value="HQ">HQ</option>
                                <option value="Outlet">Outlet</option>
                            </Input>
                        </FormGroup>

                        {this.state.role === 'Outlet' ?
                            <FormGroup>
                                <Label for='hq'>HQ Branch</Label>
                                <Input type='select' name='hq' id='hq' onChange={this.handleChange}>
                                    <option value="">--Select--</option>
                                    {this.props.retail_main.map((data, index) => (
                                        <option key={index} value={data.group_id}>{data.name} [{data.group_id}]</option>
                                    ))}
                                </Input>
                            </FormGroup> :
                            <FormGroup>
                                <Label for='mdr'>MDR Value</Label>
                                <Input type='number' name='mdr' id='mdr' value={this.state.mdr} onChange={this.handleChange} />
                            </FormGroup>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {this._renderButton()}
                        <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalAdd')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        )
    }
}

AddWorkshop = inject('mobx_config','mobx_auth')(observer(AddWorkshop))