import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, ButtonDropdown, Dropdown, CardHeader, CardBody, DropdownItem, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap'
import Charts from "components/MultipleLineCharts.jsx";
import { gradientChartOptionsConfigurationWithNumbersAndGrid } from "variables/charts.jsx";

let MONTH_LIST = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export default class RequestAnalytics extends Component {
    static propTypes = {
        data: PropTypes.object,
        load: PropTypes.bool,
        title: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.string
    }
    state = {
        today: MONTH_LIST[new Date().getMonth()] + ' ' + new Date().getFullYear(),
        dropdown: false,
    }
    toggle = state => {
        this.setState({ [state]: !this.state[state] });
    }
    _select = (data) => {
        // console.log(data);
        this.setState({today:data})
    }
    _renderDropdown = () => {
        let display
        let TEMP = []
        let YEAR = new Date().getFullYear()
        let MONTH = new Date().getMonth()
        let CURRENT = MONTH

        for (let index = 0; index < 13; index++) {
            CURRENT = MONTH - index
            let CURRENT_YEAR = YEAR
            if(CURRENT < 0){
                CURRENT = 12 + CURRENT
                CURRENT_YEAR = YEAR - 1
            }
            // console.log(MONTH_LIST[CURRENT] + ' ' + CURRENT_YEAR);
            let a = MONTH_LIST[CURRENT] + ' ' + CURRENT_YEAR
            TEMP.push(a)
        }

        display =
        <DropdownMenu style={{maxHeight:'300px', overflowY:'scroll'}} className="custom-scrollbar">
            {TEMP.map((data,index) => (
                <DropdownItem key={index} onClick={() => {this._select(data)}} style={{background:this.state.today === data ? 'rgba(58, 58, 58, 0.6)' : null, color:this.state.today === data ? 'white' : null, fontWeight:this.state.today === data ? 'bold' : null}}>{data}</DropdownItem>
            ))}
        </DropdownMenu>

        return display
    }
    _renderChart = () => {
        let display
        let DATA = this.props.data
        let TEMP = []
        let LOAD = this.props.load

        let DATES = []
        let YEAR = new Date().getFullYear()
        let MONTH = new Date().getMonth()
        let CURRENT = MONTH

        for (let index = 0; index < 13; index++) {
            CURRENT = MONTH - index
            let CURRENT_YEAR = YEAR
            if(CURRENT < 0){
                CURRENT = 12 + CURRENT
                CURRENT_YEAR = YEAR - 1
            }
            // console.log(MONTH_LIST[CURRENT] + ' ' + CURRENT_YEAR);
            let a = MONTH_LIST[CURRENT] + ' ' + CURRENT_YEAR
            DATES.push(a)
        }

        // console.log(DATA, DATES);

        if(LOAD === false){
            return display =
            <CardBody style={{padding:'95px 25px'}}>
                <div className="loader"></div>
            </CardBody>
        }
        // if(DATA.allMonth[this.]){
        //     return display =
        //     <div style={{height:'250px', lineHeight:'250px'}} className="text-center">No data available for selected month</div>
        // }
        let DATASET = []
        // var calls = 0

        DATES.forEach(month => {

            if(DATA.allMonth[month] !== undefined && DATA.billplzMonth[month] !== undefined && DATA.cashMonth[month] !== undefined){
                // console.log(month);
                // console.log(DATA.allMonth[month], DATA.billplzMonth[month], DATA.cashMonth[month]);
    
                let single_dataset = [
                    {
                        label: 'All payments',
                        data: DATA.allMonth[month].values,
                        pointRadius: 7.5,
                        borderWidth: 1,
                        borderColor: "#000080",
                        pointBorderColor: "#000080",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 1,
                        pointRadius: 3,
                        fill: false,
                        backgroundColor: 'rgba(0,0,128, 0.5)',
                    },
                    {
                        label: 'Billplz payments',
                        data: DATA.billplzMonth[month].values,
                        pointRadius: 7.5,
                        borderWidth: 1,
                        borderColor: "#004e94",
                        pointBorderColor: "#004e94",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 1,
                        pointRadius: 3,
                        fill: false,
                        backgroundColor: 'rgba(0, 78, 148, 0.5)',
                    },
                    {
                        label: 'Cash payments',
                        data: DATA.cashMonth[month].values,
                        pointRadius: 7.5,
                        borderWidth: 1,
                        borderColor: "#3f824f",
                        pointBorderColor: "#3f824f",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 1,
                        pointRadius: 3,
                        fill: false,
                        backgroundColor: 'rgba(63, 130, 79, 0.5)',
                    }
                ]
                let b = {
                    labels: DATA.allMonth[month].labels,
                    values: single_dataset,
                    month: month
                }
                DATASET.push(b)
            }
        
        })
        // console.log(DATASET);

        DATASET.forEach((set,index) => {
            var calls = set.values[0].data.reduce(
                ( accumulator, currentValue ) => accumulator + currentValue,
                0
            )
            let round_calls = (Math.round((calls + Number.EPSILON) * 100) / 100).toFixed(2)

            // console.log(set,  this.state.today);
            // console.log('sini', round_calls);
            let a = 
            <CardBody key={index} style={{display: this.state.today === set.month ? 'block' : 'none'}}>
                <Row>
                    <Col md={10}>
                        <Charts
                            labels={set.labels}
                            datasets={set.values}
                            options={gradientChartOptionsConfigurationWithNumbersAndGrid}
                            height="250px" label={this.props.name} />
                    </Col>
                    <Col md={2}>
                        <div className="mt-4">
                            <div className="text-right" style={{fontSize:'2.1em', fontWeight:'bold', paddingRight:'30px'}}>
                                {round_calls}
                            </div>
                            <div className="text-right" style={{paddingRight:'30px'}}>
                                <h6><i className={`fas fa-${this.props.icon} mr-2`}/>{this.props.name}</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>

            TEMP.push(a)
        });

        // console.log(DATASET.findIndex(a => a.month === this.state.today));
        // console.log(DATASET[DATASET.findIndex(a => a.month === this.state.today)]);
        if(DATASET.findIndex(a => a.month === this.state.today) !== -1) {
            
            display = TEMP.map(data => {return data})
        } else {
            display =
            <div style={{height:'250px', lineHeight:'250px'}} className="text-center">No data available for selected month</div>
        }

        return display
    }
    render() {
        return (
            <Card className="shadow">
                <CardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="m-0">{this.props.title}</h5>
                    <ButtonDropdown isOpen={this.state.dropdown} toggle={() => this.toggle('dropdown')}>
                        <DropdownToggle color="light" caret className="m-0">
                            <span style={{fontWeight:'bold'}}>{this.state.today}</span>
                        </DropdownToggle>
                        {this._renderDropdown()}
                    </ButtonDropdown>
                </CardHeader>

                {this._renderChart()}
            </Card>
        )
    }
}
