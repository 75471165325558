import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CardHeader, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { CONFIG } from 'configs/config.js'
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import ErrorAlert from 'components/ErrorAlert'
import { inject, observer } from 'mobx-react'
import firebase from 'firebase';

export default class TransactionsTable extends Component {
    static propTypes = {
        unsettled: PropTypes.bool,
        data: PropTypes.array,
        header: PropTypes.string,
        parentRefresh: PropTypes.func,
    }
    state = {
        select: {},
        data:[],
        modalPay: false,
        displayButt: true,
        toggle: false,
        success: false,
        reload: false

    }
   
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    };
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    
    _pay = (data) => {
        // console.log(data);
        let MAIN = data.main
        let ID = data.id
        let BOOKING_ID = data.booking_id
        // console.log(BOOKING_ID);
        let URL, csrfTokenU, csrfSecretU, headerToken
        const { browser } = this.props.mobx_config
        let COUNTRY = this.props.mobx_auth.COUNTRY
        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (CONFIG === 0) {
            URL = serv_user_development.workshopTransactionPaid
        }
        if (CONFIG === 1) {
            URL = serv_user_production.workshopTransactionPaid
        }
        let LOCATION = `&location=MY`
        let FINAL = URL + 'booking_id=' + BOOKING_ID + LOCATION
        let OPTIONS = { credentials: 'include', headers: headerToken }
        // console.log(FINAL);
  
        fetch(FINAL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                setTimeout(() => {
                    this.setState({
                        displayButt: true,
                        modalPay: false,
                    })
                    this.props.parentRefresh()
                }, 1000);
            }
        })
    }
    _renderButton = () => {
        let display

        if (this.state.displayButt === true) {
            display = <Button color='success' onClick={() => {
                this.setState({ displayButt: false }, () => {
                    this._pay(this.state.select)
                })
            }}>Yes, payment has been made</Button>
        }
        if (this.state.displayButt === false) {
            display = <Button disabled color='success' className="d-flex align-content-center justify-content-center"><div className="mr-2">Processing</div><div className="btnloader"></div></Button>
        }

        return display
    }
    render() {
        const { data } = this.props
        console.log("transactions malaysia", data)
        return (
            <>
                {/* <CardHeader className='border-0 mb-1'>
                    <h3 className='mb-0'>{this.props.header}</h3>
                </CardHeader> */}
                <Table className='align-items-center mb-0 pb-0' responsive>
                    <thead className='thead-light'>
                        <tr>
                            {this.props.unsettled ? <th scope='col'>Action</th> : null}
                            {this.props.unsettled ? <th scope='col'>Booking ID</th> : null}
                            <th scope='col'>Name</th>
                            <th scope='col'>Payment Type</th>
                            <th scope='col'>Currency</th>
                            <th scope='col'>Settlement Date</th>
                            <th scope='col'>Total Amt</th>
                            <th scope='col'>MDR</th>
                            <th scope='col'>Net Amt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length !== 0 ? data.map((data, index) => (
                                <tr key={index}>
                                    {this.props.unsettled ?
                                        <td>
                                            <Button color="dark" size="sm" style={{ fontSize: '0.8rem' }} onClick={() => {
                                                this.setState({ select: data }, () => {
                                                    this.toggleModal('modalPay')
                                                })
                                            }}>
                                                Make Payment
                                            </Button>
                                        </td> :
                                        null
                                    }
                                    {this.props.unsettled ?
                                        <td>{data.requestID}</td> : null
                                    }
                                    <td>{data.name}</td>
                                    <td>{data.type.toUpperCase()}</td>
                                    <td>MYR</td>
                                    <td>{data.date}</td>
                                    <td>{data.total_amount.toFixed(2)}</td>
                                    <td>{data.total_mdr.toFixed(2)}</td>
                                    <td>{data.net_amount.toFixed(2)}</td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan='8' className="text-center">
                                        <div style={{ padding: '65px 25px' }}>
                                            No data available
                                    </div>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </Table>

                <Modal isOpen={this.state.modalPay} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger'>
                    <ModalHeader>Mark transaction as paid</ModalHeader>
                    <ModalBody style={{ padding: '95px 25px', fontWeight: 'bold', fontSize: '' }} className="text-center">
                        Has this payment been made to the respective merchant outlet or HQ?
                    </ModalBody>
                    <ModalFooter>
                        {this._renderButton()}
                        <Button className='ml-auto' color='danger' onClick={() => {
                            this.setState({ select: {} }, () => {
                                this.toggleModal('modalPay')
                            })
                        }}>Cancel payment</Button>
                    </ModalFooter>
                    <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
                </Modal>
            </>
        )
    }
}
TransactionsTable = inject('mobx_config','mobx_auth')(observer(TransactionsTable));

