import { observable, action, decorate } from "mobx";
//import { computed, toJS } from "mobx";
export default class Config {
  config: 0;
  csrfSU: '';
  csrfSP: '';
  tokenReady: undefined;
  browser: '';

  setCONFIG(config) {
    this.config = config
  }
  setCSRFSU(csrfSU) {
    this.csrfSU = csrfSU
  }
  setCSRFSP(csrfSP) {
    this.csrfSP = csrfSP
  }
  setTOKENREADY(tokenReady) {
    this.tokenReady = tokenReady
  }
  setBROWSER(browser) {
    this.browser = browser
  }

}

decorate(Config, {
  config: observable,
  csrfSU: observable,
  csrfSP: observable,
  tokenReady: observable,
  browser: observable,
  setCONFIG: action,
  setCSRFSU: action,
  setCSRFSP: action,
  setTOKENREADY: action,
  setBROWSER: action
});
