export default () => {
    // eslint-disable-next-line
    self.addEventListener('message', e => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;
        var DATA = e.data;
        let RESPONSE = { DATA : [], VEHICLES : 0}
        let TEMP = [], VEHICLES = 0
        // console.log(DATA)

        let CURRENT
        for (const FUID in DATA) {
            CURRENT = DATA[FUID]
            CURRENT.FUID = FUID
            let CURRENT_VEHICLES = CURRENT.vehicles ? CURRENT.vehicles.length : 0
            VEHICLES = VEHICLES + CURRENT_VEHICLES
            TEMP.push(CURRENT)
        }
        RESPONSE.DATA = TEMP
        RESPONSE.VEHICLES = VEHICLES
        
        postMessage(RESPONSE);
    });
};