import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap'
import classnames from 'classnames';
import { QRCode } from 'react-qrcode-logo';
import ReactToPrint from 'react-to-print';

import SERV from 'assets/img/serv-blue.png'
import GRAPHICS from 'assets/img/qr-graphics.png'

export default class QRcode extends Component {
    static propTypes = {
        retail_main: PropTypes.string,
        retail_id: PropTypes.string,
        logo: PropTypes.string,
        workshop_name: PropTypes.string,
    }
    state = {
        modalQR: false,
        print:true,
        view:'graphics',
    }
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    }
    toggleTab = tab => {
        if(this.state.view !== tab){
            this.setState({view:tab})
        }
    }
    // logoImage={this.props.logo} logoOpacity={1}
    _renderGraphics = () => {
        let display

        if(this.state.view === 'graphics'){
            display =
            <div ref={el => (this.componentRef = el)} style={{width:'100%', height:'100%'}} className="d-flex justify-content-between flex-column align-items-center">
                <div className="d-flex justify-content-center flex-column align-items-center mb-4" style={{marginTop:'100px'}}>
                    <img src={SERV} style={{width:'200px', marginBottom:'35px'}}/>

                    <QRCode value={'walkin|' + this.props.retail_main + '|' + this.props.retail_id} size={450} fgColor='#000080' />

                    <h5 className="mb-4 font-weight-bold" style={{textTransform:'uppercase', fontSize:'2.1rem', maxWidth:'95%', textAlign:'center', color:'#000080', marginTop:'35px'}}>{this.props.workshop_name}</h5>

                    <h5 className="m-0 mb-4 mt-2 font-weight-bold" style={{textTransform:'uppercase', fontSize:'5rem', textAlign:'center', color:'#000080'}}>Scan for service</h5>
                </div>

                <div>
                    <img src={GRAPHICS} style={{width:'100%'}} />
                </div>
            </div>
        }
        if(this.state.view === 'no graphics'){
            display =
            <div ref={el => (this.componentRef = el)} style={{width:'100%', height:'100%'}} className="d-flex justify-content-center flex-column align-items-center pt-4 pb-4">
                <img src={SERV} style={{width:'200px', marginBottom:'35px', marginTop:'15px'}}/>

                <div style={{padding:'25px', border:'2px solid', borderRadius:'1rem'}}>
                    <QRCode value={'walkin|' + this.props.retail_main + '|' + this.props.retail_id} size={450} fgColor='#000080' />
                </div>
                
                <h5 className="mb-4 font-weight-bold" style={{textTransform:'uppercase', fontSize:'2.1rem', maxWidth:'95%', textAlign:'center', color:'#000080', marginTop:'35px'}}>{this.props.workshop_name}</h5>
                
                <h5 className="m-0 font-weight-bold" style={{textTransform:'uppercase', fontSize:'5rem', textAlign:'center', color:'#000080'}}>Scan for service</h5>
            </div>
        }
        

        return display
    }
    render() {
        return (
            <>
                <Button size="sm" color="dark" className="m-0" onClick={() => {
                    this.toggleModal('modalQR')
                }}>Print QR code</Button>

                <Modal style={{maxWidth:'95%'}} isOpen={this.state.modalQR} toggle={() => this.toggleModal('modalQR')}>
                    <ModalHeader toggle={() => this.toggleModal('modalQR')}>QR Code</ModalHeader>
                    <ModalBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{color:'black'}}
                                    className={classnames({ active: this.state.view === 'graphics' })}
                                    onClick={() => { this.toggleTab('graphics') }}
                                >
                                    Guided QR
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{color:'black'}}
                                    className={classnames({ active: this.state.view === 'no graphics' })}
                                    onClick={() => { this.toggleTab('no graphics') }}
                                >
                                    Standalone QR
                                </NavLink>
                            </NavItem>
                        </Nav>
                        
                        {this._renderGraphics()}
                    </ModalBody>
                    <ModalFooter>
                        <ReactToPrint
                            trigger={() => <Button color="light">Print/Save QR code</Button>}
                            content={() => this.componentRef}
                        />
                        <Button className='ml-auto' color='danger' onClick={() => {
                            this.toggleModal('modalQR')
                        }}>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
