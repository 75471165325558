import React, { Component } from 'react'
import firebase from 'firebase'
import PanelHeader from '../components/PanelHeader'
import { CarListingDisplay } from './CarListingDisplay'
import CarListingUpdate from './CarListingUpdate'
import { Button, Card, Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'

export default class CarListing extends Component {
  state = {
    motherload: {},
    load: '1',        //  1 = car make, 2 = car model, 3 = variants
    view: 1,          // states when user selected vehicle TYPE
    selectType: '',
    selectId: '',
    selectMake: {},
    selectMakeId: '',
    selectModel: []   // states when user selected vehicle MAKE
  }

  componentDidMount = () => { this.fetchMotherLoad() }

  /* To fetch the vehicle type and vehicle make */
  fetchMotherLoad = async () => {
    const DATA = { vehicleType: [], vehicleMake: {} }
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    const db = firebase.app(COUNTRY_DB).database()
    let DB_VAL, DB_SNAP

    try {
      DB_SNAP = await db.ref('vehicle_type').once('value')
      DB_VAL = await DB_SNAP.val()

      if (!DB_SNAP.exists()) return this.setState({ load: '3' }, console.log('No vehicle types listed'))
      if (DB_SNAP.exists()) {
        const TYPE = []
        for (const typeId in DB_VAL) {
          let vehicle = { type: DB_VAL[typeId], id: typeId }
          TYPE.push(vehicle)
        }
        DATA.vehicleType = TYPE
      }

      if (DATA.vehicleType.length === 0) return this.setState({ load: '3' })

      DB_SNAP = await db.ref('vehicle_make/').once('value')
      DB_VAL = await DB_SNAP.val()

      if (!DB_SNAP.exists()) return console.log('No vehicle makes listed')
      if (DB_SNAP.exists()) DATA.vehicleMake = DB_VAL

      this.setState({ motherload: DATA, load: '2' }, () => {
        if (this.state.selectType !== '' && this.state.selectId !== '') {
          this.setState({ selectMake: this.state.motherload.vehicleMake[this.state.selectId] })
        }
      })
    } catch (error) { return console.error('Error Occured', error) }
  }

  /* To fetch the model for selected make */
  fetchSelectedModel = async () => {
    const { selectId, selectMakeId } = this.state, MODEL = []
    const db = firebase.database()
    let DB_SNAP, DB_VAL

    try {
      DB_SNAP = await db.ref(`vehicle_model/${selectId}/${selectMakeId.key}`).once('value')
      DB_VAL = await DB_SNAP.val()

      if (!DB_SNAP.exists()) return this.setState({ selectModel: MODEL, view: 2 })
      if (DB_SNAP.exists()) {
        for (const key in DB_VAL) {
          let model = { model: DB_VAL[key], key: key }
          MODEL.push(model)
        }
        this.setState({ selectModel: MODEL, view: 2 })
      }

    } catch (error) { return console.error('Error Occured', error) }
  }

  refresh = () => this.fetchMotherLoad()

  refreshModel = () => this.fetchSelectedModel()

  selectedType = (data) => {
    const { motherload } = this.state
    this.setState({ selectType: data.type, selectId: data.id, selectMake: motherload.vehicleMake[data.id] })
  }

  selectedMake = (data) => {
    this.setState({ selectMakeId: data }, () => this.fetchSelectedModel())
  }

  /* Rendering the vehicle type */
  _renderVehicleType = () => {
    const { load, motherload, selectId } = this.state
    let displayVehicleType

    if (load === '1') return <Card body><div className="loader"></div></Card>

    if (load === '2') {
      if (motherload.vehicleType !== undefined && motherload.vehicleType.length !== 0) {
        displayVehicleType = motherload.vehicleType.map((data) =>
          <CarListingDisplay forType='type' key={data.id} data={data} selectId={selectId} selectedType={this.selectedType} />
        )
      } else {
        displayVehicleType = <Col md={12} className="text-center p-4">No Vehicle Types to display. Let the developers know if you plan on adding more vehicle type.</Col>
      }
      return <div style={{ position: 'relative' }}>
        <Card body className="mt-3 custom-scrollbar" style={{ overflowX: 'scroll' }}>
          <CarListingUpdate type='add' forType='type' parentRefresh={this.refresh} />
          <Row className="flex-nowrap m-0 mt-3">
            {displayVehicleType}
          </Row>
        </Card>
      </div>
    }

    if (load === '3') return <Card style={{ padding: '65px 25px' }}>
      No Vehicle Types to display. Let the developers know if you plan on adding more vehicle type.</Card>
  }

  /* Rendering the vehicle make */
  _renderVehicleMake = () => {
    const { selectType, load, selectMake, selectId } = this.state

    if (selectType === '' && load === '2') return <Card className='text-center' style={{ padding: '65px 25px' }}>Select vehicle type above</Card>

    if (selectType !== '' && load === '2') {
      let TEMP = [], displayVehicleMake, DATA = []
      for (const key in selectMake) {
        let make = { make: selectMake[key].make, logo: selectMake[key].logo, key: key }
        TEMP.push(make)
      }
      DATA = TEMP.sort((a, b) => a.make.localeCompare(b.make))

      displayVehicleMake = DATA.map(data => <CarListingDisplay forType='make' data={data} key={data.key} selectedMake={this.selectedMake} selectType={selectType} id={selectId} parentRefresh={this.refresh} />)

      return <Card body>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="m-0" style={{ display: 'inline-block' }}>Vehicle Brands</h3>
          <CarListingUpdate forType='make' type='add' id={selectId} parentRefresh={this.refresh} />
        </div>
        <Row className="custom-scrollbar mt-3 align-items-center" style={{ maxHeight: "450px", overflowY: 'scroll' }}>{displayVehicleMake.length !== 0 ? displayVehicleMake : <Col md={12} className="text-center p-4">No {this.state.selectType} Make to display. Click on the Add Vehicle Make button to add.</Col>}
        </Row>
      </Card>
    }

    if (load === '3') return <Card body className='text-center' style={{ padding: '65px 25px' }}>No vehicle type stored in database.</Card>

  }

  /* Rendering the vehicle model */
  _renderVehicleModel = () => {
    const { selectModel, selectId, selectMakeId, selectType } = this.state
    let vehicleModel
    if (selectModel.length === 0) {
      vehicleModel = <div className="text-center" style={{ padding: '65px 25px' }}>No vehicle model to display</div>
    } else {
      vehicleModel = selectModel.map((data, index) => <CarListingDisplay forType='model' key={index} model={data} make={selectMakeId} id={selectId} selectType={selectType} parentRefresh={this.refreshModel} />)
    }
    return <Card body>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h3 className="m-0">Vehicle Model</h3>
        <div>
          <Button color="dark" className="m-0 mr-3" style={{ display: 'inline-block' }} onClick={() => this.setState({ view: 1, selectMakeId: '' })}>Back</Button>
          <CarListingUpdate forType='model' type='add' id={selectId} make={selectMakeId} parentRefresh={this.refreshModel} />
        </div>
      </div>
      {vehicleModel}
    </Card>
  }


  render() {
    const { view } = this.state
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {this._renderVehicleType()}
          {view === 1 && this._renderVehicleMake()}
          {view === 2 && this._renderVehicleModel()}
        </div>
      </div>
    )
  }
}

CarListing = inject('mobx_auth')(observer(CarListing))