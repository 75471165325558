import React, { Component } from 'react'
import firebase from 'firebase';
import { PanelHeader } from "components";

import { Button, Card, CardBody } from 'reactstrap'
import AnnouncementsAdd from './AnnouncementsAdd';
import AnnouncementDelete from './AnnouncementDelete';
import { inject, observer } from 'mobx-react';

export default class Announcements extends Component {
	state = {
		data : [],
		load : '1',
	}

	componentDidMount() {
		this.fetchAnnouncement()	
	}
	fetchAnnouncement = async () => {
		let DATA = []
		let COUNTRY_DB = this.props.mobx_auth.COUNTRY
		let SNAP = await firebase.app(COUNTRY_DB).database().ref('announcementSU/').once('value')
		if(SNAP.exists()) {
			for (const key in SNAP.val()) {
				let CURRENT = SNAP.val()[key]
				CURRENT.key = key

				DATA.unshift(CURRENT)
			}
			this.setState({ data : DATA, load : '2' }, () => { console.log(this.state.data) })
		}
		else this.setState({ load : '3' })
	}

	_renderAnnouncements = () => {
		let display;
		const { load, data } = this.state

		if(load === '2'){
			display = data.map((data,index) => (
				<Card key={index}>
					<CardBody>
						<h6>{data.title}</h6>
						<p>{data.body}</p>
						<div className='d-flex justify-content-between align-items-end'>
							<small>{new Date(data.timestamp).toLocaleString('en-MY')}</small>
							<AnnouncementDelete parentFetch={this.fetchAnnouncement} data={data} />
						</div>
					</CardBody>
				</Card>
			))
		}

		return display;
	}
    render() {
        return (
            <div>
                <PanelHeader size='sm' />
                <div className='content'>
					<div className='position-relative'>
						<AnnouncementsAdd parentFetch={this.fetchAnnouncement} />
					</div>
					{this._renderAnnouncements()}
                </div>
            </div>
        )
    }
}
Announcements = inject('mobx_auth')(observer(Announcements))