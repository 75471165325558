import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, UncontrolledCollapse, Button, CardBody, Card, Badge } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import firebase from 'firebase';
import {inject, observer} from 'mobx-react'
import { version } from "configs/const.js"
import {serv_user_development, serv_provider_development, serv_user_production, serv_provider_production} from 'configs/firebaseCF.js'

import logo from "assets/img/serv-white.png";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      unread:0,
      pending:0,
    };
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    this.countUnread()
    this.countApprove()
    // this.fetchUsers()
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  
  countApprove = () => {
    let that = this;
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    
    firebase.app(COUNTRY_DB).database().ref('waitingforapproval/').on('value', function(snapshot){
      let COUNT = 0

      if(snapshot.exists()) {
        let keys = Object.keys(snapshot.val())
        keys.forEach((pending_id) => {
          COUNT = COUNT + 1
        })
        
        
        that.setState({pending:COUNT})
      } else {
        // nothing
        that.setState({pending:0})
      }
      
    })
  }

  countUnread = () => {
    let that = this
    let adminmessageREF = 'adminmessages/'
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    firebase.app(COUNTRY_DB).database().ref(adminmessageREF).on('value', function(snapshot) {
      let COUNT = 0;

      if(snapshot.exists()) {
        let keys = Object.keys(snapshot.val())
        keys.forEach((user_id) => {
          let chat = Object.keys(snapshot.val()[user_id])
          chat.forEach((chat_id) => {
            // console.log(snapshot.val()[user_id][chat_id]);
            if(snapshot.val()[user_id][chat_id].unread === true){              
              COUNT = COUNT + 1
            }
          })
        })
        
        that.setState({unread:COUNT})
        // that.props.mobx_auth.setUNREAD(COUNT)
          
      } else {
        console.log('No one has chatted yet');
        that.setState({unread:0})
        // that.props.mobx_auth.setUNREAD(0)
      }
    })
  }
  createLinks = routes => {
    // console.log(this.props.mobx_auth.ROLE);
    return routes.map((prop, key) => {
      if(!prop.redirect && !prop.collapse && prop.roles.indexOf(this.props.mobx_auth.ROLE) !== -1 && prop.country.indexOf(this.props.mobx_auth.COUNTRY) !== -1){
       {console.log("prop", prop)}
        return (
          <li key={key} className={ this.activeRoute(prop.path) + (prop.pro ? " active active-pro" : "")}>
            <NavLink to={prop.path} className="nav-link" activeClassName="active">
              <i className={"fas fa-" + prop.icon} />
              <p>{prop.name}</p>
            </NavLink>
          </li>
        );
      }
      if(!prop.redirect && prop.collapse && prop.roles.indexOf(this.props.mobx_auth.ROLE) !== -1 && prop.country.indexOf(this.props.mobx_auth.COUNTRY) !== -1){
        return (
          <li key={key} color="primary"  className={ this.activeRoute(prop.path) + (prop.pro ? " active active-pro" : "")}>
            <NavLink to={prop.path} id={prop.id} className="nav-link" activeClassName="active">
              <i className={"fas fa-" + prop.icon} />
              <p>{prop.name}</p>
              {prop.id === 'partner' && (this.state.pending !== 0 || this.state.unread !== 0) ? <div className='blinking'></div> : null}
            </NavLink>
            <UncontrolledCollapse toggler={"#" + prop.id}>
              <Card body outline style={styles.Dropdown}>
                <ul style={{padding:'0'}}>
                  {prop.pages.map((prop2, key2) => {
                    // {console.log("props 2", prop.country === 'MY')}
                    if(prop2.country.indexOf(this.props.mobx_auth.COUNTRY) !== -1) {
                      return (
                        <li key={key2} className={ this.activeRoute(prop2.path) + (prop2.pro ? " active active-pro" : "")}>
                          <NavLink to={prop2.path} className="nav-link" activeClassName="active">
                            <p>{prop2.name}</p>
  
                            {prop2.name === 'Service Provider' && this.state.unread !== 0 ? <div className='red-dot'>{this.state.unread}</div> : null}
  
                            {prop2.name === 'SP Approve' && this.state.pending !== 0 ? <div className='red-dot'>{this.state.pending}</div> : null}
                          </NavLink>
                        </li>
                      )
                    }
                  })}
                </ul>
              </Card>
            </UncontrolledCollapse>
          </li>
        );
      }
    })
  };
  _renderSidebar = () => {
    let display

    display = <Nav>{this.createLinks(this.props.routes)}</Nav>

    return display
  }
  render() {
    return (
      <div className="sidebar">
        <div className="logo">
          <a
            href="#"
            className="simple-text logo-normal"
          >
            <div className="logo-img" style={{display: 'flex',flexFlow: 'column', alignItems: 'center'}}>
              <img src={logo} style={{width:'85%'}} alt="serv-logo" />
              <div className="mt-2" style={{color:'white', whiteSpace:'pre-wrap', lineHeight:'1.4', fontSize:'0.75rem', textAlign:'center', fontWeight:'bold'}}>Booking Navigator</div>
            </div>
          </a>
          <div style={{textAlign:'center', color:'rgba(255,255,255,0.4)'}}>
            <small>V {version.version}</small>
          </div>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          {this._renderSidebar()}
        </div>
      </div>
    );
  }
}
const styles = {
  Icon: {color:'black'},
  Dropdown: { backgroundColor: 'rgba(55, 0, 128, 0.65)', boxShadow: 'none', margin: '0', padding: '5px 0 5px 0', boxSizing: 'border-box', borderRadius: '0' }
}
Sidebar = inject('mobx_auth', 'mobx_config')(observer(Sidebar));
export default Sidebar;
