export default () => {
    // const testFunction = (i) => {
    //     return 'hello' + i
    // }
    // eslint-disable-next-line
    self.addEventListener('message', e => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;
        var DATA = e.data;
        var RESPONSE = {}

        let vehicle_count = 0;
        let request_count = 0;

        // 1. calc number of vehicles
        // 2. calc number of requests made
        // 2a. successful jobs by if checklist !== undefined
        // 2b. deleted jobs if checklist === unedfined
        // 3. total spent

        RESPONSE.vehicles_analysis = {}
        // 1. calc number of vehicles
        if (DATA.vehicles !== undefined) {
            for (const vehicle_id in DATA.vehicles) {
                let VEHICLE = DATA.vehicles;
                let requests_made = 0
                let requests_success = 0, requests_fail = 0
                vehicle_count = vehicle_count + 1;

                if (VEHICLE[vehicle_id].book_history !== undefined) {
                    let HISTORY = VEHICLE[vehicle_id].book_history
                    requests_made = Object.keys(HISTORY).length
                    requests_success = 0;
                    requests_fail = 0;

                    for (const request_id in HISTORY) {
                        request_count = request_count + 1;
                        if (HISTORY[request_id].checklist !== undefined) {
                            requests_success = requests_success + 1
                        } else {
                            requests_fail = requests_fail + 1
                        }
                    }

                }

                RESPONSE.vehicles_analysis[vehicle_id] = {
                    'requests_made': requests_made,
                    'requests_fail': requests_fail,
                    'requests_success': requests_success,
                }
            }

            RESPONSE.vehicle_count = vehicle_count
            RESPONSE.request_count = request_count
        }

        // console.log(vehicle_count, request_count)

        postMessage(RESPONSE);
    });
};