import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Progress } from 'reactstrap';
import firebase from 'firebase';
import PLACE from "assets/img/logo-placeholder.jpg"
import { inject, observer } from 'mobx-react';

class ServiceListingUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAdd: false,
            displayButt: true,
            TILE_ID: '',
            progress: 0,
            // main items for service tiles branch
            type: 'webview',
            show: false,
            title: '',
            link: '',
            icon: '',
            canUploadIcon: false,
            previewIcon: PLACE,
            poweredBy: '',
            canUplaodPoweredBy: false,
            previewPoweredBy: PLACE,
        };
    }
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    //HANDLE THE CHOOSE FILE BUTTON
    handleIconChange = (evt) => {
        let that = this;
        const file = document.querySelector('#upload_icon').files[0];

        if(file){
            var checker = new Image();

            checker.src = window.URL.createObjectURL(file);

            checker.onload = function() {
                let fileSize = file.size;
                // var width = checker.naturalWidth,height = checker.naturalHeight;
                window.URL.revokeObjectURL(checker.src);
                if(fileSize < 100000){
                    that.setState({canUploadIcon:true, previewIcon: URL.createObjectURL(file)})
                } else {
                    that.setState({canUploadIcon:false})
                }
            }
        } else {
            console.log("Image is empty");
        }

    }
    //HANDLE THE CHOOSE FILE BUTTON
    handlePoweredChange = (evt) => {
        let that = this;
        const file = document.querySelector('#upload_powered').files[0];

        if(file){
            var checker = new Image();

            checker.src = window.URL.createObjectURL(file);

            checker.onload = function() {
                let fileSize = file.size;
                // var width = checker.naturalWidth,height = checker.naturalHeight;
                window.URL.revokeObjectURL(checker.src);
                if(fileSize < 100000){
                    that.setState({canUplaodPoweredBy:true, previewPoweredBy: URL.createObjectURL(file)})
                } else {
                    that.setState({canUplaodPoweredBy:false})
                }
            }
        } else {
            console.log("Image is empty");
        }

    }
    // ================ SAVING FLOW ================
    // 1. upload icon into storage _1uploadIcon
    // 2. if poweredby exists, upload poweredby image into storage
    // 3. upload all the non image data into the database
    // 4. close and clear all
    _save = async () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let TILE_ID = firebase.app(COUNTRY_DB).database().ref('service_tiles/').push().getKey()
        let fileIcon = document.querySelector('#upload_icon').files[0];
        let filePowered = document.querySelector('#upload_powered').files[0];
        // 0. error handlers
        console.log('0. running error handlers')
        if(this.state.type === ''){
            alert('Please pick a tile type!')
            this.setState({displayButt: true});
            return;
        }
        if(this.state.title === ''){
            alert('Please fill in Tile Title!')
            this.setState({displayButt: true});
            return;
        }
        if(this.state.type === 'webview' && this.state.link === ''){
            alert('Please fill in the URL for the webview!')
            this.setState({displayButt: true});
            return;
        }
        // Checks that icon image is chosen and within file size limits
        if(fileIcon === undefined){
            alert('Please upload image for tile icon!')
            this.setState({displayButt: true});
            return;
        }
        if(fileIcon !== undefined && this.state.canUploadLogo === false){
            alert('Icon image file size too large! Please upload images below 100kb.')
            this.setState({displayButt: true});
            return;
        }
        // KIV error handler for powered by

        // 1. upload icon into storage _1uploadIcon
        await this.setState({progress: 10}, () => {
            this._1uploadIcon(TILE_ID);
        })
    }
    _1uploadIcon = (TILE_ID) => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let that = this;
        let fileIcon = document.querySelector('#upload_icon').files[0];
        this.setState({TILE_ID : TILE_ID})
        const storageRef = firebase.storage().ref('service_tiles');
        let uploadIcon = storageRef.child(TILE_ID).child('icon').put(fileIcon);

        console.log('1. upload icon', TILE_ID);
        console.log(this.state.progress);

        // UNCOMMENT THIS CODE
        uploadIcon.on('state_changed', function(snapshot) {
            that.setState({progress: 25})
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                // that.setState({progressColor:'warning', text : 'PAUSED'})
                break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                // that.setState({progressColor:'success', text : 'UPLOADING'})
                break;
            }
        }, function(error){
            // IF IMAGE UPLOAD FAILED
            that.setState({progress: 0})
            alert('Upload failed, please try again!\n', error.message);
            that.setState({displayButt: true})
        }, function () {
            // IF IMAGE UPLOAD SUCCEED
            uploadIcon.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                firebase.app(COUNTRY_DB).database().ref('service_tiles/' + TILE_ID).update({
                    icon: downloadURL,
                }).then(() => {
                    // Successfully stored image and downloadURL
                    that.setState({progress: 35})
                    // 2. if poweredby exists, upload poweredby image into storage
                    let filePowered = document.querySelector('#upload_powered').files[0];
                    if(filePowered !== undefined){
                        that.setState({progress: 45}, () => {
                            that._2uploadPoweredBy(TILE_ID);
                        })
                    } else {
                        that.setState({progress: 85}, () => {
                            firebase.app(COUNTRY_DB).database().ref('service_tiles/' + TILE_ID).update({
                                poweredBy: ''
                            }).then(() => {
                                that._3saveDataToFirebase(TILE_ID);
                            })
                        })
                    }

                })
                
            })
        })
    }
    _2uploadPoweredBy = (TILE_ID) => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // 2. upload poweredby image into storage
        console.log('2. upload powered by', this.state.TILE_ID);
        let that = this;
        let filePowered = document.querySelector('#upload_powered').files[0];
        const storageRef = firebase.storage().ref('service_tiles');
        let uploadPowered = storageRef.child(TILE_ID).child('poweredBy').put(filePowered);

        uploadPowered.on('state_changed', function(snapshot) {
            that.setState({progress: 65})
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                // that.setState({progressColor:'warning', text : 'PAUSED'})
                break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                // that.setState({progressColor:'success', text : 'UPLOADING'})
                break;
            }
        }, function(error){
            // IF IMAGE UPLOAD FAILED
            that.setState({progress: 0})
            alert('Upload failed, please try again!\n', error.message);
            that.setState({displayButt: true})
        }, function () {
            // IF IMAGE UPLOAD SUCCEED
            uploadPowered.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                firebase.app(COUNTRY_DB).database().ref('service_tiles/' + TILE_ID).update({
                    poweredBy: downloadURL,
                }).then(() => {
                    // Successfully stored image and downloadURL
                    that.setState({progress: 85})
                    // 2. if poweredby exists, upload poweredby image into storage
                    that._3saveDataToFirebase(TILE_ID);
                })
                
            })
        })
    }
    _3saveDataToFirebase = (TILE_ID) => {
        console.log('3. save to firebase', this.state.TILE_ID)
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // 3. upload all the non image data into the database
        firebase.app(COUNTRY_DB).database().ref('service_tiles/' + TILE_ID).update({
            type: this.state.type,
            show: false,
            title: this.state.title,
            link: this.state.type === 'webview' ? this.state.link : '',
        }).then(() => {
            // 4. close and clear all
            this.setState({progress:100}, () => {
                setTimeout(() => {
                    this.setState({
                        progress: 0,
                        modalAdd: !this.state.modalAdd,
                        displayButt: true,
                        type: 'webview',
                        show: false,
                        title: '',
                        link: '',
                        canUplaodPoweredBy: false,
                        canUploadIcon: false,
                        previewIcon: PLACE,
                        previewPoweredBy: PLACE,
                    })
                }, 1000);
            })
            console.log('4. close & clear');
        })
    }
    _renderURL = () => {
        let display;

        if(this.state.type === 'webview'){
            display =
            <FormGroup>
                <Label for='link'>Webview Link</Label>
                <Input type='text' name='link' id='link' onChange={this.handleChange}/>
            </FormGroup>
        }

        return display;
    }
    _renderButton = () => {
        let display;

        if(this.state.displayButt === true){
            display =
            <Button color='primary' onClick={() => {
                this.setState({displayButt: false, progress: 10}, () => {
                    this._save();
                })
            }}>Save</Button>
        }
        if(this.state.displayButt === false){
            display =
            <Button disabled color='success' className="d-flex align-items-center" onClick={this._save}><div className="mr-1">Saving</div><div className="btnloader"></div></Button>
        }

        return display;
    }
    render() {
        return (
            <div>
                <Button className="m-0" color="dark" onClick={() => {this.toggleModal('modalAdd')}}>Add Service Tile</Button>

                <Modal className='modal-default' backdrop="static" isOpen={this.state.modalAdd} toggle={() => {this.toggleModal('modalAdd')}}>
                    <ModalHeader toggle={() => {this.toggleModal('modalAdd')}}>Add New Service Tile</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for='type'>Type</Label>
                            <Input type='select' name='type' id='type' onChange={this.handleChange} value={this.state.type}>
                                <option value='webview'>Webview</option>
                                <option value='hardcoded'>Hardcoded</option>
                            </Input>
                            <FormText className="muted">Type of tile (Hardcoded or webview)</FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label for='title'>Tile Title</Label>
                            <Input type='text' name='title' id='title' onChange={this.handleChange}/>
                        </FormGroup>
                        {this._renderURL()}
                        <Row>
                            <Col>
                                <Label for="upload_icon">Upload Icon</Label>
                                <Input type="file" accept="image/*" name="upload_icon" id="upload_icon" onChange={this.handleIconChange}/>
                                <FormText color="danger">
                                    Icon (Make sure the the image size is less than 100kb!)
                                </FormText>
                                <div>
                                    <img style={{width:250}} src={this.state.previewIcon}/>
                                </div>    
                            </Col>

                            <Col>
                                <Label for="upload_powered">Upload Powered By (Optional)</Label>
                                <Input type="file" accept="image/*" name="upload_powered" id="upload_powered" onChange={this.handlePoweredChange}/>
                                <FormText color="danger">
                                    Powered By (Make sure the the image size is less than 100kb!)
                                </FormText>
                                <div>
                                    <img style={{width:250}} src={this.state.previewPoweredBy}/>
                                </div>
                                <div className="text-right">
                                    <Button color="danger" onClick={() => {
                                        console.log('B4',document.querySelector('#upload_powered').files);
                                        document.querySelector('#upload_powered').value = ""
                                        this.setState({previewPoweredBy: PLACE}, () => {
                                            console.log('AFT',document.querySelector('#upload_powered').files);
                                        })
                                    }}>Clear Selection</Button>
                                </div>
                            </Col>
                        </Row>

                        {
                            this.state.progress !== 0 ?
                            <div>
                                <Progress animated color="success" value={this.state.progress}>{this.state.progress}</Progress>
                            </div> : null
                        }
                       
                    </ModalBody>
                    <ModalFooter>
                        {this._renderButton()}{' '}
                        <Button className='ml-auto' color='secondary' onClick={() => {this.toggleModal('modalAdd')}}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ServiceListingUpdate.propTypes = {

};

ServiceListingUpdate = inject('mobx_auth')(observer(ServiceListingUpdate))
export default ServiceListingUpdate