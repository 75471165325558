import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { PanelHeader } from 'components';
import { Card } from 'reactstrap'
import BookingRequest from 'pages/BookingRequestMain.jsx';
// import BookingRequestSingle from 'pages/V1BookingRequestSelected.jsx';
import BookingRequestSingle from 'pages/BookingRequestSelected.jsx';

class BookingRoutes extends Component {
    render() {
        return (
            <div>
                <PanelHeader size="sm" />
                <div className='content'>
                    {/* "/user/Booking-Request" */}
                    <Route exact path="/user/Booking-Request" component={BookingRequest} />
                    <Route exact path="/user/Booking-Request/:id" component={Request} />
                </div>
            </div>
        );
    }
}


function Request({ match }) {
    // console.log(match.params.id);
    return <BookingRequestSingle id={match.params.id} />;
}

export default BookingRoutes;