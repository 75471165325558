const SERV_USER_BETA = "https://us-central1-servuserplayground.cloudfunctions.net/"
// const SERV_USER_BETA = "https://us-central1-serv-unique-plate-sandbox.cloudfunctions.net/"

const SERV_PROVIDERADMIN_BETA = "https://us-central1-servprovideradmin.cloudfunctions.net/"
const SERV_USER_PRODUCTION = "https://us-central1-serv-v2-production-fcb23.cloudfunctions.net/"
const SERV_PROVIDERADMIN_PRODUCTION = "https://us-central1-servprovider-v2-production.cloudfunctions.net/"

const serv_user_development = {
    "completejobOnUser": SERV_USER_BETA + "completejobOnUser?",

    "deletejobOnUser": SERV_USER_BETA + "deletejobOnUser?",

    "jobAssigned": SERV_USER_BETA + "jobAssigned?",

    "newAnnouncement": SERV_USER_BETA + "newAnnouncement?",

    "fetchAnnouncement": SERV_USER_PRODUCTION + "fetchAnnouncement?",

    "newPromoCode": SERV_USER_BETA + "newPromoCode?",

    "updatePaymentToUnpaid": SERV_USER_BETA + "updatePaymentToUnpaid?",

    "updateQuotationStatus": SERV_USER_BETA + "updateQuotationStatus?",

    "deleteAnnouncement": SERV_USER_BETA + "deleteAnnouncement?",

    "fetchUsers": SERV_USER_BETA + "fetchUsers?",

    "newVehicleType": SERV_USER_BETA + "newVehicleType?",

    "addVehicleMake": SERV_USER_BETA + "addVehicleMake?",

    "fetchVehicleMotherload": SERV_USER_BETA + "fetchVehicleMotherload?",

    "addUserType": SERV_USER_BETA + "addUserType?",

    "fetchUserType": SERV_USER_BETA + "fetchUserType?",

    "deleteUserType": SERV_USER_BETA + "deleteUserType?",

    "editUserType": SERV_USER_BETA + "editUserType?",

    "addUserTier": SERV_USER_BETA + "addUserTier?",

    "workshopAdd": SERV_USER_BETA + "workshopAdd?",

    "workshopAmenitiesAdd": SERV_USER_BETA + "workshopAmenitiesAdd?",

    "workshopAmenities": SERV_USER_BETA + "workshopAmenities?",

    "workshopFetch": SERV_USER_BETA + "workshopFetch?",

    "workshopFetchIndividual": SERV_USER_BETA + "workshopFetchIndividual?",

    "workshopCreateWorkshopAdmin": SERV_USER_BETA + "workshopCreateWorkshopAdmin?",

    "workshopCreateWorkshopAdmin_existing": SERV_USER_BETA + "workshopCreateWorkshopAdmin_existing?",

    "workshopVerify": SERV_USER_BETA + "workshopVerify?",

    "workshopAnalytics": SERV_USER_BETA + "workshopAnalytics?",

    "workshopPaymentAdmin": SERV_USER_BETA + "workshopPaymentAdmin?",

    "personalNotifications": SERV_USER_BETA + "personalNotifications?",

    "workshopTransactionPaid": SERV_USER_BETA + "workshopTransactionPaid?",

    "workshopMDR": SERV_USER_BETA + "workshopMDR?",

    "myAssistAnalysis": SERV_USER_BETA + "myAssistAnalysis?",

    "merchantSummary": SERV_USER_BETA + "merchantSummary?",

    "workshopMonthlyReport": SERV_USER_BETA + "workshopMonthlyReport?",

    "fetchReturningCustomer": SERV_USER_BETA + "fetchReturningCustomer?",

    "bugReport": SERV_USER_BETA + "bugReport?",

    "featureSuggestion": SERV_USER_BETA + "featureSuggestion?",

    "deleteRequests": SERV_USER_BETA + "deleteRequests?",

    "readBookStatus": SERV_USER_BETA + "readBookStatus?",

    "userAnalytics": SERV_USER_BETA + "userAnalytics?",

    "vehicleAnalytics": SERV_USER_BETA + "vehicleAnalytics?",

    "createCSRFU": SERV_USER_BETA + "createCSRFU?",

    "removeCookieU": SERV_USER_BETA + "removeCookieU?",

    "workshopRemoveIndividual": SERV_USER_BETA + "workshopRemoveIndividual?",

    "workshopFetchAdmin": SERV_USER_BETA + "workshopFetchAdmin?",

    "workshopRemoveAdmin": SERV_USER_BETA + "workshopRemoveAdmin?",

    "updateDateTimeBook": SERV_USER_BETA + "updateDateTimeBook?",

    "checkAdmin": SERV_USER_BETA + "checkAdmin"
}

const serv_provider_development = {
    "addRequestData": SERV_PROVIDERADMIN_BETA + "addRequestData?",

    "bookingdetailsbyUser": SERV_PROVIDERADMIN_BETA + "bookingdetailsbyUser?",

    "newAnnouncement": SERV_PROVIDERADMIN_BETA + "newAnnouncement?",

    "newChatAdmin": SERV_PROVIDERADMIN_BETA + "newChatAdmin?",

    "newJobAssigned": SERV_PROVIDERADMIN_BETA + "newJobAssigned?",

    "newJobRequestAvailable": SERV_PROVIDERADMIN_BETA + "newJobRequestAvailable?",

    "promoCodes": SERV_PROVIDERADMIN_BETA + "promoCodes?",

    "requestToDelete": SERV_PROVIDERADMIN_BETA + "requestToDelete?",

    "updateReceipt": SERV_PROVIDERADMIN_BETA + "updateReceipt?",

    "usePromoCodes": SERV_PROVIDERADMIN_BETA + "usePromoCodes?",

    "notifyDeclined": SERV_PROVIDERADMIN_BETA + "notifyDeclined?",

    "createCSRFP": SERV_PROVIDERADMIN_BETA + "createCSRFP?",

    "removeCookieP": SERV_PROVIDERADMIN_BETA + "removeCookieP?",

    "fetchBookingsHistory": SERV_PROVIDERADMIN_BETA + "fetchBookingsHistory?",
    
    "bookingHistorySelected": SERV_PROVIDERADMIN_BETA + "bookingHistorySelected?",
}

const serv_user_production = {
    "completejobOnUser": SERV_USER_PRODUCTION + "completejobOnUser?",

    "deletejobOnUser": SERV_USER_PRODUCTION + "deletejobOnUser?",

    "jobAssigned": SERV_USER_PRODUCTION + "jobAssigned?",

    "newAnnouncement": SERV_USER_PRODUCTION + "newAnnouncement?",

    "fetchAnnouncement": SERV_USER_PRODUCTION + "fetchAnnouncement?",

    "newPromoCode": SERV_USER_PRODUCTION + "newPromoCode?",

    "updatePaymentToUnpaid": SERV_USER_PRODUCTION + "updatePaymentToUnpaid?",

    "updateQuotationStatus": SERV_USER_PRODUCTION + "updateQuotationStatus?",

    "deleteAnnouncement": SERV_USER_PRODUCTION + "deleteAnnouncement?",

    "fetchUsers": SERV_USER_PRODUCTION + "fetchUsers?",

    "newVehicleType": SERV_USER_PRODUCTION + "newVehicleType?",

    "addVehicleMake": SERV_USER_PRODUCTION + "addVehicleMake?",

    "fetchVehicleMotherload": SERV_USER_PRODUCTION + "fetchVehicleMotherload?",

    "addUserType": SERV_USER_PRODUCTION + "addUserType?",

    "fetchUserType": SERV_USER_PRODUCTION + "fetchUserType?",

    "deleteUserType": SERV_USER_PRODUCTION + "deleteUserType?",

    "editUserType": SERV_USER_PRODUCTION + "editUserType?",

    "addUserTier": SERV_USER_PRODUCTION + "addUserTier?",

    "workshopAdd": SERV_USER_PRODUCTION + "workshopAdd?",

    "workshopAmenitiesAdd": SERV_USER_PRODUCTION + "workshopAmenitiesAdd?",

    "workshopAmenities": SERV_USER_PRODUCTION + "workshopAmenities?",

    "workshopFetch": SERV_USER_PRODUCTION + "workshopFetch?",

    "workshopFetchIndividual": SERV_USER_PRODUCTION + "workshopFetchIndividual?",

    "workshopCreateWorkshopAdmin": SERV_USER_PRODUCTION + "workshopCreateWorkshopAdmin?",

    "workshopCreateWorkshopAdmin_existing": SERV_USER_PRODUCTION + "workshopCreateWorkshopAdmin_existing?",

    "workshopVerify": SERV_USER_PRODUCTION + "workshopVerify?",

    "workshopAnalytics": SERV_USER_PRODUCTION + "workshopAnalytics?",

    "workshopPaymentAdmin": SERV_USER_PRODUCTION + "workshopPaymentAdmin?",

    "personalNotifications": SERV_USER_PRODUCTION + "personalNotifications?",

    "workshopTransactionPaid": SERV_USER_PRODUCTION + "workshopTransactionPaid?",

    "workshopMDR": SERV_USER_PRODUCTION + "workshopMDR?",

    "myAssistAnalysis": SERV_USER_PRODUCTION + "myAssistAnalysis?",

    "merchantSummary": SERV_USER_PRODUCTION + "merchantSummary?",

    "workshopMonthlyReport": SERV_USER_PRODUCTION + "workshopMonthlyReport?",

    "fetchReturningCustomer": SERV_USER_PRODUCTION + "fetchReturningCustomer?",

    "bugReport": SERV_USER_PRODUCTION + "bugReport?",

    "featureSuggestion": SERV_USER_PRODUCTION + "featureSuggestion?",

    "deleteRequests": SERV_USER_PRODUCTION + "deleteRequests?",

    "readBookStatus": SERV_USER_PRODUCTION + "readBookStatus?",

    "userAnalytics": SERV_USER_PRODUCTION + "userAnalytics?",

    "vehicleAnalytics": SERV_USER_PRODUCTION + "vehicleAnalytics?",

    "createCSRFU": SERV_USER_PRODUCTION + "createCSRFU?",

    "removeCookieU": SERV_USER_PRODUCTION + "removeCookieU?",

    "workshopRemoveIndividual": SERV_USER_PRODUCTION + "workshopRemoveIndividual?",

    "workshopFetchAdmin": SERV_USER_PRODUCTION + "workshopFetchAdmin?",

    "workshopRemoveAdmin": SERV_USER_PRODUCTION + "workshopRemoveAdmin?",

    "updateDateTimeBook": SERV_USER_PRODUCTION + "updateDateTimeBook?",

    "checkAdmin": SERV_USER_PRODUCTION + "checkAdmin"
}

const serv_provider_production = {
    "addRequestData": SERV_PROVIDERADMIN_PRODUCTION + "addRequestData?",

    "bookingdetailsbyUser": SERV_PROVIDERADMIN_PRODUCTION + "bookingdetailsbyUser?",

    "newAnnouncement": SERV_PROVIDERADMIN_PRODUCTION + "newAnnouncement?",

    "newChatAdmin": SERV_PROVIDERADMIN_PRODUCTION + "newChatAdmin?",

    "newJobAssigned": SERV_PROVIDERADMIN_PRODUCTION + "newJobAssigned?",

    "newJobRequestAvailable": SERV_PROVIDERADMIN_PRODUCTION + "newJobRequestAvailable?",

    "promoCodes": SERV_PROVIDERADMIN_PRODUCTION + "promoCodes?",

    "requestToDelete": SERV_PROVIDERADMIN_PRODUCTION + "requestToDelete?",

    "updateReceipt": SERV_PROVIDERADMIN_PRODUCTION + "updateReceipt?",

    "usePromoCodes": SERV_PROVIDERADMIN_PRODUCTION + "usePromoCodes?",

    "notifyDeclined": SERV_PROVIDERADMIN_PRODUCTION + "notifyDeclined?",

    "createCSRFP": SERV_PROVIDERADMIN_PRODUCTION + "createCSRFP?",

    "removeCookieP": SERV_PROVIDERADMIN_PRODUCTION + "removeCookieP?",

    "fetchBookingsHistory": SERV_PROVIDERADMIN_PRODUCTION + "fetchBookingsHistory?",

    "bookingHistorySelected": SERV_PROVIDERADMIN_PRODUCTION + "bookingHistorySelected?",
}


export {
    serv_user_development,
    serv_provider_development,
    serv_user_production,
    serv_provider_production
};