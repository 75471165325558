import React, { Component } from "react";
import PanelHeader from '../components/PanelHeader'
import firebase from "firebase";
import { Card, Button, Row, Col } from "reactstrap";
import TireListingAdd from "./TireListingAdd";
import { inject, observer } from "mobx-react";

export default class TireListing extends Component {
  state = {
    display: "diameter",
    load: "1",
    diameterData: [],
    sizeData: ["size1", "size2"],
    selectedDiameter: {},
    selectedSize: [],
    input: {
      diameter: "",
      size: [],
    },
  };
  componentWillMount = () => {
    this.fetchDiameterData();
    this.refresh();
  };

  fetchDiameterData = async () => {
    await this.setState({ load: "1" });
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    firebase.app(COUNTRY_DB).database().ref("tire_diameter/").once("value").then((snapshot) => {
      if (!snapshot.exists()) {
        this.setState({ load: "3" });
      } else {
        let DATA = [];
        let DATA_ARR = [];
        let TEMP;
        TEMP = snapshot.val();
        for (const key in TEMP) {
          let DIAMETER_ID = key;
          let DIAMETER = TEMP[key];
          let DIAMETER_DATA = {
            diameterId: DIAMETER_ID,
            diameterVal: DIAMETER,
          };
          DATA_ARR.push(DIAMETER);
          DATA.push(DIAMETER_DATA);
        }
        this.setState({
          diameterArr: DATA_ARR,
          diameterData: DATA,
          load: "2",
        });
      }
    });
  };

  fetchSelectedSize = async () => {
    await this.setState({ display: "size", load: "1" });
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    let DIAMETER_ID = this.state.selectedDiameter.diameterId;
    let SIZE = [];
    firebase.app(COUNTRY_DB).database().ref(`tire_size/${DIAMETER_ID}`).once("value").then((snapshot) => {
      if (snapshot.exists()) {
        SIZE = snapshot.val();
        this.setState({ sizeData: SIZE, load: "2" });
      } else {
        this.setState({ load: "3" });
      }
    })
      .catch((err) => console.log(err));
  };

  addDiameter = async (diameter) => {
    let DIAMETER = diameter;
    let DIAMETER_ID;
    let UPDATES = {};
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    if (this.state.diameterArr.indexOf(DIAMETER) !== -1) {
      alert(
        "The diameter is already added, please check the diameter list again. Thank you"
      );
      return;
    }
    DIAMETER_ID = firebase.app(COUNTRY_DB).database().ref("tire_diameter/").push().getKey();

    UPDATES[`tire_diameter/${DIAMETER_ID}`] = DIAMETER;

    firebase.app(COUNTRY_DB).database().ref().update(UPDATES).then(() => console.log("Uploaded successfully")).catch((err) => console.log(err));
  };

  addSize = async (size) => {
    let DIAMETER = this.state.selectedDiameter;
    let SIZE = this.state.sizeData;
    let DIAMETER_ID = this.state.selectedDiameter.diameterId;
    let UPDATES = {};
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    if (SIZE.indexOf(size) !== -1) {
      alert(
        "The size is already added, please check the size list again. Thank you"
      );
      return;
    }
    SIZE.push(size);
    UPDATES[`tire_size/${DIAMETER_ID}`] = SIZE;

    firebase.app(COUNTRY_DB).database().ref().update(UPDATES).then(() => this.viewSize(this.state.selectedDiameter)).catch((err) => console.log(err));
  };

  getInput = async (input, type) => {
    if (input === null) {
      alert(`Please fill in the tire ${type}`);
      return;
    }
    await this.setState({ input: input });
    if (type === "diameter") {
      this.addDiameter(this.state.input.diameter);
      this.refresh();
    }
    if (type === "size") {
      this.addSize(this.state.input.size);
    }
  };

  viewSize = async (data) => {
    await this.setState({ load: "1", selectedDiameter: data });
    this.fetchSelectedSize();
  };

  // Remove Diameter + Size too
  removeDiameter = async (data) => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    let s = window.confirm(
      "Are you sure you want to REMOVE the DIAMETER? Please take note that, the SIZES for the selected DIAMETER will be removed too. "
    );
    if (s) {
      await firebase
        .app(COUNTRY_DB)
        .database()
        .ref(`tire_diameter/${data.diameterId}`)
        .remove()
        .then(() => console.log("Successfully removed"))
        .catch((err) => console.log(err));

      await firebase
        .app(COUNTRY_DB)
        .database()
        .ref(`tire_size/${data.diameterId}`)
        .remove()
        .then(() => this.refresh())
        .catch((err) => console.log(err));
    }
  };
  removeSize = async (data) => {
    let s = window.confirm("Are you sure you want to REMOVE the SIZE ?");
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    if (s) {
      const SIZES = this.state.sizeData;
      const SELECTED_DIAMETER = this.state.selectedDiameter;
      let UPDATES = {};
      let FILTER_SIZES = SIZES.filter((size) => size !== data);
      UPDATES[`/tire_size/${SELECTED_DIAMETER.diameterId}`] = FILTER_SIZES;
      firebase
        .app(COUNTRY_DB)
        .database()
        .ref()
        .update(UPDATES)
        .then(() => this.viewSize(SELECTED_DIAMETER))
        .catch((err) => console.log(err));
    }
  };

  refresh = () => {
    this.fetchDiameterData();
    this.setState({ sizeData: [] });
  };

  _renderDiameter = () => {
    let display;
    if (this.state.load === "1") {
      display = <div className="loader"></div>;
    }
    if (this.state.load === "2") {
      const { diameterData } = this.state;
      let SORT = diameterData.sort((a, b) => a.diameterVal - b.diameterVal);
      let diameterList = SORT.map((data) => (
        <Col md={12} key={data.diameterId} style={{ padding: "10px 15px" }}>
          <Row className="align-items-center justify-content-center">
            <Col md={6} className="text-center" style={{ fontSize: "2rem" }}>
              {data.diameterVal} inches
            </Col>
            <Col md={6} className="text-right">
              <Button
                color="light"
                className="m-0 mr-3"
                onClick={() => this.viewSize(data)}
              >
                View Sizes
              </Button>
              <Button
                color="danger"
                className="m-0 mr-3"
                onClick={() => this.removeDiameter(data)}
              >
                Remove Diameter
              </Button>
            </Col>
          </Row>
        </Col>
      ));
      display = (
        <Row
          className="custom-scrollbar mt-3 align-items-center"
          style={{ maxHeight: "450px", overflowY: "scroll" }}
        >
          {diameterList}
        </Row>
      );
    }
    if (this.state.load === "3") {
      display = (
        <Row
          className="custom-scrollbar mt-3 align-items-center"
          style={{ maxHeight: "450px", overflowY: "scroll" }}
        >
          <Col md={12} className="text-center p-4">
            No Tire Diameter yet, please add the Tire Diameter by click on the
            button above.
          </Col>
        </Row>
      );
    }
    return display;
  };

  back = () => {
    this.refresh();
    this.setState({ display: "diameter" });
  };

  _renderSize = () => {
    let display;
    if (this.state.load === "1") {
      display = <div className="loader"></div>;
    }
    if (this.state.load === "2") {
      const { sizeData } = this.state;
      let SORT = sizeData.sort();
      let sizeList = SORT.map((data, index) => (
        <Col md={12} key={index} style={{ padding: "10px 15px" }}>
          <Row className="align-items-center justify-content-center">
            <Col md={6} className="text-center" style={{ fontSize: "1rem" }}>
              {data}
            </Col>
            <Col md={6} className="text-right">
              <Button
                color="danger"
                className="m-0 mr-3"
                onClick={() => this.removeSize(data)}
              >
                Delete Size
              </Button>
            </Col>
          </Row>
        </Col>
      ));
      display = (
        <Row
          className="custom-scrollbar mt-3 align-items-center"
          style={{ maxHeight: "450px", overflowY: "scroll" }}
        >
          {sizeList}
        </Row>
      );
    }
    if (this.state.load === "3") {
      display = (
        <Row
          className="custom-scrollbar mt-3 align-items-center"
          style={{ maxHeight: "450px", overflowY: "scroll" }}
        >
          <Col md={12} className="text-center p-4">
            No Tire Size for {this.state.selectedDiameter.diameterVal} inches.
            Please ADD by clicking on the Add Tire Size above.
          </Col>
        </Row>
      );
    }

    return display;
  };

  _renderContent = () => {
    let display;
    if (this.state.display === "diameter") {
      display = this._renderDiameter();
    }
    if (this.state.display === "size") {
      display = this._renderSize();
    }
    return (
      <div className="content">
        <Card body>
          <div className="d-flex justify-content-between align-items-center">
            {this.state.display === "diameter" ? (
              <>
                <h3 className="m-0" style={{ display: "inline-block" }}>
                  Tire Diameter
                </h3>

                <TireListingAdd
                  display={this.state.display}
                  addDiameter={this.addDiameter}
                  getInput={this.getInput}
                  parentRefresh={this.refresh}
                  btnTitle="Add Tire Diameter"
                  modalTitle="New Tire Diameter"
                  formLabel="Tire Diameter"
                  inputName="diameter"
                />
              </>
            ) : null}
            {this.state.display === "size" ? (
              <>
                <h3 className="m-0" style={{ display: "inline-block" }}>
                  Tire Size -{" "}
                  <strong>{this.state.selectedDiameter.diameterVal}</strong>{" "}
                  inches tire
                </h3>

                <TireListingAdd
                  display={this.state.display}
                  selectedDiameter={this.state.selectedDiameter}
                  addDiameter={this.addDiameter}
                  getInput={this.getInput}
                  btnTitle="Add Tire Size"
                  modalTitle="New Tire Size"
                  formLabel="Tire Size"
                  formText={`Add the new tire size for ${this.state.selectedDiameter.diameterVal} inches tire.`}
                  inputName="size"
                  back={this.back}
                />
              </>
            ) : null}
          </div>
          <hr />
          {display}
        </Card>
      </div>
    );
  };

  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        {this._renderContent()}
      </div>
    );
  }
}

TireListing = inject('mobx_auth')(observer(TireListing))
