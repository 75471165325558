import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, Button, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { PanelHeader } from "components";
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js';
import firebase from 'firebase';
import { inject, observer } from 'mobx-react'

import safebox from 'assets/img/safebox.png';
import ErrorAlert from 'components/ErrorAlert';

class SPApprove extends React.Component {
  state = {
    data: [],
    load: '1',
    modal: false,
    modalData: {},
    toggle: false,
    success: false,
    reload: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  childToggle = () => this.setState({ toggle: false }, () => {
    if (this.state.reload === true) {
      sessionStorage.removeItem('CT')
      document.location.reload()
    }
  })

  _approve = () => {
    firebase.database().ref('servproviderUID/' + this.state.modalData.uid).update({
      approve_status: 'Approved',
      approval: true,
    }).then(() => {
      firebase.database().ref('waitingforapproval/' + this.state.modalData.delete_key).remove();
    }).then(() => {
      this._refresh();
      this.setState({
        modal: !this.state.modal,
      })
    });
  }
  _decline = async () => {
    let DATA = this.state.modalData;
    let UID = DATA.uid;
    let DELETE_KEY = DATA.delete_key;
    let PUSH_TOKEN = DATA.pushToken;
    // console.log(this.state.modalData);

    const { browser } = this.props.mobx_config
    let URL, csrfTokenP, csrfSecretP, headerToken;

    if (browser === 'safari') {
      csrfTokenP = sessionStorage.getItem('CSRFTokenP')
      csrfSecretP = sessionStorage.getItem('CSRFSecretP')
      headerToken = { csrftoken: csrfTokenP, csrfsecret: csrfSecretP }
    }

    if (this.props.mobx_config.config === 0) {
      URL = serv_provider_development.notifyDeclined
    }
    if (this.props.mobx_config.config === 1) {
      URL = serv_provider_production.notifyDeclined
    }
    let PUSH = 'pushToken=' + PUSH_TOKEN
    let FINAL_URL = URL + PUSH

    const idToken = await firebase.auth().currentUser.getIdToken()

    let headers = { Authorization: `Bearer ${idToken}`, ...headerToken }
    let OPTIONS = { credentials: 'include', headers }

    const response = await fetch(FINAL_URL, OPTIONS).then(response => {
      if (response.status !== 200) {
        this.setState({ toggle: true, success: false, reload: true })
        return response.status
      }
      return response.json();
    })

    if (response !== 401) {
      await firebase.database().ref('servproviderUID/' + UID).update({
        approve_status: "Have not applied"
      }).then(() => {
        firebase.database().ref('waitingforapproval/' + DELETE_KEY).remove()
      }).then(() => {
        this._refresh();
        this.setState({
          modal: !this.state.modal,
        })
      })
    }
  }

  _refresh = () => {
    this.setState({ load: '1' });
    this.fetchWaitingForApproval();
  }

  fetchProfile = (SPID) => {
    let that = this;
    let DATA = [];

    SPID.forEach((x) => {
      //Need to call x.UID as x has become an object
      //This firebase call function uses promises (async and await in its core)
      firebase.database().ref(`servproviderUID/${x.UID}`).once('value').then((snapshot) => {
        if (snapshot.exists()) {
          //Save the key for deletion purposes later
          let a = snapshot.val();
          a.uid = x.UID;
          a.delete_key = x.delete_key;
          DATA.unshift(a);
        } else {
          console.log('Snapshot does not exists');
        }
      }).then(() => {
        that.setState({ data: DATA })
      }).then(() => {
        that.setState({ load: '3' });
      })
    })
  }

  fetchWaitingForApproval() {
    //Create empty array, this will be used to send pass it into the function
    let SPID = [];
    //Pull the data from the firebase
    firebase.database().ref(`waitingforapproval`).once('value').then((snapshot) => {
      if (snapshot.exists()) {
        for (const key in snapshot.val()) {
          let a = {
            UID: '',
            delete_key: '',
          }
          //Add the serv provider UID and object key for deletion later
          a.UID = Object.keys(snapshot.val()[key]).toString();
          a.delete_key = [key].toString();
          // Put the values onto the array
          SPID.unshift(a);
        }

        //Call function to get profile Data
        this.fetchProfile(SPID);
      } else {
        console.log('firebase is empty sorry');
        this.setState({ load: '2' });
      }
    })
  }

  componentDidMount() {
    this.setState({ load: '1' });
    this.fetchWaitingForApproval();
  }
  _renderContent = () => {
    /********************************** Start ***********************************/
    //Conditional Rendering for content
    let content = [];
    /********************************** Loading ***********************************/
    if (this.state.load === '1') {
      content.push(
        <Col xs={12} md={12} key={1}>
          <Card className="text-center">
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <div className='loader'></div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )
    }
    /********************************** Nothing to show ***********************************/
    if (this.state.load === '2') {
      content.push(
        <Col xs={12} md={12} key={2}>
          <Card className="text-left">
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <img style={{ width: '35%', marginBottom: '15px' }} src={safebox} />
                <div>Data not available</div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )
    }
    /********************************** Show everything ***********************************/
    if (this.state.load === '3') {
      content = this.state.data.map((data, index) => (
        <Col xs={12} md={4} key={index}>
          <Card className="text-left">
            <CardBody>
              <div>
                Name: {data.name}
              </div>
              <div>
                Email: {data.email}
              </div>
              <div>
                Phone: {data.phone}
              </div>
              <div>
                Approval Status: {data.approve_status}
              </div>
              <div style={{ textAlign: 'justify' }}>
                Skills: {data.skills_desc}
              </div>
              <div>
                Service Cover Area: {data.cover_area}
              </div>
              <div>
                Specific Cover Area: {data.specific_cover_area}
              </div>
            </CardBody>
            <CardFooter className="text-center">
              <Button color="dark" onClick={() => {
                this.setState({
                  // passes all the data of this row to the modal, linking modal to the row
                  modalData: data,
                  modal: !this.state.modal,
                })
              }}
              >
                Action
              </Button>
            </CardFooter>
          </Card>
        </Col>
      ))
    }

    return content
  }
  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Button
              color="light"
              style={{ marginLeft: '15px', borderRadius: '50px', padding: '8px 10px', zIndex: '100' }} onClick={this._refresh}>
              <i className="now-ui-icons arrows-1_refresh-69" style={{ fontSize: '20px', fontWeight: 'bold' }} />
            </Button>
          </Row>
          <Row>
            {this._renderContent()}
          </Row>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className="approve-modal" style={{ maxWidth: '50%' }}>
          <ModalHeader toggle={this.toggle}>Are you sure you want to approve?</ModalHeader>
          <ModalBody>
            <Table dark bordered responsive>
              <tbody style={{ backgroundColor: '#00005f' }}>
                <tr>
                  <th>Name : </th>
                  <td>{this.state.modalData.name}</td>
                </tr>
                <tr>
                  <th>Email : </th>
                  <td>{this.state.modalData.email}</td>
                </tr>
                <tr>
                  <th>Skills : </th>
                  <td>{this.state.modalData.skills_desc}</td>
                </tr>
                <tr>
                  <th>Service Cover Area : </th>
                  <td>{this.state.modalData.cover_area}</td>
                </tr>
                <tr>
                  <th>Specific Cover Area : </th>
                  <td>{this.state.modalData.specific_cover_area}</td>
                </tr>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <div>
              <Button color="success" onClick={this._approve}>Yes, Approve</Button>{' '}
              <Button color="danger" onClick={this._decline}>Decline</Button>
            </div>

            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
      </div>
    );
  }
}

SPApprove = inject('mobx_config')(observer(SPApprove));
export default SPApprove;
