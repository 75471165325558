import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, CardHeader, CardBody, CardImg, CardTitle, UncontrolledCollapse, Button,
  Form, FormGroup, Label, Input, FormText, Badge, InputGroupAddon, InputGroup } from "reactstrap";
  import { inject, observer } from 'mobx-react';
import firebase from 'firebase';

class Notepad extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      notes:'',
      dataNotes: [],
      countNotes: 0,
      load: '1',

    }

  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  componentDidMount() {
    this.fetchNotes();
    this.timeago();
  }

  timeago(){
    var x = new Date().getTime() / 1000;
    
    this.setState({current:x})
  }

  timeDifference(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {return Math.round(elapsed/1000) + ' seconds ago';}

    else if (elapsed < msPerHour) {return Math.round(elapsed/msPerMinute) + ' minutes ago';}

    else if (elapsed < msPerDay ) {return Math.round(elapsed/msPerHour ) + ' hours ago';}

    else if (elapsed < msPerMonth) {return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';}

    else if (elapsed < msPerYear) {return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';}

    else {return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';}
  }

  fetchNotes = () => {
    let that = this;
    // let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    
    // 
    firebase.database().ref('adminnotes/').on('value', function(snapshot){
      let COUNT = 0
      let DATA = []
      if(snapshot.exists()) {
       
        let keys = Object.keys(snapshot.val())

        keys.forEach((note_id) => {
          let a = snapshot.val()[note_id]
          a.delete_key = note_id

          COUNT = COUNT + 1;
          DATA.push(a);
        })
        
        
        that.setState({dataNotes:DATA, load: '2', countNotes:COUNT})
      } else {
        
        that.setState({load: '3', countNotes:0})
      }
    })
  }

  _submit = (e) => {
    let that = this;
    e.preventDefault();
    // let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    if(this.state.notes === 'Insert your notes' || this.state.notes === '' || this.state.name === 'Admin User Name' || this.state.name === '') {
      alert('Empty notes')
    } else {
      // alert(this.state.notes);
      firebase.database().ref('adminnotes/').push({
        name: that.state.name,
        body : that.state.notes,
        timestamp : firebase.database.ServerValue.TIMESTAMP,
      }).then(() => {
        that.setState({notes : '', name:''})
      }).then(() => {
        this.fetchNotes();
      })
    }
  }

  _delete = (key) => {
    let that = this;
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY

    firebase.app(COUNTRY_DB).database().ref('adminnotes/' + key).remove().then(() => {
      this.fetchNotes()
    })

  }

  render () {
    let renderContent = []
    if(this.state.load === '1') {
      renderContent = <div className="loader"></div>
    }
    if(this.state.load === '2') {
      renderContent = this.state.dataNotes.map((data) =>
      <Row key={data.delete_key} style={{paddingTop:'10px', margin:'0', color:'white'}}>
        <Col md={11}>
          <p style={{margin:'0', textAlign:'justify'}}>{data.body}</p>
          <p style={{margin:'0', fontSize:'75%', fontWeight:'normal'}}>by {data.name}</p>
          <small style={{}}>{this.timeDifference(new Date(),new Date(data.timestamp))}</small>
        </Col>
        <Col md={1}>
          <Button
            onClick = {() => {this._delete(data.delete_key)}}
            style={{float:'right', background:'transparent', border:'2px solid red', borderRadius:'50%', padding:'2px 3px', lineHeight:'1'}}>
            <i style={{color:'red', fontWeight:'bold'}} className="now-ui-icons ui-1_simple-remove"/>
          </Button>
        </Col>
      </Row>
    )}
    if(this.state.load === '3') {
      renderContent = <div style={{padding:'15px', textTransform:'uppercase', fontWeight:'bold', color:'white'}}>No notes to display</div>
    }

    return(
      <div style={{borderRadius:'1rem 0 0 0',background: '#a7a7a7',position: 'sticky', bottom:'0', right:'0', float:'right', width: '500px', marginBottom:'0', zIndex:'51'}}>
        <div id="notepad" style={{borderRadius:'1rem 0 0 0', width:'100%', margin:'0', padding: '10px 15px', background:'rgb(251,152,0)', color:'white', display:'flex', justifyContent:'space-between', borderBottom:'1px solid black'}}>

          <div className="d-flex align-items-center">
            {
              this.state.countNotes !== 0 ? 
              <Badge style={{fontSize:'100%'}} color="danger">{this.state.countNotes}</Badge> :
              null
            }

            <span style={{fontSize:'1.1rem', fontWeight:'bold', display:'inline-block', paddingLeft:'20px', color:'white'}}>Admin Notes</span>
          </div>
          
          <div>X</div>
        </div>
        <UncontrolledCollapse toggler="#notepad">
          <Card style={{background: '#a7a7a7', boxShadow:'none', margin:'0', maxHeight:'550px', overflow: 'scroll', color:'#3e3e3e', borderRadius:'0'}}>
            <div style={{padding:'0'}}>
              {renderContent}
            </div>

            <Form onSubmit={this._submit} style={{position:'sticky', bottom:'0', padding:'10px', background:'#a7a7a7', borderTop:'1px solid', display:'flex', marginTop:'5px'}}>
              <div style={{flexGrow:'2', border:'1px solid rgba(0,0,0,0.2)', borderRadius:'0.5rem 0 0 0.5rem'}}>
                <InputGroup className="mb-0" style={{borderRadius:'0.5rem 0 0 0.5rem'}}>
                  <InputGroupAddon addonType="prepend" className="align-items-center p-2">
                    <Label className="mb-0" style={{lineHeight:'1', color:'white'}}>Note</Label>
                  </InputGroupAddon>
                  <Input style={{border:'none', borderRadius:'0', background:'#a7a7a7'}} type="text" name="notes" id="notes" onChange={this.handleChange} value={this.state.notes}/>
                </InputGroup>

                <InputGroup className="mb-0" style={{borderTop:'1px solid rgba(0,0,0,0.2)', borderRadius:'0 0 0.5rem 0.5rem'}}>
                  <InputGroupAddon addonType="prepend" className="align-items-center p-2">
                    <Label className="mb-0" style={{lineHeight:'1', color:'white'}}>Name</Label>
                  </InputGroupAddon>
                  <Input style={{border:'none', borderRadius:'0', background:'#a7a7a7'}} type="text" name="name"  id="name" onChange={this.handleChange} value={this.state.name}/>
                </InputGroup>
              </div>
              <Button style={{margin:'0', padding:'5px 8px', borderRadius: '0 0.5rem 0.5rem 0', width:'10%'}} color="dark" type="submit">+</Button>
            </Form>
          </Card>
        </UncontrolledCollapse>
      </div>
    );
  }
}

export default Notepad;
// Notepad = inject('mobx_auth')(observer(Notepad))