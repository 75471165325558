import React from 'react';
import { Route, Switch } from 'react-router-dom';

import indexRoutes from 'routes/indexRoutes.jsx';

export default class App extends React.Component{
  render(){
    return(
      <Switch>
        {indexRoutes.map((prop, key) => {
          return <Route path={prop.path} key={key} component={prop.component} />;
        })}
      </Switch>
    )
  }
}