import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  FormText,
} from "reactstrap";
import { inject, observer } from "mobx-react";

class TireListingAdd extends Component {
  state = {
    toggleModal: false,
    displayBtn: true,
    type: null,
    input: {},
  };

  toggleModal = () => {
    this.setState({ toggleModal: !this.state.toggleModal });
  };

  handleChange = (evt) => {
    this.setState({
      ...this.state,
      type: evt.target.name,
      input: { ...this.state.input, [evt.target.name]: evt.target.value },
    });
  };

  add = async () => {
    this.props.getInput(this.state.input, this.state.type);
    await this.setState({ diameter: "", displayBtn: true });
    this.toggleModal();
  };

  _renderButton = () => {
    let display;
    if (this.state.displayBtn) {
      display = (
        <Button
          color="light"
          type="submit"
          onClick={() => {
            this.setState({ displayBtn: false }, () => {
              this.add();
            });
          }}
        >
          Add
        </Button>
      );
    }
    if (!this.state.displayBtn) {
      display = (
        <Button
          disabled
          color="light"
          className="d-flex align-items-center"
          onClick={this.add}
        >
          <div className="mr-1">Adding</div>
          <div className="btnloader"></div>
        </Button>
      );
    }
    return display;
  };

  render() {
    return (
      <div style={{ display: "inline-block" }}>
        {this.props.display === "size" ? (
          <Button
            color="dark"
            style={{ margin: "0px 10px" }}
            onClick={() => {
              this.props.back();
            }}
          >
            Back
          </Button>
        ) : null}

        <Button
          color="dark"
          style={{ margin: "0" }}
          onClick={() => {
            this.toggleModal();
          }}
        >
          {this.props.btnTitle}
        </Button>

        <Modal
          className="modal-dialog-centered modal-default"
          style={{ maxWidth: "55%" }}
          isOpen={this.state.toggleModal}
          toggle={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>
            {this.props.modalTitle}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for={this.props.inputName}>{this.props.formLabel}</Label>
              <Input
                type="text"
                name={this.props.inputName}
                id={this.props.inputName}
                onChange={this.handleChange}
              />
              <FormText>{this.props.formText}</FormText>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {this._renderButton()}{" "}
            <Button
              className="ml-auto"
              color="secondary"
              onClick={() => {
                this.toggleModal();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

TireListingAdd = inject("mobx_config","mobx_auth")(observer(TireListingAdd));
export default TireListingAdd;
