import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col, Progress, FormText } from 'reactstrap'
import firebase from 'firebase';
import LOGO from "assets/img/logo-placeholder.jpg"
import { inject, observer } from 'mobx-react';

export default class EditMarketplace extends Component {
    static propTypes = {
        data: PropTypes.object,
        parentRefresh: PropTypes.func,
    }
    state = {
        displayButt:true,
        modalEdit: false,
        data: {},

        progress: 0,
        PreviewLogo: LOGO,
        canUploadLogo: false,
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    }
    handleChange = (evt) => {
        this.setState({ data : { ...this.state.data, [evt.target.name]: evt.target.value } });
    }
    //HANDLE THE CHOOSE FILE BUTTON
    handleLogoChange = (evt) => {
        let that = this;
        const file = document.querySelector('#upload_logo').files[0];

        if(file){
            var checker = new Image();

            checker.src = window.URL.createObjectURL(file);

            checker.onload = function() {
                let fileSize = file.size;
                // var width = checker.naturalWidth,height = checker.naturalHeight;
                window.URL.revokeObjectURL(checker.src);
                if(fileSize < 100000){
                    that.setState({canUploadLogo:true, PreviewLogo: URL.createObjectURL(file)})
                } else {
                    alert('Image size has to be less than 100KB')
                    document.querySelector('#upload_logo').value = ""
                    that.setState({canUploadLogo:false})
                }
            }
        } else {
            console.log("Image is empty");
        }

    }
    _save = () => {
        // 1. error handler
        // 2. if file ada, upload logo
        // 3. save data
        let ErrorHandler = this.state
        let state = this.state.data
        const file = document.querySelector('#upload_logo').files[0];

        if(ErrorHandler.data.company_name === ''){
            alert('Please fill in company name!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.data.link === ''){
            alert('Please fill in the link for the listing!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.data.name === ''){
            alert('Please fill in the name of the site as it should appear on the SERV User App!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.data.description === ''){
            alert('Please fill in the description of the site as it should appear on the SERV User App!')
            this.setState({displayButt:true, progress:0})
            return
        }
        if(ErrorHandler.data.category === ''){
            alert('Please select the category of the listing!')
            this.setState({displayButt:true, progress:0})
            return
        }
        // if(file && ErrorHandler.canUploadLogo === false){
        //     alert('Please select ta valid image!')
        //     this.setState({displayButt:true, progress:0})
        //     return
        // }

        if(file){
            console.log('ada logo, uplaod logo');
            this._uploadLogo()
        } else {
            console.log('no logo, save data');
            this._uploadData(this.state.data.logo, this.state.data.market_id)
        }
    }
    _uploadLogo = () => {
        // 2. upload logo
        let that = this;
        let fileLogo = document.querySelector('#upload_logo').files[0];
        let MARKETPLACE_ID = this.state.data.market_id;
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        const storageRef = firebase.app(COUNTRY_DB).storage().ref('marketplace_logo');
        let uploadLogo = storageRef.child(MARKETPLACE_ID).put(fileLogo);

        // UNCOMMENT THIS CODE
        uploadLogo.on('state_changed', (snapshot) => {
            that.setState({progress: 35})
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.app(COUNTRY_DB).storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                // that.setState({progressColor:'warning', text : 'PAUSED'})
                break;
                case firebase.app(COUNTRY_DB).storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                // that.setState({progressColor:'success', text : 'UPLOADING'})
                break;
            }
        }, (error) => {
            // IF IMAGE UPLOAD FAILED
            that.setState({progress: 0})
            alert('Upload failed, please try again!\n', error.message);
            that.setState({displayButt: true})
        }, () => {
            // IF IMAGE UPLOAD SUCCEED
            uploadLogo.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // 2. get downloadURL
                console.log('File available at', downloadURL);
                that.setState({progress: 65}, () => {
                    that._uploadData(downloadURL, MARKETPLACE_ID)
                })
            })
        })
    }
    _uploadData = (url, marketplace_id) => {
        // 3. save data
        let state = this.state.data
        let downloadURL = url
        let MARKETPLACE_ID = marketplace_id

        let DATA = {
            company_name: state.company_name,
            link: state.link,
            name: state.name,
            category: state.category,
            description: state.description,
            logo: downloadURL,
        }

        // console.log(DATA);
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        firebase.app(COUNTRY_DB).database().ref('marketplace/' + MARKETPLACE_ID).update(DATA).then(() => {
            this.setState({progress:100}, () => {
                this.props.parentRefresh()
            })
        }).then(() => {
            setTimeout(() => {
                this.setState({
                    modalEdit: false,
                    displayButt: true,
                    data: this.props.data,
                    progress: 0,
                    PreviewLogo: LOGO,
                    canUploadLogo: false,
                })
            }, 500)
        })
    }
    _renderButton = () => {
        let display

        if(this.state.displayButt === true){
            display =
            <Button color='dark' onClick={() => {
                this.setState({progress:10, displayButt: false}, () => {
                    this._save()
                })
            }}>Update marketplace listing</Button>
        }
        if(this.state.displayButt === false){
            display =
            <Button color='dark' disabled className="d-flex align-items-center justify-content-center"><div className="mr-2">Updating...</div><div className="btnloader"></div></Button>
        }

        return display
    }
    render() {
        return (
            <>
                <Button size="sm" color="light" className="m-0" onClick={() => {
                    console.log(this.props.data)
                    this.setState({data: this.props.data}, () => {
                        this.toggleModal('modalEdit')
                    })
                }}>View</Button>

                <Modal isOpen={this.state.modalEdit} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger'>
                    <ModalHeader>{this.props.data.company_name}</ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-3">
                            <Label for='company_name'>Company Name</Label>
                            <Input type='text' name='company_name' id='company_name' onChange={this.handleChange} value={this.state.data.company_name}/>
                        </FormGroup>

                        <Label for='link'>Link</Label>
                        <FormGroup className="mb-3 align-items-center" row>
                            <Col md={9}>
                                <Input type='text' name='link' id='link' onChange={this.handleChange} value={this.state.data.link}/>
                            </Col>
                            <Col>
                                <Button size="sm" color="info" className="m-0" onClick={() => {window.open(this.state.data.link,'_blank')}}>View website</Button>
                            </Col>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for='name'>Name in SERV User App</Label>
                            <Input type='text' name='name' onChange={this.handleChange} value={this.state.data.name}/>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for='description'>Desctiption in SERV User App</Label>
                            <Input type='text' name='description' id='description' onChange={this.handleChange} value={this.state.data.description}/>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for='category'>Category</Label>
                            <Input type='select' name='category' id='category' onChange={this.handleChange} value={this.state.data.category}>
                                <option value="Grocery Delivery">Grocery Delivery</option>
                                <option value="eBazaar">eBazaar</option>
                                <option value="Online Services">Online Services</option>
                            </Input>
                        </FormGroup>

                        <Row>
                            <Col>
                                <Label for="upload_logo">Edit Company Logo</Label><br/>
                                <img src={this.state.data.logo} style={{width:'250px'}} />
                            </Col>
                            <Col>
                                <Input type="file" accept="image/*" name="upload_logo" id="upload_logo" onChange={this.handleLogoChange}/>
                                <FormText color="danger">
                                    Logo (Make sure the the image size is less than 100kb!)
                                </FormText>
                                <div>
                                    <img style={{width:250}} src={this.state.PreviewLogo}/>
                                </div>
                            </Col>
                        </Row>

                        {
                            this.state.progress !== 0 ? <div className="mt-3"><Progress animated color="success" value={this.state.progress} /></div> : null
                        }
                    </ModalBody>
                    <ModalFooter>
                        {this._renderButton()}
                        <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalEdit')}>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

EditMarketplace = inject('mobx_auth')(observer(EditMarketplace))