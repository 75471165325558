import React, { Component } from 'react'
import { Card, Row, Col, Table, CardHeader, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, } from 'reactstrap'
import PanelHeader from '../components/PanelHeader'

import firebase from 'firebase';
import AddMarketplace from './AddMarketplace';
import DisplayMarketplace from './DisplayMarketplace';
import Switch from "react-switch";

import GRID from "assets/img/grid.svg";
import LIST from "assets/img/list.svg";
import { inject, observer } from 'mobx-react';

export default class Marketplace extends Component {
    state = {
        load: '1',
        data: [],
        list: false,
        search: '',
    }
    handleChange = (list) => {
        this.setState({ list });
    }
    handleSearchBar = (evt) => {
        this.setState({ search: evt.target.value })
    }
    componentDidMount() {
        this.fetchMarketplace()
    }
    fetchMarketplace = () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        firebase.app(COUNTRY_DB).database().ref('marketplace/').once('value').then((snapshot) => {
            if (snapshot.exists()) {
                let SNAP = snapshot.val()
                let DATA = []
                for (const market_id in SNAP) {
                    let a = SNAP[market_id]
                    a.market_id = market_id
                    DATA.push(a)
                }
                this.setState({ load: '2', data: DATA })
            } else {
                this.setState({ load: '3' })
            }
        })
    }
    _renderContent = () => {
        let display

        if (this.state.load === '1') {
            display = <div style={{ padding: '65px 25px' }}><div className="loader"></div></div>
        }
        if (this.state.load === '2') {
            let filteredData = this.state.data.filter((data) => {
                return data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 || data.company_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
            })
            // console.log(filteredData);

            display = this.state.list === true ?
                <Table className='align-items-center' responsive>
                    <thead className='thead-light'>
                        <tr>
                            <th scope='col'>Company</th>
                            <th scope='col'>Category</th>
                            <th scope='col'>Name</th>
                            <th scope='col'>Description</th>
                            <th scope='col' />
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((data, index) => (
                            <DisplayMarketplace key={index} data={data} parentRefresh={this.fetchMarketplace} list={this.state.list} />
                        ))}
                    </tbody>
                </Table> :
                <Row className="m-0 mt-3">
                    {filteredData.map((data, index) => (
                        <Col md={4} key={index}>
                            <DisplayMarketplace data={data} parentRefresh={this.fetchMarketplace} />
                        </Col>
                    ))}
                </Row>
        }
        if (this.state.load === '3') {
            display =
                <div style={{ padding: '65px 25px' }} className="text-center">No data available. Click on 'Add' to add a marketplace merchant.</div>
        }

        return display
    }
    render() {
        return (
            <div>
                <PanelHeader size='sm' />
                <div className='content'>
                    <Card className="shadow">
                        <CardHeader style={{ background: 'rgb(30, 25, 80)', borderRadius: '0.9rem 0.9rem 0 0', color: 'white', paddingBottom: '15px' }} className="d-flex align-items-center justify-content-between">
                            <div className="d-flex">
                                <AddMarketplace parentRefresh={this.fetchMarketplace} />
                                <InputGroup className='no-border' style={{ marginBottom: '0', marginLeft: '15px', width: '350px' }}>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText style={{ background: 'rgba(255,255,255,0.8)' }}>
                                            <i className='now-ui-icons ui-1_zoom-bold' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input style={{ background: 'rgba(255,255,255,0.8)' }} placeholder='Search' onChange={this.handleSearchBar} />
                                </InputGroup>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <img src={GRID} style={{ width: '30px', padding: '0 5px' }} />
                                <Switch
                                    checked={this.state.list} onChange={this.handleChange}
                                    onColor="#86d3ff" onHandleColor="#2693e6"
                                    handleDiameter={30} uncheckedIcon={false} checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20} width={48} className="react-switch" id="material-switch"
                                />
                                <img src={LIST} style={{ width: '30px', padding: '0 5px' }} />
                            </div>
                        </CardHeader>

                        {this._renderContent()}
                    </Card>
                </div>
            </div>
        )
    }
}

Marketplace = inject('mobx_auth')(observer(Marketplace))