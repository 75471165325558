import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Card } from 'reactstrap'
import CAR from 'assets/img/car-placeholder.png'
import firebase from 'firebase';
import { inject, observer } from 'mobx-react';

export default class UserDetailVehicle extends Component {
  static propTypes = {
    data: PropTypes.object,       // Get the user data object from parent
    parentSelect: PropTypes.func, // To update the view to 'vehicle'
    analytics: PropTypes.object,  // Get the analytics data object
  }

  _renderVehiclePic = () => {
    const { data } = this.props
    let IMAGE
    if (data.picture_car !== undefined && data.picture_car.downloadURL !== '') IMAGE = data.picture_car.downloadURL
    if (data.picture_car === undefined || data.picture_car.downloadURL === '') {
      if (data.type === 'Car' || data.type === undefined) IMAGE = CAR
    }
    return <div style={{ background: `url(${IMAGE})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%', paddingTop: '100%', margin: '0 auto', borderRadius: '50%' }}></div>
  }

  _renderVehicleCard = (data, index) => {
    return (
      <Col md={4} key={index}>
        <Card body className='shadow-none' style={{ color: 'black', border: '1px solid', borderRadius: '1rem' }}>
          <Row>
            <Col md={4}>{this._renderVehiclePic()}</Col>
            <Col md={8}>
              <div>{data.vehicle_id}</div>
              {/* {this.fetchVehicle(sel_data.vehicle_id)} */} 
              {/* <div>{data.car_details.make}</div>
              <div>{data.car_details.model}</div> */}
            </Col>
          </Row>
          <Button color='dark' className='m-0 mt-3' onClick={() => { this.props.parentSelect(data, 'vehicle') }}>View</Button>
        </Card>
      </Col>
    )
  }

  _renderVehiclesTab = () => {
    const vehicle_display = []
    const { data, analytics, load } = this.props
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    if (load === '2' && data.vehicles === undefined) return <div className='text-center' style={{ padding: '95px 25px' }}>This user has not added any vehicles yet.</div>
    if (load === '2' && data.vehicles !== undefined) {
      for (const vehicle_id in data.vehicles) {
        let a = data.vehicles[vehicle_id]
        a.vehicle_id = vehicle_id
        firebase.app(COUNTRY_DB).database().ref(`plate_number/${vehicle_id}`).once('value').then((snapshot) => {
          if (snapshot.exists()) {
            let vehicle = snapshot.val()
            a.car_details = vehicle
          }
        })
        // console.log(analytics.vehicles_analysis[vehicle_id])
        // a.analytics = analytics.vehicles_analysis[vehicle_id]
        vehicle_display.push(a)
      }

      return <Row>{vehicle_display.map((data, index) => this._renderVehicleCard(data, index))}</Row>
    }
  }
  render() {
    return this._renderVehiclesTab()
  }
}
UserDetailVehicle = inject('mobx_auth')(observer(UserDetailVehicle))