import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, HashRouter } from 'react-router-dom';

import firebase from 'firebase';
import { Provider, inject, observer } from 'mobx-react';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/css/demo.css';
import 'assets/scss/now-ui-dashboard.css';
import 'assets/css/nasreen.css'

import Login from 'pages/Login.jsx';
import PrivateRoute from 'routes/PrivateRoute.js';
import RealDashboard from 'routes/RealDashboard.js';
import stores from 'stores';
import { CONFIG } from 'configs/config.js'
import { API_KEY_DEVELOPMENT, API_KEY_PRODUCTION, API_UNIQUE_CARPLATE_SANDBOX } from 'configs/value.js';
import { serv_provider_development, serv_provider_production, serv_user_development, serv_user_production } from 'configs/firebaseCF.js';
// ========== STAGING ==========
// const staging = firebase.initializeApp(API_KEY_DEVELOPMENT);
// const stagingMY = firebase.initializeApp({...API_KEY_DEVELOPMENT, databaseURL: 'https://servuserplayground.firebaseio.com/'}, 'MY');
// const stagingTR = firebase.initializeApp({...API_KEY_DEVELOPMENT, databaseURL: 'https://servuserplayground-ed2ac.firebaseio.com/'}, 'TR');

// ========== PRODUCTION ==========
const production = firebase.initializeApp(API_KEY_PRODUCTION);
const productionMY = firebase.initializeApp({...API_KEY_PRODUCTION, databaseURL: 'https://serv-v2-production-fcb23.firebaseio.com/'}, 'MY');
const productionTR = firebase.initializeApp({...API_KEY_PRODUCTION, databaseURL: 'https://serv-v2-production-fcb23-2d933.europe-west1.firebasedatabase.app/'}, 'TR');

class Checker extends React.Component {
    state = { loading: true, authenticated: false, user: null };

    componentDidMount = async() => {
        this.props.mobx_config.setCONFIG(CONFIG) 
        this.getBrowser()
        if(localStorage.getItem("country") === null){
            await fetch('https://ipapi.co/json/').then(response => {
                return response.json()
            }).then(response => {
                let RES = response
                // console.log('tak ada country', response)
               
                if (RES.country === 'US'){
                    localStorage.setItem("country", 'MY')
                    this.props.mobx_auth.setCOUNTRY('MY')
                } else {
                    localStorage.setItem("country", RES.country)
                    this.props.mobx_auth.setCOUNTRY(RES.country)
                }
            })
            .catch(err => {
                console.log(err.message)
            })

            
        } else {
            console.log('dah ada country', localStorage.getItem("country"))
            this.props.mobx_auth.setCOUNTRY(localStorage.getItem("country"))
        }
        this.checkUser()
    }


    /* Receive browser's information to detect which platform used by users */
    getBrowser = () => {
        const userAgent = navigator.userAgent.toLowerCase()

        if ((userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) && userAgent.includes('safari')) return this.props.mobx_config.setBROWSER('safari')
        if (userAgent.includes('chrome')) return this.props.mobx_config.setBROWSER('chrome')
        if (userAgent.includes('safari')) return this.props.mobx_config.setBROWSER('safari')
        if (userAgent.includes('firefox')) return this.props.mobx_config.setBROWSER('firefox')

        return this.props.mobx_config.setBROWSER('chrome')
    }

    logout = () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        this.props.mobx_auth.setFUID('')
        firebase.app(COUNTRY_DB).auth().signOut().then(async () => {
            // Sign-out successful.
            let URL_SU, URL_SP, FINAL_URLSU, FINAL_URLSP, OPTIONS

            if (this.props.mobx_config.config === 0) {
                URL_SU = serv_user_development.removeCookieU
                URL_SP = serv_provider_development.removeCookieP
            }
            if (this.props.mobx_config.config === 1) {
                URL_SU = serv_user_production.removeCookieU
                URL_SP = serv_provider_production.removeCookieP
            }

            OPTIONS = { credentials: 'include' }
            FINAL_URLSU = `${URL_SU}`
            FINAL_URLSP = `${URL_SP}`

            await fetch(FINAL_URLSU, { credentials: 'include' }).then((res) => res.json()).then(res => console.log(res))
            await fetch(FINAL_URLSP, { credentials: 'include' }).then((res) => res.json()).then(res => console.log(res))
            sessionStorage.clear()
        }).catch((error) => {
            // An error happened.
        });
    }


    // checkUser = async () => {
  
    //     let COUNTRY_DB = this.props.mobx_auth.COUNTRY 
    //     console.log("countryyy", COUNTRY_DB)
    //     firebase.app(COUNTRY_DB).auth().onAuthStateChanged(async (user) => {
    //         // console.log(user)

    //         if (user) {
    //             this.props.mobx_config.setCONFIG(CONFIG)
    //             console.log('configuration MOBX', this.props.mobx_config.config);

    //             this.props.mobx_auth.setFUID(user.uid);
    //             sessionStorage.setItem('unique_id', user.uid);

    //             const DB_SNAP = await firebase.app(COUNTRY_DB).database().ref(`serv_admin/${user.uid}`).once('value')
    //             if (!DB_SNAP.exists()) {
    //                 console.log('not a serv admin');
    //                 this.logout();
    //             } else {
    //                 this.props.mobx_auth.setROLE(DB_SNAP.val().role)
    //             }
                

    //             const CT = sessionStorage.getItem('CT')
    //             if (!CT && window.location.href.includes('user')) {
    //                 await this.fetchToken()

    //                 sessionStorage.setItem('CT', 'available')
    //             }

    //             this.setState({ authenticated: true, currentUser: user, loading: false })
    //         } else {
    //             this.setState({ authenticated: false, currentUser: null, loading: false })
    //         }
    //     })
    // }

    /* Listen for authentication status changes */
    checkUser =  () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // let COUNTRY_DB = localStorage.getItem("country")
        console.log("country 11", COUNTRY_DB)
        // console.log("authh", firebase.app(COUNTRY_DB).auth())
        firebase.app(COUNTRY_DB).auth().onAuthStateChanged(async (user) => {
            // console.log(user)

            if (user) {

                console.log("userr", user)
                this.props.mobx_config.setCONFIG(CONFIG)
                // console.log('configuration MOBX', this.props.mobx_config.config);

                this.props.mobx_auth.setFUID(user.uid);
                sessionStorage.setItem('unique_id', user.uid);

                // const DB_SNAP = await firebase.database().ref(`serv_admin/${user.uid}`).once('value')
                const DB_SNAP = await firebase.app(COUNTRY_DB).database().ref(`serv_admin/${user.uid}`).once('value')
                if (!DB_SNAP.exists()) {
                    console.log('not a serv admin');
                    this.logout();
                } else {
                    this.props.mobx_auth.setROLE(DB_SNAP.val().role)
                }
              

                const CT = sessionStorage.getItem('CT')
                if (!CT && window.location.href.includes('user')) {
                    await this.fetchToken()

                    sessionStorage.setItem('CT', 'available')
                }

                this.setState({ authenticated: true, currentUser: user, loading: false })
            } else {
                this.setState({ authenticated: false, currentUser: null, loading: false })
            }
        })
    }

    /* Generate CSRF Token once logged in and checks token validation throughout user's session */
    fetchToken = async () => {
        const { config, browser } = this.props.mobx_config
        let URLSU, URLSP, FINAL_URLSU, FINAL_URLSP, COMPLETEP = false, COMPLETEU = false

        if (config === 0) {
            URLSU = serv_user_development.createCSRFU
            URLSP = serv_provider_development.createCSRFP
        }
        if (config === 1) {
            URLSU = serv_user_production.createCSRFU
            URLSP = serv_provider_production.createCSRFP
        }

        FINAL_URLSU = `${URLSU}device=browser&browser=${browser}`
        FINAL_URLSP = `${URLSP}device=browser&browser=${browser}`

        await fetch(FINAL_URLSU, { credentials: 'include' }).then((res) => res.json()).then(res => {
            if (res.data) {
                const { CSRFSecretU, CSRFTokenU } = res.data
                sessionStorage.setItem('CSRFSecretU', CSRFSecretU)
                sessionStorage.setItem('CSRFTokenU', CSRFTokenU)
            }
            return COMPLETEU = true
        })

        await fetch(FINAL_URLSP, { credentials: 'include' }).then((res) => res.json()).then(res => {
            if (res.data) {
                const { CSRFSecretP, CSRFTokenP } = res.data
                sessionStorage.setItem('CSRFSecretP', CSRFSecretP)
                sessionStorage.setItem('CSRFTokenP', CSRFTokenP)
            }
            return COMPLETEP = true
        })

        if (COMPLETEP && COMPLETEU) return
    }

    render() {
        const { authenticated, loading } = this.state;

        if (loading) {
            return (
                <div style={{ background: '#000080' }}>
                    <div className='loading-screen' />
                </div>
            );
        }

        return (
            <HashRouter>
                <Switch>
                    <Route path='/login' component={Login} />
                    <PrivateRoute path='/' component={RealDashboard} authenticated={authenticated} />
                </Switch>
            </HashRouter>
        )
    }
}

Checker = inject('mobx_auth', 'mobx_config')(observer(Checker))

ReactDOM.render(
    <Provider {...stores}>
        <Checker />
    </Provider>,
    document.getElementById('root')
);