import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledCollapse, FormGroup, Label, Input, } from "reactstrap";
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';
import firebase from 'firebase';
import VehicleVerificationDisplayUserEmail from './VehicleVerificationDisplayUserEmail';

export default class VehicleVerificationDisplayUser extends Component {
    static propTypes = {
        data: PropTypes.object,
        index: PropTypes.number,
        length: PropTypes.number,
        type: PropTypes.string,
        parentRefresh: PropTypes.func,
    }
    state = {
        modalGrantt: false,
        reason: "",
        buttonLoadReject: false,
        buttonLoadApprove: false,
        modalDisplay: false,
        selected_FUID: '',
    }
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state], selected_FUID: '' });
    };
    _sendNotification = () => {
        const { data } = this.props
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        alert('Do you want to prompt the user to upload a grant?')
        console.log('send notification', data);

        let URL, FINAL_URL, BODY, TEXT, Q_FUID, Q_VEHICLE_ID, Q_RETAIL_MAIN, Q_RETAIL_ID, Q_BOOKING_ID, csrfTokenU, csrfSecretU, headerToken
        let user_branch = data.FUID.length === 20 ? 'users_non_serv' : 'users'

        if (this.props.mobx_config.browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
        if (this.props.mobx_config.config === 0) URL = serv_user_development.personalNotifications
        if (this.props.mobx_config.config === 1) URL = serv_user_production.personalNotifications

        console.log(csrfTokenU, URL);

        TEXT = 'text=Verify your vehicle ownership'
        BODY = `&body=Please verify that this vehicle ${data.plate} belongs to you by uploading your vehicle registration certificate`
        Q_FUID = `&userKey=${data.FUID}`
        Q_VEHICLE_ID = `&vehicle_id=${data.plate}`
        let LOCATION = `&location=${COUNTRY_DB}`
        FINAL_URL = URL + TEXT + BODY + Q_FUID + Q_VEHICLE_ID + LOCATION
        let OPTIONS = { credentials: 'include', headers: headerToken }
        console.log(user_branch, data.FUID, FINAL_URL);

        // 1. NOTIFY USER THAT THEY NEED TO UPLOAD GRANT
        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) this.setState({ toggle: true, success: false, reload: true })
            return response.json()
        }).then(async (response) => {
            await firebase.app(COUNTRY_DB).database().ref(`plate_number_verification/${data.plate}/${data.FUID}/`).update({
                name: data.name,
                status: "notified",
                timestamp: new Date().getTime(),
                timestamp_notify: new Date().getTime()
            })

            // console.log(`${user_branch}/${data.FUID}/vehicles/${data.plate}/status`);
            await firebase.app(COUNTRY_DB).database().ref(`${user_branch}/${data.FUID}/vehicles/${data.plate}/status`).set('verify')
            this.props.parentRefresh()
        }).catch(err => {
            console.log(err);
        })
    }
    _removeUser = async () => {
        const { data, } = this.props
        const FUID = data.FUID, PLATE = data.plate
        let user_branch = FUID.length === 20 ? 'users_non_serv' : 'users'
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // console.log('remove user');
        let r = window.confirm('Would you like to remove this vehicle from this user?\nThey can always re-add the vehicle and request co-ownership again')

        if(!r) return;

        // console.log(data);
        // 1. REMOVE USER FROM VEHICLE
        // 2. REMOVE VEHICLE FROM USER
        // 3. REMOVE PLATE_NUMBER_VERIFICATION/USER
        await firebase.app(COUNTRY_DB).database().ref(`${user_branch}/${FUID}/vehicles/${PLATE}`).remove()
        await firebase.app(COUNTRY_DB).database().ref(`plate_number/${PLATE}/user/${FUID}`).remove()
        await firebase.app(COUNTRY_DB).database().ref(`plate_number_verification/${PLATE}/${FUID}`).remove()

        this.props.parentRefresh()
    }
    _makeTrueOwner = () => {
        const { data, } = this.props
        const FUID = data.FUID, PLATE = data.plate
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        console.log('mark true owner');
        
        let r = window.confirm(`Would you like to make this user the true owner of the vehicle ${PLATE}? Please double check the user details with the uploaded grantt.\n\nClick 'OK' to confirm.`)

        if(!r) return;

        // 1. CHECK CURRENT OWNER, AND SAVE INTO TEMPORARY STORE
        // 2. REPLACE WITH THIS USER AS OWNER
        // 3. ADD IN THE 
        let URL, FINAL_URL, BODY, TEXT, Q_FUID, Q_VEHICLE_ID, Q_RETAIL_MAIN, Q_RETAIL_ID, Q_BOOKING_ID, csrfTokenU, csrfSecretU, headerToken

        if (this.props.mobx_config.browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
        if (this.props.mobx_config.config === 0) URL = serv_user_development.personalNotifications
        if (this.props.mobx_config.config === 1) URL = serv_user_production.personalNotifications

        TEXT = 'text=True owner request approved'
        BODY = `&body=You are now approved as the true owner for vehicle ${data.plate}.`
        Q_FUID = `&userKey=${data.FUID}`
        Q_VEHICLE_ID = `&vehicle_id=${data.plate}`
        let LOCATION = `&location=${COUNTRY_DB}`
        FINAL_URL = URL + TEXT + BODY + Q_FUID + Q_VEHICLE_ID + LOCATION
        let OPTIONS = { credentials: 'include', headers: headerToken }

        console.log(FUID, PLATE, data.owner, data);
        
        // 1. NOTIFY USER THAT THEY NEED TO UPLOAD GRANT
        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) this.setState({ toggle: true, success: false, reload: true })
            return response.json()
        }).then(async (response) => {
            let CURRENT_OWNER = {}
            let user_branch = FUID.length === 20 ? 'users_non_serv' : 'users'
            // 1. FETCH CURRENT LISTED OWNER
            let snapshot_owner = await firebase.app(COUNTRY_DB).database().ref(`plate_number/${PLATE}/owner`).once('value')
            let snapshot_user = await firebase.app(COUNTRY_DB).database().ref(`${user_branch}/${FUID}/Phone`).once('value')

            if(snapshot_owner.exists()) {
                CURRENT_OWNER = snapshot_owner.val()
                let NEW_OWNER = { name : data.name, FUID: data.FUID, phone: snapshot_user.val(), email: data.email }
                console.log('UPDATE', data);

                // if current owner and approved owner isnt the owner, REPLACE
                if(CURRENT_OWNER.FUID !== data.FUID) {
                    let owner_branch = CURRENT_OWNER.FUID.length === 20 ? 'users_non_serv' : 'users'
                    console.log(CURRENT_OWNER, NEW_OWNER);
                    await firebase.app(COUNTRY_DB).database().ref(`plate_number/${PLATE}/owner`).update( NEW_OWNER )
                    await firebase.app(COUNTRY_DB).database().ref(`plate_number/${PLATE}/user/${data.FUID}`).remove()

                    await firebase.app(COUNTRY_DB).database().ref(`${owner_branch}/${CURRENT_OWNER.FUID}/vehicles/${PLATE}`).remove()
                    await firebase.app(COUNTRY_DB).database().ref(`plate_number_verification/${PLATE}/${CURRENT_OWNER.FUID}`).remove()
                } else {
                    console.log('CURRENT OWNER SAMA DENGAN ASSIGNED OWNER');
                }

                // Tukar users jadi approve for true owner
                await firebase.app(COUNTRY_DB).database().ref(`${user_branch}/${FUID}/vehicles/${PLATE}`).update({ status : "approved", })
                await firebase.app(COUNTRY_DB).database().ref(`plate_number/${PLATE}`).update({
                    car_registration : {
                        file : data.user_verify.grantt,
                        timestamp_approved : new Date().getTime()
                    },
                    make : data.make,
                    model : data.model,
                })
                
                await firebase.app(COUNTRY_DB).database().ref(`plate_number_verification/${PLATE}/${FUID}`).remove()

                // delete semua user
                if(data.user){
                    for (const key in Object.values(data.user)) {
                        let CURRENT = Object.values(data.user)[key]
                        let CURRENT_FUID = Object.keys(data.user)[key]
                        let current_user = CURRENT_FUID.length === 20 ? 'users_non_serv' : 'users'

                        // console.log('the key = ', CURRENT_FUID, NEW_OWNER.FUID);
                        if(CURRENT.relationship === 'not owner' && NEW_OWNER.FUID !== CURRENT_FUID){
                            await firebase.app(COUNTRY_DB).database().ref(`plate_number/${PLATE}/user/${CURRENT_FUID}`).remove()
                            await firebase.app(COUNTRY_DB).database().ref(`${current_user}/${CURRENT_FUID}/vehicles/${PLATE}`).remove()
                            await firebase.app(COUNTRY_DB).database().ref(`plate_number_verification/${PLATE}/${CURRENT_FUID}`).remove()
                        }
                    }
                }
    
                await this.setState({ modalGrantt : !this.state.modalGrantt, buttonLoadApprove : false })
                this.props.parentRefresh()
            }            
        })
    }
    _rejectTrueOwner = async () => {
        const { reason, } = this.state
        const { data, } = this.props
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        const FUID = data.FUID, PLATE = data.plate
        console.log('reject true owner');
        // let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        if(reason === "") {
            alert('Please select a reason for rejection to proceed!')
            return;
        }

        let r = window.confirm(`Would you like to reject the user's appeal as true owner for the vehicle ${PLATE}? Please double check the user details with the uploaded grantt.\n\nClick 'OK' to confirm.`)

        if(!r) return;

        // 1. UPDATE STATUS TO REJECT ON USER/VEHICLES/{PLATE}
        // 2. UPDATE REJECT STATUS AND REASON IN PLATE_NUMBER

        let URL, FINAL_URL, BODY, TEXT, Q_FUID, Q_VEHICLE_ID, Q_RETAIL_MAIN, Q_RETAIL_ID, Q_BOOKING_ID, csrfTokenU, csrfSecretU, headerToken

        if (this.props.mobx_config.browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
        if (this.props.mobx_config.config === 0) URL = serv_user_development.personalNotifications
        if (this.props.mobx_config.config === 1) URL = serv_user_production.personalNotifications

        TEXT = 'text=True owner request rejected'
        BODY = `&body=It seems that the vehicle ${data.plate} does not belong to you and you are not verified as the true owner. View the vehicle to acknowledge. If you think this is a mistake, please contact SERV Admin.`
        Q_FUID = `&userKey=${data.FUID}`
        Q_VEHICLE_ID = `&vehicle_id=${data.plate}`
        let LOCATION = `&location=${COUNTRY_DB}`
        FINAL_URL = URL + TEXT + BODY + Q_FUID + Q_VEHICLE_ID + LOCATION
        let OPTIONS = { credentials: 'include', headers: headerToken }

        console.log(FUID, PLATE);
        
        // 1. NOTIFY USER THAT THEY NEED TO UPLOAD GRANT
        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) this.setState({ toggle: true, success: false, reload: true })
            return response.json()
        }).then(async (response) => {
            let user_branch = FUID.length === 20 ? 'users_non_serv' : 'users'

            await firebase.app(COUNTRY_DB).database().ref(`${user_branch}/${FUID}/vehicles/${PLATE}`).update({ status : "reject" })
            await firebase.app(COUNTRY_DB).database().ref(`plate_number_verification/${PLATE}/${FUID}`).update({ reason : reason, status : "reject" })
            // await firebase.database().ref(`plate_number/${PLATE}/user/${FUID}`).remove()
            // await firebase.database().ref(`users/${data.FUID}/vehicles/${data.plate}/status`).set('verify')

            await this.setState({ modalGrantt : !this.state.modalGrantt, buttonLoadReject : false })
            this.props.parentRefresh()
        })
    }
    _displayNotifiedStatus = () => {
        let display
        const { data, type } = this.props
        const user_verify = data.user_verify

        display = user_verify && user_verify.timestamp_notify ? <small>Notified : {new Date(data.user_verify.timestamp_notify).toLocaleDateString('en-MY')}</small> : <small>Not yet notified</small>

        return display
    }
    _displayGrantUploadStatus = () => {
        let display, displayStatus
        const { data, type } = this.props
        const user_verify = data.user_verify
        
        if(user_verify) {
            if(user_verify.status !== "reject" || user_verify.status !== "approve") displayStatus = <Badge color="warning" pill>Grantt Uploaded</Badge>
            if(user_verify.status === "reject") displayStatus = <Badge color="danger" pill>Rejected</Badge>
            if(user_verify.status === "approve") displayStatus = <Badge color="success" pill>Approved</Badge>
        }

        display = user_verify && user_verify.grantt && <div className="d-flex align-items-center justify-content-lg-end">
            {displayStatus}
            <Button size="sm" color="dark" className="m-0 ml-2" onClick={() => this.toggleModal('modalGrantt')}>View Grantt</Button>
        </div>

        return display
    }
    _renderDetails = () => {
        const { data, type } = this.props
        const user_verify = data.user_verify
        let display
        let notified_display = <div className="d-flex align-items-center justify-content-between">
            {this._displayNotifiedStatus()}
            <Button color="info" className="m-0" size="sm" onClick={() => {this.setState({modalDisplay:true, selected_FUID: data.FUID})}}>Contact Details</Button>
        </div>

        if(type === 'conflict') {
            // console.log(data);
            display =
            <div>
                Name : {data.name}<br/>
                Email : {data.email}<br/>
                Make : {data.make ? data.make : 'Unspecified'}<br/>
                Model : {data.model ? data.model : 'Unspecified'}<br/>
                Relationship : {data.relationship === 'not owner' ? 'existing conflicting data' : data.relationship}<br/>
                <small>FUID : {data.FUID}</small><br/>
                {notified_display}
            </div>
        }
        if(type === 'verify') {
            // console.log(data);
            display =
            <div>
                Name : {user_verify.name}<br/>
                Email : {data.email}<br/>
                Make : {data.make ? data.make : 'Unspecified'}<br/>
                Model : {data.model ? data.model : 'Unspecified'}<br/>
                <small>FUID : {data.FUID}</small><br/>
                {notified_display}
            </div>
        }
        if(type === 'owner') {
            // console.log('owner', data);
            display =
            <div>
                <Badge pill color="info" style={{}}>owner</Badge>
                <div>
                    Name : {data.owner.name}<br/>
                    Email : {data.owner.email}<br/>
                    Make : {data.make ? data.make : 'Unspecified'}<br/>
                    Model : {data.model ? data.model : 'Unspecified'}<br/>
                    <small>FUID : {data.FUID}</small><br/>
                    {notified_display}
                </div>
            </div>
        }

        return display
    }
    _renderNotifyButton = () => {
        const { data, type } = this.props
        const user_verify = data.user_verify
        let display

        console.log(data.plate, user_verify);
        if(user_verify && user_verify.grantt) return null

        return display = <Button size="sm" className="m-0" color="dark" onClick={this._sendNotification}>Notify</Button>
    }
    _renderRemoveButton = () => {
        const { data, type } = this.props
        let display = null
        // console.log('remove user', data.user_verify, data.relationship, type);

        if(data.relationship === 'not owner' && type !== 'owner' && (data.user_verify !== null && !data.user_verify.grantt)) {
            display = <div>
                <Button size="sm" className="m-0 ml-2" color="danger" onClick={this._removeUser}>Remove User</Button>
            </div>
        }

        return display
    }
    render() {
        const { data, index, length, type } = this.props
        const user_verify = data.user_verify
        // if(data.user_verify) console.log(data.plate, data.user_verify);
        // console.log(data.plate);

        return (
            <div style={{border:'1px solid', borderRadius:'1rem', padding:'1rem', marginBottom: length !== index ? '1rem' : '0.5rem'}}>
                {this._renderDetails()}
                <div className="mt-2 d-flex align-items-center justify-content-end">
                    {this._renderNotifyButton()}
                    {this._renderRemoveButton()}
                    {this._displayGrantUploadStatus()}
                </div>
                {/* <div>
                    <Button className="m-0 mt-2" color="dark" onClick={this._makeTrueOwner}>Make True Owner</Button>
                </div> */}
                {user_verify && user_verify.grantt ? <Modal isOpen={this.state.modalGrantt} toggle={() => this.toggleModal('modalGrantt')} className='modal-dialog-centered'>
                    <ModalHeader toggle={() => this.toggleModal('modalGrantt')}>User Vehicle Grantt</ModalHeader>
                    <ModalBody>
                        Name : {user_verify && user_verify.name}<br/>
                        Phone : {user_verify && user_verify.phone}<br/>
                        Email : {data.email}<br/>
                        Make : {data.make}<br/>
                        Model : {data.model}<br/>
                        <small>FUID : {data.FUID}</small>

                        <div>
                            Grantt :<br/>
                            <img src={user_verify.grantt} style={{width:'45%'}} />
                        </div>
                        
                    </ModalBody>
                    <ModalFooter className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-start flex-column">
                            <Button color="danger" id="toggler" style={{ marginBottom: '1rem' }}>Reject</Button>
                            <UncontrolledCollapse toggler="#toggler">
                                <div>
                                    <FormGroup>
                                        <Label for='reason'>Reject Reason</Label>
                                        <Input type='select' name='reason' id='reason' onChange={this.handleChange} value={this.state.reason}>
                                            <option value="">--Select--</option>
                                            {/* <option value="Ownership certificate expired">Ownership certificate expired</option> */}
                                            <option value="Owner name on certificate incorrect">Owner name on certificate incorrect</option>
                                            <option value="Incorrect document uploaded">Incorrect document uploaded</option>
                                            <option value="Ownership certificate image not clear">Ownership certificate image not clear</option>
                                            <option value="Vehicle details added not the same as in vehicle ownership certificate">Vehicle details added not the same as in vehicle ownership certificate</option>
                                        </Input>
                                    </FormGroup>
                                    {this.state.buttonLoadReject ? <Button disabled color='danger' className="d-flex align-items-center justify-content-center">Rejecting<div className="btnloader ml-1"></div></Button> : <Button color='danger' onClick={async () => {
                                        await this.setState({buttonLoadReject : true})
                                        this._rejectTrueOwner()
                                    }}>Confirm Reject</Button>}
                                </div>
                            </UncontrolledCollapse>
                        </div>
                        
                        {this.state.buttonLoadApprove ? <Button disabled className='ml-auto d-flex align-items-center justify-content-center' color='success'>Loading<div className="btnloader ml-1 "></div></Button> : <Button className='ml-auto' color='success' onClick={async () => {
                            await this.setState({buttonLoadApprove:true})
                            this._makeTrueOwner()
                        }}>Make True Owner</Button>}
                    </ModalFooter>
                </Modal> : null}

                {this.state.modalDisplay ? <VehicleVerificationDisplayUserEmail toggleModal={this.toggleModal} modalDisplay={this.state.modalDisplay} FUID={this.state.selected_FUID} /> : null}
            </div>
        )
    }
}
VehicleVerificationDisplayUser = inject('mobx_config', 'mobx_auth')(observer(VehicleVerificationDisplayUser))