import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GRID from "assets/img/grid.svg";
import LIST from "assets/img/list.svg";
import Switch from "react-switch";
import ReactExport from "react-export-excel";
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

/* THE SEARCH BAR COMPONENT */
export default class SearchBar extends Component {
  static propTypes = {
    classContainer: PropTypes.string,               // Custom classname for the component container.
    searchPlaceholder: PropTypes.string.isRequired, // The placeholder string for the search input
    checked: PropTypes.bool.isRequired,             // True or false, to view the toggle on/off for the switch
    excelData: PropTypes.array,                     // An array of data that used to download into excel
    styleInput: PropTypes.object,                   // For the styling of the input
    styleContainer: PropTypes.object,               // For the styling of the component container
    addButton: PropTypes.func,                      // Display the add button component
    parentSearch: PropTypes.func.isRequired,        // To pass the search input to the parent
    parentSwitch: PropTypes.func.isRequired         // To pass the switch input to the parent
  }

  /* RENDER THE EXCEL DOWNLOAD BUTTON */
  _renderExcelDownload = (excelData) => {
    const { ExcelFile } = ReactExport
    const { ExcelSheet, ExcelColumn } = ExcelFile
    return (
      <ExcelFile element={<Button color="dark" className="m-0 ml-4">Download ExcelSheet</Button>}>
        <ExcelSheet data={excelData} name="SERV User">
          <ExcelColumn label="Name" value="name" />
          <ExcelColumn label="Phone" value="email" />
          <ExcelColumn label="Email" value="phone" />
          <ExcelColumn label="Registered Date" value="registered_date" />
          <ExcelColumn label="Registered Time" value="registered_time" />
        </ExcelSheet>
      </ExcelFile>
    )
  }

  /* RENDER THE INPUT ON SEARCHBAR */
  _renderSearchBar = (searchPlaceholder, parentSearch) => {
    return (
      <InputGroup className="no-border" style={{ ...this.props.styleInput, marginBottom: '0', width: '350px' }}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText style={{ background: "rgba(255,255,255,0.8)" }}>
            <i className="now-ui-icons ui-1_zoom-bold" />
          </InputGroupText>
        </InputGroupAddon>
        <Input style={{ background: "rgba(255,255,255,0.8)" }} placeholder={searchPlaceholder} onChange={parentSearch} />
      </InputGroup>
    )
  }

  /* RENDER THE SWITCH GRID OR LIST */
  _renderSwitch = (parentSwitch, checked) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={GRID} style={{ width: "30px", padding: "0 5px" }} alt='icon' />
        <Switch checked={checked} onChange={parentSwitch} onColor="#86d3ff" onHandleColor="#2693e6" handleDiameter={30} uncheckedIcon={false} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" id="material-switch" />
        <img src={LIST} style={{ width: "30px", padding: "0 5px" }} alt='icon' />
      </div>
    )
  }

  render() {
    const { addButton, excelData, searchPlaceholder, parentSearch, parentSwitch, checked, styleContainer, classContainer } = this.props
    return (
      <div style={{ ...styleContainer }} className={classContainer}>
        <div className="d-flex">
          {addButton && addButton()}
          {this._renderSearchBar(searchPlaceholder, parentSearch)}
          {excelData && this._renderExcelDownload(excelData)}
        </div>
        {this._renderSwitch(parentSwitch, checked)}
      </div>
    )
  }
}
