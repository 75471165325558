import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, FormText, Row, Col,
} from 'reactstrap';
import firebase from 'firebase';
import { CONFIG } from 'configs/config.js'
import { serv_user_development, serv_user_production } from 'configs/firebaseCF';
import ErrorAlert from '../components/ErrorAlert';
import { inject, observer } from 'mobx-react';

class BookingRequestEditServiceTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalEdit: false,
            customer_day: '',
            customer_month: '',
            customer_year: '',
            customer_time: '',
            toggle: false,
            success: false,
            reload: false
        };
    }

    componentDidMount() {
        this.setState({
            customer_day: this.props.data.customer_day,
            customer_month: this.props.data.customer_month,
            customer_year: this.props.data.customer_year,
            customer_time: this.props.data.customer_time,
        })
    }


    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    toggleEdit = () => {
        this.setState({ modalEdit: !this.state.modalEdit })
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })


    _save = async () => {
        this.toggleEdit();
        let BOOKING = this.props.data.booking_id
        let props = this.props.data
        let NOTIFICATION, UPDATE_DATE_TIME, csrfTokenU, csrfSecretU, headerToken;

        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (CONFIG === 0) {
            NOTIFICATION = serv_user_development.personalNotifications
            UPDATE_DATE_TIME = serv_user_development.updateDateTimeBook
        }
        if (CONFIG === 1) {
            NOTIFICATION = serv_user_production.personalNotifications
            UPDATE_DATE_TIME = serv_user_production.updateDateTimeBook
        }
        let TEXT = `text=On The Go Updated Date and Time`
        let BODY = `&body=There has been a change to your On-The-Go booking date and time for the vehicle ${this.props.data.customer_carplate}. The date has been confirmed to be at ${props.customer_day} ${props.customer_month} ${props.customer_year} at ${props.customer_time}. Please check the booking for more information.`
        let USER_KEY = `&userKey=${this.props.data.customer_FUID}`
        let VEHICLE_ID = `&vehicle_id=${this.props.data.customer_vehicleID}`
        let TYPE = `&type=sotg`
        let FINAL = NOTIFICATION + TEXT + BODY + USER_KEY + VEHICLE_ID + TYPE
        let OPTIONS = { credentials: 'include', headers: headerToken }

        const response = await fetch(FINAL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        })
        if (response !== 401) {
            await firebase.database().ref('request/' + BOOKING).update({
                customer_day: this.state.customer_day,
                customer_month: this.state.customer_month,
                customer_year: this.state.customer_year,
                customer_time: this.state.customer_time,
            })

            const day = `day=${this.state.customer_day}`
            const month = `month=${this.state.customer_month}`
            const year = `year=${this.state.customer_year}`
            const time = `time=${this.state.customer_time}`

            let FINAL_DATE_TIME_URL = `${UPDATE_DATE_TIME}user_key=${this.props.data.customer_FUID}&car_key=${this.props.data.customer_vehicleID}&${day}&${month}&${year}&${time}`
            await fetch(FINAL_DATE_TIME_URL, OPTIONS).then((response) => {
                if (response.status !== 200) {
                    this.setState({ toggle: true, success: false, reload: true })
                    return response.status
                }
                return response.json()
            })
            this.props.parentFetch();
        }
    }

    render() {
        const time = [
            "10:00AM", "10:30AM", "11:00AM", "11:30AM", "12:00PM", "12:30PM", "1:00PM", "1:30PM",
            "2:00PM", "2:30PM", "3:00PM", "3:30PM", "4:00PM", "4:30PM", "5:00PM", "5:30PM",
            "6:00PM", "6:30PM", "7:00PM", "7:30PM", "8:00PM", "8:30PM", "9:00PM", "9:30PM",
            "10:00PM"
        ]
        let TIME = [];
        for (var i = 0; i < time.length; i++) {
            TIME.push(
                <option key={i} value={time[i]}>{time[i]}</option>
            )
        }
        const day = [
            "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15",
            "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"
        ]
        let DAY = [];
        for (var i = 0; i < day.length; i++) {
            DAY.push(
                <option key={i} value={day[i]}>{day[i]}</option>
            )
        }
        const month = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        let MONTH = [];
        for (var i = 0; i < month.length; i++) {
            MONTH.push(
                <option key={i} value={month[i]}>{month[i]}</option>
            )
        }
        const current_year = new Date().getFullYear()
        let year = [current_year, current_year + 1, current_year + 2], YEAR = [];
        for (var i = 0; i < year.length; i++) {
            YEAR.push(
                <option key={i} value={year[i]}>{year[i]}</option>
            )
        }
        return (
            <div style={{ display: 'inline' }}>
                <Button onClick={this.toggleEdit} color="warning" style={{ margin: '0', padding: '3px 10px', borderRadius: '25px' }}>Edit</Button>

                <Modal isOpen={this.state.modalEdit} toggle={this.toggleEdit} style={{ maxWidth: '45%' }}>
                    <ModalHeader toggle={this.toggleEdit}>Edit Service Time and Date</ModalHeader>
                    <ModalBody>
                        <h6 style={{ display: 'inline-block' }}>Current service day & time :</h6>
                        <span>{' '}{this.props.data.customer_day}{' '}{this.props.data.customer_month}{' '}{this.props.data.customer_year},{' '}{this.props.data.customer_time}</span>

                        <hr />

                        <h6 style={{ display: 'inline-block' }}>Service date : </h6>
                        <Row>
                            <Col md={3} className="pr-1">
                                <FormGroup>
                                    <Input type="select" name="customer_day" id="customer_day" value={this.state.customer_day} onChange={this.handleChange}>
                                        {DAY}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={5} className="pr-1 pl-1">
                                <FormGroup>
                                    <Input type="select" name="customer_month" id="customer_month" value={this.state.customer_month} onChange={this.handleChange}>
                                        {MONTH}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={4} className="pl-1">
                                <FormGroup>
                                    <Input type="select" name="customer_year" id="customer_year" value={this.state.customer_year} onChange={this.handleChange}>
                                        {YEAR}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <h6 style={{ display: 'inline-block' }}>Service Time : </h6>
                        <FormGroup>
                            <Input type="select" name="customer_time" id="customer_time" value={this.state.customer_time} onChange={this.handleChange}>
                                {TIME}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={this._save}>Save</Button>{' '}
                        <Button color='secondary' onClick={this.toggleEdit}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        );
    }
}

BookingRequestEditServiceTime.propTypes = {
    data: PropTypes.object.isRequired,
    parentFetch: PropTypes.func.isRequired,
};

BookingRequestEditServiceTime = inject('mobx_config')(observer(BookingRequestEditServiceTime));
export default BookingRequestEditServiceTime;
