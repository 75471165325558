import React, { Component } from 'react'


import { Card, CardBody, CardHeader, } from 'reactstrap'

export default class DashboardTurkey extends Component {
   

    render() {
        return (
            <div className='content'>
                    <Card className="kanban-card card">
                        <CardBody style={{ padding: '95px 25px' }}>
                        <h4 className="text-center" style={{fontSize:35}}>Welcome to Admin Dashboard</h4>
                        </CardBody>
                    </Card>
            </div>
           
        )
    }
}
