import React, { Component } from 'react';
import { PanelHeader, BusinessAdd } from 'components';
import { Card, Row, Col, } from 'reactstrap';
import { inject, observer } from 'mobx-react'
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js';
import BusinessDisplay from 'components/BusinessDisplay';
import ErrorAlert from 'components/ErrorAlert';

class ListingBusiness extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_type: [],
            user_tiers: {},
            load: '1',
            toggle: false,
            success: false,
            reload: false
        };
    }
    componentDidMount() {
        this.fetchEverything()
    }
    fetchEverything = () => {
        let URL, csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.fetchUserType
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.fetchUserType
        }

        let OPTIONS = { credentials: 'include', headers: headerToken }

        fetch(URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                console.log(response.data);
                this.setState({ user_type: response.data.type, user_tiers: response.data.tiers, load: '2' })
            }
        })
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    _refresh = () => {
        this.fetchEverything()
    }
    _renderContents = () => {
        let display;

        if (this.state.load === '1') {
            display = <Card body><div className="loader"></div></Card>
        }
        if (this.state.load === '2') {
            display = this.state.user_type.length !== 0 ? this.state.user_type.map((data, index) => (
                <BusinessDisplay key={index} data={data} tiers={this.state.user_tiers[data.id] !== undefined ? this.state.user_tiers[data.id] : null} parentRefresh={this._refresh} />
            )) : <Card style={{ padding: '65px 25px', textAlign: 'center' }}>No User Business Types to Display</Card>
        }

        return display;
    }
    render() {
        return (
            <div>
                <PanelHeader size="sm" />
                <div className='content'>
                    <Row>
                        <Col md={3}>
                            <BusinessAdd parentRefresh={this._refresh} />
                        </Col>
                        <Col md={9}>
                            {this._renderContents()}
                        </Col>
                    </Row>
                </div>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        );
    }
}
ListingBusiness = inject('mobx_config')(observer(ListingBusiness));
export default ListingBusiness;