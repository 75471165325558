import React from 'react'

import { Row, Card, CardHeader, CardBody, Table, CardFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { PanelHeader } from "components";

import CardAnalyticsChart from 'components/CardAnalyticsChart.jsx';
import Pagination from "react-paginating";
import RoadAssistanceReport from './RoadAssistanceReport';

import firebase from 'firebase';
import { inject, observer } from 'mobx-react';
import { CONFIG } from 'configs/config.js'
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'
import workerAssistChartAnalysis from 'workers/workerAssistChartAnalysis.js';
import workerAssistUserData from 'workers/workerAssistUserData.js';
import WebWorker from 'workers/workerSetup.js';
import ErrorAlert from 'components/ErrorAlert';

class MyAssistAnalysis extends React.Component {
    state = {
        analytics: {},
        users: [],
        load: false,
        load_users: '1',
        currentPage: 1,
        search: '',
        toggle: false,
        success: false,
        reload: false
    }
    handlePageChange = (page, e) => {
        this.setState({
            currentPage: page
        })
    }
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    // PERFORMS SPILICING ON ARRAY THAT HAS FETCHED DATA FROM FIREBASE
    chunkArray = (myArray, chunk_size) => {
        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];
        var TEMP = [];
        let myChunk

        if (arrayLength !== 0) {
            for (var j = 0; j < arrayLength; j++) {
                TEMP.push(myArray[j])
            }
            let TEMPlength = TEMP.length

            for (index = 0; index < TEMPlength; index += chunk_size) {
                myChunk = TEMP.slice(index, index + chunk_size);
                tempArray.push(myChunk);
            }
        }
        return tempArray;
    }

    componentDidMount() {
        this._fetchAnalytics()
        this.workerAssistChartAnalysis = new WebWorker(workerAssistChartAnalysis);
        this.workerAssistUserData = new WebWorker(workerAssistUserData);
    }

    _fetchAnalytics = () => {
        let URL, URL_USERS, csrfTokenU, csrfSecretU, csrfTokenP, csrfSecretP, headerTokenU, headerTokenP;
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        const { browser } = this.props.mobx_config

        if (CONFIG === 0) {
            URL = serv_user_development.myAssistAnalysis
            URL_USERS = serv_user_development.fetchUsers
        }
        if (CONFIG === 1) {
            URL = serv_user_production.myAssistAnalysis
            URL_USERS = serv_user_production.fetchUsers
        }

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            csrfTokenP = sessionStorage.getItem('CSRFTokenP')
            csrfSecretP = sessionStorage.getItem('CSRFSecretP')

            headerTokenU = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
            headerTokenP = { csrftoken: csrfTokenP, csrfsecret: csrfSecretP }
        }

        let RESPONSE = {}
        let OPTIONS_U = { credentials: 'include', headers: headerTokenU }
        let OPTIONS_P = { credentials: 'include', headers: headerTokenP }
        let LOCATION = `location=${COUNTRY_DB}`

        const SENT_LOCATION = URL + LOCATION
        const SENT_LOCATION_USER = URL_USERS + LOCATION
        fetch(SENT_LOCATION, OPTIONS_P).then(response => {
            if (response.status !== 200) this.setState({ toggle: true, success: false, reload: true })
            return response.json()
        }).then(response => {
            RESPONSE = response.data
            this.workerAssistChartAnalysis.postMessage(RESPONSE);

            this.workerAssistChartAnalysis.addEventListener('message', event => {
                // console.log('WW response', event.data)
                this.setState({ analytics: event.data, load: true })
            })
        }).then(() => {
            // console.log(RESPONSE);
            let CALLS = RESPONSE
            fetch(SENT_LOCATION_USER, OPTIONS_U).then(response => {
                if (response.status !== 200) this.setState({ toggle: true, success: false, reload: true })
                return response.json()
            }).then(response => {
                let USERS = response.data
                // console.log(USERS);

                let POST = { users: USERS, calls: CALLS }
                this.workerAssistUserData.postMessage(POST);
                this.workerAssistUserData.addEventListener('message', event => {
                    // console.log('WW response', event.data)
                    this.setState({ users: event.data, load_users: event.data.length !== 0 ? '2' : '3' })
                    console.log("worker assitsUserdata", this.state.users)
                });
            })
        })
    }

    _renderAnalytics = () => {
        let display

        display = this.state.load === true ? <CardAnalyticsChart title='My Assist Analytics' data={this.state.analytics} load={this.state.load} icon="phone" name="Calls" /> : <Card body><div className="loader"></div></Card>

        return display
    }
    _renderUsers = () => {
        let display
        let state = this.state

        if (state.load_users === '1') {
            display = <Card style={{ padding: '95px 25px' }} className="text-center"><div className="loader"></div></Card>
        }
        if (state.load_users === '2') {
            const { currentPage } = this.state;

            let filteredData = state.load_users === '2' ? state.users.filter((data) => {
                if (data.name !== undefined) {
                    return data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                }
            }) : []

            let limit = 25;
            let pageCount = 7;
            let pagesArray = this.chunkArray(filteredData, 25);
            let content;
            // console.log(filteredData);

            if (pagesArray.length !== 0) {
                content =
                    <Table className='align-items-center pb-0' responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th style={{ padding: '8px 7px', fontSize: '1.1rem' }} scope='col'>Name</th>
                                <th style={{ padding: '8px 7px', fontSize: '1.1rem' }} scope='col'>Contact</th>
                                <th style={{ padding: '8px 7px', fontSize: '1.1rem' }} scope='col'>No. Of Calls</th>
                                <th style={{ padding: '8px 7px', fontSize: '1.1rem', textAlign: 'right' }} scope='col'>Last Call</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pagesArray[currentPage - 1].map((data, index) => (
                                <tr key={index}>
                                    <td width="25%" style={{ whiteSpace: 'pre-wrap', padding: '15px' }}>{data.name !== "" ? data.name : <span className="text-danger">Data not available</span>}</td>
                                    <td width="40%" style={{ whiteSpace: 'pre-wrap', padding: '15px' }}>
                                        <div>Email : {data.email !== undefined && data.email !== "" ? data.email : <span className="text-danger">Not available</span>}</div>
                                        <div>Phone : {data.phone !== undefined && data.phone !== "" ? data.phone : <span className="text-danger">Not available</span>}</div>
                                        <small>{data.FUID}</small>
                                    </td>
                                    <td width="10%" style={{ whiteSpace: 'pre-wrap', textAlign: 'center', padding: '15px' }}>{data.call_count}</td>
                                    <td width="20%" style={{ whiteSpace: 'pre-wrap', textAlign: 'right', padding: '15px' }}>{new Date(data.last_call).toLocaleString('en-MY', { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
            }
            if (pagesArray.length === 0) {
                content =
                    <div style={{ padding: '10rem 1.5rem', textAlign: 'center' }}>
                        We couldn't find data for "{this.state.search}"<br />Make sure the user name is spelled and formatted correctly
                </div>
            }

            let pagination =
                <Pagination
                    total={pagesArray.length * limit}
                    limit={limit}
                    pageCount={pageCount}
                    currentPage={currentPage}
                >
                    {({ pages, currentPage, hasNextPage, hasPreviousPage, previousPage, nextPage, totalPages, getPageItemProps }) => (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <div
                                style={{ cursor: 'pointer' }}
                                {...getPageItemProps({
                                    pageValue: 1,
                                    onPageChange: this.handlePageChange
                                })}
                            >
                                {"<<"}
                            </div>
                            {hasPreviousPage ? (
                                <div
                                    style={{ padding: '5px 10px', cursor: 'pointer' }}
                                    {...getPageItemProps({
                                        pageValue: previousPage,
                                        onPageChange: this.handlePageChange
                                    })}
                                >
                                    {"<"}
                                </div>
                            ) : <div style={{ padding: '5px 10px', cursor: 'pointer' }}>{'<'}</div>}

                            {pages.map(page => {
                                let activePage = null;
                                if (currentPage === page) {
                                    activePage = { fontWeight: '800', textDecoration: 'none' };
                                }
                                return (
                                    <div
                                        style={{ padding: '5px 10px', textDecoration: 'underline', ...activePage, cursor: 'pointer' }}
                                        key={page}
                                        // style={activePage}
                                        {...getPageItemProps({
                                            pageValue: page,
                                            onPageChange: this.handlePageChange
                                        })}
                                    >
                                        {page}
                                    </div>
                                );
                            })}

                            {hasNextPage ? (
                                <div
                                    style={{ padding: '5px 10px', cursor: 'pointer' }}
                                    {...getPageItemProps({
                                        pageValue: nextPage,
                                        onPageChange: this.handlePageChange
                                    })}
                                >
                                    {">"}
                                </div>
                            ) : <div style={{ padding: '5px 10px', cursor: 'pointer' }}>{'>'}</div>}

                            <div
                                style={{ cursor: 'pointer' }}
                                {...getPageItemProps({
                                    pageValue: totalPages,
                                    onPageChange: this.handlePageChange
                                })}
                            >
                                {">>"}
                            </div>
                        </div>
                    )}
                </Pagination>

            display =
                <Card>
                    <CardHeader className="pb-3 mb-0 border-0 shadow d-flex align-items-center justify-content-between" style={{ background: '#202020', color: 'white', borderRadius: '1rem 1rem 0 0' }}>
                        <h5 className="m-0">User Data</h5>
                        <InputGroup className='no-border' style={{ marginBottom: '0', width: '55%' }}>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={{ background: 'rgba(255,255,255,0.8)' }}>
                                    <i className='now-ui-icons ui-1_zoom-bold' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input name="search" style={{ background: 'rgba(255,255,255,0.8)' }} placeholder='Search user name' onChange={this.handleChange} />
                        </InputGroup>
                        <RoadAssistanceReport usersData={this.state.users} />
                    </CardHeader>

                    {content}

                    <CardFooter style={{ borderTop: '1px solid #dee2e6' }}>
                        {pagination}
                    </CardFooter>
                </Card>
        }
        if (state.load_users === '3') {
            display = <Card style={{ padding: '65px 25px' }} className="text-center">No data available</Card>
        }

        return display
    }
    render() {
        return (
            <div>
                <PanelHeader size="sm" />
                <div className="content">
                    {this._renderAnalytics()}
                    {this._renderUsers()}
                </div>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        );
    }
}

MyAssistAnalysis = inject('mobx_auth', 'mobx_quote', 'mobx_config')(observer(MyAssistAnalysis));
export default MyAssistAnalysis;