import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js';
import { inject, observer } from 'mobx-react'
import BusinessAddTiers from './BusinessAddTiers';
import ErrorAlert from './ErrorAlert';

class BusinessDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayButt: true,
            modalEdit: false,
            user_type: {},
            toggle: false,
            success: false,
            reload: false

        };
    }
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    _delete = () => {
        let r = window.confirm('Are you sure you want to delete this listing?')
        if (!r) {
            return;
        }
        const { browser } = this.props.mobx_config


        let URL, csrfTokenU, csrfSecretU, headerToken;
        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.deleteUserType
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.deleteUserType
        }

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        let USER_ID = 'type_id=' + this.props.data.id
        let FINAL_URL = URL + USER_ID;
        let OPTIONS = { credentials: 'include', headers: headerToken }

        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                // console.log(response.data);
                this.toggleModal('modalEdit')
                this.props.parentRefresh();
            }
        })
        // this.setState({displayButt: false})
    }
    _save = () => {
        const { browser } = this.props.mobx_config

        let URL, csrfTokenU, csrfSecretU, headerToken;
        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.editUserType
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.editUserType
        }

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        let USER_TYPE = 'user_type=' + this.state.user_type
        let USER_ID = '&type_id=' + this.props.data.id

        let FINAL_URL = URL + USER_TYPE + USER_ID;
        let OPTIONS = { credentials: 'include', headers: headerToken }

        

        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                // console.log(response.data);
                this.toggleModal('modalEdit')
                this.props.parentRefresh();
            }
            // this.setState({displayButt: false})
        })
    }
    _renderTiers = () => {
        let display = [];
        const { data, tiers } = this.props;

        if (tiers !== null) {
            for (const tier_id in tiers) {
                display.push(
                    <div key={tier_id}>
                        {tiers[tier_id]}
                    </div>
                )
            }
        }
        if (tiers === null) {
            display =
                <div>
                    No tiers for this Business Type.
            </div>
        }

        return display;
    }
    render() {
        // console.log(this.props.data)
        const { data } = this.props;
        return (
            <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={12} md={12}>
                    <Card style={{ borderRadius: '5px', borderLeft: '5px ridge #7163b8', boxShadow: '0 5px 10px rgba(27,27,27,0.7)' }}>
                        <CardBody style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px' }}>

                            <div style={{ width: '75%', fontSize: '35px', fontWeight: 'bold' }}>
                                {data.name}
                            </div>

                            <div style={{ width: '25%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                <Button color="dark" className="m-0" style={{ width: '100%' }} onClick={() => {
                                    // console.log(this.props);
                                    this.setState({ user_type: this.props.data }, () => {
                                        
                                        this.toggleModal('modalEdit')
                                    })
                                }}>
                                    Edit
                                </Button>{' '}
                            </div>

                        </CardBody>
                    </Card>
                </Col>

                <Modal className='modal-default' isOpen={this.state.modalEdit} toggle={() => { this.toggleModal('modalEdit') }}>
                    <ModalHeader toggle={() => { this.toggleModal('modalEdit') }}>{this.props.data.name}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for='user_type'>User Business Type</Label>
                            <Input type='text' name='user_type' id='user_type' onChange={this.handleChange} value={this.state.user_type.name} />
                        </FormGroup>
                        <hr />

                        <BusinessAddTiers type_id={this.props.data.id} parentRefresh={this.props.parentRefresh} />

                        {/* {this._renderTiers()} */}
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <Button color='light' onClick={this._save}>Save</Button>{' '}
                            <Button color="danger" onClick={() => {
                                this._delete()
                            }}>
                                Delete
                            </Button>
                        </div>

                        <Button className='ml-auto' color='secondary' onClick={() => {
                            this.setState({ user_type: this.props.data.type }, () => {
                                this.toggleModal('modalEdit')
                            })
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </Row>
        );
    }
}

BusinessDisplay.propTypes = {
    data: PropTypes.object.isRequired,
    tiers: PropTypes.object,
};
BusinessDisplay = inject('mobx_config')(observer(BusinessDisplay));
export default BusinessDisplay;