import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import { PanelHeader } from "components";

import { thead, tbody } from "variables/quotationRequest";
import wipGif from "assets/img/wip.gif"
class Wip extends React.Component {
  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div style={{textAlign:'center'}}>
                    <div style={{padding:'20px'}}>
                      You came here too early! We're still working on it.
                    </div>
                    <img src={wipGif} style={{marginBottom: '20px', width: '85%'}} />
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Wip;
