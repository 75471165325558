import React, { Component } from 'react'
import { Card, Button } from "reactstrap";
import { PanelHeader } from "components";
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';
import { Route } from 'react-router-dom'
import MerchantMain from 'pages/MerchantMain.jsx';
import MerchantSelected from 'pages/MerchantSelected.jsx';
// import MerchantMainTR from 'pages/MerchantMainTR.jsx';

export default class Merchant extends Component {
    render() {
        return (
            <div>
                <PanelHeader size="sm" />
                <div className="content">
                    <Route exact path="/user/Merchant-Malaysia" component={MerchantMain} />
                    <Route exact path="/user/Merchant-Malaysia/:id" component={Individual} />
                </div>
            </div>
        )
    }
}

function Individual({ match }) {
    return <MerchantSelected id={match.params.id} />;
}

Merchant = inject('mobx_config')(observer(Merchant))