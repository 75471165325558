import React, { Component } from 'react'
import firebase from 'firebase'
import { inject, observer } from 'mobx-react'
import PanelHeader from '../components/PanelHeader'
import BusinessListingUpdate from './BusinessListingUpdate'
import BusinessListingDisplay from './BusinessListingDisplay'
import { Col, Row, Card } from 'reactstrap'

export default class BusinessListing extends Component {
  state = { data: {}, load: '1' }

  componentDidMount = () => this.fetchEverything()

  refresh = () => this.fetchEverything()

  /* Fetch Business and Tier Type from DB */
  fetchEverything = async () => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    // console.log("COUNTRY", COUNTRY_DB)
    const db = firebase.app(COUNTRY_DB).database(), DATA = { type: [], tiers: {} }, TYPE = []
    let DB_SNAP, DB_VAL

    try {
      DB_SNAP = await db.ref('user_type/').once('value')
      DB_VAL = await DB_SNAP.val()

      if (!DB_SNAP.exists()) return this.setState({ load: '3' })
      if (DB_SNAP.exists()) {
        for (const typeID in DB_VAL) {
          let type = DB_VAL[typeID]
          type.id = typeID
          type.bg = DB_VAL[typeID].bg ? DB_VAL[typeID].bg : ''
          TYPE.push(type)
        }
        DATA.type = TYPE
      }

      DB_SNAP = await db.ref('user_tier/').once('value')
      DB_VAL = await DB_SNAP.val()
      if (!DB_SNAP.exists()) return console.log('No tier listed...')
      if (DB_SNAP.exists()) DATA.tiers = DB_VAL

      await this.setState({ data: DATA, load: '2' })

    } catch (error) { return console.error('Error Occured', error) }
  }

  /* Rendering the content based on load state */
  _renderContent = () => {
    const { load, data } = this.state
    let displayBusiness

    if (load === '1') displayBusiness = <Card body><div className='loader'></div></Card>

    if (load === '2') displayBusiness = data.type.map(type => <BusinessListingDisplay key={type.id} data={type} tiers={data.tiers[type.id] !== undefined ? data.tiers[type.id] : null} parentRefresh={this.refresh} />)

    if (load === '3') displayBusiness = <Card style={{ padding: '65px 25px', textAlign: 'center' }}>No User Business Types to Display</Card>

    return <Row><Col md={3}><BusinessListingUpdate forType='type' type='add' parentRefresh={this.refresh} /></Col><Col md={9}>{displayBusiness}</Col></Row>
  }

  render() { return <div><PanelHeader size='sm' /><div className='content'>{this._renderContent()}</div></div> }
}
BusinessListing = inject('mobx_config','mobx_auth')(observer(BusinessListing))
