import React, { Component } from 'react'
import { Card, Badge, Button, InputGroup, InputGroupAddon, InputGroupText, Input, Collapse, } from "reactstrap";
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';
import firebase from 'firebase'
import AddWorkshop from './MerchantAdd.jsx';
import DisplayWorkshopTurkey from './MerchantDisplayTurkey.jsx';

import RETAIL from 'static_data/retail.json'
import RETAIL_MAIN from 'static_data/retail_main.json'
import ErrorAlert from 'components/ErrorAlert.jsx';
import workerMerchantMain from '../workers/workerMerchantMain.js';
import WebWorker from 'workers/workerSetup.js';


export default class MerchantMainTR extends Component {
    constructor(props) {
        super(props)

        this.state = {
            load: '1',
            data: [],
            workshop_main: [],
            workshop: {},
            search: '',
            toggle: false,
            success: false,
            reload: false,
            count_preferred: 0,
            toggleFilter: false,
            filterButton: false,
        }
    }
    componentDidMount() {
        this.fetchWorkshop()
        this.workerMerchantMain = new WebWorker(workerMerchantMain)
        // this.fetchStatic()
    }

    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    handleFilter = () => this.setState({ toggleFilter:!this.state.toggleFilter })

    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })

    filterPreferredWorkshops = () => {
        const { data:retails } = this.state
        let storeRetails = []

        for (const retailID in retails) {
            let currentRetail = retails[retailID]
            
            currentRetail.OUTLETS.forEach(outlet => {
                const exists = storeRetails.find(retail => retail.group_id === currentRetail.group_id) === undefined

                if (outlet.preferred !== undefined && outlet.preferred === true && !!exists) {
                    storeRetails.push(currentRetail)
                }
            });
        }

        this.setState({ data:storeRetails })
    }

    fetchStatic = () => {
        let WORKSHOPS = []
        let retail_main = RETAIL_MAIN
        let retail = RETAIL

        // 1. iterate through each workshop_main
        for (const group in retail_main) {
            let a = retail_main[group]
            a.group_id = group
            let OUTLETS = []

            // 2. iterate through each outlet in workshop and store into outlet array
            for (const workshop_id in retail[group]) {
                if (workshop_id !== 'retail_id') {
                    let CURRENT = retail[group][workshop_id]
                    CURRENT.workshop_id = workshop_id
                    OUTLETS.push(CURRENT)
                }
            }
            // 3. push array of outlets into the main object 

            a.OUTLETS = OUTLETS

            WORKSHOPS.push(a)
        }

        // console.log(WORKSHOPS)

        this.setState({ load: '2', data: WORKSHOPS })
    }

 

    fetchWorkshop = () => {
        let URL, csrfTokenU, csrfSecretU, headerToken;
        let LOCATION = `location=TR`
        const { browser } = this.props.mobx_config
      
        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopFetch
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopFetch
        }
        // console.log(URL)
        let OPTIONS = { credentials: 'include', headers: headerToken }
        let SENT_LOCATION = URL + LOCATION
        // console.log("sent location yooo", SENT_LOCATION)
        fetch(SENT_LOCATION, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                // let WORKSHOPS = [], COUNT = 0
                let WORKSHOP = response.workshop
                const { data, count_preferred, load} =  this.state
                let WORKSHOP_MAIN = response.workshop_main
                // console.log(response)
                let POST = {workshop: WORKSHOP, workshop_main:WORKSHOP_MAIN}
                this.workerMerchantMain.postMessage(POST)
                this.workerMerchantMain.addEventListener('message', event => {
                    // console.log("event", event.data.count)
                    this.setState({ load: '2', data: event.data.retail_main, count_preferred:event.data.count })
                    
                    !!this.state.filterButton && this.filterPreferredWorkshops()
                })
            } else {
                this.setState({ load: '3' })
            }
        })
    }

//     fetchWorkshop = ()  =>{

//         // 1. iterate retail main data 
//        let COUNT_VERIFIED = 0
//        let  COUNT = 0
//        let WORKSHOPS = [], RETAIL_MAIN = []
//        const { data, load, workshop_main, count_preferred} = this.state
       
//        firebase.app('TR').database().ref(`retail_main/`).once('value', (snapshot) => {
//            if(snapshot.exists()) {
//                let data = snapshot.val() 
//                for(const group in data) {
//                    let a = data[group]
//                    a.group_id = group
//                    let OUTLETS = []
   
//                    firebase.app('TR').database().ref(`retail/`).on('value', (snap) => {
//                        if(snap.exists()) {
//                            let data_retail = snap.val()
   
//                            for(const workshop_id in data_retail[group]) {
//                                let CURRENT = data_retail[group][workshop_id]
//                                CURRENT.workshop_id = workshop_id
//                                if(CURRENT.display === '3') {
//                                    COUNT_VERIFIED = COUNT_VERIFIED + 1
//                                }
                   
//                                if(CURRENT.preferred !== undefined && CURRENT.preferred === true) {
//                                    COUNT = COUNT + 1
                                 
//                                }
//                                OUTLETS.push(CURRENT)
//                            }
                           
//                        }
//                       this.setState({count_preferred:COUNT})
//                    })
                  
//                    a.OUTLETS = OUTLETS
//                    RETAIL_MAIN.push(a)
//                    WORKSHOPS.unshift(a)           
//                }
             
//                this.setState({ load: '2', data: WORKSHOPS, workshop_main: RETAIL_MAIN})
//                !!this.state.filterButton && this.filterPreferredWorkshops()
//            } else {
//                this.setState({load: '3'})
//            }
//        })
//    }


    _renderContent = () => {
        let display
        const { load, data } = this.state

        if (load === '1') {
            display = <Card style={{ padding: '45px 25px' }}><div className="loader"></div></Card>
        }
        if (load === '2') {
            let filteredData = data.filter(
                (item) => {
                    if (item.name !== undefined) {
                        return item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
                    }
                }
            )

            display = filteredData.map((data, index) => (
                <DisplayWorkshopTurkey key={index} data={data} parentFetch={this.fetchWorkshop} />
            ))
        }
        if (load === '3') {
            display =
                <Card style={{ padding: '65px 25px' }} className="text-center">
                    No data to display. Add workshop to begin.
            </Card>
        }

        return display
    }

    _renderFilterOptions = () => {
        const { filterButton, toggleFilter } = this.state

        return (
            <Collapse isOpen={toggleFilter}>
                {
                    !filterButton ? 
                    <Button style={styles.button} onClick={() => this.setState({ filterButton:true }, this.filterPreferredWorkshops())}>Preferred Workshops</Button> :
                    <Button color='info' style={styles.buttonSelected} onClick={() => this.setState({ filterButton:false, load:'1' }, this.fetchWorkshop())}>
                        <i className='fa fa-check mr-2' style={styles.iconSelected}></i>
                        <span style={{ color:'white', fontWeight:'500' }}>Preferred Workshops</span>
                    </Button>
                }
            </Collapse>
        )
    }

    _renderTopRight = () => {
        return (
            <>
            <Button color='light' className='d-flex justify-content-start' style={{ padding:'10px 15px', zIndex:'100' }} >
              <i className='fas fa-filter' style={{ fontSize:'15px', fontWeight:'bold' }} /> 
              <span className='ml-2' style={{ fontSize:'15px', fontWeight:'500' }} onClick={() => this.handleFilter()} >Filter</span>
            </Button>
            <Card className='ml-2 p-2 pl-3 pr-3 mt-auto mb-auto' color='info' style={{ color:'white', fontWeight:'500' }}>{this.state.count_preferred} preferred outlets</Card>
            </>
        )
    }

    render() {
        return (
            <div className="content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className='d-flex justify-content-start'>
                        {this.props.mobx_auth.COUNTRY === 'TR' ? <AddWorkshop retail_main={this.state.workshop_main} parentFetch={this.fetchWorkshop} />:null}
                        
                       <InputGroup className='no-border' style={{ marginBottom: '0', width: '350px' }}> 
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={{ background: 'rgba(255,255,255,1)' }}>
                                    <i className='now-ui-icons ui-1_zoom-bold' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input name="search" style={{ background: 'rgba(255,255,255,1)' }} placeholder='Search merchant name' onChange={this.handleChange} />
                        </InputGroup>
                    </div>
                     <div className='d-flex justify-content-end'>
                        {this._renderTopRight()}
                    </div> 
                </div>

                {this._renderFilterOptions()}

                {this._renderContent()}
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        )
    }
}

const styles = {
    button: { 
        color:'#1e1950',
        border:'2px solid #1e1950',
        background:'white',
        fontWeight:'500' 
    },
    buttonSelected: {
        background:'#1e1950',
        border:'2px solid #1e1950',
    },
    iconSelected: {
        textAlign:'left',
        fontSize:'10px',
        color:'white'
    }
}

MerchantMainTR = inject('mobx_config', 'mobx_auth')(observer(MerchantMainTR))