import React, { Component } from 'react'
import PanelHeader from 'components/PanelHeader.jsx';
import { Button, Card, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Progress } from 'reactstrap'
import firebase from 'firebase';
import ErrorAlert from 'components/ErrorAlert';
import LOGO from 'assets/img/placeholder.png'
import Cropper from 'react-easy-crop';
import getCroppedImg from 'components/cropImage';
import Compressor from 'compressorjs';
import MarketingBannerEdit from './MarketingBannerEdit';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { inject, observer } from 'mobx-react';

const minZoom = 0.5

export default class MarketingBanner extends Component {
    state = {
        data : [], load : '1', max : 5, modal : false, modalEdit : false,
        name : '', link : '', id : '', compressedFile : null,
        toggle : false, text : '', displayButt : true,
        canUpload : false, previewUpload : LOGO,
        croppedImage: null, croppedAreaPixels: { x: 0, y: 0 },
        crop: { x: 0, y: 0 }, zoom: 1, aspect: 3 / 1.2,
        progress: { text : '', percentage : 0 },
        edit : null, items: []
    }
    componentDidMount = async () => {
        this.fetchData()
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let MAX = await firebase.app(COUNTRY_DB).database().ref(`consts/marketing_banner_max`).once('value')
        if(MAX.exists()) this.setState({max : MAX.val()})
    }
    componentWillUnmount() {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        firebase.app(COUNTRY_DB).database().ref(`marketing_banner/`).off()
    }
    onCropChange = (crop) => {
        this.setState({ crop })
    }
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }
    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }
    
    toggleModal = (state) => {
        this.setState({[state] : !this.state[state]})
    }
    toggleModalParent = (state, type) => {
        this.setState({[state] : !this.state[state], edit : type === 'close' ? null : this.state.edit})
    }
    childToggle = () => {
        this.setState({toggle : !this.state.toggle})
    }
    handleChange = (evt) => {
        let value = evt.target.value, name = evt.target.name
        this.setState({[name] : value})
    }
    handleImage = () => {
        const file = document.querySelector('#upload_banner').files[0]
        if(file){
            if(!file.type.includes("image/")) {
                console.log('not an image');
            }

            var checker = new Image();
            checker.src = window.URL.createObjectURL(file)

            checker.onload = () => {
                let fileSize = file.size;
                console.log(fileSize, file.type);
                window.URL.revokeObjectURL(checker.src)
                if(fileSize < 500000) {
                    this.setState({canUpload:true, previewUpload: URL.createObjectURL(file), croppedImage: URL.createObjectURL(file)})
                } else {
                    document.querySelector('#upload_banner').value = ""
                    this.setState({ previewUpload: LOGO, canUpload:false, toggle:true,
                        text: <span>File size too large! File size has to be less than 100KB.<br/>You can compress your image <a href="https://resizeimage.net/" target="_blank" style={{textDecoration:'underline'}}>here</a></span> })
                }
            }
        } else {
            this.setState({ previewUpload: LOGO, canUpload:false, toggle:true, text: <span>Please select an image to upload!</span> })
        }
    }
    errorHandler = () => {
        const file = document.querySelector('#upload_banner').files[0]
        const { name, link, canUpload } = this.state
        if(name === ""){
            this.setState({ previewUpload: LOGO, canUpload:false, toggle:true, text: <span>Please fill in the name of the campaign.</span> })
            return;
        }
        if(link === ""){
            this.setState({ previewUpload: LOGO, canUpload:false, toggle:true, text: <span>Please fill in the link to open the webview for the campaign.</span> })
            return;
        }
        if(!file || !canUpload){
            this.setState({ previewUpload: LOGO, canUpload:false, toggle:true, text: <span>Please select an image to upload!</span> })
            return;
        }
        this.fetchCroppedImage();
    }
    fetchCroppedImage = async () => {
        try {
            let progress = {text:'compressing image', percentage:15}
            this.setState({ progress  })

            const croppedImage = await getCroppedImg(this.state.previewUpload, this.state.croppedAreaPixels)
            console.log('donee', croppedImage, this.state.progress)

            let compressedImage = await new Compressor(croppedImage, {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                success: async (compressedResult) => {
                    console.log('compressed result', compressedResult);
    
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.        
                    this.setState({ croppedImage : compressedResult })
                    progress = {text:'saving data', percentage:35}
                    console.log(this.state.croppedImage)
                    this.setState({ progress  }, () => {
                        console.log('can upload', this.state.progress);
                        this._saveBannerCampaign()
                    })
                },
            })
        } catch (e) {
            console.error(e)
            console.log("You're catched")
        }

    }
    _saveBannerCampaign = () => {
        const { name, link, id, croppedImage } = this.state
        let blob = croppedImage, that = this
        let progress = { text:'saving data', percentage:50 }
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        this.setState({ progress })
        let DATA = {
            name : name,
            link : link,
            id : id,
            croppedImage : croppedImage
        }
    
        // console.log(DATA);
        let KEY = firebase.app(COUNTRY_DB).database().ref('marketing_banner/').push().getKey();
        const storageRef = firebase.app(COUNTRY_DB).storage().ref('marketing_banner');
        let uploadBanner = storageRef.child(KEY).put(blob);
        
        uploadBanner.on('state_changed', (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.app(COUNTRY_DB).storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                // that.setState({progressColor:'warning', text : 'PAUSED'})
                break;
                case firebase.app(COUNTRY_DB).storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                // that.setState({progressColor:'success', text : 'UPLOADING'})
                break;
            }
        }, (error) => {
            // IF IMAGE UPLOAD FAILED
            alert('Upload failed, please try again!\n', error.message);
            that.setState({
                displayButt: true, modalError:true,
                text: <span>Error uploading image!<br/>Check your internet connection and try again.</span>,
                text:'saving data', percentage:70 
            })
        }, () => {
            // IF IMAGE UPLOAD SUCCEED
            uploadBanner.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // console.log('File available at', downloadURL);
                firebase.app(COUNTRY_DB).database().ref(`marketing_banner/${KEY}`).update({
                    name : name,
                    link : link,
                    id : id,
                    banner_image: downloadURL,
                    timestamp: new Date().getTime()
                }).then(() => {
                    firebase.app(COUNTRY_DB).database().ref(`marketing_banner_schema/${this.state.data.length}`).update({
                        banner_id : KEY,
                        name : name,
                        link : link,
                        id : id,
                        banner_image: downloadURL,
                        timestamp: new Date().getTime()
                    })
                }).then(() => {
                    that.setState({
                        text:'saving data', percentage:100,
                        displayButt:true,
                        canUpload: false, previewUpload: LOGO, text: '',
                    }, () => {
                        alert('Succesfully uploaded banner!')
                        // this.props.parentRefresh()
                        that.setState({
                            text:'', percentage:0,
                            displayButt:true, modal: false,
                            canUpload: false, previewUpload: LOGO,
                            name : '', link : '', compressedFile : null
                        })
                    })
                })
            })
        })
    }
    _deleteBannerCampaign = async(data) => {
        let r = window.confirm("Are you sure you want to delete this campaign?\n\nThe banner will be removed from being displayed in the app. Click 'OK' to proceed.")
        if(!r) return;

        let DATA = data, downloadURL = DATA.banner_image
        let STORE = {}
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let DB = await firebase.app(COUNTRY_DB).database().ref(`marketing_banner/${DATA.banner_id}`).once('value')
        if(!DB.exists()) return;
        
        STORE = DB.val()
        await firebase.app(COUNTRY_DB).database().ref(`marketing_banner_archives/${DATA.banner_id}`).update(STORE)
        await firebase.app(COUNTRY_DB).database().ref(`marketing_banner/${DATA.banner_id}`).remove()

        const index = this.state.data.findIndex(banner => banner.banner_id === DATA.banner_id)
        this.state.data.splice(index, 1);

        if (index !== -1)  firebase.app(COUNTRY_DB).database().ref('marketing_banner_schema/').set(this.state.data)
    }
    fetchData = () => {
        let DB = firebase.app(COUNTRY_DB).database().ref(`marketing_banner/`)
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        DB.on('value', (snapshot) => {
            let SNAPSHOT = snapshot.val()
            let DATA = [], schema = []

            if(snapshot.exists()){
                for (const key in SNAPSHOT) {
                    let a = SNAPSHOT[key]

                    a.banner_id = key
                    DATA.push(a)
                }

                firebase.app(COUNTRY_DB).database().ref(`marketing_banner_schema/`).once('value').then(snapshot => {
                    if (snapshot.exists()) schema = snapshot.val()

                    this.setState({data:schema.length !== 0 ? schema : DATA, load:'2'})
                })
            } else {
                this.setState({load:'3'})
            }
        })
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        this.setState({ data:result })
        return result;
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) return
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        const items = this.reorder(this.state.data, result.source.index, result.destination.index)

        firebase.app(COUNTRY_DB).database().ref('marketing_banner_schema/').set(items)
    }

    _renderDNDContent = () => {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId='droppable'>
                {provided => (
                    <div ref={provided.innerRef} >
                        {this.state.data.map((data, index) => (
                            <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} {...provided.draggableProps.style} >
                                        <Card body>
                                            <Row>
                                                <Col lg="4"><img src={data.banner_image} /></Col>
                                                <Col lg="7">
                                                    <h5 className="m-0">{data.name}</h5>
                                                    <Button color="danger" size="sm" className="m-2 m-0" onClick={() => {this._deleteBannerCampaign(data)}}>Delete</Button>
                                                    <Button color="light" size="sm" className="m-0" onClick={() => {
                                                        this.setState({ modalEdit : true, edit : data })
                                                    }}>Edit</Button>
                                                </Col>
                                                <Col lg='1' className='mt-auto mb-auto'>
                                                    <i className='fas fa-solid fa-bars mt-auto mb-auto' style={{ fontSize:'27px', fontWeight:'bold', color:'#000063' }} />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
        )
    }

    _renderContent = () => {
        let display
        const { load } = this.state

        if(load === '1') return display = <Card body><div className="loader"></div></Card>
        if(load === '2') return display = this._renderDNDContent()
    }
    
    render() {
        const { progress, max, data, displayButt } = this.state
        return (
            <div>
                <PanelHeader size='sm' />
                <div className="content">
                    <Card body className="d-flex justify-content-between flex-row align-items-center">
                        <Button className="m-0" color="dark" onClick={() => {
                            if(data.length !== max) this.toggleModal('modal');
                            else this.setState({ text : 'Maximum campaigns reached! Delete a campaign to add another or reach out to Nasreen to increase the max.', toggle : true})
                        }}>Add Campaign</Button>
                        <div>{data.length} / {max} campaigns</div>
                    </Card>
                    {this._renderContent()}

                    <Modal isOpen={this.state.modal} toggle={() => {this.toggleModal('modal')}} className="modal-dialog-centered modal-sm">
                        <ModalHeader toggle={() => {this.toggleModal('modal')}}>Add new banner campaign</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="name">Campaign Name</Label>
                                        <Input type="text" name="name" id="name" onChange={this.handleChange} placeholder="Name of campaign associated with the banner" />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label for="id">Campaign ID</Label>
                                        <Input type="text" name="id" id="id" onChange={this.handleChange} placeholder="ID for developers to refer to" />
                                        <FormText color="danger">* This ID is for any hardcoded banners in the app, please check with the developers first if you want anything specific hardcoded, otherwise, you may put whatever.</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="link">Campaign Link</Label>
                                <Input type="text" name="link" id="link" onChange={this.handleChange} placeholder="Webview link" />
                                <FormText color="danger">* This is the link for the webview.</FormText>
                            </FormGroup>

                            <FormGroup>
                                <Label>Upload Banner</Label><br />
                                <button id='upload_banner_button' onClick={this.attachFileInputToButton} className='mb-1 m-0' style={{ fontSize: '0.7rem', borderRadius: '5px', display: 'block' }}>Upload Banner</button>
                                <Input type="file" accept="image/*" name="upload_banner" id="upload_banner" style={{marginBottom:'5px'}} onChange={this.handleImage}/>
                                <FormText color="danger">* Image upload guide : Recommended file size less than 150kb. Image dimensions do not matter as long as it has an aspect ratio of 3:1!</FormText>
                            </FormGroup>
                            <Card style={{ height:'270px'}}>
                                <Cropper
                                    minZoom={minZoom}
                                    image={this.state.previewUpload}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={this.state.aspect}
                                    restrictPosition={true}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                    zoomSpeed={0.2}
                                    objectFit="contain"
                                />
                            </Card>
                            {progress.percentage !== 0 && <Progress bar animated color="success" value={progress.percentage}>{progress.text}</Progress>}
                        </ModalBody>
                        <ModalFooter>
                            {displayButt ? <Button color="dark" onClick={this.errorHandler} className="m-0">Save</Button> : <Button color="dark" className="m-0 d-flex align-items-center justify-content-center" disabled><div>Saving</div><div className="btnloader"></div></Button>}
                        </ModalFooter>
                    </Modal>
                    
                    <MarketingBannerEdit modal={this.state.modalEdit} data={this.state.edit} toggleModal={this.toggleModalParent} banners={this.state.data} />
                    <ErrorAlert text={this.state.text} toggle={this.state.toggle} parentToggle={this.childToggle} />
                </div>
            </div>
        )
    }
}

MarketingBanner = inject('mobx_auth')(observer(MarketingBanner))