import React, { Component } from 'react'
import { Card, Button, Row, Col, CardBody, Badge } from "reactstrap";
import { PanelHeader } from "components";
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';
import PLACEHOLDER from 'assets/img/logo-placeholder.png'
import RETAIL from 'static_data/retail.json'
import RETAIL_MAIN from 'static_data/retail_main.json'

import { createHashHistory } from 'history';
import ErrorAlert from 'components/ErrorAlert';
const history = createHashHistory();

export default class Analytics extends Component {
    constructor(props) {
        super(props)

        this.state = {
            load: '1',
            data: [],
            workshop_main: [],
            workshop: {},
            toggle: false,
            success: false,
            reload: false
        }
    }
    _view = (data) => {
        history.push('/user/Merchant-Analytics/' + data.group_id)
    }
    componentDidMount() {
        this.fetchWorkshop()
        // this.fetchStatic()
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    fetchStatic = () => {
        let WORKSHOPS = []
        let retail_main = RETAIL_MAIN
        let retail = RETAIL

        // 1. iterate through each workshop_main
        for (const group in retail_main) {
            let a = retail_main[group]
            a.group_id = group
            let OUTLETS = []
           
            // 2. iterate through each outlet in workshop and store into outlet array
            for (const workshop_id in retail[group]) {
                if (workshop_id !== 'retail_id') {
                    let CURRENT = retail[group][workshop_id]
                   
                    CURRENT.workshop_id = workshop_id
                    OUTLETS.push(CURRENT)
                }
            }
            // 3. push array of outlets into the main object 

            a.OUTLETS = OUTLETS

            WORKSHOPS.push(a)
        }

        

        this.setState({ load: '2', data: WORKSHOPS })
    }
    fetchWorkshop = () => {
        let URL, csrfTokenU, csrfSecretU, headerToken;

        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopFetch
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopFetch
        }
       

        let OPTIONS = { credentials: 'include', headers: headerToken }

        fetch(URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                let WORKSHOPS = []
                let workshop = response.workshop
                let workshop_main = response.workshop_main
                

                if (response.workshop_main !== null && response.workshop_main !== null && response.workshop_main !== undefined && response.workshop_main !== undefined) {
                    // 1. iterate through each workshop_main
                    let RETAIL_MAIN = []
                    for (const group in workshop_main) {
                        let a = workshop_main[group]
                        a.group_id = group
                        let OUTLETS = []

                        // 2. iterate through each outlet in workshop and store into outlet array
                        for (const workshop_id in workshop[group]) {
                            let CURRENT = workshop[group][workshop_id]
                            CURRENT.workshop_id = workshop_id
                            OUTLETS.push(CURRENT)
                        }
                        // 3. push array of outlets into the main object 


                        a.OUTLETS = OUTLETS

                        RETAIL_MAIN.push(a)
                        WORKSHOPS.push(a)
                    }

                   

                    this.setState({ load: '2', data: WORKSHOPS, workshop_main: RETAIL_MAIN })
                }
            } else {
                this.setState({ load: '3' })
            }
        })
    }

    _renderContent = () => {
        let display
        const { load, data } = this.state

        if (load === '1') {
            display = <Card style={{ padding: '45px 25px' }}><div className="loader"></div></Card>
        }
        if (load === '2') {
            let columns = data.map((data, index) => (
                <Col md={4} key={index}>
                    <Card>
                        <CardBody
                            className="d-flex align-items-center justify-content-between"
                            style={{ background: 'rgb(30, 25, 80)', borderRadius: '0.9rem 0.9rem 0 0', color: 'white' }}
                        >
                            <div>
                                <img src={data.logo !== '' ? data.logo : PLACEHOLDER} style={{ width: '30px', marginRight: '15px', borderRadius: data.logo !== '' ? '0' : '50%' }} />
                                {data.name}
                            </div>

                            <Button color="light" className="m-0" style={{ borderRadius: '10px', padding: '5px 10px', textAlign: 'center', fontWeight: 'bold' }} onClick={() => {
                                this._view(data)
                            }}>View</Button>
                        </CardBody>
                        <CardBody>
                            <div className="mb-3">
                                HQ Address: {data.address}<br />
                                HQ Phone Number: {data.phone}
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <Badge pill color="info">{data.OUTLETS.length} outlets</Badge>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))

            display =
                <Row>
                    {columns}
                </Row>
        }
        if (load === '3') {
            display =
                <Card body>
                    No workshop to display. Add workshop to begin.
            </Card>
        }

        return display
    }
    render() {
        return (
            <div className="content">
                {this._renderContent()}
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        )
    }
}
Analytics = inject('mobx_config')(observer(Analytics))