import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import { PanelHeader, FormInputs, CardAuthor, CardSocials } from "components";
import { inject, observer } from 'mobx-react';

import empty from 'assets/img/mechanic.svg'

const image = "https://media.giphy.com/media/3oz8xwsGcaZWWB8KyY/giphy.gif"

class ServEmptyProfile extends React.Component{
  render(){
    return(
      <div style={{textAlign:'center'}}>
        <div style={{padding:'20px'}}>
          Click a profile from the list to view SP profile or chat.
        </div>
        <img src={empty} style={{width:'45%', margin:'20px auto'}}/>
      </div>
    )
  }
}
ServEmptyProfile = inject('mobx_serv')(observer(ServEmptyProfile))


export default ServEmptyProfile;
