import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, CardHeader, Button, ButtonGroup, Input } from 'reactstrap'
import Chat from 'components/Chat.jsx'
import { CONFIG } from 'configs/config.js'
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'

import firebase from 'firebase';
import ErrorAlert from 'components/ErrorAlert'
import { inject, observer } from 'mobx-react'

export default class BookingRequestAssignSP extends Component {
    static propTypes = {
        data: PropTypes.object,
        booking_id: PropTypes.string,
        parentRefresh: PropTypes.func,
    }
    state = {
        load: '1',
        data: [],
        method: 'star',
        select: null,

        button_select: 1,
        search: '',
        jobStatus: '',

        // 1 = unpaid, unassigned
        // 2 = paid, unassigned
        // 3 = paid, assigned
        status: '1',
        toggle: false,
        success: false,
        reload: false

    }
    onRadioBtnClick = (select) => {
        if (select === 1 && this.state.button_select !== select) {
            this.setState({ load: '1', method: 'star', button_select: select }, () => {
                this._fetchMethod()
            })
        }
        if (select === 2 && this.state.button_select !== select) {
            this.setState({ load: '1', method: 'manual', button_select: select }, () => {
                this._fetchMethod()
            })
        }
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    componentDidMount() {
        this._checkStatus()
        this._fetchMethod()
    }
    _fetchMethod = () => {
        let that = this;
        let MANUAL = [];
        let STAR = [];
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // ========== FETCH DATA STARRED ==========
        if (this.state.method === 'star') {
            firebase.app(COUNTRY_DB).database().ref('request/' + this.props.booking_id + '/star').once('value').then((snapshot) => {
                if (snapshot.exists()) {
                    let DATA = snapshot.val()
                    let index = 0

                    for (const SP_ID in DATA) {
                        // console.log(SP_ID);
                        let a

                        firebase.app(COUNTRY_DB).database().ref('servproviderUID/' + SP_ID).once('value').then((snapshot2) => {
                            if (snapshot2.exists()) {
                                a = snapshot2.val()
                                a.key = SP_ID
                            }
                        }).then(() => {
                            firebase.app(COUNTRY_DB).database().ref('adminmessages/' + SP_ID).once('value').then((snp) => {
                                let MESSAGES = snp.val()
                                let UNREAD_COUNT = 0
                                for (const key in MESSAGES) {
                                    let u = MESSAGES[key].unread;

                                    if (u === true && u !== undefined) {
                                        UNREAD_COUNT = UNREAD_COUNT + 1;
                                    }
                                }
                                a.unread = UNREAD_COUNT
                            })

                            STAR.push(a);
                            // console.log(STAR)
                        }).then(() => {
                            if (index === Object.keys(DATA).length - 1) {
                                this.setState({ data: STAR, load: '2' })
                            }

                            index = index + 1
                        })
                    }
                } else {
                    // console.log('Nobody starred yet');
                    this.setState({ load: '3' })
                }
            })

        }

        // ========== FETCH DATA MANUALLY ==========
        if (this.state.method === 'manual') {

            firebase.app(COUNTRY_DB).database().ref('servproviderUID/').once('value').then((snapshot) => {
                if (snapshot.exists()) {
                    let DATA = snapshot.val()
                    let index = 0

                    for (const SP_ID in DATA) {
                        let a = snapshot.val()[SP_ID]
                        a.key = SP_ID;

                        firebase.app(COUNTRY_DB).database().ref('adminmessages/' + SP_ID).once('value').then((snp) => {
                            let MESSAGES = snp.val()
                            let UNREAD_COUNT = 0
                            for (const key in MESSAGES) {
                                let u = MESSAGES[key].unread;

                                if (u === true && u !== undefined) {
                                    UNREAD_COUNT = UNREAD_COUNT + 1;
                                }
                            }
                            a.unread = UNREAD_COUNT

                            MANUAL.push(a)
                        }).then(() => {
                            if (index === Object.keys(DATA).length - 1) {
                                this.setState({ data: MANUAL, load: '2' })
                            }

                            index = index + 1
                        })
                    }
                } else {
                    console.log('No registered SP');
                    this.setState({ load: '3' })
                }
            })

        }
    }
    _checkStatus = () => {
        let ASSIGNED = this.props.data.assign._id;
        let STATUS = this.props.data.assign.status
        let PAYMENT = this.props.data.receipt_payment_url;

        // UNPAID AND UNASSIGNED
        if (ASSIGNED === "" && PAYMENT === undefined) {
            this.setState({ status: '1' })
            // console.log('scene 1');
        }
        // PAID AND UNASSIGNED
        if (ASSIGNED === "" && PAYMENT !== undefined) {
            this.setState({ status: '2' })
            // console.log('scene 2');
        }
        // PAID AND ASSIGNED
        if (ASSIGNED !== "" && PAYMENT !== undefined) {
            this.setState({ status: '3', select: this.props.data.assign })
            // console.log('scene 3');
        }

        /************************************************
         * STATUS OF THE JOB IF THE JOB HAS BEEN ASSIGNED
         ************************************************/
        if (STATUS === 'waiting for approval') {
            this.setState({ jobStatus: 'pending' })
        }
        if (STATUS === 'done') {
            this.setState({ jobStatus: 'done' })
        }
    }
    selectSP = (data) => {
        this.setState({ select: null }, () => {
            let NEW_STATE = {
                _id: data.key,
                _name: data.name,
                profile_pic: data.profile_pic.downloadURL,
                number_plate: data.number_plate
            }
            this.setState({ select: NEW_STATE })
        })
    }
    _assign = async () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let r = window.confirm('Are you sure you want to this the SP to this job?')

        if (!r) {
            return
        }

        let DATA = this.props.data;
        let BOOKING_ID = this.props.booking_id
        let state = this.state
        let csrfTokenU, csrfSecretU, csrfTokenP, csrfSecretP, headerTokenU, headerTokenP;

        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            csrfTokenP = sessionStorage.getItem('CSRFTokenP')
            csrfSecretP = sessionStorage.getItem('CSRFSecretP')

            headerTokenU = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
            headerTokenP = { csrftoken: csrfTokenP, csrfsecret: csrfSecretP }
        }

        if (state.select !== null) {
            let assignURL;
            let userassignURL;
            let userkey = 'key=' + state.select._id;
            let customerKey = 'userkey=' + DATA.customer_FUID;
            let carkey = '&carkey=' + DATA.customer_vehicleID;
            let action = '&action=assign'
            let location = '&location=' + COUNTRY_DB

            if (CONFIG === 0) {
                assignURL = serv_provider_development.newJobAssigned

                userassignURL = serv_user_development.jobAssigned
            }
            if (CONFIG === 1) {
                assignURL = serv_provider_production.newJobAssigned
                userassignURL = serv_user_production.jobAssigned
            }

            // console.log(BOOKING_ID, state.select, DATA);
            let ASSIGN = {
                _id: state.select._id,
                status: 'assigned',
                _name: state.select._name,
                profile_pic: state.select.profile_pic !== undefined ? state.select.profile_pic : '',
                number_plate: state.select.number_plate,
            }
            let SP_DATA = {
                customer_address: DATA.customer_address,
                customer_carmake: DATA.customer_carmake,
                customer_carmodel: DATA.customer_carmodel,
                customer_carplate: DATA.customer_carplate,
                customer_caryear: DATA.customer_caryear,
                customer_day: DATA.customer_day,
                customer_email: DATA.customer_email,
                customer_month: DATA.customer_month,
                customer_name: DATA.customer_name,
                customer_phone: DATA.customer_phone,
                customer_time: DATA.customer_time,
                customer_year: DATA.customer_year,
                latitude: DATA.latitude,
                longitude: DATA.longitude,
                timestamp: DATA.timestamp,
            }

            const idToken = await firebase.auth().currentUser.getIdToken()

            let headers = { Authorization: `Bearer ${idToken}`, ...headerTokenP }
            let OPTIONS = { credentials: 'include', headers }
            let FINAL_userassignURL = userassignURL + customerKey + carkey + action + location
            let FINAL_assignURL = assignURL + userkey + action + location

            // push notifications to SU
            const response = await fetch(FINAL_userassignURL, { credentials: 'include', headers: headerTokenU }).then((response) => {
                if (response.status !== 200) {
                    this.setState({ toggle: true, success: false, reload: true })
                    return response.status
                }
                return response.json()
            })
            // push notifications to SP
            const response1 = await fetch(FINAL_assignURL, OPTIONS).then((response) => {
                if (response.status !== 200) {
                    this.setState({ toggle: true, success: false, reload: true })
                    return response.status
                }
                return response.json()
            })

            if (response !== 401 && response1 !== 401) {
                firebase.app(COUNTRY_DB).database().ref('request/' + BOOKING_ID + '/assign/').update(ASSIGN).then(() => {
                    this.props.parentRefresh()
                })
                firebase.app(COUNTRY_DB).database().ref('servproviderUID/' + state.select._id + '/job/' + BOOKING_ID).update(SP_DATA)
            }
        } else {
            alert('Please select on a SERV Provider to assign job.')
        }
    }
    _unassign = async () => {
        let r = window.confirm('Are you sure you want to unassign the SP from this job?')
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        if (!r) {
            return
        }

        let DATA = this.props.data;
        let BOOKING_ID = this.props.booking_id
        let state = this.state
        let csrfTokenU, csrfSecretU, csrfTokenP, csrfSecretP, headerTokenU, headerTokenP;

        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            csrfTokenP = sessionStorage.getItem('CSRFTokenP')
            csrfSecretP = sessionStorage.getItem('CSRFSecretP')

            headerTokenU = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
            headerTokenP = { csrftoken: csrfTokenP, csrfsecret: csrfSecretP }
        }

        // console.log(DATA, BOOKING_ID, state);
        let assignURL;
        let userassignURL;
        let userkey = 'key=' + state.select._id;
        let customerKey = 'userkey=' + DATA.customer_FUID;
        let carkey = '&carkey=' + DATA.customer_vehicleID;
        let action = '&action=unassign'
        let location = '&location=' + COUNTRY_DB

        if (CONFIG === 0) {
            assignURL = serv_provider_development.newJobAssigned

            userassignURL = serv_user_development.jobAssigned
        }
        if (CONFIG === 1) {
            assignURL = serv_provider_production.newJobAssigned
            userassignURL = serv_user_production.jobAssigned
        }

        let REQUEST_DATA = {
            _id: '',
            status: 'none',
            _name: '',
            profile_pic: '',
            number_plate: '',
        }

        const idToken = await firebase.auth().currentUser.getIdToken()

        let headers = { Authorization: `Bearer ${idToken}`, ...headerTokenP }
        let OPTIONS = { credentials: 'include', headers }

        // 1.push notifications to SU
        let FINAL_userassignURL = userassignURL + customerKey + carkey + action
        const responseJobAssigned = await fetch(FINAL_userassignURL, { credentials: 'include', headers: headerTokenU }).then((response) => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        })

        // 2.push notifications to SP
        let FINAL_assignURL = assignURL + userkey + action
        const responseNewJobAssigned = fetch(FINAL_assignURL, OPTIONS).then((response) => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        })

        if (responseJobAssigned !== 401 && responseNewJobAssigned !== 401) {
            // 3. EMPTY REQUEST ASSIGN BRANCH
            firebase.app(COUNTRY_DB).database().ref('request/' + BOOKING_ID + '/assign/').update(REQUEST_DATA).then(() => {
                this.props.parentRefresh()
            })

            // 4.REMOVE JOB FROM SP BRANCH
            firebase.app(COUNTRY_DB).database().ref('servproviderUID/' + state.select._id + '/job/' + BOOKING_ID).remove()
        }
    }
    _renderActionButtons = () => {
        let display

        let butt, chatButt, unassignButt;

        // UNPAID AND UNASSIGNED
        if (this.state.status === '1') {
            butt =
                <div className="mt-3">
                    <div style={{ background: 'red', width: '100%', padding: '5px', color: 'white', borderRadius: '0.1875rem' }} className="text-center">User hasn't paid,<br />cannot assign job to SP.</div>
                    <small style={{ display: 'block', marginBottom: '10px' }}>Click on button to assign</small>
                </div>
        }
        // PAID AND UNASSIGNED
        if (this.state.status === '2') {
            butt =
                <div className="mt-3">
                    <Button className="m-0" style={{ background: 'black', width: '100%' }} onClick={this._assign}>Assign SP</Button>
                    <small style={{ display: 'block', marginBottom: '10px' }}>Click on button to assign</small>
                </div>
        }
        // PAID AND ASSIGNED
        if (this.state.status === '3') {
            butt =
                <div className="mt-3">
                    <div style={{ background: 'green', width: '100%', padding: '5px', color: 'white', fontWeight: 'normal', borderRadius: '0.1875rem' }} className="text-center">Assigned to {this.props.data.assign._name}</div>
                    <small style={{ display: 'block', marginBottom: '10px' }}>Click on button to unassign</small>
                </div>
            chatButt = <Button style={{ flex: '1', margin: '0 3px', color: 'white', fontWeight: 'normal', background: 'black' }}>Chat</Button>

            if (this.state.jobStatus !== 'pending' && this.state.jobStatus !== 'done') {
                unassignButt = <Button style={{ flex: '1', margin: '0 3px', color: 'white', fontWeight: 'normal', background: 'black' }} onClick={this._unassign}>Unassign</Button>
            }
            if (this.state.jobStatus === 'pending') {
                unassignButt = <Button style={{ flex: '1', margin: '0 3px', color: 'white', fontWeight: 'normal', background: 'black' }}>SP has completed job</Button>
            }
        }
        // if(this.props.mobx_quote.check_assign === 'delete') {
        //     butt =
        //     <div style={{width:'100%', padding:'5px', color:'white', fontWeight:'normal'}} className="text-center bg-info">
        //         User has requested to cancel service. Cancel request or contact user to resolve issue.
        //     </div>
        // }

        display =
            <>
                {butt}
                <div className="d-flex align-items-center justify-content-between">
                    {chatButt}{unassignButt}
                </div>
            </>

        return display
    }
    _renderSPList = () => {
        let display
        let filteredData = this.state.data.filter(
            (data) => {
                return data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
            }
        );

        let display_list
        if (this.state.load === '1') {
            display_list = <div style={{ height: '330px' }} className="d-flex align-items-center justify-content-center"><div className="loader"></div></div>
        }
        if (this.state.load === '2' && filteredData.length !== 0) {
            display_list =
                <div style={{ height: '330px', overflowY: 'scroll', }}>
                    {filteredData.map((item, index) => (
                        <Card
                            key={index}
                            className="provideList"
                            onClick={() => { this.selectSP(item) }}
                        >
                            <CardBody>
                                {/* TO DO LATER : DISPLAY UNREAD */}
                                {/* {displayUnread} */}
                                <div style={{ fontWeight: 'bold' }}>{item.name}</div>
                                <div>Email: {item.email}</div>
                            </CardBody>
                        </Card>
                    ))}
                </div>
        }
        if (this.state.load === '2' && filteredData.length === 0) {
            display_list = <div style={{ height: '330px', overflowY: 'scroll', paddingTop: '55px' }} className="d-flex justify-content-center">Could not find SP "{this.state.search}".<br /> Check spelling and try again.</div>
        }
        if (this.state.load === '3') {
            display_list = <div style={{ height: '330px' }}><Card className='text-center provideList' body>No SP has starred this request</Card></div>
        }

        display =
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <ButtonGroup>
                        <Button color="warning" className="m-0"
                            onClick={() => {
                                this.onRadioBtnClick(1);
                            }} active={this.state.rSelected === 1}
                        >
                            Starred
                    </Button>
                        <Button color="warning" className="m-0"
                            onClick={() => {
                                this.onRadioBtnClick(2);
                            }} active={this.state.rSelected === 2}
                        >
                            Manual
                    </Button>
                    </ButtonGroup>
                    <Button
                        className="m-0"
                        style={{ borderRadius: '50%', padding: '8px 10px', lineHeight: '1', fontSize: '18px' }}
                        color="dark"
                        onClick={() => {
                            this.setState({ load: '1' }, () => { this._fetchMethod() })
                        }}
                    >
                        <i style={{ fontWeight: 'bold' }} className="now-ui-icons arrows-1_refresh-69" />
                    </Button>
                </div>

                {this._renderActionButtons()}

                <hr />

                <p style={{ margin: '0', textTransform: 'uppercase' }}>{this.state.method} mode</p>

                {this.state.method === 'manual' ?
                    <Input type="text" name="search" value={this.state.search} onChange={this.handleChange} placeholder="Search Serv Provider" style={{ marginBottom: "10px" }} />
                    : null}

                {display_list}
            </div>

        return display
    }


    _renderStatus = () => {
        let display
        let status = this.state.status === '1' ? 'Not Assigned' : 'Assigned'
        let color = this.state.status === '1' ? 'text-danger' : 'text-success'

        display =
            <div className="mr-4 d-flex align-items-center text-white">
                <span className="mr-1">{status}</span>
                <i className={"fas fa-circle " + color}></i>
            </div>

        return display
    }
    render() {
        return (
            <Card>
                <CardHeader className="d-flex align-items-center justify-content-between pb-3" style={{ background: 'rgb(32, 32, 32)', borderRadius: '1rem 1rem 0px 0px' }}>
                    <h5 className="mb-0 font-weight-bold text-white">Assign SERV Partner</h5>
                    {this._renderStatus()}
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs={12} md={8} className="d-flex justify-content-center flex-column">
                            {this.state.select !== null ? <h6 className="m-0 mb-2">Now chatting with : {this.state.select._name}</h6> : null}
                            <Chat servproviderID={this.state.select !== null ? this.state.select._id : null} />
                        </Col>

                        <Col xs={12} md={4}>
                            {this._renderSPList()}
                        </Col>
                    </Row>
                </CardBody>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </Card>
        )
    }
}
BookingRequestAssignSP = inject('mobx_config', 'mobx_auth')(observer(BookingRequestAssignSP));
