import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default class ErrorAlert extends Component {
    static defaultProps = {
        text: 'Something went wrong. Please refresh the page.'
    }
    static propTypes = {
        text: PropTypes.any.isRequired, // text of modal body
        toggle: PropTypes.bool.isRequired, // 
        parentToggle: PropTypes.func.isRequired, // toggle function from parent
        parentAction: PropTypes.func, // action when clicking proceed, if not exists, it just closes the modal
        title: PropTypes.string, // title of modal, if not exists, it shows 'Alert!'
        close: PropTypes.any, // if true, means ada proceed button
    }
    state = {
        modal: false
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    };
    // componentDidMount() {
    //     this._toggle()
    // }
    _toggle = () => {
        this.props.parentToggle();
    }
    render() {
        if (this.props.toggle === true) {
            return (
                <Modal style={{ maxWidth: '55%' }} isOpen={this.props.toggle} toggle={this._toggle} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger'>
                    <ModalHeader toggle={this._toggle}>{this.props.title ? this.props.title : 'Alert!'}</ModalHeader>
                    <ModalBody>
                        <div className="text-center d-flex flex-wrap flex-column">
                            <i className="fas fa-exclamation-triangle fa-6x mb-3" />
                            <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{this.props.text}</span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color='primary'>Save changes</Button> */}
                        {this.props.close ? <>
                                <Button className='m-0' color='dark' onClick={() => {
                                    this.props.parentAction()
                                    this._toggle()
                                }}>Proceed</Button>
                                <Button className='ml-auto m-0' color='danger' onClick={this._toggle}>Cancel</Button>
                            </> : <Button className='ml-auto' color='dark' onClick={this._toggle}>Close</Button>}
                    </ModalFooter>
                </Modal>
            )
        }
        return (
            null
        )
    }
}
