import React, { Component } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase'
import LOGO from 'assets/img/logo-placeholder.jpg'
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import ModalForm from '../components/ModalForm'
import { inject, observer } from 'mobx-react';

export default class CarListingUpdate extends Component {
  static propTypes = {
    type: PropTypes.string,           // The type of operation either ADD or EDIT
    forType: PropTypes.string,        // To determine which vehicle mode: type, make, model, variant, cc
    id: PropTypes.string,             // The vehicle type id
    selectedType: PropTypes.string,   // The selected vehicle type
    make: PropTypes.object,           // The vehicle make data object
    model: PropTypes.object,          // The vehicle model data object
    variant: PropTypes.object,        // The vehicle variant
    parentRefresh: PropTypes.func     // To refetch all the data after updating
  }

  state = {
    modalAdd: false, modalMakeAdd: false, modalMakeEdit: false, modalModelAdd: false, modalMakeLogo: false, modalModelEdit: false, modalVariantAdd: false, modalVariantEdit: false, modalCCEdit: false, ccArray: [], progress: 0, formState: { canUpload: false, showBtn: true, previewImage: LOGO }, check: 'GTZ!9H7NqV', checkMake: ['GTZ!9H7NqV', 'nasreen']
  }

  toggleModal = (state) => this.setState({ [state]: !this.state[state] })

  handleProgress = (progress, cb) => this.setState({ progress }, () => cb && cb())

  check = () => {
    const { check, checkMake } = this.state, { forType, type } = this.props
    if (forType === 'type' && type === 'add') {
      let r = window.prompt('If you need to add a new vehicle type, enter the password. Only the developers know this password:')

      if (r !== check) return alert('Wrong password.\n\nIf you want to add a new vehicle, please inform the developers.')
      // if (r === check) return this.toggleModal('modalAdd')
    }
    if (forType === 'make' && type === 'add') {
      let r = window.prompt('If you need to add a new car make, enter the password. Only the developers know this password:')

      if (checkMake.indexOf(r) === -1) return alert('Wrong password.\n\nIf you want to add a new vehicle, please inform the developers.')
    }
  }

  updateVehicle = async (input, cb, downloadURL, make_id) => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    const { forType, parentRefresh, type, id, selectedType, make, model, variant } = this.props, { ccArray } = this.state
    const db = firebase.app(COUNTRY_DB).database()
    let TYPE, TYPE_ID, MAKE, MAKE_ID, DATA, DOWNLOAD_URL, MODEL_ID, MODEL, SELECTED_TYPE, VARIANT, VARIANT_ID, CC_ARRAY

    // console.log(id, selectedType, make_id, input.make)


    if (forType === 'type' && type === 'add') {
      TYPE = input.vehicle_type
      if (!downloadURL) TYPE_ID = await db.ref('vehicle_type/').push().getKey()

      this.handleProgress(85)

      await db.ref(`vehicle_type/${TYPE_ID}`).set(TYPE)
      this.handleProgress(100)

      this.setState({ formState: { canUpload: false, showBtn: true, previewImage: LOGO } })
      parentRefresh()

      this.toggleModal('modalAdd')

    }

    if (forType === 'make' && type === 'add') {
      TYPE_ID = id
      MAKE_ID = make_id
      MAKE = input.make
      DOWNLOAD_URL = downloadURL ? downloadURL : ''
      DATA = { make: MAKE, logo: DOWNLOAD_URL }

      this.handleProgress(85)
      await db.ref(`vehicle_make/${TYPE_ID}/${MAKE_ID}`).update(DATA)

      this.handleProgress(100, () => {
        parentRefresh()
        let s = window.confirm('Do you want to add another Car Make?\nClick on "OK" if you want to add another Car Make.')
        if (!s) {
          this.handleProgress(0)
          this.setState({ formState: { ...this.state.formState, showBtn: true } })
          this.toggleModal('modalMakeAdd')
        }
        if (s) {
          this.handleProgress(0)
          this.setState({ canUpload: false, showBtn: true, previewImage: LOGO })
          cb()
        }
      })
    }

    if (forType === 'make' && type === 'edit') {
      TYPE_ID = id
      SELECTED_TYPE = selectedType
      MAKE_ID = make.key
      MAKE = input.make
      DOWNLOAD_URL = downloadURL ? downloadURL : ''
      DATA = { make: MAKE, logo: DOWNLOAD_URL }

      this.handleProgress(85)

      await db.ref(`vehicle_make/${TYPE_ID}/${MAKE_ID}`).update({ make: MAKE })
      this.handleProgress(100)

      this.setState({ formState: { canUpload: false, showBtn: true, previewImage: LOGO } })

      this.toggleModal('modalMakeEdit')

      parentRefresh()
    }

    if (forType === 'model' && type === 'add') {
      TYPE_ID = id
      MAKE_ID = make.key
      MODEL_ID = db.ref(`vehicle_model/${TYPE_ID}/${MAKE_ID}/`).push().getKey()

      this.handleProgress(85)

      await db.ref(`vehicle_model/${TYPE_ID}/${MAKE_ID}/${MODEL_ID}`).set(input.model)
      this.handleProgress(100, () => {
        parentRefresh()
        let s = window.confirm('Do you want to add another Car Model?\nClick on "OK" if you want to add another Car Model.')
        if (!s) {
          this.handleProgress(0)
          this.setState({ formState: { ...this.state.formState, showBtn: true } })
          this.toggleModal('modalModelAdd')
        }
        if (s) {
          this.handleProgress(0)
          this.setState({ canUpload: false, showBtn: true, previewImage: LOGO })
          cb()
        }
      })
    }
    if (forType === 'model' && type === 'edit') {
      TYPE_ID = id
      SELECTED_TYPE = selectedType
      MAKE_ID = make.key
      MODEL_ID = model.key
      MODEL = input.model

      this.handleProgress(85)
      await db.ref(`vehicle_model/${TYPE_ID}/${MAKE_ID}/${MODEL_ID}`).set(MODEL)

      parentRefresh()
      this.handleProgress(100)
      this.toggleModal('modalModelEdit')
      this.setState({ formState: { canUpload: false, showBtn: true, previewImage: LOGO } })
    }

    if (forType === 'variant' && type === 'add') {
      MODEL_ID = model.key
      CC_ARRAY = ccArray
      VARIANT = input.variant
      VARIANT_ID = db.ref(`vehicle_variant/${MODEL_ID}`).push().getKey()
      DATA = { variant: VARIANT, cc: CC_ARRAY }

      this.handleProgress(85)
      await db.ref(`vehicle_variant/${MODEL_ID}/${VARIANT_ID}`).update(DATA)

      parentRefresh()
      this.handleProgress(100)
      this.toggleModal('modalVariantAdd')
      this.setState({ formState: { canUpload: false, showBtn: true, previewImage: LOGO }, ccArray: [] })
    }

    if (forType === 'variant' && type === 'edit') {
      MODEL_ID = model.key
      VARIANT_ID = variant.key
      VARIANT = input.variant

      this.handleProgress(85)
      await db.ref(`vehicle_variant/${MODEL_ID}/${VARIANT_ID}`).update({ variant: VARIANT })

      parentRefresh()
      this.handleProgress(100)
      this.toggleModal('modalVariantEdit')
      this.setState({ formState: { canUpload: false, showBtn: true, previewImage: LOGO } })


    }

  }

  uploadLogo = async (input, file, cb) => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    const { id, forType, make, parentRefresh } = this.props, db = firebase.app(COUNTRY_DB).database()
    let MAKE_ID, TYPE_ID, storageRef, uploadLogo

    if (forType === 'make' || forType === 'logo') {
      TYPE_ID = id
      MAKE_ID = make ? make.key : db.ref(`vehicle_make/${TYPE_ID}`).push().getKey()

      if (!file) this.updateVehicle(input, cb, file, MAKE_ID)
      if (file) {
        storageRef = firebase.storage().ref('logo')
        uploadLogo = storageRef.child(MAKE_ID).put(file)
      }
    }
  
    if (file) {
      uploadLogo.on('state_changed', (snapshot) => {
        this.handleProgress(35)
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`Upload is ${progress}% done`)
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING:
            console.log('Upload is running')
            break
        }
      }, (error) => {
        this.handleProgress(0)
        alert(`Upload failed, please try again!\n, ${error.message}`)
      }, () => {
        uploadLogo.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          this.handleProgress(65, async () => {
            if (forType === 'make') return this.updateVehicle(input, cb, downloadURL, MAKE_ID)
            if (forType === 'logo') {
              await firebase.app(COUNTRY_DB).database().ref(`vehicle_make/${TYPE_ID}/${MAKE_ID}`).update({ logo: downloadURL })
              parentRefresh()
              this.setState({ showBtn: true })
              this.toggleModal('modalMakeLogo')
            }
          })
        })
      })
    }
  }

  deleteItem = async () => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    const { forType, type, make, id, model, variant, parentRefresh } = this.props, db = firebase.app(COUNTRY_DB).database()
    let MODELS = [], MAKE, MAKE_ID, TYPE_ID, MODEL_ID, VARIANTS, DB_SNAP, DB_VAL, VARIANT_ID, storageRef

    if (forType === 'make' && type === 'edit') {
      MAKE = make
      MAKE_ID = make.key
      TYPE_ID = id

      let r = window.confirm("Are you sure you want to delete this brand?\nClicking on 'OK' makes the deleting permanent and will remove this brand and all its associated variables.")

      if (!r) return

      DB_SNAP = await db.ref(`vehicle_model/${TYPE_ID}/${MAKE_ID}`).once('value')
      DB_VAL = await DB_SNAP.val()

      if (DB_SNAP.exists()) {
        for (const key in DB_VAL) {
          console.log(key, DB_VAL[key]);
          MODELS.push(key)
        }
      } else { console.log('no model for this') }

      if (MODELS.length !== 0) {
        MODELS.forEach(model => {
          console.log('model', model)
          // 1.b) Foreach model --> delete variants from vehicle_variant
          db.ref(`vehicle_variant/${model}`).remove()
        })
        // 2. Delete models from vehicle_model
        await db.ref(`vehicle_model/${TYPE_ID}/${MAKE_ID}`).remove()
      }

      if (MAKE.logo !== '') {
        storageRef = firebase.storage().ref('logo').child(MAKE_ID);
        // Delete the file
        await storageRef.delete().then(() => {
          // File deleted successfully
        }).catch(error => {
          // Uh-oh, an error occurred!
          console.log('ERR', error);
        })
      }

      await db.ref(`vehicle_make/${TYPE_ID}/${MAKE_ID}`).remove()

      this.setState({ showBtn: true }, () => {
        parentRefresh()
        this.toggleModal('modalMakeEdit')
      })

    }

    if (forType === 'model' && type === 'edit') {
      TYPE_ID = id
      MAKE_ID = make.key
      MODEL_ID = model.key
      VARIANTS = variant

      let r = window.confirm("Are you sure you want to delete this model?\nClicking on 'OK' makes the deleting permanent and will remove this model and all its associated variables.")

      if (!r) return this.toggleModal('modalModelEdit')


      if (VARIANTS.length !== 0) {
        VARIANTS.forEach(variant => {
          let VARIANT_ID = variant.key
          db.ref(`vehicle_variant/${MODEL_ID}/${VARIANT_ID}`).remove()
        })
      }
      db.ref(`vehicle_model/${TYPE_ID}/${MAKE_ID}/${MODEL_ID}`).remove().then(() => {
        parentRefresh()
        this.toggleModal('modalModelEdit')
      })
    }

    if (forType === 'variant' && type === 'edit') {
      MODEL_ID = model.key
      VARIANT_ID = variant.key

      let r = window.confirm("Are you sure you want to delete this model's variant?\nClicking on 'OK' makes the deleting permanent.")
      if (!r) return

      await db.ref(`vehicle_variant/${MODEL_ID}/${VARIANT_ID}`).remove()
      parentRefresh()
      this.toggleModal('modalVariantEdit')
    }
  }

  addCC = () => this.setState({ ccArray: [...this.state.ccArray, ''] })

  handleChangeCC = (evt) => {
    const targetIndex = evt.target.name
    const newValue = evt.target.value
    const array = this.state.ccArray

    array.splice(targetIndex, 1, newValue)
    this.setState({ ccArray: array })
  }

  removeCC = (index) => {
    const targetIndex = index;
    const array = this.state.ccArray;

    array.splice(targetIndex, 1)
    this.setState({ ccArray: array })
  }

  saveCC = () => {
    const { model, variant, parentRefresh } = this.props, { ccArray } = this.state
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    let MODEL_ID = model.key
    let VARIANT_ID = variant.key
    let CC_ARRAY = ccArray

    if (ccArray.length === 0) {
      alert('Please fill in at least one CC for the variant')
      return
    }
    if (ccArray.indexOf('') !== -1) {
      alert('Please fill in all of the fields that you have added or remove the fields that are empty!')
      return
    }

    firebase.app(COUNTRY_DB).database().ref(`vehicle_variant/${MODEL_ID}/${VARIANT_ID}`).update({ cc: CC_ARRAY }).then(() => {
      parentRefresh()
      this.toggleModal('modalCCEdit');
    })

  }

  _renderAddCC = () => {
    const { ccArray } = this.state
    return <div>
      <Label for="cc">CC List</Label>
      <Button color="light" className="ml-3" onClick={this.addCC}>Add CC</Button>
      {ccArray.length > 0 ?
        <div>
          {ccArray.map((data, index) => (
            <FormGroup style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: "5px" }} key={index}>
              <Input type="text" style={{ width: '65%', marginRight: '5px' }} name={index} id={index} onChange={(e) => this.handleChangeCC(e)} value={data} /><Button color="light" className="m-0" onClick={() => { this.removeCC(index) }}>remove</Button>
            </FormGroup>
          ))}
        </div> : null}
    </div>
  }

  _renderOpenBtn = () => {
    const { type, forType, variant } = this.props

    if (type === 'add' && forType === 'type') {
      return <div className="d-flex align-items-center" onClick={() => { this.toggleModal('modalAdd') }} style={{ cursor: 'pointer' }}>
        <Button color="light" style={{ margin: '0 15px 0 0', padding: '10px 12px', borderRadius: '50%' }}>
          <i className="fas fa-plus fa-2x" />
        </Button>
        <span style={{ fontSize: '1.2rem' }}>New Vehicle Type</span>
      </div>
    }

    if (forType === 'make' && type === 'add') {
      return <div style={{ display: 'inline-block' }}>
        <Button color='dark' style={{ margin: '0' }} onClick={() => this.toggleModal('modalMakeAdd')}>Add Vehicle Make</Button>
      </div>
    }

    if (forType === 'make' && type === 'edit') {
      return <div>
        <Button color='dark' className="m-0" onClick={() => { this.toggleModal('modalMakeEdit') }}>Edit Make</Button>
      </div >
    }
    if (forType === 'logo' && type === 'edit') {
      return <div>
        <Button color='dark' className="m-0 mr-3" onClick={() => { this.toggleModal('modalMakeLogo') }}>Edit Logo</Button>
      </div >
    }
    if (forType === 'model' && type === 'add') {
      return <Button color="dark" className="m-0" onClick={() => { this.toggleModal('modalModelAdd') }}>Add New Model</Button>
    }

    if (forType === 'model' && type === 'edit') return <Button color="dark" className="m-0 mb-2" style={{ width: '100%' }} onClick={() => { this.toggleModal('modalModelEdit') }}>Edit Model</Button>

    if (forType === 'variant' && type === 'add') return <Col md={2} className='pr-1'><Button color="dark" className="m-0" style={{ width: '100%' }} onClick={() => this.toggleModal('modalVariantAdd')}> Add Variant</Button></Col>

    if (forType === 'variant' && type === 'edit') return <Button color="dark" className="m-0 mb-2" style={{ width: '100%' }} onClick={() => { this.toggleModal('modalVariantEdit') }}>Edit Variant</Button>

    if (forType === 'cc' && type === 'edit') return <Button color="dark" className="m-0 mb-2" style={{ width: '100%' }} onClick={() => { this.toggleModal('modalCCEdit'); this.setState({ ccArray: variant.cc }) }}>Edit CC</Button>

  }

  _renderExistingLogo = () => {
    const { make, forType, type } = this.props
    if (forType === 'logo' && type === 'edit') return <><Label for='upload_logo'>Current Logo</Label><br /><img src={make.logo} style={{ width: '250px' }} /></>
  }

  _renderModalContent = () => {
    const { forType, type, make, model, variant } = this.props, { modalAdd, modalMakeAdd, modalMakeEdit, modalMakeLogo, modalModelAdd, modalModelEdit, modalVariantAdd, modalVariantEdit, modalCCEdit, formState, progress, ccArray } = this.state
    let toggle, modalContent, saveBtn, delBtn, imageInput, input, inputError

    if (forType === 'type' && type === 'add' && modalAdd) {
      toggle = { state: modalAdd, method: this.toggleModal, stateStr: 'modalAdd' }
      modalContent = { title: 'New Vehicle Type' }
      saveBtn = { textSave: 'Add', textSaving: 'Adding', color: 'primary' }
      input = [{ label: 'Vehicle Type', id: 'vehicle_type', value: '' }]
      inputError = [{ type: 'text', id: 'vehicle_type', message: 'Please fill in the vehicle type' }]
      return <ModalForm toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} input={input} inputError={inputError} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} check={this.check} updateInput={this.updateVehicle} style={{ marginTop: '200px' }} />
    }

    if (forType === 'make' && type === 'add' && modalMakeAdd) {
      toggle = { state: modalMakeAdd, method: this.toggleModal, stateStr: 'modalMakeAdd' }
      modalContent = { title: 'Add Vehicle Make' }
      saveBtn = { textSave: 'Add', textSaving: 'Adding', color: 'light' }
      input = [{ label: 'Vehicle Make', id: 'make', value: '' }]
      imageInput = [{ label: 'Upload Logo (Optional)', id: 'upload_logo', value: '' }]
      inputError = [{ type: 'text', id: 'make', message: 'Please fill in car make' }]

      return <ModalForm toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} input={input} imageInput={imageInput} inputError={inputError} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} updateInput={this.updateVehicle} uploadLogo={this.uploadLogo} style={{ maxWidth: '55%' }} classname='modal-dialog-centered modal-default' />
    }

    if (forType === 'make' && type === 'edit' && modalMakeEdit) {
      toggle = { state: modalMakeEdit, method: this.toggleModal, stateStr: 'modalMakeEdit' }
      modalContent = { title: 'Edit Vehicle Make' }
      saveBtn = { textSave: 'Save Make', textSaving: 'Saving', color: 'light' }
      delBtn = { textDel: 'Delete Make', textDeleting: 'Deleting' }
      input = [{ label: 'Vehicle Make', id: 'make', value: make.make }]
      inputError = [{ type: 'text', id: 'make', message: 'Please fill in car make' }]

      return <ModalForm toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} delBtn={delBtn} input={input} inputError={inputError} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} updateInput={this.updateVehicle} deleteItem={this.deleteItem} classname='modal-dialog-centered' />
    }

    if (forType === 'logo' && type === 'edit' && modalMakeLogo) {
      toggle = { state: modalMakeLogo, method: this.toggleModal, stateStr: 'modalMakeLogo' }
      modalContent = { title: `Edit ${make.make} Make Logo` }
      saveBtn = { textSave: 'Upload New Logo', textSaving: 'Uploading New Logo', color: 'light' }
      imageInput = [{ label: 'Upload New Logo', id: 'upload_logo', value: '' }]
      input = [{ label: '', id: 'make', value: '' }]
      inputError = [{ type: 'image', id: 'upload_logo', message: 'Please upload image for tier icon!' }]

      return <ModalForm existLogo={make.logo} showLogo={this._renderExistingLogo} toggle={toggle} modalContent={modalContent} imageInput={imageInput} inputError={inputError} input={input} saveBtn={saveBtn} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} uploadLogo={this.uploadLogo} logoOrientation='column' updateInput={this.updateVehicle} hideInput />
    }

    if (forType === 'model' && type === 'add' && modalModelAdd) {
      toggle = { state: modalModelAdd, method: this.toggleModal, stateStr: 'modalModelAdd' }
      modalContent = { title: 'Add New Model' }
      saveBtn = { textSave: 'Add Model', textSaving: 'Adding Model', color: 'light' }
      input = [{ label: 'Vehicle Model', id: 'model', value: '' }]
      inputError = [{ type: 'text', id: 'model', message: 'Please fill in vehicle model' }]

      return <ModalForm toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} input={input} inputError={inputError} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} updateInput={this.updateVehicle} style={{ maxWidth: '55%' }} classname='modal-dialog-centered modal-default' />
    }

    if (forType === 'model' && type === 'edit' && modalModelEdit) {
      toggle = { state: modalModelEdit, method: this.toggleModal, stateStr: 'modalModelEdit' }
      modalContent = { title: 'Edit Vehicle Model' }
      saveBtn = { textSave: 'Save Model', textSaving: 'Saving Model', color: 'light' }
      delBtn = { textDel: 'Delete', textDeleting: 'Deleting' }
      input = [{ label: 'Vehicle Model', id: 'model', value: model.model }]
      inputError = [{ type: 'text', id: 'model', message: 'Please fill in vehicle model' }]

      return <ModalForm toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} delBtn={delBtn} input={input} inputError={inputError} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} updateInput={this.updateVehicle} deleteItem={this.deleteItem} style={{ maxWidth: '55%' }} classname='modal-dialog-centered modal-default' />
    }
    if (forType === 'variant' && type === 'add' && modalVariantAdd) {
      toggle = { state: modalVariantAdd, method: this.toggleModal, stateStr: 'modalVariantAdd' }
      saveBtn = { textSave: 'Save', textSaving: 'Saving', style: { marginLeft: 'auto', marginBottom: '0px' } }
      input = [{ label: 'Model Variant', id: 'variant', value: '' }]
      inputError = [{ type: 'text', id: 'variant', message: 'Please fill in vehicle variant' }]

      return <Col md={10} className='pl-1'>
        <ModalForm collapseType additionalComp={this._renderAddCC} ccArray={ccArray} toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} input={input} inputError={inputError} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} updateInput={this.updateVehicle} style={{ maxWidth: '55%' }} classname='modal-dialog-centered modal-default' />
      </Col>
    }

    if (forType === 'variant' && type === 'edit' && modalVariantEdit) {
      toggle = { state: modalVariantEdit, method: this.toggleModal, stateStr: 'modalVariantEdit' }
      modalContent = { title: 'Edit Variant Name' }
      saveBtn = { textSave: 'Save Model', textSaving: 'Saving Model', color: 'light' }
      delBtn = { textDel: 'Delete Variant', textDeleting: 'Deleting' }
      input = [{ label: 'Model Variant', id: 'variant', value: variant.variant }]
      inputError = [{ type: 'text', id: 'variant', message: 'Please fill in the variant name' }]

      return <ModalForm toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} delBtn={delBtn} input={input} inputError={inputError} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} updateInput={this.updateVehicle} deleteItem={this.deleteItem} style={{ maxWidth: '55%' }} classname='modal-dialog-centered modal-default' />
    }

    if (forType === 'cc' && type === 'edit' && modalCCEdit) {
      toggle = { state: modalCCEdit, method: this.toggleModal, stateStr: 'modalCCEdit' }
      modalContent = { title: 'Edit CC' }
      saveBtn = { textSave: 'Save Model', textSaving: 'Saving Model', color: 'light' }
      delBtn = { textDel: 'Delete Variant', textDeleting: 'Deleting' }
      input = [{ label: 'Model Variant', id: 'variant', value: variant.variant }]
      inputError = [{ type: 'text', id: 'variant', message: 'Please fill in the variant name' }]

      return <Modal className='modal-dialog-centered modal-default' isOpen={toggle.state} style={{ maxWidth: '55%' }} toggle={() => toggle.method(toggle.stateStr)}>
        <ModalHeader toggle={() => toggle.method(toggle.stateStr)}>Edit CC</ModalHeader>
        <ModalBody>{this._renderAddCC()}</ModalBody>
        <ModalFooter>
          <Button color='light' onClick={this.saveCC}>Save CC</Button>
          <Button className='ml-auto' color='secondary' onClick={() => toggle.method(toggle.stateStr)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    }

  }



  render() {
    // console.log(this.props)
    const { forType, type } = this.props

    return (
      <div style={forType === 'model' && type === 'add' ? { display: 'inline-block' } : null}>
        {forType === 'variant' && type === 'add' ? <Row>
          {this._renderOpenBtn()}
          {this._renderModalContent()}
        </Row> : <>{this._renderOpenBtn()}
            {this._renderModalContent()}</>}
      </div>
    )
  }
}

CarListingUpdate = inject('mobx_auth')(observer(CarListingUpdate))
