import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, ButtonDropdown, Dropdown, CardHeader, CardBody, DropdownItem, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap'
import Charts from "components/SingleLineCharts.jsx";
import { gradientChartOptionsConfigurationWithNumbersAndGrid } from "variables/charts.jsx";

let MONTH_LIST = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export default class RequestAnalytics extends Component {
    static propTypes = {
        data: PropTypes.any,
        load: PropTypes.bool,
        title: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.string
    }
    state = {
        today: MONTH_LIST[new Date().getMonth()] + ' ' + new Date().getFullYear(),
        dropdown: false,
    }
    toggle = state => {
        this.setState({ [state]: !this.state[state] });
    }
    _select = (data) => {
        
        this.setState({ today: data })
    }
    _renderDropdown = () => {
        let display
        let TEMP = []
        let YEAR = new Date().getFullYear()
        let MONTH = new Date().getMonth()
        let CURRENT = MONTH

        for (let index = 0; index < 13; index++) {
            CURRENT = MONTH - index
            let CURRENT_YEAR = YEAR
            if (CURRENT < 0) {
                CURRENT = 12 + CURRENT
                CURRENT_YEAR = YEAR - 1
            }
           
            let a = MONTH_LIST[CURRENT] + ' ' + CURRENT_YEAR
            TEMP.push(a)
        }

        display =
            <DropdownMenu style={{ maxHeight: '300px', overflowY: 'scroll' }} className="custom-scrollbar">
                {TEMP.map((data, index) => (
                    <DropdownItem key={index} onClick={() => { this._select(data) }} style={{ background: this.state.today === data ? 'rgba(58, 58, 58, 0.6)' : null, color: this.state.today === data ? 'white' : null, fontWeight: this.state.today === data ? 'bold' : null }}>{data}</DropdownItem>
                ))}
            </DropdownMenu>

        return display
    }
    _renderChart = () => {
        let display
        let DATA = this.props.data
        let TEMP = []
        let LOAD = this.props.load
       

        if (LOAD === false) {
            return display =
                <CardBody style={{ padding: '95px 25px' }}>
                    <div className="loader"></div>
                </CardBody>
        }
        if (DATA[this.state.today] === undefined) {
            return display =
                <div style={{ height: '250px', lineHeight: '250px' }} className="text-center">No data available for selected month</div>
        }

        for (const MONTH in DATA) {
            var calls = Object.values(DATA[this.state.today].values).reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
            )
            let a =
                <CardBody key={MONTH} style={{ display: DATA[this.state.today] !== undefined && this.state.today === MONTH ? 'block' : 'none' }}>
                    <Row>
                        <Col md={10}>
                            <Charts
                                data={DATA[MONTH]}
                                options={gradientChartOptionsConfigurationWithNumbersAndGrid}
                                height="250px" label={this.props.name} />
                        </Col>
                        <Col md={2}>
                            <div className="mt-4">
                                <div className="text-right" style={{ fontSize: '2.1em', fontWeight: 'bold', paddingRight: '30px' }}>
                                    {calls}
                                </div>
                                <div className="text-right" style={{ paddingRight: '30px' }}>
                                    <h6><i className={`fas fa-${this.props.icon} mr-2`} />{this.props.name}</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>

            TEMP.push(a)
        }

        if (DATA[this.state.today] !== undefined && calls !== 0) {
            display = TEMP.map(data => { return data })
        } else {
            display =
                <div style={{ height: '250px', lineHeight: '250px' }} className="text-center">No data available for selected month</div>
        }

        return display
    }
    render() {
        return (
            <Card className="shadow">
                <CardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="m-0">{this.props.title}</h5>
                    <ButtonDropdown isOpen={this.state.dropdown} toggle={() => this.toggle('dropdown')}>
                        <DropdownToggle color="light" caret className="m-0">
                            <span style={{ fontWeight: 'bold' }}>{this.state.today}</span>
                        </DropdownToggle>
                        {this._renderDropdown()}
                    </ButtonDropdown>
                </CardHeader>

                {this._renderChart()}
            </Card>
        )
    }
}
