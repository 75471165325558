export default () => {
    // eslint-disable-next-line
    self.addEventListener('message', e => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;
        var DATA = e.data;
        let RESPONSE = {}

        let month_list = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let thirty = [3, 5, 8, 10]
        let thirty_one = [0, 2, 4, 6, 7, 9, 11]

        let today = new Date()
        let month = today.getMonth()
        let year = today.getFullYear()

        let string = ''

        let data_analytics = {}
        let label = []
        let value = []

        for (let i=0; i < 12; i++) {

            if (month < 0) {
                month = month + 12
                year = year - 1
            }

            if (thirty.indexOf(month) !== -1) {
                label = new Array(30).fill(0)
                value = new Array(30).fill(0) 
            }
            if (thirty_one.indexOf(month) !== -1) {
                label = new Array(31).fill(0)
                value = new Array(31).fill(0) 
            }
            if (year % 4 === 0 && thirty.indexOf(month) === -1 && 
            thirty_one.indexOf(month) === -1) {
                label = new Array(29).fill(0)
                value = new Array(29).fill(0) 
            }
            if (year % 4 === 1 && thirty.indexOf(month) === -1 && 
            thirty_one.indexOf(month) === -1) {
                label = new Array(28).fill(0)
                value = new Array(28).fill(0) 
            }

            string = month_list[month] + ' ' + year

            data_analytics[string] = {
                labels: label,
                values: value,
                // counts: 0
            }

            for (let i = 0; i < data_analytics[string].labels.length; i++) {
                data_analytics[string].labels[i] = i + 1;
            }

            month = month - 1
        }

        let userID = []

        for (const user_id in DATA) {
            userID = DATA[user_id]
            // console.log(userID);

            for (const ID in userID) {
                let current = userID[ID]
                let timestamp = new Date(current.opened)
                // console.log(timestamp);
                let current_date = timestamp.getDate()
                let current_month = timestamp.getMonth()
                let current_year = timestamp.getFullYear()

                // console.log(current_date, current_month, current_year);

                let current_string = month_list[current_month] + ' ' + current_year

                if (data_analytics[current_string] !== undefined) {
                    let index = data_analytics[current_string].labels.indexOf(current_date)
                    // console.log(index);

                    if (index !== -1) {
                        data_analytics[current_string].values[index] = data_analytics[current_string].values[index] + 1
                        // data_analytics[current_string].counts = data_analytics[current_string].counts + 1
                    }
                }
            }
        }

        RESPONSE = data_analytics
        postMessage(RESPONSE);
    });
};