export default () => {
    // test = (i) => {
    //     return 'hello' + i
    // }
    // eslint-disable-next-line
    self.addEventListener('message', e => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;
        var DATA = e.data;
        var RESPONSE = []
        let TEMP = []
        // console.log('worker',DATA);

        // console.log(response);
        let keys = Object.keys(DATA);
        keys.forEach((user_id) => {
            let a = DATA[user_id]
            a.key = user_id

            TEMP.push(a)
        })

        RESPONSE = TEMP

        postMessage(RESPONSE);
    });
};