import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button, Label, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input } from 'reactstrap';
import { inject, observer } from 'mobx-react'
import firebase from 'firebase'

export default class MerchantPreferred extends Component {
   static propTypes = {
      workshop: PropTypes.object,
      retailID: PropTypes.string,
      parentRefresh: PropTypes.func,
      preferred: PropTypes.bool
   }

   state = {
      modalOpen: false,
      saveButton: true,

      preferred: false
   }

   componentDidMount = () => {
      this.checkPreferred()
   }

   checkPreferred = () => {
      const { workshop } = this.props

      if(workshop.preferred === undefined) this.setState({ preferred:false })
      if(workshop.preferred !== undefined) this.setState({ preferred:workshop.preferred })
   }

   handleChange = evt => this.setState({ [evt.target.name]: evt.target.value });

   toggleModal = state => this.setState({ [state]:!this.state[state], preferred:this.state.preferred })

   savePreferredWorkshop = () => {
      let COUNTRY_DB = this.props.mobx_auth.COUNTRY
      const { preferred } = this.state
      const { retailID, workshop } = this.props
      let result

      switch (preferred) {
         case 'true': result = true
            break;
         case 'false': result = false
            break;
      }

      firebase.app(COUNTRY_DB).database().ref(`retail/${retailID}/${workshop.workshop_id}`).update({ preferred:result }).then(() => {
         !!result ? alert(`Successfully updated ${workshop.name} as preferred workshop!`) : alert(`Successfully removed ${workshop.name} as preferred workshop!`) 
         this.setState({ modalOpen:false, saveButton:true })
         this.props.parentRefresh()
      })
   }

   _renderButton = () => {
      const { saveButton } = this.state

      if(saveButton) return <Button color='dark' onClick={() => this.setState({ saveButton:false }, () => this.savePreferredWorkshop())}>Save</Button>
      if(!saveButton) return <Button disabled color='dark' className='d-flex align-items-center justify-content-center'><div className='mr-2'>Saving</div><div className='btnloader'></div></Button>
   }

   _renderModal = () => {
      const { modalOpen, preferred } = this.state

      return (
         <Modal className='modal-dialog-centered' backdrop='static' isOpen={modalOpen} toggle={() => this.toggleModal('modalOpen')}>
            <ModalHeader toggle={() => this.toggleModal('modalOpen')}>Preferred Workshop</ModalHeader>
            <ModalBody>
               <FormGroup>
                  <Label for='preferred'>Do you want to make this workshop as 'Preferred Workshop?'</Label>
                  <Input type='select' name='preferred' id='preferred' value={preferred} onChange={this.handleChange}>
                     <option value='true'>Yes</option>
                     <option value='false'>No</option>
                  </Input>
               </FormGroup>
            </ModalBody>
            <ModalFooter>
               {this._renderButton()}
               <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalOpen')}>Cancel</Button>
            </ModalFooter>
         </Modal>
      )
   }

   render() {
      const checker = this.props.preferred !== undefined && !!this.props.preferred ? true : false

      return(
         <>
            <Button 
               className='ml-2' 
               style={{ fontSize:'0.65rem', padding:'5px 10px' }} 
               color='light' 
               size='sm' 
               onClick={() => this.setState({ preferred:checker }, this.toggleModal('modalOpen'))}
            >
               Preferred Workshop
            </Button>
         
            {this._renderModal()}
         </>
         
      )
   }
}

MerchantPreferred = inject('mobx_config', 'mobx_auth')(observer(MerchantPreferred));