import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup,FormText } from 'reactstrap'
import ErrorAlert from '../components/ErrorAlert'
import firebase from 'firebase';
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';

export default class AnnouncementsAdd extends Component {
    static propTypes = {
        parentFetch : PropTypes.func,
    }
    state = {
        modalAdd : false,
        displayButt : true,
        title: '',
        body : '',

        text : '',
        toggle : false,
    }
    toggleModal = state => { this.setState({ [state]: !this.state[state] }) }
    childToggle = () => { this.setState({toggle:false}) }
    handleChange = (evt) => { this.setState({ [evt.target.name]: evt.target.value }) }
    _send = async () => {
        const { title, body } = this.state

        if(title === '') {
            this.setState({ text : 'Please fill in title!', toggle : true })
            return;
        }
        if(body === '') {
            this.setState({ text : 'Please fill in body!', toggle : true })
            return;
        }

        let DATA = {
            title : title,
            body : body,
            timestamp : new Date().getTime()
        }
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        console.log(DATA);
        // push notify SU
        let url, csrfTokenU, csrfSecretU, headerToken;
        let GETkey = firebase.app(COUNTRY_DB).database().ref('announcementSU/').push().getKey()
        let TITLE = 'title=' + this.state.title;
        let BODY = '&body=' + this.state.body;
        let reminder = '&reminder=no'
        let key = '&key=' + GETkey;
        let location = `&location=${COUNTRY_DB}`
        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            url = serv_user_development.newAnnouncement
        }
        if (this.props.mobx_config.config === 1) {
            url = serv_user_production.newAnnouncement
        }

        let finalURL = url + TITLE + BODY + reminder + key +location;
        let OPTIONS = { credentials: 'include', headers: headerToken }

        await fetch(finalURL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            let RESPONSE = response.response
            console.log(RESPONSE)
            if (RESPONSE !== 401) {
                if (RESPONSE === 'OK') {
                    firebase.app(COUNTRY_DB).database().ref('announcementSU/' + GETkey).update({
                        title: this.state.title,
                        body: this.state.body,
                        timestamp: firebase.database.ServerValue.TIMESTAMP
                    }).then(() => {
                        this.toggleModal('modalAdd')
                        this.props.parentFetch()
                    })
                }
            }
        })
    }
    _renderButton = () => {
        let display

        display = this.state.displayButt === true ? <Button className='ml-auto m-0' color='dark' onClick={() => {
            this.setState({displayButt:false}, () => {
                this._send()
            })
        }}>Send</Button> : <Button className='ml-auto m-0 d-flex justify-content-center' color='dark' disabled><div className='mr-2'>Sending</div> <div className="btnloader"></div></Button>

        return display
    }
    render() {
        return (
            <>
                <Button color='light' onClick={() => this.toggleModal('modalAdd')}>Push new notification</Button>
                <Modal isOpen={this.state.modalAdd} toggle={() => this.toggleModal('modalAdd')} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger'>
                    <ModalHeader toggle={() => this.toggleModal('modalAdd')}>Send new notification</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="title">Notification Title</Label>
                            <Input type="text" name="title" id="title" onChange={this.handleChange} value={this.state.title} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="body">Notification Body</Label>
                            <Input type="textarea" maxLength="140" name="body" id="body" onChange={this.handleChange} value={this.state.body} />
                            <FormText className="muted">
                                Max 140 characters
                            </FormText>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color='primary'>Save changes</Button> */}
                        {this._renderButton()}
                    </ModalFooter>
                </Modal>
                <ErrorAlert text={this.state.text} toggle={this.state.toggle} parentToggle={this.childToggle} />
            </>
        )
    }
}
AnnouncementsAdd = inject('mobx_config', 'mobx_auth')(observer(AnnouncementsAdd))