import React, { Component } from 'react'
import firebase from 'firebase'
import { Card } from 'reactstrap'
import PanelHeader from 'components/PanelHeader.jsx';
import { inject, observer } from 'mobx-react';

export default class IAM extends Component {
    componentDidMount() {
        this.fetchAdminUsers()
    }
    fetchAdminUsers = () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let DB = firebase.app(COUNTRY_DB).database().ref(`serv_admin`).on('value', (snapshot) => {
            console.log(snapshot.val());
        })
    }
    render() {
        return (
            <div>
                <PanelHeader size='sm' />
                <div className='content'>
                    <Card body>Access Management</Card>
                </div>
            </div>
        )
    }
}

IAM = inject('mobx_auth')(observer(IAM))
