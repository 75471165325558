// ##############################
// // // tasks list for Tasks card in Dashboard view
// #############################

const tasks = [
  {
    checked: true,
    text: 'Sign contract for "What are conference organizers afraid of?"'
  },
  {
    checked: false,
    text: "Lines From Great Russian Literature? Or E-mails From My Boss?"
  },
  {
    checked: true,
    text:
      "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit"
  }
];

// ##############################
// // // table head data and table body data for Tables view
// #############################

const thead = ["Name", "Vehicle No.", "Address", "Status"];
const tbody = [
  {
    className: "",
    data: ["Nasreen Razak", "WWN8111", "Selangor", "Pending"]
  },
  {
    className: "",
    data: ["Anak Jamil", "123456", "Penang", "Declined"]
  },
  {
    className: "",
    data: ["Pak Dollah", "N00B2", "Johor", "Approved"]
  },
  {
    className: "",
    data: ["Aminah Gila", "Putrajaya123", "Putrajaya", "Pending"]
  },
  {
    className: "",
    data: ["Fairus Ezzaty", "B4B1", "Putrajaya", "Declined"]
  },
  {
    className: "",
    data: ["Farhana Shuhaiba", "G1L4", "Cyberjaya", "Declined"]
  },
  {
    className: "",
    data: ["Aidan Acquah", "D1CC", "Sarawak", "Pending"]
  }
];

// tasks list for Tasks card in Dashboard view
// data for <thead> of table in TableList view
// data for <tbody> of table in TableList view
export { tasks, thead, tbody };
