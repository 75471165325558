import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Row, Col, Card, CardHeader, CardBody, CardImg, CardTitle, Table, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js';
import { inject, observer } from 'mobx-react';

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class RoadAssistanceReport extends Component {
    static propTypes = {
        usersData: PropTypes.array,
    }

    state = {
        data: [],
        displayButt: true,
        modalEdit: false,
        from: null,
        to: null,
        disabled: true
    }
    getInitialState = () => {
        return {
            from: null,
            to: null,
        };
    }
    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
    }
    handleResetClick = () => {
        this.setState(this.getInitialState());
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    }
    // TO FLATTEN NESTED OBJECT
    _flattenObject = (obj, path = []) => {
        return Object.keys(obj).reduce((result, prop) => {
        if (typeof obj[prop] !== "object") {
            result[path.concat(prop).join(".")] = obj[prop];
            return result;
        }
        return Object.assign(result, this._flattenObject(obj[prop], path.concat(prop), result));
        }, {});
    }
    _selectDates = () => {
        let TEMP = []
        const DATA = this.props.usersData
        const START = this.state.from
        const END = this.state.to

        if(this.state.from === null){
            alert('Please select start date!')
            this.setState({displayButt:true})
            return
        }
        if(this.state.to === null){
            alert('Please select end date!')
            this.setState({displayButt:true})
            return
        }

        for (const user_id in DATA) {
            let CURRENT = DATA[user_id]
    
            if (CURRENT.last_call > START && CURRENT.last_call < END) {
              let a = CURRENT
    
              TEMP.push(a)
            }
        }

        if (TEMP !== undefined) {
            let x, filteredData = TEMP, USER = []

            filteredData.map((data, index) => {
                x = this._flattenObject(data);
                x.last_call = new Date(data.last_call).toLocaleString('en-MY', {day:'numeric', month:'short', year:'numeric', hour:'2-digit', minute:'2-digit'})
    
                USER.unshift(x)
            })

            this.setState({data:USER, load:'2', displayButt: true, disabled: false}, () => {
                this._renderExcel()
                this.toggleModal('modalEdit')
                this.setState({from:null, to:null, load:'2', displayButt: true, disabled: true})
            })
            return
        } else {
            alert('No data available for the selected date');
            this.setState({displayButt:true})
            return
        }
        
    }
    _renderButton = () => {
        let display
        
        if(this.state.displayButt === true){
            display = 
                this._renderExcel()
        } else {
            display = <Button disabled className="d-flex align-items-center justify-content-center m-0" color='dark'><div>Loading...</div><div className="btnloader ml-1"></div></Button>
        }

        return display
    }
    // EXPORT DATA INTO EXCEL FOR CUSTOMER AND USER
    _renderExcel = () => {
        let display, USER = this.state.data

        display = 
        <>
            <Button color='dark' onClick={() => {this.setState({displayButt: false}, () => {this._selectDates()})}} className="m-0">Export Report</Button>
            
            {this.state.disabled === false ? 
            <ExcelFile filename='User Data Report' hideElement={true} >
                <ExcelSheet data={USER} name='Users' >
                    {/* <ExcelColumn label='User ID' value='FUID'/> */}
                    <ExcelColumn label='Name' value='name'/>
                    <ExcelColumn label='Email' value='email'/>
                    <ExcelColumn label='Phone No.' value='phone'/>
                    <ExcelColumn label='No. of Calls' value='call_count'/>
                    <ExcelColumn label='Last Call' value='last_call'/>
                </ExcelSheet>
            </ExcelFile> : null}
        </>
        
        return display
    }

  render () {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    
    return(
        <>
            <Button className="m-0 ml-2" color="info" onClick={() => {
                this.toggleModal('modalEdit')
            }}>Generate Report</Button>
            
            <Modal backdrop='static' isOpen={this.state.modalEdit} toggle={() => this.toggleModal('modalEdit')} className='modal-dialog-centered'>
                <ModalHeader>Select Dates to Generate Report</ModalHeader>
                <ModalBody className="text-center">
                    <DayPicker
                        className="Selectable"
                        numberOfMonths={2}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                        month={this.state.to === null ? new Date(new Date().getFullYear(), new Date().getMonth() - 1) : this.state.to}
                    />
                    {this.state.from && this.state.to && (
                        <div>
                            <Button size='sm' color="warning" className="m-0" onClick={this.handleResetClick}>
                                Reset dates
                            </Button>
                        </div>
                    )}
                    <Helmet>
                        <style>{`
                        .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                            background-color: #f0f8ff !important;
                            color: #4a90e2;
                        }
                        .Selectable .DayPicker-Day {
                            border-radius: 0 !important;
                        }
                        .Selectable .DayPicker-Day--start {
                            border-top-left-radius: 50% !important;
                            border-bottom-left-radius: 50% !important;
                        }
                        .Selectable .DayPicker-Day--end {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                        }
                        `}</style>
                    </Helmet>
                </ModalBody>
                <ModalFooter>
                    {this._renderButton()}
                    <Button className='m-0 ml-auto' color='danger' onClick={() => this.toggleModal('modalEdit')}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
  }
}

RoadAssistanceReport = inject('mobx_config')(observer(RoadAssistanceReport))
