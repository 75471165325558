import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Badge, Card, CardBody, CardHeader, Col, Row, Button, } from "reactstrap";
import VehicleVerificationDisplayUser from './VehicleVerificationDisplayUser';
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';
import firebase from 'firebase';

export default class VehicleVerificationDisplay extends Component {
    static propTypes = {
        data: PropTypes.object,
        parentRefresh: PropTypes.func,
    }
    
    _remove = async () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        const { data } = this.props
        let PLATE = data.plate
        let OWNER_FUID = data.owner.FUID
        let USERS = data.user
        let USER_BRANCH_OWNER = data.owner.FUID.length === 20 ? 'users_non_serv' : 'users'
        
        let URL, FINAL_URL, BODY, TEXT, Q_FUID, Q_VEHICLE_ID, Q_RETAIL_MAIN, Q_RETAIL_ID, Q_BOOKING_ID, csrfTokenU, csrfSecretU, headerToken

        let r = window.confirm(`Are you sure you want to delete the vehicle ${PLATE}?\n\nPress 'OK' to continue.`)
        if(!r) return

        await this.setState({ load:'1' })

        if (this.props.mobx_config.browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
        if (this.props.mobx_config.config === 0) URL = serv_user_development.personalNotifications
        if (this.props.mobx_config.config === 1) URL = serv_user_production.personalNotifications

        TEXT = 'text=Vehicle Removed'
        BODY = `&body=The vehicle ${data.plate} has been removed from your posession. If this is a mistake, please re-add your vehicle and upload your vehicle registration certificate.`
        Q_FUID = `&userKey=${OWNER_FUID}`
        Q_VEHICLE_ID = `&vehicle_id=${data.plate}`
        let LOCATION = `&location=${COUNTRY_DB}`
        FINAL_URL = URL + TEXT + BODY + Q_FUID + Q_VEHICLE_ID + LOCATION
        let OPTIONS = { credentials: 'include', headers: headerToken }

        // console.log(OWNER_FUID, PLATE, data.owner, data);
        // let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // 1. NOTIFY USER THAT THEY NEED TO UPLOAD GRANT
        await fetch(FINAL_URL, OPTIONS).then(async response => {
            if (response.status !== 200) this.setState({ toggle: true, success: false, reload: true })
            console.log('remove owner', PLATE, OWNER_FUID, USER_BRANCH_OWNER);
            // 1. REMOVE OWNER
            await firebase.app(COUNTRY_DB).database().ref(`${USER_BRANCH_OWNER}/${OWNER_FUID}/vehicles/${PLATE}/`).remove()
            return response.json()
        })
        
        

        // 2. REMOVE ALL OTHER USERS
        if(USERS) {
            for (const FUID in USERS) {
                Q_FUID = `&userKey=${FUID}`
                FINAL_URL = URL + TEXT + BODY + Q_FUID + Q_VEHICLE_ID

                let USER_BRANCH = FUID.length === 20 ? 'users_non_serv' : 'users'
                await fetch(FINAL_URL, OPTIONS).then(async response => {
                    if (response.status !== 200) this.setState({ toggle: true, success: false, reload: true })
                    console.log('remove user', PLATE, FUID, USERS[FUID], USER_BRANCH);
                    // 1. REMOVE OWNER
                    await firebase.app(COUNTRY_DB).database().ref(`${USER_BRANCH}/${FUID}/vehicles/${PLATE}/`).remove()
                    return response.json()
                })
            }
        }

        // 3. REMOVE THE VEHICLE
        await firebase.app(COUNTRY_DB).database().ref(`plate_number/${PLATE}`).remove()

        this.props.parentRefresh()
    }

    _renderBadge = (conflict, verify) => {
        let display
        
        if(conflict) return display = <Badge color='danger'>Conflict</Badge>

        if(verify) return display = <Badge color='info'>Verify</Badge>

        display = <Badge color='success'>Nothing to do</Badge>

        return display
    }
    _renderOwnerData = () => {
        const { data } = this.props
        let OWNER = data.owner
        // const OWNER_ID = data.owner.FUID
        let VERIFICATION_DATA = data.verification && data.verification[OWNER.FUID] ? data.verification[OWNER.FUID] : null

        // console.log('owner', OWNER, { ...OWNER, FUID : OWNER.FUID, ...data, user_verify : VERIFICATION_DATA });
        let display = <VehicleVerificationDisplayUser data={{ ...OWNER, FUID : OWNER.FUID, ...data, user_verify : VERIFICATION_DATA }} parentRefresh={this.props.parentRefresh} type="owner" />

        return display
    }
    _renderData = () => {
        const { data } = this.props
        let display, toMap
        let USER = data.user
        let CONFLICT = USER && Object.values(USER).find(a => a.relationship === 'not owner') ? true : false
        let VERIFY = data.verification ? true : false
        let OWNER = data.owner
        // console.log(CONFLICT, VERIFY, data.plate);

        // IF ADA USER KENA CHECK ADA VERIFICATION KE TAK
        if(USER) {
            // console.log('ada user', data.plate, USER);
            toMap = Object.keys(USER).map((key, index) => {
                let VERIFICATION_DATA = data.verification && data.verification[key] ? data.verification[key] : null
                // console.log(data.plate, data.owner, CONFLICT, VERIFY, VERIFICATION_DATA);
                // console.log('OI', data.plate, USER[key]);
                if(data.owner.FUID === key) return null

                return <VehicleVerificationDisplayUser key={index} data={{ FUID : key, ...data, user_verify : VERIFICATION_DATA, ...USER[key]}} index={index} length={Object.keys(USER).length - 1} parentRefresh={this.props.parentRefresh} type="conflict" />
            })
        }
        // IF TAK ADA USER TAPI ADA VERIFICATION
        if(!USER && VERIFY) {
            toMap = Object.keys(data.verification).map((key, index) => {
                let VERIFICATION_DATA = data.verification && data.verification[key] ? data.verification[key] : null
                // console.log(data.plate, CONFLICT, VERIFY, VERIFICATION_DATA, data);
                // console.log(data.plate, data.owner.FUID, key, data.owner.FUID === key);
                if(data.owner.FUID === key) return null

                return <VehicleVerificationDisplayUser key={index} data={{FUID : key, ...data, user_verify : VERIFICATION_DATA}} index={index} length={Object.keys(data.verification).length - 1} parentRefresh={this.props.parentRefresh} type="verify" />
            })
        }

        display =
        <div>{toMap}</div>

        return display
    }

    render() {
        const { data } = this.props
        let USER = data.user
        let CONFLICT = USER && Object.values(USER).find(a => a.relationship === 'not owner') ? true : false
        let VERIFY = data.verification ? true : false
        let OWNER = data.owner
        // console.log(CONFLICT, VERIFY, data.plate);

        return (
            <Col md={6} >
                <Card>
                    <CardHeader className="d-flex align-items-center justify-content-between text-light"
                        style={{ background: 'rgb(30, 25, 80)', borderRadius:'0.95rem 0.95rem 0 0', padding:'1rem' }}
                    >
                        <div className="d-flex align-items-center justify-content-start">
                            <h4 className="m-0">{data.plate}</h4>
                            <Button className="m-0 ml-2" color="danger" size="sm" onClick={this._remove}>Remove Vehicle</Button>
                        </div>
                        {OWNER && this._renderBadge(CONFLICT, VERIFY)}
                    </CardHeader>

                    <CardBody>
                        {/* <b>Current Alleged True Owner</b> */}
                        <div style={{marginBottom:'1rem'}}>
                            {this._renderOwnerData()} 
                        </div>

                        {((USER && CONFLICT) || VERIFY) &&
                            this._renderData()
                        }
                    </CardBody>
                </Card>
            </Col>
        )
    }
}
VehicleVerificationDisplay = inject('mobx_config', 'mobx_auth')(observer(VehicleVerificationDisplay))