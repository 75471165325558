import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col } from 'reactstrap'

import ONE from 'assets/img/one.png'
import TWO from 'assets/img/two.png'
import THREE from 'assets/img/three.png'
import FOUR from 'assets/img/four.png'

const IMAGE = [ONE, TWO, THREE, FOUR]

export default class PageHeader extends Component {
    static propTypes = {
        title: PropTypes.string,            // The title of for the page
        text: PropTypes.string,             // The sub title or text that need to be displayed under the title
        image: PropTypes.number.isRequired, // The image for the phone on the right side
    }

    state = { screen: 0 }

    componentDidMount = () => {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize = () => this.setState({ screen: window.innerWidth })

    _renderContent = () => {
        const { title, text, image } = this.props
        return (
            <Card className="custom-page-header-parent" style={{ background: '#ffbb3b' }}>
                <Row className="m-0" style={{ height: '100%' }}>
                    <Col md={7} className="d-flex align-items-start justify-content-center flex-column custom-page-header">
                        <h3 className="m-0 mb-3 font-weight-bold">
                            {title ? title : 'Welcome to the SERV Dashboard'}
                        </h3>
                        <p className="m-0">
                            {text ? text : 'Your online retail business platform for your loyal customers'}
                        </p>
                    </Col>
                    <Col md={5} className="custom-page-header-right">
                        <img src={IMAGE[image - 1]} style={{ height: '230px', bottom: '20px', right: '15px' }} />
                    </Col>
                </Row>
            </Card>
        )
    }

    render = () => this._renderContent()
}
