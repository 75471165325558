import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import firebase from 'firebase'
import ModalForm from '../components/ModalForm'
import LOGO from 'assets/img/logo-placeholder.jpg'
import { Button, Col, Label, Row } from 'reactstrap'

export default class BusinessListingUpdate extends Component {
  static propTypes = {
    forType: PropTypes.string,          // To determine either type or tier that using this component
    type: PropTypes.string,             // To determine either for 'add' or 'edit' type
    selectedBusiness: PropTypes.object, // Grab the selected business data, display the current and updated for specific business type
    selectedTier: PropTypes.object, // Grab the selected business tier data, display the current and updated for specific business tier
    parentRefresh: PropTypes.func.isRequired,      // To re fetch all the data from database, to update the view
    renderTier: PropTypes.func          // A rendering function to display the tier list in the selected business type modal during edit as additional component
  }

  state = { modalAdd: false, modalEdit: false, modalTierEdit: false, modalTierAdd: false, progress: 0, formState: { canUpload: false, showBtn: true, previewImage: LOGO } }

  /* Toggle the selected modal through state argument */
  toggleModal = (state) => this.setState({ [state]: !this.state[state] })

  /* Handle the progress bar */
  handleProgress = (progress, cb) => this.setState({ progress }, () => cb && cb())

  /* Uploading Logo to the Firebase Storage */
  uploadLogo = (input, file, cb) => {
    const { selectedBusiness, forType } = this.props
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    const db = firebase.app(COUNTRY_DB).database()
    let TYPE_ID, TIER_ID, storageRef, uploadLogo

    if (forType === 'type') {
      TYPE_ID = db.ref('user_type/').push().getKey()
      storageRef = firebase.storage().ref('business_logo')
      uploadLogo = storageRef.child(TYPE_ID).child('logo').put(file)
    }
    if (forType === 'tiers') {
      TYPE_ID = selectedBusiness.id
      TIER_ID = db.ref('user_tier/').push().getKey()
      storageRef = firebase.storage().ref('business_logo')
      uploadLogo = storageRef.child(TYPE_ID).child(TIER_ID).put(file)
    }
    console.log('1. Upload Logo', `TYPE ID = ${TYPE_ID}`, forType === 'tiers' && `TIER ID = ${TIER_ID}`)

    uploadLogo.on('state_changed', (snapshot) => {
      this.handleProgress(35)
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log(`Upload is ${progress}% done`)
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused')
          break
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running')
          break
      }
    }, (error) => {
      this.handleProgress(0)
      alert(`Upload failed, please try again!\n, ${error.message}`)
    }, () => {
      uploadLogo.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at', downloadURL)
        this.handleProgress(65, () => {
          if (forType === 'type') return this.updateTypeTiers(input, cb, downloadURL, TYPE_ID)
          if (forType === 'tiers') return this.updateTypeTiers(input, cb, downloadURL, TYPE_ID, TIER_ID)
        })
      })
    })
  }

  /* Updating Tier Input to the Firebase DB */
  updateTypeTiers = async (input, cb, downloadURL, type_id, tier_id) => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    const { parentRefresh, selectedBusiness, selectedTier, forType } = this.props, { modalAdd, modalEdit, modalTierAdd, modalTierEdit } = this.state
    const db = firebase.app(COUNTRY_DB).database()
    let TYPE_ID, TIER_ID, TYPE, BG, LINK, TIER, DOWNLOAD_URL, DATA

    if (forType === 'type') {
      TYPE_ID = type_id && modalAdd ? type_id : selectedBusiness.id
      TYPE = input.user_type
      BG = input.bg ? input.bg : selectedBusiness.bg
      LINK = input.link ? input.link : selectedBusiness.link
      DOWNLOAD_URL = downloadURL ? downloadURL : selectedBusiness.logo
      DATA = { name: TYPE, bg: BG, logo: DOWNLOAD_URL, link: LINK }
    }
    if (forType === 'tiers') {
      TYPE_ID = type_id && modalTierAdd ? type_id : selectedBusiness.id
      TIER_ID = tier_id && modalTierAdd ? tier_id : selectedTier.id
      TIER = input.user_tier ? input.user_tier : selectedTier.tier.name
      DOWNLOAD_URL = downloadURL ? downloadURL : selectedTier.tier.icon
      DATA = { name: TIER, icon: DOWNLOAD_URL }
    }

    this.handleProgress(85)

    if (forType === 'type') await db.ref(`user_type/${TYPE_ID}`).update(DATA)
    if (forType === 'tiers') await db.ref(`user_tier/${TYPE_ID}/${TIER_ID}`).update(DATA)

    this.handleProgress(100, () => {
      parentRefresh()
      if (forType === 'type' && modalAdd) {
        setTimeout(() => {
          this.handleProgress(0)
          this.setState({ formState: { input: { link: 'https://www.google.com' }, canUpload: false, showBtn: true, previewImage: LOGO } })
          this.toggleModal('modalAdd')
        }, 500)
      }
      if (forType === 'type' && modalEdit) {
        this.handleProgress(0)
        this.setState({ canUpload: false, showBtn: true, previewImage: LOGO }, cb())
        this.toggleModal('modalEdit')
      }
      if (forType === 'tiers' && modalTierAdd) {
        this.handleProgress(0)
        this.toggleModal('modalTierAdd')
      }
      if (forType === 'tiers' && modalTierEdit) {
        this.toggleModal('modalTierEdit')
        this.setState({ canUpload: false, showBtn: true, previewImage: LOGO }, cb())
      }
    })
  }

  /* Rendering the Open Modal Button */
  _renderOpenBtn = () => {
    const { type, forType } = this.props

    if (type === 'add' && forType === 'type') return <Button onClick={() => this.toggleModal('modalAdd')} style={{ background: '#00006c', borderRadius: '0', boxShadow: '3px -3px 0 #00004b, 6px -6px 0 #000039', width: '100%', position: 'relative', zIndex: '50', }}>Add Business</Button>
    if (type === 'edit' && forType === 'type') return <Button color='dark' className='m-0' onClick={() => this.toggleModal('modalEdit')} style={{ width: '100%' }}>Edit</Button>

    if (type === 'add' && forType === 'tiers') return <Col md={2}><Button className='m-0 mr-3' style={{ width: '100%' }} color='dark' onClick={() => this.toggleModal('modalTierAdd')}>Add Tier</Button></Col>
    if (type === 'edit' && forType === 'tiers') return <Button className='m-0' color='dark' onClick={() => this.toggleModal('modalTierEdit')}>Edit Tier</Button>
  }

  /* Rendering the Existing Logo if Logo Already Exist */
  _renderExistingLogo = () => {
    const { selectedBusiness, selectedTier, forType } = this.props
    if (forType === 'type') return <Col><Label for='upload_logo'>Current Logo</Label><br /><img src={selectedBusiness.logo} style={{ width: '250px' }} /></Col>

    if (forType === 'tiers') return <Col><Label for='upload_logo'>Current Logo</Label><br /><img src={selectedTier.tier.icon} style={{ width: '250px' }} /></Col>
  }

  /* Rendering the Content that Need to be Display */
  _renderModalContent = () => {
    const { forType, type, selectedBusiness, selectedTier, renderTier } = this.props, { modalAdd, modalEdit, modalTierAdd, modalTierEdit, formState, progress } = this.state
    let toggle, modalContent, saveBtn, imageInput, input, inputError

    if (forType === 'type' && type === 'add' && modalAdd) {

      toggle = { state: modalAdd, method: this.toggleModal, stateStr: 'modalAdd' }
      modalContent = { title: 'Add User Business' }
      saveBtn = { textSave: 'Add Business Type', textSaving: 'Adding Business Type' }
      imageInput = [{ label: 'Upload Logo', id: 'upload_logo', value: '' }]
      input = [
        { label: 'Business Name', id: 'user_type', value: '' },
        { label: 'Business Brand Colour', id: 'bg', value: '' },
        { label: 'T&C Link', id: 'link', value: 'https://www.google.com' }
      ]
      inputError = [
        { type: 'text', id: 'user_type', message: 'Please enter User Business Type!' },
        { type: 'text', id: 'bg', message: 'Please enter User Business Brand Colour!' },
        { type: 'image', id: 'upload_logo', message: 'Please upload image for tile icon!' }
      ]

      return <ModalForm toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} imageInput={imageInput} input={input} inputError={inputError} parentState={formState} parentProgress={progress} uploadLogo={this.uploadLogo} handleProgress={this.handleProgress} />
    }

    if (forType === 'type' && type === 'edit' && modalEdit) {
      toggle = { state: modalEdit, method: this.toggleModal, stateStr: 'modalEdit' }
      modalContent = { title: selectedBusiness.name }
      saveBtn = { textSave: 'Save', textSaving: 'Saving' }
      imageInput = [{ label: 'Upload New Logo', id: 'edit_logo', value: '' }]
      input = [{ label: 'User Business Type', id: 'user_type', value: selectedBusiness.name }]
      inputError = [{ type: 'text', id: 'user_type', message: 'Please specify Business Name!' }]

      return <ModalForm existLogo={selectedBusiness.logo} toggle={toggle} modalContent={modalContent} saveBtn={saveBtn} imageInput={imageInput} input={input} inputError={inputError} parentState={formState} parentProgress={progress} additionalComp={renderTier} showLogo={this._renderExistingLogo} updateInput={this.updateTypeTiers} uploadLogo={this.uploadLogo} handleProgress={this.handleProgress} />
    }
    if (forType === 'tiers' && type === 'add' && modalTierAdd) {
      toggle = { state: modalTierAdd, method: this.toggleModal, stateStr: 'modalTierAdd' }
      saveBtn = { textSave: 'Save', textSaving: 'Saving' }
      imageInput = [{ label: 'Upload Logo', id: 'upload_logo', value: '' }]
      input = [{ label: 'Tier Name', id: 'user_tier', value: '' }]
      inputError = [
        { type: 'text', id: 'user_tier', message: 'Please enter Tier name!' },
        { type: 'image', id: 'upload_logo', message: 'Please upload image for tier icon!' }
      ]

      return (
        <Col md={10}>
          <ModalForm collapseType toggle={toggle} input={input} imageInput={imageInput} inputError={inputError} saveBtn={saveBtn} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} uploadLogo={this.uploadLogo} />
        </Col>
      )
    }

    if (forType === 'tiers' && type === 'edit' && modalTierEdit) {
      toggle = { state: modalTierEdit, method: this.toggleModal, stateStr: 'modalTierEdit' }
      modalContent = { title: 'Edit Tier' }
      saveBtn = { textSave: 'Save', textSaving: 'Saving' }
      imageInput = [{ label: 'Upload New Logo', id: 'edit_logo_tier', value: '' }]
      input = [{ label: 'Tier Name', id: 'user_tier', value: selectedTier.tier.name }]
      inputError = [{ type: 'text', id: 'user_tier', message: 'Please enter Tier name!' }]

      return (
        <Col md={10}>
          <ModalForm existLogo={selectedTier.tier.icon} showLogo={this._renderExistingLogo} toggle={toggle} modalContent={modalContent} input={input} imageInput={imageInput} inputError={inputError} saveBtn={saveBtn} parentState={formState} parentProgress={progress} handleProgress={this.handleProgress} uploadLogo={this.uploadLogo} updateInput={this.updateTypeTiers} />
        </Col>
      )
    }
  }

  render() {
    const { forType, type } = this.props
    if (forType === 'type') return <div style={type === 'edit' ? { width: '25%', alignItems: 'center', display: 'flex', justifyContent: 'center' } : null}> {this._renderOpenBtn()}{this._renderModalContent()}</div >

    if (forType === 'tiers' && type === 'edit') return <>{this._renderOpenBtn()}{this._renderModalContent()}</>
    if (forType === 'tiers' && type === 'add') return <Row className='mb-3'>{this._renderOpenBtn()}{this._renderModalContent()}</Row>
  }
}
BusinessListingUpdate = inject('mobx_config','mobx_auth')(observer(BusinessListingUpdate))
