import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, Button, Badge } from 'reactstrap'
import EditMarketplace from './EditMarketplace'

export default class DisplayMarketplace extends Component {
    static propTypes = {
        data: PropTypes.object,
        parentRefresh: PropTypes.func,
    }

    render() {
        const { data } = this.props
        if(this.props.list){
            return (
                <tr>
                    <td style={{padding:'5px 7px'}}>{data.company_name}</td>
                    <td style={{padding:'5px 7px'}}>{data.category}</td>
                    <td style={{padding:'5px 7px'}}>{data.name}</td>
                    <td style={{padding:'5px 7px'}} width="35%">{data.description}</td>
                    <td style={{padding:'5px 7px'}}>
                        <EditMarketplace data={data} parentRefresh={this.props.parentRefresh} />    
                    </td>
                </tr>
            )
        }

        return (
            <Card className="no-shadow" style={{height: 'calc(100% - 20px)', border:'1px solid'}}>
                <CardHeader style={{paddingBottom:'15px', borderBottom:'1px solid'}} className="d-flex align-items-center justify-content-between">
                    <div style={{marginRight:'5px'}}>
                        <h6 className="m-0 mb-1 one-line-text" style={{lineHeight:'1'}}>{data.company_name}</h6>
                        <Badge pill className="m-0" color="info">{data.category}</Badge>
                    </div>
                    <EditMarketplace data={data} parentRefresh={this.props.parentRefresh} />
                </CardHeader>
                <CardBody>
                    <div><b>Name :</b> {data.name}</div>
                    <div className="two-line-text"><b>Description :</b> {data.description}</div>
                </CardBody>
            </Card>
        )
    }
}
