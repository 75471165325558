import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, FormGroup, Label, Input, FormText, Progress, Col, Row, Collapse } from 'reactstrap'

export default class ModalForm extends Component {
  static propTypes = {
    collapseType: PropTypes.bool,     // Specify the UI for parent component either collapsible or modal
    parentProgress: PropTypes.number, // To show the progress on the progress bar
    existLogo: PropTypes.string,      // To check either logo exist or not
    input: PropTypes.array,           // Array on text input that need to be loop over and displayed
    imageInput: PropTypes.array,      // Array on image input that need to be loop over and displayed
    inputError: PropTypes.array,      // Error handler for the input
    modalContent: PropTypes.object,   // For the modal header title
    toggle: PropTypes.object,         // To toggle open or close the modal and collapsible
    saveBtn: PropTypes.object,        // Contains the info for button text
    parentState: PropTypes.object,    // Initial state from parent
    handleProgress: PropTypes.func,   // For updating progress
    uploadLogo: PropTypes.func,       // Uploading logo function to the firebase
    additionalComp: PropTypes.func,   // Render additional component in the modal if needed
    showLogo: PropTypes.func,         // To show the existing logo if the logo already exist in database
    updateInput: PropTypes.func,      // Updating (add or edit) the data on firebase database
  }

  state = { previewImage: null, progress: 0, canUpload: false, showBtn: true, file: null, input: {}, existLogo: '', ccArray: [] }

  componentDidMount = () => {
    const { parentState, input, existLogo } = this.props
    let editInput = {}

    if (input) {
      for (const item of input) { editInput[item.id] = item.value }               // Set the initial value for each input when this component mounted
    }
    if (existLogo) this.setState({ existLogo })                                 // Set the existing logo if the logo already exist (for edit)
    this.setState({ previewImage: parentState.previewImage, input: editInput }) // Set the initial state for the image and input
  }

  componentDidUpdate = (prevProps) => {
    const { parentState, parentProgress, ccArray } = this.props

    if (parentProgress !== prevProps.parentProgress) this.setState({ progress: parentProgress })                                    // Re update the progress when the progress state in parent comp changed
    if (parentState.previewImage !== prevProps.parentState.previewImage) this.setState({ previewImage: parentState.previewImage })  // Re update the previewImage state according parent comp
    if (parentState.canUpload !== prevProps.parentState.canUpload) this.setState({ canUpload: parentState.canUpload })              // Re update the canUpload state according to parent comp
    if (parentState.showBtn !== prevProps.parentState.showBtn) this.setState({ showBtn: parentState.showBtn })                      // Re update the showBtn state according to parent comp
  }

  /* To reset the image value after uploading or when the file is too large */
  resetImageInput = () => this.props.imageInput.forEach(item => document.querySelector(`#${item.id}`).value = '')

  /* Grab the input value from the form and update to state */
  handleInput = (evt) => this.setState({ input: { ...this.state.input, [evt.target.name]: evt.target.value } })

  /* Grab the image input, check the size and update to the state */
  handleImageInput = (evt) => {
    const file = evt.target.files[0]
    if (file) {
      const checker = new Image()
      checker.src = window.URL.createObjectURL(file)
      checker.onload = () => {
        const fileSize = file.size
        window.URL.revokeObjectURL(checker.src)
        if (fileSize < 100000) { this.setState({ canUpload: true, previewImage: URL.createObjectURL(file), file: file }) } else { this.setState({ canUpload: false, file: file }) }
      }
    } else { console.log('Image is empty') }
  }

  /* Submit button trigger, error handling and pass the input data to parent component to be updated on DB */
  submitBtn = () => {
    const { handleProgress, uploadLogo, inputError, existLogo, updateInput, check, ccArray } = this.props, { file, input, canUpload } = this.state

    if (check) check()
    handleProgress(10)

    for (const item of inputError) {
      if (item.type !== 'image') {
        if (!input[item.id] || input[item.id] === '') {
          alert(item.message)
          this.setState({ showBtn: true })
          return
        }
      }

      if (item.type === 'image') {
        if (!file && !existLogo) {
          alert(item.message)
          this.setState({ showBtn: true })
          return
        }
      }
    }

    if (ccArray !== undefined) {
      if (ccArray.length === 0) {
        alert('Please fill in at least one CC for the variant')
        this.setState({ showBtn: true })
        return
      }
      if (ccArray.indexOf('') !== -1) {
        alert('Please fill in all of the fields that you have added or remove the fields that are empty!')
        this.setState({ showBtn: true })
        return
      }
    }

    if (file && canUpload === false) {
      alert('Icon image file size too large! Please upload images below 100kb.')
      this.setState({ showBtn: true })
      this.resetImageInput()
      return
    }
    if (existLogo && file) {
      console.log('Ada Logo Baru')
      this.setState({ showBtn: false }, () => uploadLogo(input, file, this.resetImageInput))
      return
    }
    if (existLogo && !file) {
      console.log('Tak ada logo baru')
      this.setState({ showBtn: false }, () => updateInput(input, this.resetImageInput))
      return
    }
    if (!existLogo) return this.setState({ showBtn: false }, () => {
      if (uploadLogo) {
        uploadLogo(input, file, () => {
          this.resetImageInput()
          this.setState({ showBtn: true })
        })
      } else {
        updateInput(input, () => this.setState({ showBtn: true }))
      }
    })
  }

  deleteBtn = () => {
    const { deleteItem } = this.props
    this.setState({ showBtn: false }, () => deleteItem())
  }

  /* Render the progress bar */
  _renderProgress = () => this.state.progress !== 0 && <div className='mt-3'><Progress animated color='success' value={this.state.progress} /></div>

  /* Loop over the input props and display out the input form */
  _renderForm = () => {
    const { input, collapseType } = this.props, { input: inputState } = this.state
    if (input) {
      if (inputState !== undefined) return input.map(item =>
        <FormGroup key={item.id} row={collapseType} className={collapseType && 'align-items-center m-0'}>
          <Label for={item.id} className={collapseType && 'm-0'} style={{ flex: 2 }}>{item.label}</Label>
          {collapseType ? <Col md={10}><Input type='text' name={item.id} id={item.id} value={inputState[item.id] || ''} onChange={this.handleInput} /></Col> : <Input type='text' name={item.id} id={item.id} value={inputState[item.id] || ''} onChange={this.handleInput} />}
        </FormGroup>
      )
    }
  }

  /* Loop over the input image props and display the image input form */
  _renderImageForm = () => {
    const { imageInput } = this.props
    if (imageInput) {
      return imageInput.map(input =>
        <Col key={input.id}>
          <Label for={input.id}>{input.label}</Label>
          <Input type='file' accept='image/*' name={input.id} id={input.id} onChange={this.handleImageInput} />
          <FormText color='danger'>Logo (Make sure the the image size is less than 100kb!)</FormText>
          <div><img style={{ width: 250 }} src={this.state.previewImage} /></div>
        </Col>
      )
    }
  }

  /* Render the save button */
  _renderButton = () => {
    const { showBtn } = this.state, { saveBtn, uploadLogo } = this.props

    if (showBtn && !saveBtn.style) return <Button color={saveBtn.color ? saveBtn.color : 'light'} onClick={this.submitBtn}>{saveBtn.textSave}</Button>

    if (showBtn && saveBtn.style) return <div style={{ display: 'flex' }}><Button color={saveBtn.color ? saveBtn.color : 'light'} onClick={this.submitBtn} style={saveBtn.style}>{saveBtn.textSave}</Button></div>

    if (!showBtn && !saveBtn.style) return <Button color={saveBtn.color ? saveBtn.color : 'light'} className='d-flex align-items-center' onClick={() => uploadLogo()}><div>{saveBtn.textSaving}</div><div className='btnloader'></div></Button>

    if (!showBtn && saveBtn.style) return <div style={{ display: 'flex' }}><Button color={saveBtn.color ? saveBtn.color : 'light'} className='d-flex align-items-center' onClick={() => uploadLogo()} style={saveBtn.style}><div>{saveBtn.textSaving}</div><div className='btnloader'></div></Button></div>
  }

  _renderDeleteButton = () => {
    const { delBtn, deleteSelected } = this.props, { showBtn } = this.state
    if (delBtn && showBtn) return <Button color='danger' style={{ marginLeft: '15px' }} onClick={this.deleteBtn}>{delBtn.textDel}</Button>

    if (delBtn && !showBtn) return <Button disabled color='danger' style={{ display: 'inline-flex', marginLeft: '15px' }} className="align-items-center" onClick={deleteSelected}><div className="mr-1">{delBtn.textDeleting}</div><div className="btnloader"></div></Button>
  }

  /* Render the content type either modal or collapsible */
  _renderContent = () => {
    const { hideInput, toggle, modalContent, showLogo, additionalComp, collapseType, style, classname, delBtn, logoOrientation, input, imageInput } = this.props

    if (!collapseType) {
      return (
        <Modal className={classname ? classname : 'modal-default'} isOpen={toggle.state} toggle={() => { toggle.method(toggle.stateStr) }} style={{ ...style }}>
          <ModalHeader toggle={() => { toggle.method(toggle.stateStr) }}>{modalContent ? modalContent.title : ''}</ModalHeader>
          <ModalBody>
            {!hideInput && this._renderForm()}
            {showLogo && logoOrientation === 'column' ? showLogo() : null}
            <Row>{showLogo && logoOrientation !== 'column' ? showLogo() : null}{this._renderImageForm()}</Row>
            {this._renderProgress()}{additionalComp && additionalComp()}
          </ModalBody>
          <ModalFooter>
            {this._renderButton()}
            {delBtn && this._renderDeleteButton()}
            <Button className='ml-auto' color='secondary' onClick={() => { toggle.method(toggle.stateStr) }}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )
    }
    if (collapseType) {
      return (
        <Collapse isOpen={toggle.state}>
          <div style={{ border: '1px solid', borderRadius: '5px', padding: '15px' }}>
            {this._renderForm()}
            {imageInput && <Row>{this._renderImageForm()}</Row>}
            {additionalComp && additionalComp()}
            {this._renderProgress()}{this._renderButton()}
          </div>
        </Collapse>
      )
    }
  }
  render() { return this._renderContent() }
}
ModalForm = inject('mobx_config')(observer(ModalForm))
