import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import CarListingUpdate from './CarListingUpdate'

export default class CarListingVariantCC extends Component {

  state = { modalVariant: false }

  toggleModal = (state) => this.setState({ [state]: !this.state[state] })

  _renderOpenBtn = () => <Button color="dark" className="m-0" style={{ width: '100%' }} onClick={() => { this.toggleModal('modalVariant') }}>Edit Model Variants</Button>

  _renderVariants = () => {
    const { variant, model, parentRefresh } = this.props
    let displayVariantList;

    if (variant.length === 0) return <div className="text-center" style={{ padding: '65px 25px' }}>No variants to display.<br />Click on 'Add Variant' to add this Model's variant and CC.</div>

    if (variant.length !== 0) {
      displayVariantList = variant.map((data, index) => (
        <Row key={index} className="mb-3 pb-3" style={{ borderBottom: index !== variant.length - 1 ? '1px solid rgba(0,0,0,0.2)' : null }}>
          <Col md={5}>{data.variant}</Col>
          <Col md={5}>{data.cc !== undefined ? data.cc.map((data, index) => (<div key={index}>{data}</div>)) : <div>No cc specified</div>}</Col>
          <Col md={2} className="text-right">
            <CarListingUpdate forType='cc' type='edit' model={model} variant={data} parentRefresh={parentRefresh} />
            <CarListingUpdate forType='variant' type='edit' model={model} variant={data} parentRefresh={parentRefresh} />
          </Col>
        </Row>
      ))
      return <div><Row className="mb-3 mt-3 pt-3 pb-3" style={{ borderBottom: '1px solid rgba(0,0,0,0.2)', borderTop: '1px solid rgba(0,0,0,0.2)' }}>
        <Col md={5}>Variant</Col>
        <Col md={5}>Cc List</Col>
        <Col md={2} className="text-right">Action</Col>
      </Row>{displayVariantList}</div>
    }
  }

  _renderVariantModal = () => {
    const { model, make, parentRefresh } = this.props
    return <Modal className='modal-default' isOpen={this.state.modalVariant} backdrop="static" toggle={() => { this.toggleModal('modalVariant') }} style={{ maxWidth: '90%' }}>
      <ModalHeader toggle={() => { this.toggleModal('modalVariant') }}>Model Variants for {make.make} {model.model}</ModalHeader>
      <ModalBody className="pb-0">
        <CarListingUpdate forType='variant' type='add' model={model} make={make} parentRefresh={parentRefresh} />
        {this._renderVariants()}
        {/* {this._renderAdd()} */}

      </ModalBody>
      <ModalFooter>
        <Button className='ml-auto' color='secondary' onClick={() => { this.toggleModal('modalVariant') }}>Exit</Button>
      </ModalFooter>
    </Modal>
  }

  render() {
    // console.log(this.props)
    return (
      <div>
        {this._renderOpenBtn()}
        {this._renderVariantModal()}
      </div>
    )
  }
}
