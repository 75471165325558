import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import firebase from 'firebase';
import { inject, observer } from 'mobx-react';

export default class AnnouncementDelete extends Component {
    static propTypes = {
        parentFetch : PropTypes.func,
        data : PropTypes.object,
    }
    
    _delete = () => {
        let KEY = this.props.data.key
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let r = window.confirm("Are you sure you want to delete this announcement? Click 'OK' to proceed")
        if(!r) return;

        firebase.app(COUNTRY_DB).database().ref(`announcementSU/${KEY}`).remove().then(() => {
            this.props.parentFetch()
        })
    }
    render() {
        return (
            <>
                <Button color='danger' size='sm' className='m-0'
                    onClick={this._delete}
                >Delete</Button>
            </>
        )
    }
}

AnnouncementDelete = inject('mobx_auth')(observer(AnnouncementDelete))