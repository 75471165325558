import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Label, Input, FormGroup, FormText } from 'reactstrap'

import LOGO from 'assets/img/placeholder.png'

import ErrorAlert from 'components/ErrorAlert'

export default class ImageUpload extends Component {
   static propTypes = {
      id: PropTypes.string,
      onImageUpload: PropTypes.func
   }

   state = {
      errorText: '',                   // handles error text
      errorToggle: false,              // handles error alert modal

      isUpload: false,
      preview: LOGO,
   }

   /* toggle error alert */
   childToggle = () => this.setState({ errorToggle:!this.state.errorToggle })

   /* to handle image change then, update state function */
   handleImage = () => {
      const { id } = this.props
      const file = document.querySelector('#upload_image').files[0]

      if(file){
         if(!file.type.includes("image/")) {
            this.setState({ preview:LOGO, isUpload:false, errorToggle:true, errorText:<span>Not an image!</span> })
         }

         var checker = new Image();
         checker.src = window.URL.createObjectURL(file)

         checker.onload = () => {
            let fileSize = file.size;

            window.URL.revokeObjectURL(checker.src)
            if(fileSize < 500000) {
               this.props.onImageUpload(URL.createObjectURL(file), true, id)
               this.setState({ isUpload:true, preview:URL.createObjectURL(file) })
            } else {
               document.querySelector('#upload_image').value = ""
               this.setState({ 
                  preview:LOGO, 
                  isUpload:false, 
                  errorToggle:true,
                  errorText:<span>File size too large! File size has to be less than 100KB.<br/>You can compress your image <a href="https://resizeimage.net/" target="_blank" style={{textDecoration:'underline'}}>here</a></span> 
               })
            }
         }
      } else {
         this.setState({ preview:LOGO, isUpload:false, errorToggle:true, errorText:<span>Please select an image to upload!</span> })
      }
   }

   render() {
      const { errorText, errorToggle } = this.state
      const { width, height } = this.props
      let text
      
      text = `* Image upload guide : Recommended file size less than 150kb. Image dimensions do not matter as long as it has an aspect ratio of ${width}:${height}!`

      return (
         <>
         <FormGroup className='mt-4'>
            <Label>Upload Image</Label><br />
            <button id='upload_image_button' className='mb-1 m-0' style={{ fontSize:'0.7rem', borderRadius:'5px', display:'block' }}>Upload Image</button>
            <Input type='file' accept='image/*' name='upload_image' id='upload_image' style={{ marginBottom:'5px' }} onChange={this.handleImage} />
            <FormText color='danger'>{text}</FormText>
         </FormGroup>

         <ErrorAlert text={errorText} toggle={errorToggle} parentToggle={this.childToggle} />
         </>
      )
   }
}
