import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import firebase from 'firebase';
import { inject, observer } from 'mobx-react';
import PerfectScrollbar from 'perfect-scrollbar';

import dashboardRoutes from 'routes/dashboard.jsx';
import ErrorAlert from 'components/ErrorAlert';
import Header from 'components/Header'
import Notepad from 'components/Notepad'
import Sidebar from 'components/Sidebar'

var ps;

export default class Dashboard extends React.Component {
  state = { text: '', toggle: false, success: false, reload: false, versionText: '' }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    document.removeEventListener('mousemove', this.check)
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    this.checkVersion()
    this.checkToken()
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  /* Toggle error alert or reload page */
  childToggle = async () => {
    await this.setState({ toggle: false })
    await this.reloadPage()
  }

  /* Check dashboard's version for new updates */
  checkVersion = () => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    const currentVersion = localStorage.getItem('version_nav')
    const versionWorkshop = localStorage.getItem('version_workshop')
    const versionUser = localStorage.getItem('version_user')

    firebase.app(COUNTRY_DB).database().ref('consts/version_navigator_dashboard/').on('value', (snapshot) =>{
      if (snapshot.exists()) {
        let latestVersion = snapshot.val();

        if (currentVersion !== latestVersion) {
          if ('caches' in window) {
            caches.keys().then((names) => {
              names.forEach(name => {
                caches.delete(name);
              })
            })
            let versionText = `Version Updated`

            this.setState({ versionText: versionText })
          }
          localStorage.clear()
          sessionStorage.clear()

          localStorage.setItem('version_nav', latestVersion)
          localStorage.setItem('version_workshop', versionWorkshop)
          localStorage.setItem('version_user', versionUser)
        }
      }
    })
  }

  /* Listen to user's mouse pointer move */
  checkToken = () => document.addEventListener('mousemove', this.check);

  /* Checks token validity if mouse move triggers */
  check = () => {
    const CT = sessionStorage.getItem('CT')

    if (!CT) {
      let text

      if (this.state.versionText !== '') {
        text = `${this.state.versionText}. The page will log out and will be forced to refresh the page to update the system.`

        return this.setState({ text: text, toggle: true, success: false, reload: true })
      }
      text = `Something went wrong. Please refresh the page.`

      return this.setState({ text: text, toggle: true, success: false, reload: true })
    }
  }

  /* Reload page if invalid token */
  reloadPage = () => {
    if (this.state.reload === true) {
      document.removeEventListener('mousemove', this.check)
      document.location.reload()
    }
  }

  _renderRoutes = () => {

    return dashboardRoutes.map((prop, key) => {
      // console.log("key", key)
      if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />

      if (prop.collapse && prop.roles.indexOf(this.props.mobx_auth.ROLE) !== -1 && prop.country.indexOf(this.props.mobx_auth.COUNTRY) !== -1) {
        console.log("this mobx auth country",prop.roles.indexOf(this.props.mobx_auth.ROLE) )
        return (
          prop.pages.map((prop2, key2) => (
            <Route path={prop2.path} component={prop2.component} key={key2}/>
          ))
        )
      } 
      if(!prop.collapse){
        return (
          <Route path={prop.path} component={prop.component} key={key} />
        );
      }
      
    })
  }

  render() {
    return (
      <div className='wrapper'>
        <Sidebar {...this.props} routes={dashboardRoutes} />
        <div className='main-panel' ref='mainPanel'>
          <Header {...this.props} />
          <Switch>
            {this._renderRoutes()}
          </Switch>
        </div>

        <Notepad />
        <ErrorAlert text={this.state.text} toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />

      </div>
    );
  }
}

Dashboard = inject('mobx_config', 'mobx_auth')(observer(Dashboard))