import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, CardHeader, CardBody, CardImg, CardTitle, Table, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'
import { PanelHeader } from "components";
import DisplayRequestCard from 'components/DisplayRequestCard'

import workerBookingHistory from 'workers/workerBookingHistory.js';
import WebWorker from 'workers/workerSetup.js';
import firebase from 'firebase';
import { inject, observer } from 'mobx-react';
import { createHashHistory } from 'history';

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const history = createHashHistory();

class RequestHistory extends React.Component {
  state = {
    historyData: [],
    deletedData: {},
    load: '1',
    search: ''
  }
  componentDidMount = () => {
    this.workerBookingHistory = new WebWorker(workerBookingHistory);
    this._fetchHistory();
    this._fetchDeletedReasons();
  };
  
  _fetchHistory = () => {
    let URL, csrfTokenP, csrfSecretP, headerToken;
    const { browser } = this.props.mobx_config
    let OPTIONS = { credentials: 'include' }
    let HISTORY = [];
    let DATA

    if (browser === 'safari') {
      csrfTokenP = sessionStorage.getItem('CSRFTokenP')
      csrfSecretP = sessionStorage.getItem('CSRFSecretP')
      headerToken = { csrftoken: csrfTokenP, csrfsecret: csrfSecretP }
    }
    if (this.props.mobx_config.config === 0) {
      URL = serv_provider_development.fetchBookingsHistory
    }
    if (this.props.mobx_config.config === 1) {
      URL = serv_provider_production.fetchBookingsHistory
    }

    fetch(URL, OPTIONS).then(res => res.json().then(res => {
      if (res.data) {
        DATA = res.data
      } else {
        DATA = 'none'
      }
    }).then(() => {
      if (DATA !== 'none') {
        for (const history_id in DATA) {
          let h = DATA[history_id];
          h.booking_id = history_id;
          HISTORY.unshift(h);
        }
        this.setState({ historyData: HISTORY, load: '2' });
      } else {
        this.setState({ load: '3' })
      }
    }))
  }

  _fetchDeletedReasons = () => {
    let DELETED = {};
    firebase.database().ref('deleted_reasons/').once('value').then((snapshot) => {
      if (snapshot.exists()) {
        DELETED = snapshot.val()
      }
    }).then(() => {
      firebase.database().ref('delete/').once('value').then((snapshot) => {
        if (snapshot.exists()) {
          Object.assign(DELETED, snapshot.val())
          this.setState({ deletedData: DELETED })
        } else {
          this.setState({ deletedData: DELETED })
        }
      })
    })
  }

  _select = (data) => {
    let ID = data.booking_id;
    history.push("/user/Booking-History/" + ID)
  }

  handleSearchBar = (evt) => {
    let stateValue = evt.target.value

    stateValue = stateValue.toUpperCase()
    this.setState({ search: stateValue});
  }
  _renderContents = () => {
    let display = [];
    let DATA = this.state.historyData

    let filteredData = DATA.filter(
      (historyData) => {
        return historyData._requestID.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
          historyData.customer_carplate.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 ||
          historyData.customer_phone.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
      }
    )

    if (this.state.load === '1') {
      display =
        <Card>
          <CardBody>
            <div className='loader'></div>
          </CardBody>
        </Card>
    }
    if (this.state.load === '2') {
      display = filteredData.length !== 0 ? filteredData.map((data, index) => {
        return (
          <DisplayRequestCard key={index} data={data} history
            deleteData={this.state.deletedData[data.booking_id] !== undefined ? this.state.deletedData[data.booking_id] : null}
          />
        )
      }) : <Card style={{ padding: '95px 25px' }} className="text-center">No vehicle or phone number "{this.state.search}".<br />Please check spelling and try again.</Card>
    }
    if (this.state.load === '3') {
      display =
        <Card className="text-center">
          <CardBody style={{ padding: '35px' }}>
            No history to display.
          </CardBody>
        </Card>
    }

    return display;
  }

  // TO FLATTEN NESTED OBJECT
  _flattenObject = (obj, path = []) => {
    return Object.keys(obj).reduce((result, prop) => {
      if (typeof obj[prop] !== "object") {
        result[path.concat(prop).join(".")] = obj[prop];
        return result;
      }
      return Object.assign(result, this._flattenObject(obj[prop], path.concat(prop), result));
    }, {});
  }

  // EXPORT DATA INTO EXCEL FOR CUSTOMER AND USER
  _renderExcel = () => {
    let x, display, CUSTOMER = [], USER = [], filteredData = this.state.historyData

    filteredData.map((data, index) => {
      if (data.receipt_payment_url !== undefined && data.checklist !== undefined) {
        x = this._flattenObject(data);
        // x.service_date = data.customer_day + ' ' + data.customer_month + ' ' + data.customer_year + ', ' + data.customer_time
        x.service_date = `${data.customer_day} ${data.customer_month} ${data.customer_year}, ${data.customer_time}`

        CUSTOMER.unshift(x)
      }
      else {
        x = this._flattenObject(data);
        // x.service_date = data.customer_day + ' ' + data.customer_month + ' ' + data.customer_year + ', ' + data.customer_time
        x.service_date = `${data.customer_day} ${data.customer_month} ${data.customer_year}, ${data.customer_time}`

        USER.unshift(x)
      }
    })

    display =
      <ExcelFile filename='SOTG Requests Report' element={<Button color="info" className="m-2">Export Report</Button>}>
        <ExcelSheet color='danger' data={CUSTOMER} name='Customer' >
          <ExcelColumn label='Name' value='customer_name' />
          <ExcelColumn label='Email' value='customer_email' />
          <ExcelColumn label='Phone Number' value='customer_phone' />
          <ExcelColumn label='Service Date' value='service_date' />
          {/* <ExcelColumn label='Car Model' value='customer_carmodel'/>
            <ExcelColumn label='Car Make' value='customer_carmake'/>
            <ExcelColumn label='Car Year' value='customer_caryear'/>
            <ExcelColumn label='Car CC' value='customer_carcc'/>
            <ExcelColumn label='Car Transmission' value='customer_cartransmission'/>
            <ExcelColumn label='Car Plate' value='customer_carplate'/>
            <ExcelColumn label='Service Type' value='quotation.requested_services.basic_premium'/>  */}
        </ExcelSheet>
        <ExcelSheet data={USER} name='User'>
          <ExcelColumn label='Name' value='customer_name' />
          <ExcelColumn label='Email' value='customer_email' />
          <ExcelColumn label='Service Type' value='customer_phone' />
          <ExcelColumn label='Service Date' value='service_date' />
          {/* <ExcelColumn label='Car Model' value='customer_carmodel'/>
            <ExcelColumn label='Car Make' value='customer_carmake'/>
            <ExcelColumn label='Car Year' value='customer_caryear'/>
            <ExcelColumn label='Car CC' value='customer_carcc'/>
            <ExcelColumn label='Car Transmission' value='customer_cartransmission'/>
            <ExcelColumn label='Car Plate' value='customer_carplate'/>
            <ExcelColumn label='Service Type' value='quotation.requested_services.basic_premium'/> */}
        </ExcelSheet>
      </ExcelFile>

    return display
  }

  render() {
    const { search, load, } = this.state
    return (
      <div>
        <div className="d-flex align-items-center justify-content-between position-relative">
          <InputGroup className="no-border" style={{ width: this.state.screen < 768 ? '100%' : '50%' }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ background: 'rgba(255,255,255,1)' }}>
                <i className="now-ui-icons ui-1_zoom-bold" />
              </InputGroupText>
            </InputGroupAddon>
            <Input style={{ background: 'rgba(255,255,255,1)' }} placeholder="Search by Plate no. and Phone no." onChange={this.handleSearchBar} value={search}/>
          </InputGroup>

          <div>
            {load !== '1' && this._renderExcel()}
            <Button onClick={() => {
              this.setState({ load: '1' }, () => { this._fetchHistory() })
            }} color="light" style={{ borderRadius: '50%', float: 'right', marginRight: '25px', padding: '8px 10px', position: 'relative', zIndex: '50', fontWeight: 'bold' }}>
              <i className="now-ui-icons arrows-1_refresh-69" style={{ fontSize: '20px', fontWeight: 'bold' }} />
            </Button>
          </div>
          {/* <Button onClick={() => {
              this.setState({load:'1'}, () => {this._fetchHistory()})
            }} color="light" style={{borderRadius: '50%', float:'right', marginRight: '25px', padding: '8px 10px', position:'relative', zIndex:'50', fontWeight:'bold'}}>
            <i className="now-ui-icons arrows-1_refresh-69" style={{fontSize:'20px', fontWeight:'bold'}}/>
          </Button> */}
        </div>

        {this._renderContents()}
      </div>
    );
  }
}

RequestHistory = inject('mobx_config')(observer(RequestHistory));
export default RequestHistory;
