import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import Pagination from 'react-paginating'
import UsersListGrid from './UsersListGrid'
import PropTypes from 'prop-types'

export default class UsersDisplay extends Component {
  static propTypes = {
    load: PropTypes.string.isRequired,             // get the loading state
    users: PropTypes.array.isRequired,             // get the users data
    search: PropTypes.string.isRequired,           // show the filter data
    currentPage: PropTypes.number.isRequired,      // get the current page number
    view_user_count: PropTypes.number.isRequired,  // get the number of user per page
    chunkArray: PropTypes.func.isRequired,         // send the filtered data to parent
    checked: PropTypes.bool.isRequired,            // show either list/grid
    handlePageChange: PropTypes.func.isRequired,   // send the page change
  }

  /* RENDER THE LIST OF USERS */
  _renderUsersList = () => {
    const { users, search, checked, currentPage, view_user_count, chunkArray } = this.props
    const filteredData = users.filter((data) => (data.name !== undefined) && data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    const pagesArray = chunkArray(filteredData, view_user_count)

    // grid view
    if (pagesArray.length !== 0 && checked === false) {
      return pagesArray[currentPage - 1].map((data, i) => (
        <Col md={4} key={i} className='mb-3'>
          <UsersListGrid data={pagesArray[currentPage - 1][i]} />
        </Col>
      ))
    }

    // list view
    if (pagesArray.length !== 0 && checked === true) {
      return pagesArray[currentPage - 1].map((data, i) => (
        <Col key={i} md={12}>
          <UsersListGrid data={pagesArray[currentPage - 1][i]} max={pagesArray[currentPage - 1].length - 1} current={i} checked />
        </Col>
      ))
    }

    // no user
    if (pagesArray.length === 0) {
      return (
        <Col md={12} style={{ padding: '10rem 1.5rem', textAlign: 'center' }}>
          We couldn't find data for '{search}'<br />
          Make sure the plate no. or UID is spelled and formatted correctly
        </Col>
      )
    }
  }

  /* RENDER THE PAGINATION BAR */
  _renderPagination = () => {
    const { users, search, currentPage, view_user_count: limit, chunkArray, handlePageChange } = this.props

    const filteredData = users.filter((data) => (data.name !== undefined) && data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    const pageCount = 5
    const pagesArray = chunkArray(filteredData, limit)

    return (
      <Pagination
        total={pagesArray.length * limit}
        limit={limit}
        pageCount={pageCount}
        currentPage={currentPage}
      >
        {({
          pages,
          currentPage,
          hasNextPage,
          hasPreviousPage,
          previousPage,
          nextPage,
          getPageItemProps,
        }) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                color: 'white',
              }}
            >
              {hasPreviousPage ? (
                <div
                  style={{ padding: '5px 10px' }}
                  {...getPageItemProps({
                    pageValue: previousPage,
                    onPageChange: handlePageChange,
                  })}
                >
                  {'<'}
                </div>
              ) : (
                  <div style={{ padding: '5px 10px' }}>{'<'}</div>
                )}

              {pages.map((page) => {
                let activePage = null
                if (currentPage === page) {
                  activePage = { fontWeight: '800', textDecoration: 'none' }
                }
                return (
                  <div
                    style={{
                      padding: '5px 10px',
                      textDecoration: 'underline',
                      ...activePage,
                    }}
                    key={page}
                    // style={activePage}
                    {...getPageItemProps({
                      pageValue: page,
                      onPageChange: handlePageChange,
                    })}
                  >
                    {page}
                  </div>
                )
              })}

              {hasNextPage ? (
                <div
                  style={{ padding: '5px 10px' }}
                  {...getPageItemProps({
                    pageValue: nextPage,
                    onPageChange: handlePageChange,
                  })}
                >
                  {'>'}
                </div>
              ) : (
                  <div style={{ padding: '5px 10px' }}>{'>'}</div>
                )}
            </div>
          )
        }
      </Pagination>
    )
  }

  /* TO RENDER THE CONTENT BODY */
  _renderContent = () => {
    const { load } = this.props
    // Loading...
    if (load === '1') {
      return (
        <div style={{ padding: '65px 25px' }}>
          <div className='loader'></div>
        </div>
      )
    }
    if (load === '2') {
      return (
        <div>
          <Row className='mt-3'>{this._renderUsersList()}</Row>
          <div
            style={{ padding: '5px 15px', margin: '0 -1.25rem -1.25rem', background: 'rgb(521,125,0)', borderRadius: '0 0 1rem 1rem', }}  >
            {this._renderPagination()}
          </div>
        </div>
      )
    }
    if (load === '3') {
      return (
        <div className='text-center' style={{ padding: '45px 25px' }}>
          No users to display
        </div>
      )
    }
  }

  render() {
    return this._renderContent()
  }
}
