import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import CarPartRatings from 'components/CarPartRatings.jsx';

class ChecklistDisplay extends Component {
    constructor(props) {
        super(props);
        
    }

    _renderServiceDetails = () => {
        let display;

        display =
        <Row>
            <Col md={6}>
                <p>VIN Number : {this.props.data.checklist.VIN_number}</p>
                <p>Car Mileage : {this.props.data.checklist.customer_car_mileage}</p>
                <p>Road Tax Expiry : {this.props.data.checklist.roadtax_expiry_date}</p>
            </Col>
            <Col md={6}>
                <p>Next recommended service due at : <br/>
                    <span style={{padding:'5px 15px'}}>{this.props.data.checklist.customer_car_mileage_next_due} or {new Date(this.props.data.checklist.next_service_date).toLocaleDateString()}</span>
                </p>
            </Col>
        </Row>

        return display;
    }

    _renderChecklist = () => {
        let display, LEFT = [], RIGHT = [];
        let keys = Object.keys(this.props.data.checklist)
        
        keys.forEach((checklist_item, index) => {
            if(checklist_item !== 'VIN_number' &&
                checklist_item !== 'customer_car_mileage' &&
                checklist_item !== 'customer_car_mileage_next_due' &&
                checklist_item !== 'next_service_date' &&
                checklist_item !== 'roadtax_expiry_date') {
                    if(index <= 16) {
                        LEFT.push(
                            <CarPartRatings key={index} condition={this.props.data.checklist[checklist_item]} name={checklist_item} />
                        )
                    }
                    if(index > 16) {
                        RIGHT.push(
                            <CarPartRatings key={index} condition={this.props.data.checklist[checklist_item]} name={checklist_item} />
                        )
                    }
                }
        })

        display =
        <Row>
            <Col md={6}>{LEFT}</Col>
            <Col md={6}>{RIGHT}</Col>
        </Row>

        return display;
    }

    render() {
        return (
            <div>
                <div>
                    <h5 >Service Details</h5>
                    {this._renderServiceDetails()}
                </div>

                <hr style={{borderColor:'rgb(169,169,169)'}}/>

                <div>
                    <h5 >Checklist</h5>
                    {this._renderChecklist()}
                </div>
            </div>
        );
    }
}

ChecklistDisplay.propTypes = {
    data: PropTypes.object.isRequired,
    parentFetch: PropTypes.func,
};

export default ChecklistDisplay;