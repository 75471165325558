import React, { Component } from 'react'
import { Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Input, InputGroupAddon, InputGroupText, InputGroup, } from 'reactstrap'
import classnames from 'classnames';

import { PanelHeader } from 'components'
import CardAnalyticsChart from 'components/CardAnalyticsChart';
import TransactionsTableTurkey from './TransactionTableTurkey';
import TransactionAnalyticsChart from './TransactionAnalyticsChart';
import TransactionsReportTurkey from './TransactionsReportTurkey';

import { inject, observer } from 'mobx-react';
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js';
import ErrorAlert from 'components/ErrorAlert';
import firebase from 'firebase';
// import WebWorker from 'workers/workerSetup.js';
// import workerFetchMerchantSummary from '../workers/workerFetchMerchantSummary';


export default class TransactionsTurkey extends Component {
    state = {
        load: '1',
        load_values: false,
        activeTab: '1',
        search: '',
        transactions: {},
        workshops: {},
        analytics: {},
        analytics_values: { allMonth: {}, billplzMonth: {}, cashMonth: {} },
        data: [],
        data_settled: [],
        toggle: false,
        success: false,
        reload: false
    }
    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    componentDidMount() {
        this.fetchTransactions()
        this.fetchMerchantSummary()
        // this.fetchWorkshops()
    }
    fetchTransactions = () => {
        let URL, csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config
        let COUNTRY = this.props.mobx_auth.COUNTRY

        if (browser === 'safari' || browser === 'chrome') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopPaymentAdmin
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopPaymentAdmin
        }
        let LOCATION = `location=TR`
        let OPTIONS = { credentials: 'include', headers: headerToken }
        const SENT_LOCATION = URL + LOCATION
        fetch(SENT_LOCATION, OPTIONS).then(response => {
            if (response.status !== 200) {
                
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            
            if (response.message === 'OK' && response !== 401) {
                // transactions:response.data.billplz_transactions
                console.log('fetchTransaction turkey', response.data);
                this.setState({ transactions: response.data.transactions, analytics: response.data.analytics }, () => {
                    // console.log("analytics", this.state.analytics);
                    this.fetchWorkshops()
                })
            } else {
                this.setState({ load: '3' })
            }
        })
    }
    fetchWorkshops = () => {
        let URL, csrfTokenU, csrfSecretU, headerToken
        const { browser } = this.props.mobx_config
        let COUNTRY = this.props.mobx_auth.COUNTRY
        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopFetch
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopFetch
        }
        let LOCATION = `location=TR`
        // let LOCATION = `location=${}`
        let OPTIONS = { credentials: 'include', headers: headerToken }
        const SENT_LOCATION = URL + LOCATION
        fetch(SENT_LOCATION, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                let WORKSHOPS = {}
                let workshop = response.workshop
                let workshop_main = response.workshop_main
                

                if (response.workshop !== null && response.workshop !== undefined) {
                    for (const retail_main in workshop_main) {
                        let CURRENT = workshop_main[retail_main]
                        CURRENT.outlets = workshop[retail_main]
                        

                        WORKSHOPS[retail_main] = CURRENT
                    }

                    

                    this.setState({ workshops: WORKSHOPS }, () => {
                        this.sortData()
                    })
                } else {
                    this.setState({load:'3'})
                }
            }
        })
    }
    fetchMerchantSummary = () => {
        let URL, csrfTokenU, csrfSecretU, headerToken
        const { browser } = this.props.mobx_config

        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
        if (this.props.mobx_config.config === 0) {
            // URL = `http://localhost:5000/servuserplayground/asia-east2/merchantSummary`
            URL = serv_user_development.merchantSummary
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.merchantSummary
        }

        let LOCATION = `location=TR`
        let OPTIONS = { credentials: 'include', headers: headerToken }
        const SENT_LOCATION = URL + LOCATION
        let RESPONSE = {}
        fetch(SENT_LOCATION, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
           
            if (response !== 401) {
                this.setState({ analytics_values: response.data, load_values: true })
                console.log("fetch merchant TURKEY", this.state.analytics_values)
            }
        })
    }

 
    sortData = async() => {
        const { transactions, workshops } = this.state
        console.log("transaction,", transactions)
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let unsettled_rows = [], settled_rows = []
        let i = 0
        for (const key in transactions) {
            let SPLIT = key.split('|')
            let RETAIL_MAIN = SPLIT[0]
            let RETAIL_ID = SPLIT[1]
            console.log("request id", RETAIL_MAIN, RETAIL_ID)
            if (workshops[RETAIL_MAIN] !== undefined && workshops[RETAIL_MAIN].outlets[RETAIL_ID]) {
                let WORKSHOP_MAIN = workshops[RETAIL_MAIN]
                let WORKSHOP_OUTLET = WORKSHOP_MAIN.outlets[RETAIL_ID]
                let CURRENT = transactions[key]
                let MDR = WORKSHOP_MAIN.mdr !== undefined ? WORKSHOP_MAIN.mdr : 1.5
                let request, requestID
                for (const id in CURRENT) {
                    let CURRENT_DATE = new Date(CURRENT[id].pay_date).toLocaleDateString()
                    let TOTAL_MDR = parseFloat(((MDR * parseFloat(CURRENT[id].amount)) / 100).toFixed(2))
                    let NET_AMOUNT = parseFloat((parseFloat(CURRENT[id].amount) - TOTAL_MDR).toFixed(2))
                    // console.log(RETAIL_MAIN, RETAIL_ID);
                    // console.log(CURRENT[id]);
                    
                    if (CURRENT[id].paid === undefined || CURRENT[id].paid === false) {
                        await firebase.app('TR').database().ref(`history_walkin/${RETAIL_MAIN}/${RETAIL_ID}/${id}/`).once('value').then( (snap2) => {
                            if (snap2.exists()){
                                request = snap2.val()._requestID
                                requestID = request
                                
                            }
                        })
                        await firebase.app('TR').database().ref(`request_walkin/${RETAIL_MAIN}/${RETAIL_ID}/${id}/`).once('value').then((snap) => {
                            if (snap.exists()){
                                request = snap.val()._requestID
                                requestID = request
                            }
                        })
                    
                        let a = {
                            id: RETAIL_ID,
                            main: RETAIL_MAIN,
                            name: WORKSHOP_OUTLET.name,
                            timestamp: new Date(CURRENT[id].pay_date),
                            date: CURRENT_DATE,
                            count: CURRENT[id].count,
                            total_amount: CURRENT[id].amount,
                            mdr: MDR,
                            total_mdr: TOTAL_MDR,
                            net_amount: NET_AMOUNT,
                            booking_id: id,
                            settled: CURRENT[id].paid,
                            type: CURRENT[id].type,
                            requestID: requestID
                        }
                        unsettled_rows.push(a)
                    } else {
                        let a = {
                            id: RETAIL_ID,
                            main: RETAIL_MAIN,
                            name: WORKSHOP_OUTLET.name,
                            timestamp: new Date(CURRENT[id].pay_date),
                            date: CURRENT_DATE,
                            count: CURRENT[id].count,
                            total_amount: CURRENT[id].amount,
                            mdr: MDR,
                            total_mdr: TOTAL_MDR,
                            net_amount: NET_AMOUNT,
                            booking_id: id,
                            settled: CURRENT[id].paid,
                            type: CURRENT[id].type,
                        }
                        settled_rows.push(a)
                    }
                }
            }
        }
        // console.log(unsettled_rows)
        let SORT_1 = unsettled_rows.sort((a, b) => b.timestamp - a.timestamp)
        let SORT_2 = settled_rows.sort((a, b) => b.timestamp - a.timestamp)
        // console.log('UNSETTLED',SORT_1);
        // console.log('SETTLED',SORT_2);

        this.setState({ data: SORT_1, data_settled: SORT_2, load: '2' })
    }
    
    // _renderTable = () => {
    //     let display
    //     // const { data, data_settled } = this.state
    //     console.log("transaction turkey", this.state.data)
    //     let filteredData = this.state.data.filter(
            
    //         (data) => {
    //             return data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
    //         }
    //     );
    //     let filteredData_settled = this.state.data_settled.filter(
    //         (data) => {
    //             return data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
    //         }
    //     );

    //     display =
    //         <Card className='shadow'>
    //             <CardHeader style={{ paddingLeft: '0', paddingRight: '0' }}>
    //                 <Nav tabs style={{ paddingLeft: '15px', paddingRight: '15px' }}>
    //                     <NavItem>
    //                         <NavLink className={classnames({ active: this.state.activeTab === '1' })} style={{ textTransform: 'uppercase', fontSize: '1.2rem' }} onClick={() => { this.toggleTab('1'); }} >
    //                             Standing Payments
    //                     </NavLink>
    //                     </NavItem>
    //                     <NavItem>
    //                         <NavLink className={classnames({ active: this.state.activeTab === '2' })} style={{ textTransform: 'uppercase', fontSize: '1.2rem' }} onClick={() => { this.toggleTab('2'); }} >
    //                             Settled Payments
    //                     </NavLink>
    //                     </NavItem>
    //                 </Nav>

    //                 <div className="p-2">
    //                     <div style={{ width: '75%' }}>
    //                         <InputGroup className="no-border mb-0">
    //                             <Input name="search" placeholder="Search by merchant name" onChange={this.handleChange} />
    //                             <InputGroupAddon addonType="append">
    //                                 <InputGroupText>
    //                                     <i className="now-ui-icons ui-1_zoom-bold" />
    //                                 </InputGroupText>
    //                             </InputGroupAddon>
    //                         </InputGroup>
    //                     </div>
    //                 </div>
    //             </CardHeader>

    //             <CardBody style={{ paddingLeft: '0', paddingRight: '0', paddingTop: '0' }}>
    //                 <TabContent activeTab={this.state.activeTab} style={{ padding: '0' }}>
    //                     <TabPane tabId="1">
    //                         <TransactionsTableTurkey data={filteredData} header='Unsettled Payments' unsettled parentRefresh={this.fetchTransactions} />
    //                     </TabPane>
    //                     <TabPane tabId="2">
    //                         <TransactionsTableTurkey data={filteredData_settled} header='Settled Payments' parentRefresh={this.fetchTransactions} />
    //                     </TabPane>
    //                 </TabContent>
    //             </CardBody>
    //             {/* <CardFooter></CardFooter> */}
    //         </Card>

    //     return display
    // }
    _renderGMV = () => {
        let display

        if (this.state.load === '2') {
            display = <TransactionAnalyticsChart title="Total Value of Transaction" data={this.state.analytics_values} load={this.state.load_values} name="RM" icon="dollar-sign" />
        }

        return display
    }
    _renderAnalytics = () => {
        let display

        if (this.state.load === '2') {
            // display = <TransactionAnalytics data={this.state.analytics} />
            display = <CardAnalyticsChart data={this.state.analytics.data} title="Transactions" name="Txns" icon="user" load={this.state.load_values} />
        }

        return display
    }
    _renderSettlement = () => {
        let display

        if (this.state.load === '1') {
            display = <Card style={{ padding: '45px 25px' }} className="text-center"><div className="loader"></div></Card>
        }
        if (this.state.load === '2') {
            display = this._renderTable()
        }
        if (this.state.load === '3') {
            display = <Card style={{ padding: '95px 25px' }} className="text-center">No data available</Card>
        }

        return display
    }
    _renderReport = () => {
        let display

        if (this.state.load === '2') {
            display = <TransactionsReportTurkey workshop={this.state.workshops} />
        }

        return display
    }
    render() {
        return (
            <div>
                <PanelHeader size='sm' />
                <div className='content'>
                    {this._renderGMV()}
                    {this._renderAnalytics()}
                    {/* {this._renderSettlement()} */}
                    {this._renderReport()}
                </div>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        )
    }
}
TransactionsTurkey = inject('mobx_config', 'mobx_auth')(observer(TransactionsTurkey))