import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Line } from "react-chartjs-2";

export default class Charts extends Component {
    static propTypes = {
        data: PropTypes.any,
        options: PropTypes.object,
        height: PropTypes.string,
        labels: PropTypes.string,
        hover_label: PropTypes.string,
    }

    render() {
        const draw = (canvas) => {
            var ctx = canvas.getContext("2d");
            // createLinearGradient(x0,y0,x1,y1)
            var gradientFill = ctx.createLinearGradient(0,250,0,80);
            gradientFill.addColorStop(0, "rgba(132, 210, 239, 0)");
            gradientFill.addColorStop(1, "rgba(132, 210, 239, 0.40)");

            return {
                labels: this.props.data.labels,
                datasets: [
                    {
                        label: this.props.label,
                        data: this.props.data.values,
                        pointRadius: 7.5,
                        borderWidth: 1,
                        borderColor: "#000080",
                        pointBorderColor: "#000080",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 1,
                        pointRadius: 3,
                        fill: true,
                        backgroundColor: gradientFill,
                    }
                ]
            }
        }

        return (
            <div className="chart-area" style={{height:this.props.height}}>
                <Line
                    data={draw}
                    options={this.props.options}
                />
            </div>
        )
    }
}
