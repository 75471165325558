import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, CardHeader, CardBody, CardImg, CardTitle,
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { EditPromotions } from 'components';
import firebase from 'firebase';

// ICONS
import promo from 'assets/img/promo-purp.svg'
import { inject, observer, propTypes } from 'mobx-react';

class DisplayPromotions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modalDelete : false,
      modalEdit : false,
      dataDelete : {},

      promo_code : '',
      deduct_RM : 0,
      usage : 0,

      oldCode : '',

    }
  }
  toggleDelete = () => {
    this.setState({modalDelete: !this.state.modalDelete})
  }
  _delete = () => {
    let that = this;
    let HISTORY = {};
    // console.log(this.state.dataDelete);
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    firebase.app(COUNTRY_DB).database().ref('promo_code/' + this.state.dataDelete.promo_code).once('value').then((snapshot) => {
      if(snapshot.exists()) {
        HISTORY = snapshot.val()
        // console.log(HISTORY);
      } else {
        alert('Promo code doesnt exist.')
      }
    }).then(() => {
      firebase.app(COUNTRY_DB).database().ref('history_promo_code/' + this.state.dataDelete.promo_code).update(HISTORY)
    }).then(() => {
      firebase.app(COUNTRY_DB).database().ref('promo_code/' + this.state.dataDelete.promo_code).remove()
    }).then(() => {
      that.setState({
        modalDelete : !that.state.modalDelete,
        dataDelete : '',
      }, () => {this.props.fetchParent()})
    })
  }
  _renderContent = () => {
    let content;

    if(this.props.load === '1') {
      content =
        <Card className="text-center">
          <CardBody>
            <div className="loader"></div>
          </CardBody>
        </Card>
    }
    if(this.props.load === '2') {
      content = this.props.passData.map((data, key) =>
        <Row key={key} style={{justifyContent:'center',alignItems:'center'}}>
          <Col xs={12} md={12}>
            <Card className="text-center" style={{borderRadius:'5px', borderLeft: '5px ridge #7163b8', boxShadow:'0 5px 10px rgba(27,27,27,0.7)'}}>
              <CardBody style={{display:'flex',justifyContent:'space-around',alignItems:'center', padding:'15px'}}>

                <div style={{width:'75%', alignItems:'center', display:'flex', justifyContent:'flex-start'}}>
                  {
                    data.deduct_RM !== undefined ?
                    <span className="d-flex align-items-center">
                      RM <span style={{fontSize:'45px', fontWeight:'bold'}}>{data.deduct_RM}</span>
                    </span> :
                    <span className="d-flex align-items-center">
                      <span style={{fontSize:'45px', fontWeight:'bold'}}>{data.deduct_percent}</span>%
                    </span>
                  }
                  <span style={{marginRight:'10px',marginLeft:'10px'}}>|</span>CODE : {data.promo_code}
                </div>

                <div style={{width:'25%', alignItems:'center', display:'flex', justifyContent:'center'}}>
                  <EditPromotions data={data} type_data={this.props.type_data} codeCheck={this.props.codeCheck} fetchParent={this.props.fetchParent} />
                  {' '}
                  <Button
                    color="danger"
                    onClick={() => {
                      this.setState({dataDelete : data}, () => {this.toggleDelete()})
                    }}>Delete</Button>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>
      )
    }
    if(this.props.load === '3') {
      content =
        <Card className="text-center">
          <CardBody style={{padding:'65px 15px'}}>
            No promo codes to display, please add promo codes to begin.
          </CardBody>
        </Card>
    }

    return content;
  }
  render () {
    return(
      <div>
        {this._renderContent()}

        <Modal fade={false} isOpen={this.state.modalDelete} toggle={this.toggleDelete} style={{maxWidth:'55%'}}>
          <ModalHeader toggle={this.toggleDelete}>Delete promo code</ModalHeader>
          <ModalBody className="text-center">
            Are you sure you want to delete promo CODE : {this.state.dataDelete.promo_code} ?<br/>
            Deduct value when using code is RM {this.state.dataDelete.deduct_RM}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this._delete}>Delete</Button>{' '}
            <Button color="secondary" onClick={this.toggleDelete}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DisplayPromotions.propTypes = {
  passData: PropTypes.array.isRequired,
  codeCheck: PropTypes.array,
  type_data: PropTypes.object,
  load: PropTypes.string,
  fetchParent: PropTypes.func,
};
export default DisplayPromotions;
DisplayPromotions = inject('mobx_auth')(observer(DisplayPromotions))