import React, { Component } from 'react'
import { PanelHeader } from 'components'
import { Card, Col, Row, Button, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, } from "reactstrap";
import firebase from 'firebase';
import VehicleVerificationDisplay from './VehicleVerificationDisplay';
import workerPlateVerification from 'workers/workerPlateVerification.js';
import WebWorker from 'workers/workerSetup.js';
import { inject, observer } from 'mobx-react';
export default class VehicleVerification extends Component {
    state = {
        data : [],
        load : '1',
        search : '',
    }
    componentDidMount() {
        this.workerPlateVerification = new WebWorker(workerPlateVerification);
        this.fetchVerification()
        // this.fetchPlates()
    }
    handleSearchBar = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    refresh = async () => {
        await this.setState({ load : '1' })
        this.fetchVerification()
    }

    fetchVerification = async () => {
        let TEMP = []
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let DB = firebase.app(COUNTRY_DB).database().ref('plate_number_verification')
        let snapshot = await DB.once('value')
        if(snapshot.exists()){
            TEMP = snapshot.val()
        }

        this.fetchPlates(TEMP)
    }
    fetchPlates = async (verification) => {
        let VERIFICATION = verification, TEMP = []
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let DB = firebase.app(COUNTRY_DB).database().ref('plate_number')

        let snapshot = await DB.once('value')
        if(snapshot.exists()) {
            console.log('sini');

            let PASS = {
                VERIFICATION : verification,
                DATA : snapshot.val()
            }
            this.workerPlateVerification.postMessage(PASS);

            this.workerPlateVerification.addEventListener('message', event => {
                // console.log('WW response', event.data)
                TEMP = event.data
                this.setState({data : TEMP, load : '2'})
            });
            
        } else {
            console.log('tak exits');
        }
    }

    _renderData = () => {
        let display
        const { data, load } = this.state

        if(data.length === 0 || load === '1') return <Card body><div className="loader"/></Card>
        if(load === '3') return <Card body>Nothing to display</Card>

        let FILTERED_DATA = data.filter((item) => {
            if (item.plate !== undefined) {
                return item.plate.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
            }
        })
        
        display = FILTERED_DATA.map((data, index) => {
            return <VehicleVerificationDisplay data={data} key={index} parentRefresh={this.refresh} />
        })

        return display
    }
    

    render() {
        return (
            <div>
                <PanelHeader size='sm' />
                <div className='content'>
                    <div className="position-relative mb-3 d-flex justify-content-between">
                        <InputGroup className='no-border' style={{marginBottom:'0', width:'350px'}}>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={{background:'rgba(255,255,255,1)'}}>
                                    <i className='now-ui-icons ui-1_zoom-bold' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input name="search" style={{background:'rgba(255,255,255,1)'}} placeholder='Search' onChange={this.handleSearchBar}/>
                        </InputGroup>

                        <Button style={{ margin: '0', display: 'inline-flex', alignItems: 'center' }} color="light" onClick={this.refresh}>
                            <i style={{ marginRight: '3px', lineHeight: '1' }} className="now-ui-icons arrows-1_refresh-69"></i>
                            <div>Refresh</div>    
                        </Button>
                    </div>
                    <Row>{this._renderData()}</Row>
                </div>
            </div>
        )
    }
}

VehicleVerification = inject('mobx_config','mobx_auth')(observer(VehicleVerification));