import React, { Component } from 'react';
import PropTypes from 'prop-types'
import firebase from 'firebase';
import { ChatBubble } from './ChatBubble.jsx';
import { CONFIG } from 'configs/config.js'
import { inject, observer } from 'mobx-react';
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'
import ErrorAlert from './ErrorAlert.jsx';

class App extends Component {
    static propTypes = {
        servproviderID: PropTypes.string,
    }

    db = firebase.database();
    messageRef = this.db.ref('adminmessages/' + this.props.servproviderID);

    state = {
        newMessage: '',
        messages: {},
        usernameInput: '',
        username: null,
        toggle: false,
        success: false,
        reload: false,
        displayButt: true,
    };

    componentDidMount = () => {
        window.anchor = this.anchor;

        if (this.props.servproviderID !== '') {
            this.messageRef.on('value', (value) => {
                this.setState({ messages: value.val() });
            });
        }

    }

    handleInput = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    renderMessageArea = () => {
        const { messages } = this.state;
        return (
            <div style={messageAreaStyle}>
                {
                    messages && Object.keys(messages).map(key => {
                        return (
                            <div key={key}>
                                <ChatBubble message={messages[key]} />
                            </div>
                        )
                    })
                }
                <div
                    style={{ float: "left", clear: "both" }}
                    ref={(anchor) => this.anchor = anchor}
                />
            </div>
        );
    }
    sendMessage = async () => {
        const { username, newMessage } = this.state;
        const { browser } = this.props.mobx_config

        const key = 'key=' + this.props.servproviderID;
        const text = '&text=' + newMessage;
        const idToken = await firebase.auth().currentUser.getIdToken()

        let chatURL, csrfTokenP, csrfSecretP, headerToken;

        if (browser === 'safari') {
            csrfTokenP = sessionStorage.getItem('CSRFTokenP')
            csrfSecretP = sessionStorage.getItem('CSRFSecretP')
            headerToken = { csrftoken: csrfTokenP, csrfsecret: csrfSecretP }
        }

        let headers = { Authorization: `Bearer ${idToken}`, ...headerToken }
        let OPTIONS = { credentials: 'include', headers }

        if (CONFIG === 0) chatURL = serv_provider_development.newChatAdmin
        if (CONFIG === 1) chatURL = serv_provider_production.newChatAdmin

        
        await this.setState({ displayButt: false })
        const response = await fetch(chatURL + key + text, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
        })
        if (response !== 401) {
            this.messageRef.push({
                from: username,
                text: newMessage,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                "user": {
                    _id: this.props.mobx_auth.FUID,
                    name: "Admin",
                }
            });
            // this.anchor.scrollIntoView({behavior: 'smooth'});
            this.setState({ newMessage: '', displayButt: true });
        }
    }

    renderInputStrip() {
        const { displayButt, newMessage } = this.state
        return (
            <div style={inputStripStyle}>
                <input
                    style={{ ...usernameTextInputStyle, width: '70%' }}
                    type="text"
                    name="newMessage"
                    onChange={this.handleInput}
                    value={newMessage}
                    onKeyPress={(event) => { event.key === 'Enter' && this.sendMessage() }}
                />
                {displayButt && <button style={{ ...buttonStyle, width: '15%' }} onClick={this.sendMessage}>Send</button>}
                {!displayButt && <button disabled style={{ ...buttonStyle, width: '15%' }} className="d-flex align-items-center justify-content-center" onClick={this.sendMessage}>
                    <div>Sending</div>
                    <div className="buttonloader ml-2 inline"></div>
                </button>}
            </div>
        );
    }
    render() {
        return (
            <div>
                <div style={{ height: '400px' }}>
                    {this.renderMessageArea()}
                </div>
                <div>
                    {this.renderInputStrip()}
                </div>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        );
    }
}
App = inject('mobx_quote', 'mobx_auth', 'mobx_config')(observer(App))
const containerStyle = {
    fontFamily: 'Open Sans',
    backgroundColor: '#fff',
    width: '200px',
    height: '100px',
};
const messageAreaStyle = {
    width: '100%',
    height: 'inherit',
    backgroundColor: 'rgba(64, 64, 75, 0.5)',
    borderRadius: '15px 15px 0 0',
    padding: '20px',
    overflowY: 'auto',
    marginBottom: '100px'
};
const usernameFormStyle = {
    zIndex: 100,
    position: 'fixed',
    backgroundColor: '#efefef',
    width: '200px',
    height: '100px',
    padding: '30px 15px',
    textAlign: 'center'
};
const usernameTextInputStyle = {
    borderRadius: '25px',
    padding: '5px 10px',
    border: 'none',
    outline: 'none',
    fontSize: '20px',
    fontFamily: 'Open Sans',
    background: 'rgba(255,255,255,0.5)',
    color: 'white',
    fontFamily: 'inherit'
}
const buttonStyle = {
    backgroundColor: '#000080',
    color: 'white',
    border: 'none',
    borderRadius: '25px',
    fontSize: '14px',
    outline: 'none',
    padding: '8px 12px'
}
const inputStripStyle = {
    //position: 'fixed',
    bottom: '0px',
    width: '100%',
    backgroundColor: '#fefefe',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    backgroundColor: '#00004c',
    borderRadius: '0 0 15px 15px'
}
export default App;
