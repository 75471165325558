import React from 'react'
import PropTypes from 'prop-types'
import {
  Row, Col, Card, CardHeader, CardBody, CardImg, CardTitle,
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, FormText
} from "reactstrap";
import { PanelHeader } from "components";

import DisplayPromotions from 'pages/DisplayPromotions.jsx'
import { inject, observer } from 'mobx-react';
import firebase from 'firebase';

import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'
import AddPromotions from 'components/AddPromotions.jsx';
import ErrorAlert from 'components/ErrorAlert.jsx';

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      type_data: {},
      codeCheck: [],
      load: '1',

      promo_code: '',
      deduct_RM: 0,
      toggle: false,
      success: false,
      reload: false
    }

  }
  componentDidMount() {
    this.fetch()
  }
  childToggle = () => this.setState({ toggle: false }, () => {
    if (this.state.reload === true) {
      sessionStorage.removeItem('CT')
      document.location.reload()
    }
  })
  fetch = () => {
    let that = this;
    let DATA = [];
    let COUNTRY_DB = this.props.mobx_auth_COUNTRY

    firebase.app(COUNTRY_DB).database().ref('promo_code/').once('value').then((snapshot) => {
      if (snapshot.exists()) {
        let keys = Object.keys(snapshot.val())
        // console.log(keys);

        that.setState({ codeCheck: keys })

        keys.forEach((promo_code) => {
          let a = snapshot.val()[promo_code]
          a.promo_code = promo_code
          // console.log(a);
          DATA.push(a)
        })

        that.setState({ data: DATA }, () => { console.log(this.state.data) })
      } else {
        console.log('No promo code set up yet', DATA);
        this.setState({ load: '3', data: DATA })
      }
    }).then(() => {
      let URL;

      let csrfTokenU, csrfSecretU, headerToken;
      const { browser } = this.props.mobx_config

      if (browser === 'safari') {
        csrfTokenU = sessionStorage.getItem('CSRFTokenU')
        csrfSecretU = sessionStorage.getItem('CSRFSecretU')
        headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
      }

      if (this.props.mobx_config.config === 0) {
        URL = serv_user_development.fetchUserType;
      }
      if (this.props.mobx_config.config === 1) {
        URL = serv_user_production.fetchUserType;
      }
      let LOCATION = `location=${COUNTRY_DB}`
      let OPTIONS = { credentials: 'include', headers: headerToken }
      const SENT_LOCATION = URL + LOCATION
      fetch(SENT_LOCATION, OPTIONS).then(response => {
        if (response.status !== 200) {
          this.setState({ toggle: true, success: false, reload: true })
          return response.status
        }
        return response.json();
      }).then(response => {
        if (response !== 401) {
          console.log(response.data)
          this.setState({ type_data: response.data, load: this.state.data.length !== 0 ? '2' : '3' }, () => {
            console.log(this.state.data);
          })
        }
      })
    })
  }
  _refresh = () => {
    this.fetch();
  }

  render() {

    return (
      <div>
        <div>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} md={4}>
                <AddPromotions parentRefresh={this._refresh} codeCheck={this.state.codeCheck} type_data={this.state.type_data} />
              </Col>
              <Col xs={12} md={8}>

                <DisplayPromotions passData={this.state.data} codeCheck={this.state.codeCheck} type_data={this.state.type_data} load={this.state.load} fetchParent={this.fetch} />

              </Col>
            </Row>
          </div>
        </div>
        <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
      </div>
    );
  }
}

Promotions = inject('mobx_config','mobx_auth')(observer(Promotions));
export default Promotions;
