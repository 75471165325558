import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { createHashHistory } from 'history'
const history = createHashHistory()

export default class UsersListGrid extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,   // the user data from parent
    max: PropTypes.number,    // for bottom border in the list view
    current: PropTypes.number,// for the padding in list view
    checked: PropTypes.bool,  // either list or grid
  }

  /* BUTTON TO SHOW DETAILS OF SELECTED USER */
  view = () => history.push(`/user/Users-List/${this.props.data.key}`)
  
/* RENDER USER CARD */
  _renderContent = () => {
    const { data, max, current, checked } = this.props

    // LIST VIEW
    if (checked) {
      return (
        <Row style={{ paddingBottom: '15px', paddingTop: current === 0 ? '0' : '15px', borderBottom: max !== current ? '1px solid' : 'none', }} onClick={this.view} className='align-items-center'>
          <Col md={1} style={{}}>
            <div style={{ width: '2em', height: '2em', padding: '8px 10px', margin: '0 auto', textAlign: 'center', fontSize: '1.1em', fontWeight: 'bold', background: 'rgb(0, 0, 128)', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgba(255,255,255,0.7)', }}>
              {data.name.substr(0, 2).toUpperCase()}
            </div>
          </Col>
          <Col md={4}>{data.name}</Col>
          <Col md={3}>{data.email}</Col>
          <Col className='text-right' md={4}>
            <div>View profile</div>
          </Col>
        </Row>
      )
    }

    // GRID VIEW
    return (
      <div className='card-users' style={{ border: '1px solid rgba(0,0,0,0.4)', borderRadius: '1rem', padding: '15px' }} onClick={this.view} >
        <Row style={{ margin: '0 -15px 15px' }}>
          <Col md={4} style={{ borderRight: '1px solid' }}>
            <div style={{ width: '4em', height: '4em', padding: '8px 10px', margin: '0 auto', textAlign: 'center', fontSize: '1.5em', fontWeight: 'bold', background: 'rgb(0, 0, 128)', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgba(255,255,255,0.7)' }}>
              {data.name.substr(0, 2).toUpperCase()}
            </div>
          </Col>
          <Col md={8}>
            <div style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', fontSize: '1.2em', lineHeight: '1.2em', maxHeight: '2.2em', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}>
              {data.name}
            </div>
            <div>{data.email}</div>
          </Col>
        </Row>

        <div className='d-flex justify-content-between align-items-center' style={{ borderTop: '1px solid', paddingTop: '15px', cursor: 'pointer' }}>
          <div>View profile</div>
        </div>
      </div>
    )
  }

  render() {
    return this._renderContent()
  }
}
