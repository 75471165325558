import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Line } from "react-chartjs-2";

export default class Charts extends Component {
    static propTypes = {
        datasets: PropTypes.array,
        options: PropTypes.object,
        height: PropTypes.string,
        labels: PropTypes.array,
        hover_label: PropTypes.string,
    }

    render() {
        const draw = (canvas) => {
            var ctx = canvas.getContext("2d");
            // createLinearGradient(x0,y0,x1,y1)
            var gradientFill = ctx.createLinearGradient(0,250,0,80);
            gradientFill.addColorStop(0, "rgba(132, 210, 239, 0)");
            gradientFill.addColorStop(1, "rgba(132, 210, 239, 0.40)");

            return {
                labels: this.props.labels,
                datasets: this.props.datasets
            }
        }

        return (
            <div className="chart-area" style={{height:this.props.height}}>
                <Line
                    data={draw}
                    options={{...this.props.options, legend:{display:true, position:'right'}}}
                />
            </div>
        )
    }
}
