import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import firebase from 'firebase';
import { inject, observer } from 'mobx-react';
import keywords from 'components/keywords.js';

class AddPromotions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAdd : false,
            // true = not specific, false = specific
            general: '',
            codeCheck : [],
            promo_code: '',
            deduct_RM: 0,
            deduct_percent: 0,
            user_type: '',
            user_tier: '',
            keyword: '',
            body: '',
        };
    }
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    handleChangeUpperCase = (evt) => {
        let SPACE = evt.target.value.split(" ").join("");
        let AMPH = SPACE.split('&').join("");
        let HASH = AMPH.split('#').join("");
        let PERC = HASH.split('%').join("");
        let BRACK_R = PERC.split(')').join("");
        let BRACK_L = BRACK_R.split('(').join("");
        let AST = BRACK_L.split('*').join("");
        let DOLLA = AST.split('$').join("");
        let EXC = DOLLA.split('!').join("");
        const VALUE = EXC.toUpperCase();

        this.setState({ [evt.target.name]:  VALUE });
    }
    _toggleAdd = () => {
       
        this.setState({
            modalAdd: !this.state.modalAdd,
            codeCheck: this.props.codeCheck,
            user_type: '',
            user_tier: '',
            promo_code: '',
            deduct_RM: 0,
            deduct_percent: 0,
            keyword: '',
            body: '',
        })
    }
    _save = () => {
        let that = this;
        let promo_code = this.state.promo_code.toUpperCase();
        let ErrorHandler = this.state;
        // 1. check if promo code empty
        if(ErrorHandler.promo_code === ''){
            alert('Please fill in promo code!');
            return;
        }
        // 2. check if promo code already exists
        if (this.state.codeCheck.indexOf(promo_code) !== -1){
            alert('Promo code already exists!!');
            return;
        }
        // 3. make sure general not empty
        if(ErrorHandler.general === ''){
            alert('Please pick if promo code can be used generally or for specific business users!')
            return;
        }
        // 3a. no business, deduct rm zero
        if(ErrorHandler.general === 'no business' && ErrorHandler.deduct_RM === 0){
            alert('Please specify amount deducted from promo code!')
            return;
        }
        // 3b-1. specific business, user type and user tier empty string
        if(ErrorHandler.general === 'specific business' && (ErrorHandler.user_type === '' || ErrorHandler.user_tier === '')){
            alert('Please specify business type and tier!')
            return;
        }
        // 3b-2. specific business, deduct percent zero
        if(ErrorHandler.general === 'specific business' && ErrorHandler.deduct_percent === 0){
            alert('Please specify percentage deducted from promo code!')
            return;
        }
        // 4. check keyword
        if(ErrorHandler.general === 'specific business' && ErrorHandler.keyword === ''){
            alert('Please specify promo code keyword!')
            return;
        }
        // 5. check body
        if(ErrorHandler.general === 'specific business' && ErrorHandler.body === ''){
            alert('Please specify promo code description!')
            return;
        }

        let DATA = {};
        if(this.state.general === 'no business'){
            DATA = {
                deduct_RM : this.state.deduct_RM,
                usage : 0,
                user_type: '',
                user_tier: '',
                description: {
                    keyword: '',
                    body: '',
                }
            }
        }
        if(this.state.general === 'specific business'){
            DATA = {
                deduct_percent : this.state.deduct_percent,
                usage: 0,
                user_type: this.state.user_type,
                user_tier: this.state.user_tier,
                description: {
                    keyword: this.state.keyword,
                    body: this.state.body,
                }
            }
        }

        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        firebase.app(COUNTRY_DB).database().ref('promo_code/' + promo_code).set(DATA).then(() => {
            that._toggleAdd()
            that.props.parentRefresh()
        })
    }
    _renderType = () => {
        let display;

        if(this.state.promo_code !== ''){
            display =
            <div>
                <Label>Is this promo code for specific Business Type Users?</Label>
                <FormGroup check>
                    <Label>
                        <Input type="radio" name="general" checked={this.state.general === 'no business'} value='no business' onChange={(e) => {
                            this.setState({deduct_RM:0,deduct_percent:0})
                            this.handleChange(e)
                        }} />{' '}
                        No
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label>
                        <Input type="radio" name="general" checked={this.state.general === 'specific business'} value='specific business' onChange={(e) => {
                            this.setState({deduct_RM:0,deduct_percent:0})
                            this.handleChange(e)
                        }} />{' '}
                        Yes
                    </Label>
                </FormGroup>

                {this._renderTypeTier()}
            </div>
        }

        return display;
    }
    _renderTypeTier = () => {
        let display;

        let TYPE = this.props.type_data.type.map((data,index) => (
            <option key={index} value={data.id}>{data.name}</option>
        ))
        TYPE.unshift(
            <option key={this.props.type_data.type.length + 1} value={''}>--Select--</option>
        )
        let TIER = [], TIERSELECT;
        if(this.state.user_type !== ''){
            for(const tier_id in this.props.type_data.tiers[this.state.user_type]) {
                TIER.push(
                    <option key={tier_id} value={tier_id}>{this.props.type_data.tiers[this.state.user_type][tier_id].name}</option>
                )
            }
            TIER.unshift(
                <option key={TIER.length+1} value={'all'}>All Tiers</option>
            )
            TIER.unshift(
                <option key={TIER.length+2} value={''}>--Select--</option>
            )

            TIERSELECT =
            <FormGroup>
                <Label for="user_tier">Select Tier</Label>
                <Input type="select" name="user_tier" id="user_tier" value={this.state.user_tier} onChange={this.handleChange}>
                    {TIER}
                </Input>
                <FormText className="muted"><small>{this.state.user_tier}</small></FormText>
            </FormGroup>
        }

        let TYPESELECT =
        <FormGroup>
            <Label for="user_type">Select Business Type</Label>
            <Input type="select" name="user_type" id="user_type" value={this.state.user_type} onChange={this.handleChange}>
                {TYPE}
            </Input>
            <FormText className="muted"><small>{this.state.user_type}</small></FormText>
        </FormGroup>

        // For specific business
        if(this.state.general === 'specific business'){
            display =
            <div>
                {TYPESELECT}

                {TIERSELECT}
    
                {this._renderAmount()}
            </div>
        }
        // Not for specific business
        if(this.state.general === 'no business'){
            display =
            <div>
                {this._renderAmount()}
            </div>
        }
        

        return display;
    }
    _renderAmount = () => {
        let display;

        // Not for specific business
        if(this.state.general === 'no business'){
            display =
            <FormGroup>
                <Label for="deduct_RM">Amount deducted</Label>
                <Input type="number" name="deduct_RM" id="deduct_RM" onChange={this.handleChange} value={this.state.deduct_RM} />
            </FormGroup>
        }
        // For specific business
        if(this.state.general === 'specific business' && this.state.user_type !== ''){
            display =
            <FormGroup>
                <Label for="deduct_percent">Percentage deducted</Label>
                <Input type="number" name="deduct_percent" id="deduct_percent" onChange={this.handleChange} value={this.state.deduct_percent} />
            </FormGroup>
        }
        
        return display;
    }
    _renderDescription = () => {
        let display;
        // || (this.state.general === 'no business' && this.state.deduct_RM !== 0 && this.state.deduct_RM !== '')
        if(this.state.general === 'specific business' && this.state.deduct_percent !== 0 &&this.state.deduct_percent !== ''){
            let options = keywords.map((data,index) => (
                <option key={index} value={data.keyword}>{data.desc}</option>
            ))
            options.unshift(
                <option key={options.length+1} value={''}>--Select--</option>
            )
            display =
            <div>
                <FormGroup>
                    <Label for='body'>Promotion Description</Label>
                    <Input type='text' name='body' maxLength={70} id='body' onChange={this.handleChange} value={this.state.body}/>
                    <FormText className="muted">
                        This is the description that will appear in the 'Perks' section of the SERV User app. <span style={{color:'red', fontWeight:'bold'}}>{70 - this.state.body.length} characters left.</span>
                    </FormText>
                </FormGroup>
                <FormGroup>
                    <Label for='keyword'>Keyword</Label>
                    <Input type='select' name='keyword' id='keyword' onChange={this.handleChange} value={this.state.keyword} >
                        {options}
                    </Input>
                </FormGroup>
            </div>
        }

        return display;
    }
    render() {
        return (
            <div>
                <Button onClick={this._toggleAdd} style={{background:'#00006c', borderRadius: '0', boxShadow:'3px -3px 0 #00004b, 6px -6px 0 #000039', width:'100%', position:'relative', zIndex:'50'}}>Add promo code</Button>

                <Modal fade={false} isOpen={this.state.modalAdd} toggle={this._toggleAdd} className={this.props.className}>
                    <ModalHeader toggle={this._toggleAdd}>Add new promo code</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="promo_code">Promo Code</Label>
                                <Input type="text" name="promo_code" id="promo_code" onChange={this.handleChangeUpperCase} value={this.state.promo_code}/>
                            </FormGroup>

                            {this._renderType()}

                            {this._renderDescription()}
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this._save}>Save</Button>{' '}
                        <Button color="secondary" onClick={this._toggleAdd}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

AddPromotions.propTypes = {
    parentRefresh: PropTypes.func,
    type_data: PropTypes.object,
    codeCheck: PropTypes.array,
};

export default AddPromotions;
AddPromotions = inject('mobx_auth')(observer(AddPromotions))