import React, { Component } from 'react'
import { PanelHeader } from 'components';
import ErrorAlert from 'components/ErrorAlert';
import { Modal, Button, Card, CardHeader, Col, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';

export default class ListingWorkshopAmenitites extends Component {

  state = {
    amenitiesDefault: [],
    amenitiesIcons: [],
    modalAdd: false,
    amenity: '',
    icon: '',
    showIcon: false,
    displayButt: true,
    load: '1'
  }

  componentDidMount = () => {
    this.fetchAllAmenities()
  }

  fetchAllAmenities = async () => {
    const { config, browser } = this.props.mobx_config
    let URL, csrfTokenU, csrfSecretU, headerToken
    
    if (browser === 'safari') {
      csrfTokenU = sessionStorage.getItem('CSRFTokenU')
      csrfSecretU = sessionStorage.getItem('CSRFSecretU')
      headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
    }

    if (config === 0) URL = serv_user_development.workshopAmenities
    if (config === 1) URL = serv_user_production.workshopAmenities

    let OPTIONS = { credentials: 'include', headers: headerToken }
    const response = await fetch(URL, OPTIONS).then(res => res.json())

    const amenitiesList = response.data.list
    const amenitiesIcons = response.data.icons

    if (amenitiesIcons.length !== 0) this.setState({ amenitiesIcons })
    if (amenitiesList.length !== 0) this.setState({ amenitiesDefault: amenitiesList })

    if (response.data.list.length === 0) return this.setState({ load: '3' })
    this.setState({ load: '2' })
  }

  toggleModal = (state) => this.setState({ [state]: !this.state[state] })

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  save = async () => {
    const { amenitiesDefault, amenity, icon } = this.state
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY

    const { config, browser } = this.props.mobx_config
    let URL, FINAL_URL, csrfTokenU, csrfSecretU, headerToken

    if (amenity === '' && icon === '') {
      this.toggleModal('modalAdd')
      this.setState({ displayButt: true })
      return alert('Amenity and icon cannot be emptied!')
    }

    if (browser === 'safari') {
      csrfTokenU = sessionStorage.getItem('CSRFTokenU')
      csrfSecretU = sessionStorage.getItem('CSRFSecretU')
      headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
    }

    const newAmenity = { name: amenity, icon }
    const newAmenitiesList = [...amenitiesDefault]
    newAmenitiesList.push({ ...newAmenity })
    let OPTIONS = { credentials: 'include', headers: headerToken }

    if (config === 0) URL = serv_user_development.workshopAmenitiesAdd
    if (config === 1) URL = serv_user_production.workshopAmenitiesAdd

    FINAL_URL = `${URL}amenities=${JSON.stringify(newAmenitiesList)}&=location${COUNTRY_DB}`

    await fetch(FINAL_URL, OPTIONS).then((response) => {
      if (response.status !== 200) {
        this.setState({ toggle: true, success: false, reload: true })
        return response.status
      }
      response.json()
    }).then(response => {
      this.fetchAllAmenities()
      this.toggleModal('modalAdd')
      this.setState({ displayButt: true })
    })
  }

  _renderAmenitiesList = () => {
    const { amenitiesDefault, load } = this.state
    if (load === '1') {
      return (
        <div className='loader my-5'></div>
      )
    }
    if (load === '3') {
      return (
        <div className='text-center'>
          <p>There is no workshop amenities yet. Please add ONE on the add button above.</p>
        </div>
      )
    }
    if (amenitiesDefault.length !== 0) {
      const list = amenitiesDefault.map((amenity, index) => {
        return (
          <tr>
            <td><i className={`fas fa-${amenity.icon} mr-2`} /> <span>{amenity.name}</span></td>
            {/* <td className="text-right"><Button color='danger'><i className='fas fa-trash' /></Button></td> */}
          </tr>
        )
      })
      return (
        <Table className='align-items-center mb-0 p-0' size='sm' responsive>
          <thead className='thead-light'>
            <tr>
              <th scope='col'>Amenities</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '1.2rem' }}>
            {list}
          </tbody>
        </Table>
      )
    }
  }

  _renderSaveButton = () => {
    let display

    if (this.state.displayButt === true) {
      return (
        <Button color='dark' onClick={() => { this.setState({ displayButt: false }, () => this.save()) }}>Save</Button>
      )
    }
    if (this.state.displayButt === false) {
      return (
        <Button color='dark' disabled className="d-flex align-items-center justify-content-center"><div className="mr-2">Saving</div><div className="btnloader"></div></Button>
      )
    }

    return display
  }

  _renderAddModal = () => {
    const { modalAdd, showIcon } = this.state
    return (
      <Modal isOpen={modalAdd} toggle={() => this.toggleModal('modalAdd')} className='modal-dialog-centered' style={!showIcon ? { overflow: 'hidden' } : {}}>
        <ModalHeader toggle={() => this.toggleModal('modalAdd')}>Add Amenity</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={11}>
              <FormGroup>
                <Label for='amenity'>Amenity</Label>
                <Input type='text' name='amenity' id='amenity' onChange={this.handleChange} placeholder="Type new amenity" />
              </FormGroup>
            </Col>
            <Col md={1} className='pl-0'>
              {this._renderIconsList()}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {this._renderSaveButton()}
          <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalAdd')}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  _renderIconsList = () => {
    const { amenitiesIcons, showIcon, icon } = this.state


    if (amenitiesIcons) {
      const icons = amenitiesIcons.map((icon) => {
        return (
          <DropdownItem id={icon} className='text-center' onClick={(e) => this.setState({ icon: e.target.id })} ><i className={`fas fa-${icon}`} /></DropdownItem>
        )
      })
      return (
        <>
          <Label>Icons</Label>
          <div style={{ height: 100 }}>

            <Dropdown isOpen={showIcon} toggle={() => this.toggleModal('showIcon')} className='m-0 text-center py-2 custom-dropdown' style={{ cursor: 'pointer', border: '#E3E3E3 1px solid', borderRadius: '20px', fontSize: '0.8rem' }}>
              <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={showIcon} >
                {icon === '' ? <span>SELECT</span> : <i className={`fas fa-${icon}`} />}
              </DropdownToggle>
              <DropdownMenu container='body' modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: (data) => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: 'auto',
                        maxHeight: '300px',
                      },
                    };
                  },
                },
              }}>
                <DropdownItem id='' className='text-center' onClick={(e) => this.setState({ icon: e.target.id })} >SELECT</DropdownItem>
                {icons}
              </DropdownMenu>
            </Dropdown>
          </div>
        </>
      )

    }
  }

  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div>
                    <div className="d-flex align-items-center">
                      <h3 className="m-0" style={{ display: "inline-block" }}>Workshop Amenities</h3>
                      <Button color='dark' className='ml-auto' onClick={() => this.toggleModal('modalAdd')}>Add New Amenity</Button>
                    </div>
                    {this._renderAmenitiesList()}
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
        {/* <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} /> */}
        {this._renderAddModal()}
      </div>
    )
  }
}

ListingWorkshopAmenitites = inject('mobx_auth', 'mobx_quote', 'mobx_config')(observer(ListingWorkshopAmenitites));
