import React from 'react';
import {inject, observer} from 'mobx-react'

export class ChatBubble extends React.Component {
  	render() {
		const {message} = this.props;
		if (message.user.name.toString() === "Admin") {
			return (
				<div style={divStyle}>
					<span style={{...chatBubbleStyle,...adminStyle}}>
						<div style={fromStyle}>
							<b>{message.user.name}</b> says:
						</div>
						<div style={messageStyle}>
							{message.text}
						</div>
						<small style={{color:'white', textAlign:'right'}}>{new Date(message.timestamp).toLocaleString()}</small>
					</span>
				</div>
			)
		}
		if (message.user._id.toString() !== this.props.mobx_auth.FUID) {
			return (
				<div>
					<span style={chatBubbleStyle}>
						<div style={fromStyle}>
							<b>{message.user.name}</b> says:
						</div>
						<div style={messageStyle}>
							{message.text}
						</div>
						<small style={{color:'white', textAlign:'left'}}>{new Date(message.timestamp).toLocaleString()}</small>
					</span>
				</div>
			)
		}
  }
}

ChatBubble = inject('mobx_auth')(observer(ChatBubble))
const adminStyle = {
	backgroundColor: '#607d8b',
}

const divStyle ={
	display: 'flex',
	justifyContent: 'flex-end'
}

const chatBubbleStyle = {
	maxWidth:'85%',
	border: 'none',
	borderRadius: '4px',
	display: 'inline-block',
	backgroundColor: 'rgba(0, 0, 128, 0.7)',
	position: 'relative',
	marginBottom: '10px',
	padding: '5px 20px'
};

const messageStyle = {
	fontSize: '20px',
	color: 'white'
};

const fromStyle = {
	fontSize: '12px',
	color: 'white'
};
