import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, } from 'reactstrap'
import { CONFIG } from 'configs/config.js'
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import ErrorAlert from 'components/ErrorAlert'
import { inject, observer } from 'mobx-react'

export default class MerchantVerify extends Component {
    static propTypes = {
        id: PropTypes.string,
        data: PropTypes.object,
        parentRefresh: PropTypes.func,
    }
    state = {
        modalVerify: false,
        verify: '2',
        displayButt: true,
        toggle: false,
        success: false,
        reload: false

    }
    componentDidMount() {
        this.checkVerify()
    }
    checkVerify = () => {
        const { data } = this.props
        if (data.display === undefined) {
            this.setState({ verify: '2' })
        } else {
            this.setState({ verify: data.display })
        }
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    _save = () => {
        let retail_id = this.props.data.retail_id !== undefined ? this.props.data.retail_id : this.props.data.workshop_id
        let VERIFY = 'verify=' + this.state.verify
        let RETAIL_MAIN = '&retail_main=' + this.props.id
        let RETAIL_ID = '&retail_id=' + retail_id

        let URL, csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
        if (CONFIG === 0) {
            URL = serv_user_development.workshopVerify
        }
        if (CONFIG === 1) {
            URL = serv_user_production.workshopVerify
        }

        let FINAL = URL + VERIFY + RETAIL_MAIN + RETAIL_ID
        let OPTIONS = { credentials: 'include', headers: headerToken }

        // console.log(FINAL);
        fetch(FINAL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response.message === 'OK' && response !== 401) {
                this.setState({ displayButt: true }, () => {
                    this.props.parentRefresh()
                    this.toggleModal('modalVerify')
                })
            }
        })
    }
    _renderButton = () => {
        let display

        if (this.state.displayButt === true) {
            display =
                <Button color='dark' onClick={() => {
                    this.setState({ displayButt: false }, () => {
                        this._save()
                    })
                }}>Save</Button>
        }
        if (this.state.displayButt === false) {
            display = <Button disabled color="dark" className="d-flex align-items-center justify-content-center"><div className="mr-2">Saving</div><div className="btnloader"></div></Button>
        }

        return display
    }
    render() {
        return (
            <>
                <Button color="light" size="sm" className="m-0" style={{ fontSize: '0.75rem', padding: '5px 10px' }} onClick={() => this.toggleModal('modalVerify')}>Verification Status</Button>

                <Modal backdrop="static" isOpen={this.state.modalVerify} toggle={() => this.toggleModal('modalVerify')} className='modal-dialog-centered'>
                    <ModalHeader>Verify Workshop</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for='verify'>Verification Status</Label>
                            <Input type='select' name='verify' id='verify' value={this.state.verify} onChange={this.handleChange}>
                                <option value='1'>Unlisted</option>
                                <option value='2'>Unverified Listed</option>
                                <option value='3'>Verified Listed</option>
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {this._renderButton()}
                        <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalVerify')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </>
        )
    }
}
MerchantVerify = inject('mobx_config')(observer(MerchantVerify));
