import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, Row, Col, Button, Badge, Collapse } from 'reactstrap';

// ICONS
import service from 'assets/img/service-type.svg';
import avatar from 'assets/img/avatar-black.svg';
import calendar from 'assets/img/calendar-black.svg';
import carmodel from 'assets/img/carmodel-black.svg';
import carplate from 'assets/img/carplate-black.svg';
import phone from 'assets/img/phone-black.svg';
import address from 'assets/img/address-black.svg';
import BOOST from 'assets/img/boost-white.jpg'
import JOMP from 'assets/img/jomparking.jpg'
import IOS from 'assets/img/ios.jpg'
import ANDROID from 'assets/img/android.png'

import UNPAID from 'assets/img/umar-unpaid.png'
import PAID from 'assets/img/umar-paid.png'

import ASSIGNED from 'assets/img/umar-assigned.png'
import UNASSIGNED from 'assets/img/umar-nonassigned.png'
import RELEASE from 'assets/img/umar-pending.png'
import COMPLETED from 'assets/img/umar-completed.png'
import DELETE from 'assets/img/req-delete.svg'
import ALERT from 'assets/img/alert.svg';

import { createHashHistory } from 'history';
const history = createHashHistory();

const Styles = {
    requestData : { display:'flex', alignItems:'center' },
    requestDataImage : { width: '20px', height: '20px', marginRight: '15px', marginTop:'10px', marginBottom:'10px' }
}

export default class DisplayRequestCard extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        deleteData: PropTypes.object,
        business: PropTypes.object,
        history: PropTypes.bool,
    }
    state = {
        collapse:false,
        screen: 0,
    };
    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize = () => {
        this.setState({screen: window.innerWidth});
    }
    _select = (data) => {
        let props = this.props
        console.log(!props.history)
        if(!props.history){
            history.push("/user/Booking-Request/" + data.delete_key);
        } else {
            history.push("/user/Booking-History/" + data.booking_id);
        }
    }

    _renderQuotationStatus = () => {
        let display;
        const { data } = this.props;

        if(data.quotation.masterItems === undefined){
            display = <Badge pill style={{background:'#fb9801', border:'0.5px solid #fb9801', color:'#ffffff', margin:'0 3px 0px', display:'flex', alignItems:'center', justifyContent:'center'}}>Quotation</Badge>
        }
        if(data.quotation.read !== undefined){
            display = <Badge pill style={{background:'transparent', border:'0.5px solid rgb(255,255,255)', color:'rgb(255,255,255)', margin:'0 3px 0px', display:'flex', alignItems:'center', justifyContent:'center'}}>Quotation Read</Badge>
        }

        return display;
    }
    _renderUpahStatus = () => {
        let display;
        const { data } = this.props;

        if(data.upah.masterItems === undefined){
            display = <Badge pill style={{background:'#fb9801', border:'0.5px solid #fb9801', color:'#ffffff', margin:'0 3px 0px', display:'flex', alignItems:'center', justifyContent:'center'}}>Upah</Badge>
        }

        return display;
    }

    // ================ ASSIGNMENT ================
    _renderAssignmentStatus = (data) => {
        let display;

        if(data === 'none'){
            display = <img id="unassigned" src={UNASSIGNED} style={{width:'25px', marginRight:'2px', marginLeft:'2px'}}/>
        }
        if(data === 'assigned'){
            display = <img id="assigned" src={ASSIGNED} style={{width:'25px', marginRight:'2px', marginLeft:'2px'}}/>
        }
        if(data === 'waiting for approval'){
            display = <img id="pending" src={RELEASE} style={{width:'25px', marginRight:'2px', marginLeft:'2px'}}/>
            
        }
        if(data === 'done'){
            display = <img id="completed" src={COMPLETED} style={{width:'25px', marginRight:'2px', marginLeft:'2px'}}/>
        }
        if(data === 'request to delete'){
            display = <img id="delete" src={ALERT} style={{width:'25px', marginRight:'2px', marginLeft:'2px'}}/>
        }

        return display;
    }
    // ================ PAYMENT STATUS ================
    _renderPaymentStatus = (data) => {
        let display;

        if(data === undefined){
            display = <img src={UNPAID} style={{width:'25px', marginRight:'2px', marginLeft:'2px'}}/>
        }
        if(data !== undefined){
            display = <img src={PAID} style={{width:'25px', marginRight:'2px', marginLeft:'2px'}}/>
        }

        return display;
    }
    // =============== APP PLATFORM BADGES ===============
    _renderPlatform = (data) => {
        let display;

        if(data === 'JomParking'){
            display = <img src={JOMP} style={{width:'25px', borderRadius:'50%', marginRight:'2px', marginLeft:'2px'}}/>
        }
        if(data === 'Boost'){
            display = <img src={BOOST} style={{width:'25px', borderRadius:'50%', marginRight:'2px', marginLeft:'2px'}}/>
        }
        if(data === 'ios'){
            display = <img src={IOS} style={{width:'25px', borderRadius:'50%', marginRight:'2px', marginLeft:'2px'}}/>
        }
        if(data === 'android'){
            display = <img src={ANDROID} style={{width:'25px', borderRadius:'50%', marginRight:'2px', marginLeft:'2px'}}/>
        }

        return display;
    }
    _renderBusinessTop = () => {
        let display;
        const { data, deleteData, business } = this.props;
        if(history){
            return null;
        }

        if(data.user_business !== undefined && business.type !== undefined) {
            display = <Badge pill style={{background:'#000080', border:'0.5px solid #000080', color:'#ffffff', margin:'0 10px 0px', display:'flex', alignItems:'center', justifyContent:'center'}}>Business</Badge>
        }

        return display;
    }
    _renderBusiness = () => {
        let display, businessDisplay = [];
        const { data, deleteData, business } = this.props;
        if(history){
            return null;
        }
        
        if(data.user_business !== undefined && business.type !== undefined){
            for (const type_id in data.user_business) {
                let INDEX = business.type.findIndex(x => x.id === type_id)

                businessDisplay.push(
                    <Col md={4} key={type_id} className="shadow-none mb-2 text-center pt-2 pb-2"
                        style={{background:'rgba(0, 0, 128, 0.7)', borderRadius:'0.8rem', marginRight:'5px'}}
                    >
                        <img src={business.type[INDEX].logo} style={{width:'50%'}}/>
                        <div style={{color:'white', marginTop:'5px'}}>{business.type !== undefined ? business.type[INDEX].name : null}</div>
                    </Col>
                )

                display =
                <Row className="m-0">
                    {businessDisplay}
                </Row>
            }
        }

        return display;
    }
    _renderDate = (date, data) => {
        let display;
        if(date !== undefined){
            if(date.next_service_date !== undefined && date.next_service_date === '') {
                return display = 'Date was not filled'
            }
            if(date.next_service_date !== undefined) {      
                var parts = date.next_service_date.split('-');
                var mydate

                // CHECKING SECOND OLD DATA AND NEWEST FORM OF DATE
                if(!isNaN(parseInt(parts[1]))){
                    // OLDER FORMAT DD-MM-YYYY
                    mydate = new Date(parts[2], parts[1] - 1, parts[0]);
                } else {
                    // NEW FORMAT DD-MMMMMM-YYYY
                    mydate = new Date(date.next_service_date);
                }
                
                display = <div>Next service due : {mydate.toLocaleDateString('en-MY', {day:'numeric', month:'long', year:'numeric'})}</div>
            }
            // NEXT SERVICE DATE OLD DATA DISPLAY
            if(date.next_service_date === undefined) {
                // EVEN OLDER DATA DAY, MONTH AND YEAR WERE SEPARATED
                display = date.next_service_date_day + ' ' + date.next_service_date_month + ' ' + date.next_service_date_year
            }
        } else {
            display =
            <Card body className="bg-danger text-white shadow-none mb-0">
                This booking request was deleted before a service was performed.<br/>
                {this.props.deleteData !== null ?
                <span>Reason for cancellation: {this.props.deleteData.reason}</span> : <span>Deleted by SERV Admin</span>}
            </Card>
        }
    
        return display;
    }
    _renderMileage = (data) => {
        let display;
        
        if(data.checklist !== undefined) {
            display = <div>Next service mileage : {data.checklist.customer_car_mileage_next_due}</div>
        }
    
    
        return display;
    }
    _renderBottom = () => {
        let display;
        const { data, deleteData, history } = this.props;
       
        display = !history ?
        <div>
            <Row className="m-0 align-items-center" onClick={this.toggle}>
                <Col md={2} style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center',  flexFlow:'column wrap'}}>
                    <div className="d-flex align-items-center">
                        RM <span style={{fontSize:'3.2rem', fontWeight:'bold', lineHeight:'1'}}>{data.quotation.total_price}</span>
                    </div>
                    <Badge pill color="info" className="font-weight-bold mt-2">
                        {data.quotation.requested_services.basic_premium.toUpperCase()}
                    </Badge>
                </Col>
            
                <Col md={8} className="border-left-resp" style={{paddingLeft:'35px'}}>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={avatar}/>{data.customer_name}
                    </Row>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={phone}/>{data.customer_phone}
                    </Row>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={calendar}/>{data.customer_day} {data.customer_month} {data.customer_year}, {data.customer_time}
                    </Row>
                </Col>

                <Col md={2} className="text-right">
                    <div className="m-0">
                        {
                            this.state.collapse === false ?
                            <i className='fas fa-chevron-right'/> :
                            <i className='fas fa-chevron-down'/>
                        }
                    </div>
                </Col>
            </Row>
        </div> :
        <div>
            <Row className="m-0 align-items-stretch">
                <Col md={7}>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={avatar}/>{data.customer_name}
                    </Row>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={calendar}/>{data.customer_day} {data.customer_month} {data.customer_year}, {data.customer_time}
                    </Row>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={carmodel}/>{data.customer_carmake} {data.customer_carmodel}
                    </Row>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={carplate}/>{data.customer_carplate.toUpperCase()}
                    </Row>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={phone}/>{data.customer_phone}
                    </Row>
                    <Row className="align-items-center">
                        <img style={Styles.requestDataImage} src={address}/>
                        <div style={{display: 'inline-block', width: 'calc(100% - 35px)'}}>{data.customer_address}</div>
                    </Row>
                </Col>
                <Col md={5} style={{paddingLeft:'15px'}}>
                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        {this._renderDate(data.checklist, data)}
                    </div>

                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        {this._renderMileage(data)}
                    </div>

                    {data.quotation.promotions !== undefined ?
                        <div style={{marginTop:'10px', marginBottom:'10px'}}>
                            Promo Code used : {data.quotation.promotions.promo_code}
                        </div> : null
                    }
                </Col>
            </Row>
        </div>

        return display;
    }
    _renderCollapsed = () => {
        let display;
        const { data, deleteData } = this.props;

        display =
        <Row className="m-0 mt-3 pt-3" style={{borderTop:'1px solid rgba(0,0,0,0.3)'}}>
            <Col md={5}>
                <div className="d-flex align-items-center mb-2">
                    <img style={{width: '20px', height: '20px', marginRight: '15px'}} src={carplate}/>{data.customer_carplate.toUpperCase()}
                </div>
                <div className="d-flex align-items-center mb-2">
                    <img style={{width: '20px', height: '20px', marginRight: '15px'}} src={address}/>
                    <div style={{display: 'inline-block', width: 'calc(100% - 35px)'}}>{data.customer_address}</div>
                </div>
            </Col>

            <Col md={4}>
                {this._renderBusiness()}
                {
                    data.assign.status === 'request to delete' ?
                    <Card body className="bg-danger text-white shadow-none mt-1 mb-0">
                        This user has requested for a cancellation.<br/>
                        Reason for cancellation: {deleteData !== null ? deleteData.reason : null}
                    </Card> : null
                }
            </Col>
        </Row>

        return display;
    }
    _renderResponsive = () => {
        let display
        let props = this.props, data = this.props.data
       
        if(this.state.screen < 768){
            display =
            <div style={{width:'85%'}}>
                {
                    !props.history ? <Row style={{margin:'0 0 10px 0'}}>
                        <Col className="pr-1 pl-1">{this._renderQuotationStatus()}</Col>
                        <Col className="pr-1 pl-1">{this._renderUpahStatus()}</Col>
                    </Row> : null
                }

                <div className="d-flex flex-column justify-content-end align-items-end">
                    <div className="text-white font-weight-bold d-flex mb-1">
                        {!props.history ? this._renderBusinessTop() : null}
                        {data._requestID.toUpperCase()}
                    </div>
                    
                    <Button color="light" className="m-0" style={{borderRadius:'10px', padding:'5px 10px',textAlign:'center', fontWeight:'bold'}}
                    onClick={() => {this._select(data)}}>
                        {!props.history ? 'Edit' : 'View'}
                    </Button>
                </div>
            </div>
            
        } else {
            display =
            <>
                {
                    !props.history ? <div className="d-flex" style={{width:'25%'}}>
                        {this._renderQuotationStatus()}
                        {this._renderUpahStatus()}
                    </div> : null
                }

                {
                    !props.history ? <div className="d-flex" style={{width:'15%'}}>
                        {this._renderBusinessTop()}
                    </div> : null
                }

                <div className="d-flex justify-content-end align-items-center" style={{width:!props.history ? '45%' : '85%'}}>
                    <div className="mr-3 text-white font-weight-bold">
                        {data._requestID.toUpperCase()}
                    </div>
                    <Button color="light" className="m-0" style={{borderRadius:'10px', padding:'5px 10px',textAlign:'center', fontWeight:'bold'}}
                    onClick={() => {this._select(data)}}>
                        {!props.history ? 'Edit' : 'View'}
                    </Button>
                </div>
            </>
        }

        return display
    }
    render() {
        const { data, deleteData, history } = this.props;
      
        return (
            <Card>
                <div className="d-flex" style={{background: data.assign.status !== 'request to delete' ? 'rgb(30, 25, 80)' : '#a22626', borderRadius:'0.9rem 0.9rem 0 0', padding:'1rem'}}>
                    <div style={{width:'15%'}}>
                        {this._renderPlatform(data.app_platform)}
                        {!history ? this._renderPaymentStatus(data.receipt_payment_url) : null}
                        {!history ? this._renderAssignmentStatus(data.assign.status) : null}
                    </div>

                    {this._renderResponsive()}
                </div>
                <div style={{padding:'1rem'}}>
                    {this._renderBottom()}
                    
                    {!history ?
                        <Collapse isOpen={this.state.collapse}>
                            {this._renderCollapsed()}
                        </Collapse> : null
                    }
                </div>
            </Card>
        );
    }
}
