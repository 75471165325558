import React from "react";
import PropTypes from 'prop-types'

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import avatar from 'assets/img/mechanic.svg'

const styles = {
  profile : {
    borderRadius:'0.5rem',
    marginBottom:'10px',
    border: '2px ridge rgb(113, 99, 184)'
  },
  description : {
    margin:'5px 15px',
    padding:'15px',
    borderRadius:'0.5rem',
    marginBottom:'10px',
    border: '2px ridge rgb(113, 99, 184)'
  }
}

class ServProfile extends React.Component{
  static propTypes = {
    data: PropTypes.object,
  }

  _renderProfileImage = () => {
    let display

    if(typeof this.props.data.user_data.profile_pic.downloadURL === 'undefined') {
      display =
        <img style={{borderRadius:'50%', marginBottom:'25px', border:'5px inset #765fbd', width:'200px'}} src={avatar}/>
    }
    if(typeof this.props.data.user_data.profile_pic.downloadURL !== 'undefined') {
      display =
        <div style={{borderRadius:'50%', backgroundImage: `url(${this.props.data.user_data.profile_pic.downloadURL})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundPosition:'center', height:'200px', width:'200px', margin:'0 auto', marginBottom:'25px', border:'5px inset #765fbd'}}></div>
    }

    return display
  }
  render(){
    return(
      <div>
        <Row>
          <Col md={12} className="text-center">
            {this._renderProfileImage()}
          </Col>
        </Row>
        <Row style={{margin:'5px 0'}}>
          <Col md={8} className="pr-md-1">
            <CardBody style={styles.profile}>Name : {this.props.data.user_data.name}</CardBody>
          </Col>
          <Col md={4} className="pl-md-1">
            <CardBody style={styles.profile}>
              D.O.B : {this.props.data.user_data.date_of_birth}
            </CardBody>
          </Col>
        </Row>

        <Row style={{margin:'5px 0'}}>
          <Col md={5} className="pr-md-1">
            <CardBody style={styles.profile}>
              Phone : {this.props.data.user_data.phone}
            </CardBody>
          </Col>
          <Col md={7} className="pl-md-1">
            <CardBody style={styles.profile}>
              Email : {this.props.data.user_data.email}
            </CardBody>
          </Col>
        </Row>

        <CardBody style={styles.description}>
          Description :<br/><span style={{margin:'0 15px'}}>{this.props.data.user_data.skills_desc}</span>
        </CardBody>

      </div>
    )
  }
}

export default ServProfile;
