import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, } from 'reactstrap';
import CryptoJS from 'crypto-js'

import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';
import firebase from 'firebase';
import ErrorAlert from './ErrorAlert';
const API_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

export default class AddAdmin extends Component {
    static propTypes = {
        data: PropTypes.object,
        parentRefresh: PropTypes.func,
    }
    state = {
        displayButt: true,
        name: '',
        email: '',
        password: '',
        retail_id: '',
        role: '',
        toggle: false,
        success: false,
        reload: false
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    }
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    encrypt = (plainText) => {
        var b64 = CryptoJS.AES.encrypt(plainText, API_KEY).toString();
        var e64 = CryptoJS.enc.Base64.parse(b64);
        var eHex = e64.toString(CryptoJS.enc.Hex);
        return eHex
    }
    decrypt = (cipherText) => {
        var reb64 = CryptoJS.enc.Hex.parse(cipherText);
        var bytes = reb64.toString(CryptoJS.enc.Base64);
        var decrypt = CryptoJS.AES.decrypt(bytes, API_KEY);
        var plain = decrypt.toString(CryptoJS.enc.Utf8);

        return plain
    }

    errorCheck = () => {
        const { name, email, password, retail_id, role } = this.state
        if (!name) {
            alert('Please fill in user name.')
            return this.setState({ displayButt: true })
        }
        if (!email) {
            alert('Please fill in email.')
            return this.setState({ displayButt: true, })
        }
        if (!password) {
            alert('Please fill in password.')
            return this.setState({ displayButt: true, })
        }
        if (role !== "Master" && !retail_id) {
            alert('Please select outlet to add admin.')
            return this.setState({ displayButt: true, })
        }
        return false
    }

    _save = () => {

        const { name, email, password, retail_id, role } = this.state

        const error = this.errorCheck()
        if (error) return

        // if (retail_id === 'master') {
        //     let confirmation = window.confirm("Do you want to make this admin as Master Admin?")
        //     if (!confirmation) return
        // }
        
        let OUTLETS = this.props.data.OUTLETS
        let INDEX = OUTLETS.findIndex(a => a.retail_id === retail_id)


        // 2. Encrypt data before sending through to cloud function
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY // detect location
        let ENCRYPT_NAME = this.encrypt(name)
        let ENCRYPT_EMAIL = this.encrypt(email)
        let ENCRYPT_PASSWORD = this.encrypt(password)
        let ENCRYPT_WORKSHOPID = this.encrypt(role !== "Master" ? retail_id : "Master")
        let ENCRYPT_WORKSHOPMAIN = this.encrypt(this.props.id)
        let ENCRYPT_ROLE = this.encrypt(this.state.role)
        // if (retail_id === 'master') ENCRYPT_ROLE = this.encrypt('Master')
        // console.log('role', this.state.retail_id);
        // 3. Set queries to call cloud function
        let NAME = 'name=' + ENCRYPT_NAME
        let EMAIL = '&email=' + ENCRYPT_EMAIL
        let PASSWORD = '&password=' + ENCRYPT_PASSWORD
        let WORKSHOP_ID = '&workshop_id=' + ENCRYPT_WORKSHOPID
        let WORKSHOP_MAIN = '&workshop_main=' + ENCRYPT_WORKSHOPMAIN
        let ROLE = '&role=' + ENCRYPT_ROLE
        let LOCATION = '&location=' + COUNTRY_DB
        const { browser } = this.props.mobx_config

        let URL, csrfTokenU, csrfSecretU, headerToken;
        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopCreateWorkshopAdmin
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopCreateWorkshopAdmin
        }

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        let FINAL_URL = URL + NAME + EMAIL + PASSWORD + WORKSHOP_ID + WORKSHOP_MAIN + ROLE + LOCATION
        let OPTIONS = { credentials: 'include', headers: headerToken }

        // 4. Call Cloud Function
        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                let RESPONSE = response
               
                // 4a. If RESPONSE = true, send error and return, means this user has an account on SERV User App
                if (RESPONSE.response === true) {
                    let r = window.confirm("It seems that this user has made an account on the SERV User app. Would you still want to create this user as a workshop admin?\n\nCreating this user means that the password they need to use to login is the samee as when they log into the SERV User app.")

                    if (!r) {
                        this.setState({
                            displayButt: true,
                        })
                        return
                    }

                    let NAME_2 = 'name=' + this.state.name
                    let EMAIL_2 = '&email=' + this.state.email
                    let WORKSHOP_ID_2 = '&workshop_id=' + this.state.retail_id
                    let WORKSHOP_MAIN_2 = '&workshop_main=' + this.props.id
                    let ROLE_2 = '&role=' + this.state.role
                    let FUID_2 = '&uid=' + RESPONSE.FUID
                    let LOCATION = '&location=' + COUNTRY_DB

                    let URL_2, csrfTokenU, csrfSecretU, headerToken;
                    const { browser } = this.props.mobx_config

                    if (this.props.mobx_config.config === 0) {
                        URL_2 = serv_user_development.workshopCreateWorkshopAdmin_existing
                    }
                    if (this.props.mobx_config.config === 1) {
                        URL_2 = serv_user_production.workshopCreateWorkshopAdmin_existing
                    }

                    if (browser === 'safari') {
                        csrfTokenU = sessionStorage.getItem('CSRFTokenU')
                        csrfSecretU = sessionStorage.getItem('CSRFSecretU')
                        headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
                    }

                    let FINAL_2 = URL_2 + NAME_2 + EMAIL_2 + WORKSHOP_ID_2 + WORKSHOP_MAIN_2 + ROLE_2 + FUID_2 + LOCATION
                    let OPTIONS = { credentials: 'include', headers: headerToken }

                    

                    fetch(FINAL_2, OPTIONS).then(response => {
                        if (response.status !== 200) {
                            this.setState({ toggle: true, success: false, reload: true })
                            return response.status
                        }
                        return response.json()
                    }).then(response => {
                        // user added successfully
                        if (response !== 401) {
                            if (response.message === 'OK') {
                                alert('Successfully added user')
                                this.setState({ displayButt: true, }, () => {
                                    this.props.parentRefresh()
                                    this.toggleModal('modalAdd');
                                })
                            } else {
                                // This email account has already been used for another workshop
                                alert(response.message)
                                this.setState({ displayButt: true, })
                            }
                        }

                    })
                }
                // 4b. If RESPONSE = false, creat a user in cf branch
                if (RESPONSE.response === false) {
                    alert('Successfully added user')
                    this.setState({ displayButt: true, }, () => {
                        this.props.parentRefresh()
                        this.toggleModal('modalAdd');
                    })
                    console.log("response dri add admin", response)
                }

                // 4c. Error if data was compromised
                if (RESPONSE.response === 'error') {
                    alert("ERROR ADDING USER. CORRUPTED DATA. TRY AGAIN.")
                    this.setState({
                        displayButt: true,
                    })
                    return;
                }
            }
        })
    }
    _renderButt = () => {
        let display
        const { displayButt } = this.state

        if (displayButt === true) {
            display =
                <Button color='dark' onClick={() => {
                    this.setState({ displayButt: false }, () => {
                        this._save()
                    })
                }}>Save changes</Button>
        }
        if (displayButt === false) {
            display = <Button disabled color='dark' className="d-flex align-items-center justify-content-between"><div>Saving...</div><div className="btnloader"></div></Button>
        }

        return display
    }
    render() {
        return (
            <div className="position-relative">
                <Button color="light" onClick={() => this.toggleModal('modalAdd')}>Add Admin</Button>

                <Modal isOpen={this.state.modalAdd} toggle={() => this.toggleModal('modalAdd')} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger'>
                    <ModalHeader toggle={() => this.toggleModal('modalAdd')}>Add Workshop Admin</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for='name'>Name</Label>
                            <Input type='text' name='name' id='name' onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup>
                            <Label for='email'>Email</Label>
                            <Input type='text' name='email' id='email' onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup>
                            <Label for='password'>Password</Label>
                            <Input type='text' name='password' id='password' onChange={this.handleChange} />
                        </FormGroup>

                        <FormGroup>
                            <Label for='role'>Role</Label>
                            <Input type='select' name='role' id='role' onChange={this.handleChange}>
                                <option value="">--Select--</option>
                                <option value="Branch Manager">Branch Manager</option>
                                <option value="Master">Master</option>
                            </Input>
                        </FormGroup>

                        {this.state.role !== "Master" && <FormGroup>
                            <Label for='retail_id'>Outlet</Label>
                            <Input type='select' name='retail_id' id='retail_id' onChange={this.handleChange}>
                                <option value="">--Select--</option>
                                {/* <option value="master">MASTER ADMIN</option> */}
                                {this.props.data.OUTLETS !== undefined ? this.props.data.OUTLETS.map((data, index) => (
                                    <option key={index} value={data.retail_id}>{data.name} ({data.role}) - {data.address} ({
                                        data.retail_admin !== undefined ? Object.keys(data.retail_admin).length + ' Admins' : 'No Admin'
                                    })</option>
                                )) : null}
                            </Input>
                        </FormGroup>}

                    </ModalBody>
                    <ModalFooter>
                        {this._renderButt()}
                        <Button className='ml-auto' color='secondary' onClick={() => this.toggleModal('modalAdd')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        )
    }
}
AddAdmin = inject('mobx_config','mobx_auth')(observer(AddAdmin))