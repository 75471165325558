import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import AVATAR from "assets/img/default-avatar.png"
import UserDetailVehicle from "./UserDetailVehicle"
import UserVehicleDisplay from "./UserVehicleDisplay"
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap"

export class Content extends Component {
  static propTypes = {
    toggleTab: PropTypes.func,    // For toggle tab functionally
    parentSelect: PropTypes.func, // To get the user data and pass to parent component and set the view
    activeTab: PropTypes.string,  // To get the current active tab from parent
    view: PropTypes.string,       // To get the view either "main" or "vehicle"
    data: PropTypes.object,       // To pass to the UserDetailVehicle component
    analytics: PropTypes.object,  // To pass the analytics to child component
    load: PropTypes.string,       // For loading screen
  }

  // Render the top tabs
  _renderTabs = () => {
    const { activeTab, toggleTab } = this.props
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => { toggleTab("1") }} style={{ color: 'inherit' }}>Vehicles</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => { toggleTab("2") }} style={{ color: 'inherit' }}>Analytics</NavLink>
          </NavItem>
        </Nav>
      </>
    )
  }
  _renderEachVehicle = () => {

  }
  
  // Render the content of the card
  _renderCardContent = () => {
    const { data, analytics, load, parentSelect, view, selectedVehicle} = this.props
   
    if (view === "main") return <UserDetailVehicle data={data} analytics={analytics} load={load} parentSelect={parentSelect} />
    if (view === "vehicle") {
      return (
        <div style={{ paddingTop: "15px" }}>
          <div className="d-flex justify-content-end align-items-center">
            <Button color="dark" className="m-0" onClick={() => { parentSelect({}, "main") }}>View All</Button>
          </div>
          <div style={{ padding: "25px 25px" }}>
            <UserVehicleDisplay data={data} selectedVehicle={selectedVehicle} />
          </div>
        </div>
      )
    }
  }

  // Render the analytics section
  _renderAnalytics = () => {
    return (
      <div className="text-center" style={{ padding: "65px 25px" }}>
        This section is under construction
      </div>
    )
  }

  render() {
    const { activeTab } = this.props
    return (
      <>
        {this._renderTabs()}
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">{this._renderCardContent()}</TabPane>
          <TabPane tabId="2">{this._renderAnalytics()}</TabPane>
        </TabContent>
      </>
    )
  }
}

export class Profile extends Component {
  static propTypes = {
    data: PropTypes.object,       // Get the user data from parent
    analytics: PropTypes.object,  // Get the analytics data from parent
  }

  _renderProfile = () => {
    const { data, analytics } = this.props
    return (
      <div>
        <h5>User Profile</h5>
        {data.user_picture.downloadURL !== "" ? (<div style={{ background: `url(${data.user_picture.downloadURL})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "25%", paddingTop: "25%", margin: "0 auto 10px", borderRadius: "50%" }}></div>) : (<div style={{ background: `url(${AVATAR})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "25%", paddingTop: "25%", margin: "0 auto 10px", borderRadius: "50%" }} ></div>)}

        <h6 style={{ display: "inline-block" }}>Name :</h6>
        <span> {data.name}</span>
        <br />

        <h6 style={{ display: "inline-block" }}>Email :</h6>
        <span> {data.email}</span>
        <br />

        <h6 style={{ display: "inline-block" }}>Date of Birth :</h6>
        <span> {data.DOB}</span>
        <br />

        <h6 style={{ display: "inline-block" }}>IC :</h6>
        <span> {data.IC}</span>
        <br />

        <h6 style={{ display: "inline-block" }}>License Expiry : </h6>
        <span> {data.License_Expiry}</span>

        <h6 style={{ display: "inline-block" }}>Phone : </h6>
        <span> {data.Phone}</span>

        <h6 style={{}}>Address :</h6>
        <p style={{ marginBottom: "0", padding: "0 20px 8px", textAlign: "justify", }}>
          {data.Address}, {data.City}, {data.PostalCode} {data.State}, {data.Country}
        </p>

        <h5>Analytics</h5>
        <div>
          <h6 style={{}}># of Vehicles</h6>
          <div style={{ fontSize: "2.7rem", fontWeight: "bold", lineHeight: "1" }}>
            {analytics.vehicle_count}
          </div>
        </div>
        <div>
          <h6 style={{}}># of Requests</h6>
          <div style={{ fontSize: "2.7rem", fontWeight: "bold", lineHeight: "1" }}>
            {analytics.request_count}
          </div>
        </div>
      </div>
    )
  }
  render() {
    return this._renderProfile()
  }
}