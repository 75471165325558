import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import BusinessListingUpdate from './BusinessListingUpdate'
import { Row, Col, Card, CardBody } from 'reactstrap'

export default class BusinessListingDisplay extends Component {
  static propTypes = {
    tiers: PropTypes.object,                 // To show the tiers of the business types
    data: PropTypes.object.isRequired,       // To show the data of business type for each card in the list
    parentRefresh: PropTypes.func.isRequired // To load up all the fetch database in order to show updated content
  }

  /* Rendering the List of Business Types */
  _renderBusinessList = () => {
    const { data, parentRefresh } = this.props

    return (
      <Card style={{ borderRadius: '5px', borderLeft: '5px ridge #7163b8', boxShadow: '0 5px 10px rgba(27,27,27,0.7)', }}>
        <CardBody style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px', }}>
          <div style={{ width: '75%', fontSize: '35px', fontWeight: 'bold' }}><img src={data.logo} style={{ width: '15%', marginRight: '15px' }} />{data.name}</div>
          <BusinessListingUpdate forType='type' type='edit' selectedBusiness={data} parentRefresh={parentRefresh} renderTier={this._renderTierList} />
        </CardBody>
      </Card >
    )
  }

  /* Rendering the List of Tier Types */
  _renderTierList = () => {
    const { data, tiers, parentRefresh } = this.props, tierList = []

    for (const id in tiers) {
      let selectedTier = { id: id, tier: tiers[id] }
      tierList.push(
        <Row key={id} className='align-items-center mb-3'>
          <Col md={1}><img src={tiers[id].icon} /></Col>
          <Col md={5}>{tiers[id].name}</Col>
          <Col md={6} className='text-right'><BusinessListingUpdate forType='tiers' type='edit' selectedTier={selectedTier} selectedBusiness={data} parentRefresh={parentRefresh} /></Col>
        </Row>
      )
    }
    return <><hr /><BusinessListingUpdate forType='tiers' type='add' selectedBusiness={data} parentRefresh={parentRefresh} />{tierList.length !== 0 ? tierList : <p className='text-center'>No tier for this business type... Please add a tier if have any.</p>}</>
  }
  render() { return <Row><Col xs={12} md={12}>{this._renderBusinessList()}</Col></Row> }
}
BusinessListingDisplay = inject('mobx_config')(observer(BusinessListingDisplay))
