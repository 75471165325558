import React, { Component } from 'react'
import { withRouter } from 'react-router';

import firebase from 'firebase';
import { inject, observer } from 'mobx-react';
import { Row, Col, Button } from 'reactstrap'

import 'assets/css/Login.css';
import logo from 'assets/img/serv-blue.png'
import art from 'assets/img/artwork.png'

import ErrorAlert from 'components/ErrorAlert';
import { serv_provider_development, serv_provider_production, serv_user_development, serv_user_production } from 'configs/firebaseCF';

class Login extends Component {
    state = {
        displayButton: true,

        browser: '',
        email: '',
        password: '',

        text: '',
        toggle: false,
        success: false,
        isChecked: false,
    }

    /* Toggle error alert modal */
    childToggle = () => this.setState({ toggle: false })

    /* Change value of the element */
    handleChange = (evt) => this.setState({ [evt.target.name]: evt.target.value })

    /* Generate CSRF Token upon login only */
    fetchToken = async () => {
        const { config, browser } = this.props.mobx_config
        let URLSU, URLSP, FINAL_URLSU, FINAL_URLSP, COMPLETEP = false, COMPLETEU = false

        if (config === 0) {
            URLSU = serv_user_development.createCSRFU
            URLSP = serv_provider_development.createCSRFP
        }
        if (config === 1) {
            URLSU = serv_user_production.createCSRFU
            URLSP = serv_provider_production.createCSRFP
        }
        FINAL_URLSU = `${URLSU}device=browser&browser=${browser}`
        FINAL_URLSP = `${URLSP}device=browser&browser=${browser}`

        await fetch(FINAL_URLSU, { credentials: 'include' }).then((res) => res.json()).then(res => {
            if (res.data) {
                const { CSRFSecretU, CSRFTokenU } = res.data
                sessionStorage.setItem('CSRFSecretU', CSRFSecretU)
                sessionStorage.setItem('CSRFTokenU', CSRFTokenU)
            }
            return COMPLETEU = true
        })

        await fetch(FINAL_URLSP, { credentials: 'include' }).then((res) => res.json()).then(res => {
            if (res.data) {
                const { CSRFSecretP, CSRFTokenP } = res.data
                sessionStorage.setItem('CSRFSecretP', CSRFSecretP)
                sessionStorage.setItem('CSRFTokenP', CSRFTokenP)
            }
            return COMPLETEP = true
        })

        if (COMPLETEP && COMPLETEU) return
    }

    /* Check user's credentials, if yes, sign in and set persistent browser session, if not, error alert */
    handleLogin = async event => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        console.log("country", COUNTRY_DB)
        const { email, password, isChecked } = this.state;

        this.setState({ displayButton: false })
        event.preventDefault();

        try {
            const persistent = isChecked ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION;
            await firebase.app(COUNTRY_DB).auth().setPersistence(persistent)
            const { user } = await firebase.app(COUNTRY_DB).auth().signInWithEmailAndPassword(email, password);
            const DB_SNAP = await firebase.app(COUNTRY_DB).database().ref('serv_admin/' + user.uid).once('value')

            // await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            // const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);
            // const DB_SNAP = await firebase.database().ref('serv_admin/' + user.uid).once('value')

            if (!DB_SNAP.exists()) return this.setState({ displayButton: true, text: 'You are not authorised for logging into this dashboard! If you think this is a mistake, please contact SERV admin.', toggle: true, success: false })

            await this.fetchToken()
            await sessionStorage.setItem('CT', 'available')

            this.props.history.push('/');
        } catch (error) {
            let text

            text = `Login Failed. Please try again.`
            this.setState({ displayButton: true, text: text, toggle: true, success: false })
        }
    }

    /* Display button's visual cues */
    _renderButton = () => {
        const { displayButton } = this.state

        if (displayButton === true)
            return <Button type='button' color='login' style={{ width: '100%', fontWeight: 'bold', fontSize: '15px' }} onClick={this.handleLogin}>SIGN IN</Button>

        if (displayButton === false)
            return (
                <Button disabled type='button' color='login' style={{ width: '100%', fontWeight: 'bold', }}>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='mr-2'>Signing In</div>
                        <div className='buttonloader'></div>
                    </div>
                </Button>
            )
    }

    _renderContent = () => {
        return (
            <div style={{ height: '100vh' }}>
                <Row style={{ margin: '0', height: '100%' }}>
                    <Col md={6} className="left-column" style={{
                        background: `linear-gradient(to bottom left, #fff 50%, #080880 50%)`,
                    }}>
                        <img src={art} class='art' style={{ width: '80%' }}/>
                    </Col>

                    <Col md={6} className='right-column'>
                        <h1 style={{ color: 'white', display: 'none' }}>Serv</h1>
                        <div className='login-logo'>
                            <img src={logo} style={{ width: '100%' }} />
                        </div>
                        <br /><br />
                        <div className='login-form'>
                            <form>
                                <div className='group'>
                                    <label htmlFor='user' className='label'></label>
                                    <input name='email' type='email' placeholder='Email' className='input' onChange={this.handleChange} />
                                </div>

                                <div className='group'>
                                    <label htmlFor='password' className='label'></label>
                                    <input id='password' name='password' type='password' placeholder='Password' className='input' data-type='password' onChange={this.handleChange} />
                                </div>

                                <div className='group'>
                                    {this._renderButton()}
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
                <ErrorAlert text={this.state.text} toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </div>
        )
    }

    render() {
        return (this._renderContent())
    }
}

Login = inject('mobx_auth', 'mobx_config')(observer(Login))

export default withRouter(Login);