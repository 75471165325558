import React from "react";
import { Card, CardHeader, CardBody, Row, Col, Button, CardFooter } from "reactstrap";

import PanelHeader from "components/PanelHeader";

import ServiceProviderList from './ServiceProviderList.jsx';
import ServiceProviderProfile from './ServiceProviderProfile.jsx';
import ServEmptyProfile from './ServiceProviderEmptyProfile.jsx';
import Chat from 'components/Chat.jsx'

import firebase from 'firebase';
import { inject, observer } from "mobx-react";

class ServProvider extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      tab: true,
      data:[],
      testData:[],

      // loading === '3', data exists === '2', data doesnt exist === '1'
      userExist: '3',

      dummy: {},
      current_length_async: 0,
      length_async:0,
      select: null,
    }
  }

  componentDidMount() {
    this.fetchSP()
    // this.fetch()
  }
  _selectSP = (data) => {
    this.setState({select:null}, () => {
      this.setState({select:data})
    })
  }
  _switch = () => {
    this.setState({tab: !this.state.tab})
  }

  // =====================================================
  // 1. Fetch all SP users
  // 2. Fetch all admin messages and map to all SP users
  // let MASTER = {
  //   unread : 0,
  //   user_data : {},
  //   chat_data : {}
  // }
  // 3. Calculate any unread messages
  fetchSP = () => {
    let USERS = []
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY

    firebase.app(COUNTRY_DB).database().ref(`servproviderUID`).once('value').then((snapshot) => {
      if(snapshot.exists()){
        USERS = snapshot.val()
      }
    }).then(() => {
      this.fetchMessages(USERS)
    })
  }
  fetchMessages = (users) => {
    let USERS = users, MESSAGES = {}, TEMP = []
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    firebase.app(COUNTRY_DB).database().ref(`adminmessages`).once('value').then((snapshot) => {
      if(snapshot.exists()){
        MESSAGES = snapshot.val()
        for (const SPID in USERS) {
          let a = {
            key : SPID,
            unread : 0,
            user_data : USERS[SPID],
            chat_data : MESSAGES[SPID] !== undefined ? MESSAGES[SPID] : null
          }

          let COUNT = 0
          Object.values(MESSAGES[SPID]).forEach(chat => {
            if(chat.unread === true){
              COUNT = COUNT + 1
            }
          });
          a.unread = COUNT

          // If got unread, unshift, otherwise, push
          if(COUNT !== 0){
            TEMP.unshift(a)
          } else {
            TEMP.push(a)
          }

        }
        // console.log('final data', TEMP);
        this.setState({data: TEMP, userExist:'2'})
      }
    })
  }

  clear = () => {
    this.setState({data: [],userExist:'3'}, () => {
      this.fetchSP()
    })
  }
  _markAsRead = () => {
    let FUID = this.state.select.key
    let UNREAD = this.state.select.unread
    let CHAT_DATA = this.state.select.chat_data
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    if(UNREAD !== 0) {
      for (const chat_id in CHAT_DATA) {
        let x = CHAT_DATA[chat_id].unread;
        if(x !== undefined && x === true) {
          firebase.app(COUNTRY_DB).database().ref(`adminmessages/${FUID}/${chat_id}`).update({unread : false});
        }
      }

      this.clear()
    }
  }

  // =====================================================


  render() {
    let tab, header, button;
    if(this.state.select === null && this.state.tab == true){
      tab = <ServEmptyProfile/>
      header = <div><h5 className="title">Select a Provider Profile</h5></div>
      button = <Button style={{margin:'0'}} color='secondary' onClick={this._switch}>Select SP</Button>
    }
    if(this.state.select !== null && this.state.tab == true){
      tab = <ServiceProviderProfile data={this.state.select}/>
      header = <div><h5 className="title">Profile</h5></div>
      button =
      <Button style={{margin:'0'}} color='light' onClick={() => {
        this._switch()
        this._markAsRead()
      }}>Chat</Button>
    }
    if(this.state.select === null && this.state.tab == false){
      tab = <ServEmptyProfile/>
      header = <div><h5 className="title">Select a Provider Profile</h5></div>
      button = <Button style={{margin:'0'}} color='secondary' onClick={this._switch}>Select SP</Button>
    }
    if(this.state.select !== null && this.state.tab == false){
      tab = <Chat servproviderID={this.state.select !== null ? this.state.select.key : null} />
      header = <div><h5 className="title">Chat</h5></div>
      button = <Button style={{margin:'0'}} color='dark' onClick={this._switch}>View Profile</Button>
    }

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md={8} xs={12}>
              <Card>

                <CardHeader style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                  {header}
                  <div>
                    {button}
                  </div>
                </CardHeader>

                <CardBody>
                  {tab}
                </CardBody>

                <CardFooter/>

              </Card>
            </Col>
            <Col md={4} xs={12}>
              <Card className="card-user">

                <CardHeader>
                  <h5 className="title">Profile List</h5>
                </CardHeader>

                <CardBody>
                  <ServiceProviderList data={this.state.data} userExist={this.state.userExist} fetchParent={this.clear} parentSelect={this._selectSP} selected={this.state.select} />
                </CardBody>

              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
ServProvider = inject('mobx_auth')(observer(ServProvider))
export default ServProvider;
