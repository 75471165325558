import React, { Component } from 'react'
import firebase from 'firebase';
import { inject, observer } from 'mobx-react';

import { Row, Col } from 'reactstrap';
import BookingIcon from 'assets/fikryimage/diagnosis.svg';
import CarIcon from 'assets/fikryimage/steering-wheel.svg';
import MultiUserIcon from 'assets/fikryimage/face-scan.svg';
import ReturnCustomerIcon from 'assets/img/crm.svg';
import CardAnalytics from 'components/CardAnalytics.jsx';
import CardAnalyticsChart from 'components/CardAnalyticsChart';
import ErrorAlert from 'components/ErrorAlert';
import { CONFIG } from 'configs/config.js'
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js'

import WebWorker from 'workers/workerSetup.js';
import workerRequestsAnalytics from 'workers/workerRequestsAnalytics.js';

export default class Dashbaord_Marketing extends Component {
    state = {
        load: '1',
        data: [],
    
        toggle: false,
        success: false,
        reload: false,
    
        userCount: 0,
        userLoad: false,
    
        vehicleCount: 0,
        vehicleLoad: false,
    
        bookingCount: 0,
        bookingLoad: true,
    
        returningCustomerCount: 0,
        returningCustomerLoad: true,
    
        payments: 0,
        paymentLoad: false,
    
        analytics: {},
        analyticsLoad: false,
    
        usersAnalytics: [],
        usersAnalyticsLoad: false,
    
        vehiclesAnalytics: [],
        vehiclesAnalyticsLoad: false,
    }
    
    componentDidMount() {
        this.fetchUsers()
        // this.fetchRequests()
        this.fetchUserAnalytics()
        this.fetchVehicleAnalytics()
    
        this.workerRequestsAnalytics = new WebWorker(workerRequestsAnalytics);
    }
    
    /* Toggle error alert and reload page */
    childToggle = async () => {
        await this.setState({ toggle: false })
    
        if (this.state.reload === true) {
        await sessionStorage.removeItem('CT')
        await document.location.reload()
        }
    }
    
    /* Get token to verify in back-end */
    getToken = () => {
        const { browser } = this.props.mobx_config
        let OPTIONS, csrfTokenU, csrfSecretU, headerToken
    
        if (browser === 'safari') {
        csrfTokenU = sessionStorage.getItem('CSRFTokenU')
        csrfSecretU = sessionStorage.getItem('CSRFSecretU')
        headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
    
        return ( OPTIONS = { credentials: 'include', headers: headerToken })
    }
    
    /*  */
    fetchUsers = () => {
        let url, csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config
    
        if (CONFIG === 0) url = serv_user_development.fetchUsers
        if (CONFIG === 1) url = serv_user_production.fetchUsers
    
        if (browser === 'safari') {
        csrfTokenU = sessionStorage.getItem('CSRFTokenU')
        csrfSecretU = sessionStorage.getItem('CSRFSecretU')
        headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }
    
        let OPTIONS = { credentials: 'include', headers: headerToken }
    
        fetch(url, OPTIONS).then(response => {
        if (response.status !== 200) {
            this.setState({ toggle: true, success: false, reload: true })
            return response.status
        }
        return response.json()
        }).then(response => {
        // console.log(response.data);
        if (response !== 401) {
            let TEMP
            let DATA = [];
            let COUNT = 0
    
            TEMP = response.data
            for (const FUID in TEMP) {
                let a = TEMP[FUID]
                // console.log(FUID, a, typeof a);
                if(typeof a === "object") {
                    a.FUID = FUID
        
                    DATA.push(a)
                }
            }
            COUNT = DATA.length
    
            this.setState({ users: DATA, userCount: COUNT, userLoad: true }, () => {
                this.fetchVehicles();
            })
        }
        })
    }
    
    fetchVehicles = async () => {
        let COUNT = 0;
        let USERDATA = this.state.users;
    
        let SNAPSHOT = await firebase.database().ref(`plate_number`).once('value')
        if(SNAPSHOT.exists()) {
        COUNT = Object.keys(SNAPSHOT.val()).length
        }
    
        this.setState({ vehicleCount: COUNT, vehicleLoad: true }, () => {
        // this.fetchBooking();
        })
    }
    
    fetchBooking = () => {
        let COUNT = 0
    
        firebase.database().ref(`request`).once('value').then((snapshot) => {
        if (snapshot.exists()) {
            COUNT = Object.keys(snapshot.val()).length
        }
        }).then(() => {
        this.setState({ bookingCount: COUNT, bookingLoad: true }, () => {
            this.fetchReturningCustomer();
        })
        })
    }
    
    fetchReturningCustomer = () => {
        let storeID = [], storeNew = []
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        firebase.app(COUNTRY_DB).database().ref(`history`).once('value').then((snapshot) => {
        if (snapshot.exists()) {
            let historyData = snapshot.val()
    
            for (const booking_id in historyData) {
            const currentBookingID = historyData[booking_id];
            const userID = currentBookingID.customer_FUID
    
            storeID.push(userID)
    
            storeID.forEach(item => {
                if (storeNew.indexOf(item) === -1) storeNew.push(item)
            });
    
            }
        }
        }).then(() => {
        this.setState({ returningCustomerCount: storeNew.length, returningCustomerLoad: true })
        })
    }
    
    /* Fetch all current and history requests to process requests' data analytics */
    fetchRequests = async () => {
        let REQUESTDATA = {}, dbSnap, dbSnap1
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        try {
        dbSnap = await firebase.app(COUNTRY_DB).database().ref('request/').once('value')
        if (dbSnap.exists()) REQUESTDATA = dbSnap.val()
        if (!dbSnap.exists()) console.log('Snapshot does not exist')
    
        dbSnap1 = await firebase.app(COUNTRY_DB).database().ref('history/').once('value')
        if (dbSnap1.exists()) Object.assign(REQUESTDATA, dbSnap1.val())
        if (!dbSnap1.exists()) console.log('Snapshot does not exist')
    
        await this.workerRequestsAnalytics.postMessage(REQUESTDATA)
        await this.workerRequestsAnalytics.addEventListener('message', event => {
            this.setState({ analytics: event.data, analyticsLoad: true })
        });
        } catch (error) {
        console.error(error)
        }
    }
    
    fetchUserAnalytics = async () => {
        let URL = CONFIG === 0 ? serv_user_development.userAnalytics : serv_user_production.userAnalytics
        console.log(URL);
        const response = await fetch(URL, this.getToken())
        const userData = await response.json()
    
        if (response.status !== 200) {
        this.setState({ toggle: true, success: false, reload: true })
        return response.status
        }
    
        if (response !== 401) this.setState({ usersAnalytics: userData, usersAnalyticsLoad: true })
    }
    
    fetchVehicleAnalytics = async () => {
        // let URL = CONFIG === 0 ? serv_user_development.vehicleAnalytics : serv_user_production.vehicleAnalytics
    
        // const response = await fetch(URL, this.getToken())
        // const vehicleData = await response.json()
    
        // if (response.status !== 200) {
        // this.setState({ toggle: true, success: false, reload: true })
        // return response.status
        // }
    
        // if (response !== 401) this.setState({ vehiclesAnalytics: vehicleData, vehiclesAnalyticsLoad: true })
        let REQUESTDATA = {}, dbSnap
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        try {
            dbSnap = await firebase.app(COUNTRY_DB).database().ref('plate_number/').once('value')
            if (dbSnap) REQUESTDATA = dbSnap.val()
            else console.log('Snapshot does not exists')

            await this.workerRequestsAnalytics.postMessage(REQUESTDATA)
            await this.workerRequestsAnalytics.addEventListener('message', event => {
                this.setState({vehiclesAnalytics: event.data, vehiclesAnalyticsLoad: true})
            })
        }catch (error){
            console.error(error)
        }
    }
    render() {
        return (
            <>
                <div className='content'>
                    <Row>
                        <Col md={6}>
                        <CardAnalytics
                            title='Users Registered' value={this.state.userCount} load={this.state.userLoad}
                            image={() => {
                            return <img style={{ width: 100, height: 100, margin: '10px auto' }} src={MultiUserIcon} alt='...' />
                            }}
                        />
                        </Col>

                        <Col md={6}>
                        <CardAnalytics
                            title='Vehicles Registered' value={this.state.vehicleCount} load={this.state.vehicleLoad}
                            image={() => {
                            return <img style={{ width: 100, height: 100, margin: '10px auto' }} src={CarIcon} alt='...' />
                            }}
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                        <CardAnalytics
                            title='Booking' value={this.state.bookingCount} load={this.state.bookingLoad}
                            image={() => {
                            return <img style={{ width: 100, height: 100, margin: '10px auto' }} src={BookingIcon} alt='...' />
                            }}
                        />
                        </Col>

                        <Col md={6}>
                        <CardAnalytics
                            title='Returning Customer' value={this.state.returningCustomerCount} load={this.state.returningCustomerLoad}
                            image={() => {
                            return <img style={{ width: 100, height: 100, margin: '10px auto' }} src={ReturnCustomerIcon} alt='...' />
                            }}
                        />
                        </Col>
                    </Row>

                    {/* <CardAnalyticsChart title='Requests per Month' data={this.state.analytics} load={this.state.analyticsLoad} icon='car' name='Requests' /> */}
                    <CardAnalyticsChart title='New Users per Month' data={this.state.usersAnalytics} load={this.state.usersAnalyticsLoad} icon='user' name='New Users' />
                    <CardAnalyticsChart title='New Vehicles per Month' data={this.state.vehiclesAnalytics} load={this.state.vehiclesAnalyticsLoad} icon='car' name='New Vehicles' />
                </div>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </>
        )
    }
}
Dashbaord_Marketing = inject('mobx_config', 'mobx_auth')(observer(Dashbaord_Marketing))