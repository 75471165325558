import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Card, CardBody, CardHeader, } from 'reactstrap'

export default class CardAnalytics extends Component {
    static propTypes = {
        image: PropTypes.func,
        title: PropTypes.string,
        value: PropTypes.number,
        load: PropTypes.bool.isRequired,
    }
    _renderValue = () => {
        let display

        if(this.props.load === true){
            display = <h4 className="text-center" style={{fontSize:35}}>{this.props.value}</h4>
        } else {
            display =
            <div style={{textAlign:'center'}}>
                <div className='loader'></div>
            </div>
        }

        return display
    }
    render() {
        return (
            <Card className="kanban-card">
                <CardBody className="d-flex align-items-center justify-content-between">
                    <div style={{textAlign:'center'}}>
                        {this.props.image()}
                    </div>
                    <div>
                        {this._renderValue()}
                    </div>
                </CardBody>
                <CardHeader className="pt-1 pb-2">
                    <h4 className="text-center m-0">{this.props.title}</h4>
                </CardHeader>
            </Card>
        )
    }
}
