import React, { Component } from 'react'
import { Card, Button } from "reactstrap";
import { PanelHeader } from "components";
import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'
import { inject, observer } from 'mobx-react';
import { Route } from 'react-router-dom'
import Analytics from '../components/Analytics';
import AnalyticsIndividual from './AnalyticsIndividual';

export default class AnalyticsRoutes extends Component {
    render() {
        return (
            <div>
                <PanelHeader size="sm" />
                <div className="content">
                    <Route exact path="/user/Merchant-Analytics" component={Analytics} />
                    <Route exact path="/user/Merchant-Analytics/:id" component={Individual} />
                </div>
            </div>
        )
    }
}

function Individual({ match }) {
    return <AnalyticsIndividual id={match.params.id} />;
}

AnalyticsRoutes = inject('mobx_config')(observer(AnalyticsRoutes))