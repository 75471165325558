import React, { Component } from 'react'

import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { PanelHeader } from "components";
import FeedbackDisplay from 'components/FeedbackDisplay';

import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js';
import firebase from 'firebase';
import { inject, observer } from 'mobx-react'

import safebox from 'assets/img/safebox.png';
import ErrorAlert from 'components/ErrorAlert';

export default class FeatureSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: '1',
      data: [],
      count: [],
      total: 0,
      toggle: false,
      success: false,
      reload: false

    }
  }

  componentDidMount() {
    this._fetchFeatures()
  }

  childToggle = () => this.setState({ toggle: false }, () => {
    if (this.state.reload === true) {
      sessionStorage.removeItem('CT')
      document.location.reload()
    }
  })

  _refresh = () => {
    this.setState({ load: '1' });
    this._fetchFeatures();
  }

  // FETCH FEATURE SUGGESTIONS
  // _fetchFeatures = () => {
  //   let URL, csrfTokenU, csrfSecretU, headerToken;
  //   const { browser } = this.props.mobx_config
  //   let COUNTRY_DB = this.props.mobx_auth.COUNTRY
  //   if (browser === 'safari') {
  //     csrfTokenU = sessionStorage.getItem('CSRFTokenU')
  //     csrfSecretU = sessionStorage.getItem('CSRFSecretU')
  //     headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
  //   }

  //   if (this.props.mobx_config.config === 0) {
  //     URL = serv_user_development.featureSuggestion
  //   }
  //   if (this.props.mobx_config.config === 1) {
  //     URL = serv_user_production.featureSuggestion
  //   }

  //   let OPTIONS = { credentials: 'include', headers: headerToken }
  //   let location = 'location=' + COUNTRY_DB
  //   let SENT_LOCATION = URL + location
  //   fetch(SENT_LOCATION, OPTIONS).then(response => {
  //     if (response.status !== 200) {
  //       this.setState({ toggle: true, success: false, reload: true })
  //       return response.status
  //     }
  //     return response.json()
  //   }).then(response => {
  //     if (response !== 401) {
  //       let FEATURE_DATA = response.data
  //       let FEATURE = []

  //       if (response.data !== null && response.data !== undefined) {
  //         // ITERATE EACH DATA
  //         for (const suggestion_id in FEATURE_DATA) {
  //           let CURRENT = FEATURE_DATA[suggestion_id]

  //           // ADD suggestion_id IN ARRAY
  //           CURRENT.suggestion_id = suggestion_id
  //           FEATURE.unshift(CURRENT)
  //         }

  //         this.setState({ load: '2', data: FEATURE })
  //       } else {
  //         this.setState({ load: '3' })
  //       }
  //     }
  //   })
  // }

  //FETCH FEATURE SUGGESTIONS 
  _fetchFeatures = () => {
    let COUNTRY_DB = this.props.mobx_auth.COUNTRY
    let FEATURE = []
    firebase.app(COUNTRY_DB).database().ref(`feature_suggestion/`).once('value', (snap) => {
      if(snap.exists()) {
        let DATA = snap.val()
        for(const suggestion_id in DATA) {
          let CURRENT = DATA[suggestion_id]
          CURRENT.suggestion_id = suggestion_id
          FEATURE.unshift(CURRENT)
        }
        this.setState({ load: '2', data: FEATURE })
      } else {
        this.setState({ load: '3' })
      }
    })
  }

  // DISPLAY CARDS
  _renderContent = () => {
    let display = []
    const { data } = this.state

    // LOAD DATA
    if (this.state.load === '1') {
      display.push(
        <Col xs={12} md={12} key={1}>
          <Card className="text-center">
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <div className='loader'></div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )
    }

    // DISPLAY DATA
    if (this.state.load === '2') {
      display = data.map((data, index) => (
        <Col key={index} md={4}>
          <FeedbackDisplay data={data} />
        </Col>
      ))
    }

    // NO DATA
    if (this.state.load === '3') {
      display.push(
        <Col xs={12} md={12} key={1}>
          <Card className="text-center">
            <CardBody>
              <div style={{ textAlign: 'center' }}>
                <img style={{ width: '35%', marginBottom: '15px' }} src={safebox} />
                <div>NO FEATURE SUGGESTIONS</div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )
    }

    return display
  }

  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Row className="d-flex align-items-center justify-content-between">
            <Button
              color="light"
              style={{ marginLeft: '15px', borderRadius: '50px', padding: '8px 10px', zIndex: '100' }} onClick={this._refresh}>
              <i className="now-ui-icons arrows-1_refresh-69" style={{ fontSize: '20px', fontWeight: 'bold' }} />
            </Button>
          </Row>
          <Row>
            {this._renderContent()}
          </Row>
        </div>
        <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
      </div>
    );
  }
}

FeatureSuggestion = inject('mobx_config','mobx_auth')(observer(FeatureSuggestion))