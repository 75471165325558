export const API_KEY_DEVELOPMENT = {
  apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID
};

// export const API_KEY_PRODUCTION = {
//   apiKey: process.env.REACT_APP_FIREBASE_PROD_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_PROD_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_PROD_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROD_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_PROD_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_PROD_MESSAGING_SENDER_ID,
// };
export const API_KEY_PRODUCTION = {
  apiKey: "AIzaSyALaY9EAh0CQ7CJA_kskZXliKTe5qV6aBU",
  authDomain: "serv-v2-production-fcb23.firebaseapp.com",
  databaseURL: "https://serv-v2-production-fcb23.firebaseio.com",
  projectId: "serv-v2-production-fcb23",
  storageBucket: "serv-v2-production-fcb23.appspot.com",
  messagingSenderId: "512375293041",
  appId: "1:512375293041:web:8870a231c50e9253b907d0"
};

export const API_UNIQUE_CARPLATE_SANDBOX = {
  apiKey: "AIzaSyCyFZbdHnfg-MhCDdwWxhqTwSDm80cNSZA",
  authDomain: "serv-unique-plate-sandbox.firebaseapp.com",
  databaseURL: "https://serv-unique-plate-sandbox-default-rtdb.firebaseio.com",
  projectId: "serv-unique-plate-sandbox",
  storageBucket: "serv-unique-plate-sandbox.appspot.com",
  messagingSenderId: "251282911260",
  appId: "1:251282911260:web:a9fcf4ff720485cfceefd9"
};
