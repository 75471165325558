import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CollapsibleCard from 'components/CollapsibleCard'
import { CircularProgressbar } from 'react-circular-progressbar';

import CarPartRatings from 'components/CarPartRatings';
import { Row, Col, CardBody, CardHeader } from 'reactstrap';

export default class BookingRequestChecklist extends Component {
    static propTypes = {
        data: PropTypes.object,
        // if noStatus === true --> dont display status
        // if noStatus === undefined --> display status
        noStatus: PropTypes.bool,
    }
    state = {
        checklist: false,
    }
    componentDidMount() {
        // console.log(this.props.data.checklist);
        if (this.props.data.checklist) {
            this.setState({ checklist: true })
        }
    }
    roundDec = (num) => {
        let ROUNDED = Math.round(num * 100) / 100

        return ROUNDED
    }
    _renderStatus = () => {
        let display
        let color = this.state.checklist ? 'text-success' : 'text-danger'
        let status = this.state.checklist ? 'Submitted' : 'Not Submitted'

        display = !this.props.noStatus ?
        <div className="mr-3 d-flex align-items-center">
            <span className="mr-1">{status}</span>
            <i className={"fas fa-circle " + color}></i>
        </div> : null

        return display
    }
    _renderCarHealth = () => {
        let display
        const { data } = this.props
        let CAR_HEALTH = data.checklist
        let OVERALL = 0, INTERIOR_EXTERIOR = 0, UNDER_VEHICLE = 0, UNDER_HOOD = 0, TYRE = 0
        // let PROBLEM = []

        // Calculate partial score
        // console.log('aa', data);
        for (const key in CAR_HEALTH) {
            if (key !== 'VIN_number' && key !== 'customer_car_mileage' && key !== 'customer_next_car_mileage' && key !== 'customer_car_mileage_next_due' && key !== 'extra_notes' && key !== 'timestamp' && key !== 'timestamp_done' && key !== 'next_service_date' && key !== 'roadtax_expiry_date') {
                let RATING = CAR_HEALTH[key]
                if(typeof RATING !== "number" && RATING === "good"){
                    RATING = 5
                }
                if(typeof RATING !== "number" && RATING === "worn"){
                    RATING = 3
                }
                if(typeof RATING !== "number" && RATING === "bad"){
                    RATING = 1
                }

                OVERALL = OVERALL + parseInt(RATING)
                switch (key) {
                    // INTERIOR/EXTERIOR SCORE
                    case 'lights_exterior':
                    case 'lights_interior':
                    case 'windshield':
                    case 'wiper':
                        INTERIOR_EXTERIOR = INTERIOR_EXTERIOR + parseInt(RATING)
                        break;
                    // UNDER VEHICLE SCORE
                    case 'brake_lines':
                    case 'steering_system':
                    case 'brake_pad':
                    case 'cv_boots':
                    case 'aircond_operation':
                    case 'ac':
                    case 'exhaust':
                        UNDER_VEHICLE = UNDER_VEHICLE + parseInt(RATING)
                        break;
                    // TYRE SCORE
                    case 'tyre_left_front':
                    case 'tyre_left_rear':
                    case 'tyre_right_front':
                    case 'tyre_right_rear':
                        TYRE = TYRE + parseInt(RATING)
                        break;
                    // OTHER - UNDER HOOD
                    default:
                        UNDER_HOOD = UNDER_HOOD + parseInt(RATING)
                }

            }
        }

        let OVERALL_SCORE = this.roundDec((OVERALL / 130) * 100)
        let INT_EXT_SCORE = this.roundDec((INTERIOR_EXTERIOR / 20) * 100)
        let UNDER_VEHICLE_SCORE = this.roundDec((UNDER_VEHICLE / 30) * 100)
        let UNDER_HOOD_SCORE = this.roundDec((UNDER_HOOD / 60) * 100)
        let TYRE_SCORE = this.roundDec((TYRE / 20) * 100)

        // console.log(PROBLEM)

        display = this.props.data.checklist === undefined ? <div className="text-center" style={{ padding: '65px 25px' }}>SERV Partner assigned has not performed the service</div> :
            <>
                <Row>
                    <Col md={6}>
                        <div className="mb-3"><b>VIN # :</b> {CAR_HEALTH.VIN_number}</div>
                        <div className="mb-3"><b>Road Tax Expiry :</b> {CAR_HEALTH.roadtax_expiry_date}</div>
                        <div className="mb-3">
                            <div><b>SP notes :</b></div>
                            <div className="p-3 mt-1" style={{ border: '1px solid', borderRadius: '15px' }}>
                                {CAR_HEALTH.extra_notes !== '' ? CAR_HEALTH.extra_notes : 'SP did not input any extra notes.'}
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3"><b>Current Mileage :</b> {CAR_HEALTH.customer_car_mileage}</div>
                        <div className="mb-3"><b>Next Recommended Service :</b> {CAR_HEALTH.next_service_date} or {CAR_HEALTH.customer_car_mileage_next_due}</div>
                    </Col>
                </Row>
                <Row className="m-0 mt-2 mb-2 pt-3 pb-3">
                    <Col md={3}>
                        <div style={{ width: '50%', margin: '0 auto' }} className="text-center">
                            <CircularProgressbar value={OVERALL_SCORE} text={`${OVERALL_SCORE}%`} styles={{ path: { stroke: `#000080`, strokeLinecap: 'round' }, trail: { stroke: '#c0c2c5' }, text: { fill: '#000036', fontSize: '13px', transform: 'translate(-15%, 5%)' }, }} />
                            <div>Overall</div>
                        </div>
                    </Col>
                    <Col md={9} className="d-flex align-items-center">
                        <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                            <div className="parent-category text-center d-flex flex-column align-items-center">
                                <div style={{ width: '50%', margin: '0 auto' }}>
                                    <CircularProgressbar value={INT_EXT_SCORE} text={`${INT_EXT_SCORE}%`} styles={{ path: { stroke: `#000080`, strokeLinecap: 'round' }, trail: { stroke: '#c0c2c5' }, text: { fill: '#000036', fontSize: '13px', transform: 'translate(-15%, 5%)' }, }} />
                                </div>
                                <div>Interior / Exterior</div>
                            </div>

                            <div className="parent-category text-center d-flex flex-column align-items-center">
                                <div style={{ width: '50%', margin: '0 auto' }}>
                                    <CircularProgressbar value={UNDER_VEHICLE_SCORE} text={`${UNDER_VEHICLE_SCORE}%`} styles={{ path: { stroke: `#000080`, strokeLinecap: 'round' }, trail: { stroke: '#c0c2c5' }, text: { fill: '#000036', fontSize: '13px', transform: 'translate(-15%, 5%)' }, }} />
                                </div>
                                <div>Under Vehicle</div>
                            </div>

                            <div className="parent-category text-center d-flex flex-column align-items-center">
                                <div style={{ width: '50%', margin: '0 auto' }}>
                                    <CircularProgressbar value={UNDER_HOOD_SCORE} text={`${UNDER_HOOD_SCORE}%`} styles={{ path: { stroke: `#000080`, strokeLinecap: 'round' }, trail: { stroke: '#c0c2c5' }, text: { fill: '#000036', fontSize: '13px', transform: 'translate(-15%, 5%)' }, }} />
                                </div>
                                <div>Under Hood</div>
                            </div>

                            <div className="parent-category text-center d-flex flex-column align-items-center">
                                <div style={{ width: '50%', margin: '0 auto' }}>
                                    <CircularProgressbar value={TYRE_SCORE} text={`${TYRE_SCORE}%`} styles={{ path: { stroke: `#000080`, strokeLinecap: 'round' }, trail: { stroke: '#c0c2c5' }, text: { fill: '#000036', fontSize: '13px', transform: 'translate(-15%, 5%)' }, }} />
                                </div>
                                <div>Tyre</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div>
                    <small>Service Completed on {CAR_HEALTH.timestamp_done !== undefined ? new Date(CAR_HEALTH.timestamp_done).toLocaleString() : new Date(CAR_HEALTH.timestamp).toLocaleString()}</small>
                </div>
            </>

        return display
    }
    _renderPointBreakdown = () => {
        let display
        const { data } = this.props
        let CAR_HEALTH = data.checklist
        let OVERALL = [], INTERIOR_EXTERIOR = [], UNDER_VEHICLE = [], UNDER_HOOD = [], TYRE = []

        for (const key in CAR_HEALTH) {
            if (key !== 'VIN_number' && key !== 'customer_car_mileage' && key !== 'customer_next_car_mileage' && key !== 'customer_car_mileage_next_due' && key !== 'extra_notes' && key !== 'timestamp' && key !== 'timestamp_done' && key !== 'next_service_date' && key !== 'roadtax_expiry_date') {
                let RATING = CAR_HEALTH[key]
                if(typeof RATING !== "number" && RATING === "good"){
                    RATING = 5
                }
                if(typeof RATING !== "number" && RATING === "worn"){
                    RATING = 3
                }
                if(typeof RATING !== "number" && RATING === "bad"){
                    RATING = 1
                }

                switch (key) {
                    // INTERIOR/EXTERIOR SCORE
                    case 'lights_exterior':
                    case 'lights_interior':
                    case 'windshield':
                    case 'wiper':
                        INTERIOR_EXTERIOR.push(
                            <div className="pl-1 pr-1" key={key}>
                                <CarPartRatings condition={parseInt(RATING)} name={key} />
                            </div>
                        )
                        break;
                    // UNDER VEHICLE SCORE
                    case 'brake_lines':
                    case 'steering_system':
                    case 'brake_pad':
                    case 'cv_boots':
                    case 'aircond_operation':
                    case 'ac':
                    case 'exhaust':
                        UNDER_VEHICLE.push(
                            <div className="pl-1 pr-1" key={key}>
                                <CarPartRatings condition={parseInt(RATING)} name={key} />
                            </div>
                        )
                        break;
                    // TYRE SCORE
                    case 'tyre_left_front':
                    case 'tyre_left_rear':
                    case 'tyre_right_front':
                    case 'tyre_right_rear':
                        TYRE.push(
                            <div className="pl-1 pr-1" key={key}>
                                <CarPartRatings condition={parseInt(RATING)} name={key} />
                            </div>
                        )
                        break;
                    // OTHER - UNDER HOOD
                    default:
                        UNDER_HOOD.push(
                            <div className="pl-1 pr-1" key={key}>
                                <CarPartRatings condition={parseInt(RATING)} name={key} />
                            </div>
                        )
                }

            }
        }

        display =
            <>
                <hr />
                <h2 className="m-0 mt-4 text-center">Inspection Sheet</h2>
                <Row className="ml-0 mr-0">
                    <Col md={6}>
                        <h3 className="m-0 mt-5 font-weight-bold">Interior / Exterior</h3>
                        {INTERIOR_EXTERIOR}
                    </Col>

                    <Col md={6}>
                        <h3 className="m-0 mt-5 font-weight-bold">Under Vehicle</h3>
                        {UNDER_VEHICLE}
                    </Col>

                    <Col md={6}>
                        <h3 className="m-0 mt-5 font-weight-bold">Under Hood</h3>
                        {UNDER_HOOD}
                    </Col>

                    <Col md={6}>
                        <h3 className="m-0 mt-5 font-weight-bold">Tyre</h3>
                        {TYRE}
                    </Col>
                </Row>
            </>

        return display
    }
    _renderComponent = () => {
        let display

        display = this.props.data.checklist !== undefined ? this._renderPointBreakdown() : null

        return display
    }
    render() {
        return <CollapsibleCard title="Inspection Checklist" component={this._renderComponent} status={this._renderStatus} headerSneak={this._renderCarHealth} />;
    }
}
