import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import firebase from 'firebase';
import { inject, observer } from 'mobx-react';
import keywords from 'components/keywords.js';

class EditPromotions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalEdit: false,
            data: {},
        };
    }
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state],
            data: this.props.data,
        });
    };
    handleChange = (evt) => {
        // console.log(evt.target.name)
        if(evt.target.name !== 'body' && evt.target.name !== 'keyword'){
            this.setState({
                data: {
                    ...this.state.data,
                    [evt.target.name]: evt.target.value
                }
            }, () => {console.log('1',this.state.data)});
        } else {
            // console.log('body or keyword');
            
            this.setState({
                data: {
                    ...this.state.data,
                    description: {
                        ...this.state.data.description,
                        [evt.target.name]: evt.target.value
                    }
                }
            }, () => {console.log('2',this.state.data)});
        }
    }
    handleChangePromo = (evt) => {
        let SPACE = evt.target.value.split(" ").join("");
        let AMPH = SPACE.split('&').join("");
        let HASH = AMPH.split('#').join("");
        let PERC = HASH.split('%').join("");
        let BRACK_R = PERC.split(')').join("");
        let BRACK_L = BRACK_R.split('(').join("");
        let AST = BRACK_L.split('*').join("");
        let DOLLA = AST.split('$').join("");
        let EXC = DOLLA.split('!').join("");
        const VALUE = EXC.toUpperCase();

        this.setState({
            data: {
                ...this.state.data,
                [evt.target.name]: VALUE
            }
        });
    }
    _save = () => {
        let that = this;
        console.log('save', this.state.data)
        
        let promo_code = this.state.data.promo_code.toUpperCase();
        let old_promo_code = this.props.data.promo_code.toUpperCase();
        let ErrorHandler = this.state.data;
        // 1. check if promo code empty
        if(ErrorHandler.promo_code === ''){
            alert('Please fill in promo code!');
            return;
        }
        // 2. check if promo code already exists
        if (this.props.codeCheck.indexOf(promo_code) !== -1 && promo_code !== old_promo_code){
            alert('Promo code already exists!!');
            return;
        }
        // 3a. deduct rm exists, not zero
        if(ErrorHandler.deduct_RM !== undefined && (ErrorHandler.deduct_RM === "0" || ErrorHandler.deduct_RM === "")){
            alert('Please specify amount deducted from promo code!')
            return;
        }
        // 3b-1. deduct percentage exists, tier type not empty
        if(ErrorHandler.deduct_percent !== undefined && (ErrorHandler.user_type === '' || ErrorHandler.user_tier === '')){
            alert('Please specify business type and tier!')
            return;
        }
        // 3b-2. deduct percentage exists, not zero
        if(ErrorHandler.deduct_percent !== undefined && (ErrorHandler.deduct_percent === "0" || ErrorHandler.deduct_percent === "")){
            alert('Please percentage amount deducted from promo code!')
            return;
        }
        // 4. check keyword
        if(ErrorHandler.deduct_percent !== undefined && ErrorHandler.keyword === ''){
            alert('Please specify promo code keyword!')
            return;
        }
        // 5. check body
        if(ErrorHandler.deduct_percent !== undefined && ErrorHandler.body === ''){
            alert('Please specify promo code description!')
            return;
        }

        let DATA = {};
        if(this.state.data.deduct_RM !== undefined){
            DATA = {
                deduct_RM : this.state.data.deduct_RM,
                usage: this.state.data.usage,
                user: this.state.data.user !== undefined ? this.state.data.user : null,
                user_type: '',
                user_tier: '',
                description: {
                    keyword: '',
                    body: '',
                }
            }
        }
        if(this.state.data.deduct_RM === undefined){
            DATA = {
                deduct_percent : this.state.data.deduct_percent,
                usage: this.state.data.usage,
                user: this.state.data.user !== undefined ? this.state.data.user : null,
                user_type: this.state.data.user_type,
                user_tier: this.state.data.user_tier,
                description: {
                    keyword: this.state.data.description.keyword,
                    body: this.state.data.description.body,
                }
            }
        }

        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        firebase.app(COUNTRY_DB).database().ref('promo_code/' + promo_code).update(DATA).then(() => {
            if(promo_code !== old_promo_code){
                firebase.app(COUNTRY_DB).database().ref('promo_code/' + old_promo_code).remove().then(() => {
                    this.toggleModal('modalEdit')
                    that.props.fetchParent()
                })
            } else {
                this.toggleModal('modalEdit')
                that.props.fetchParent()
            }
        })
    }
    _renderByType = () => {
        let display;

        if(this.props.data.deduct_RM !== undefined){
            display =
            <FormGroup>
                <Label for="deduct_RM">Amount Deducted</Label>
                <Input type="number" name="deduct_RM" id="deduct_RM" onChange={this.handleChange} value={this.state.data.deduct_RM}/>
            </FormGroup>
        }
        if(this.props.data.deduct_RM === undefined){
            let TYPE = this.props.type_data.type.map((data,index) => (
                <option key={index} value={data.id}>{data.name}</option>
            ))
            let TIER = [], TIERSELECT;
            for(const tier_id in this.props.type_data.tiers[this.state.data.user_type]) {
                TIER.push(
                    <option key={tier_id} value={tier_id}>{this.props.type_data.tiers[this.state.data.user_type][tier_id].name}</option>
                )
            }
            TIER.unshift(
                <option key={TIER.length+1} value={'all'}>All Tiers</option>
            )

            TIERSELECT =
            <FormGroup>
                <Label for="user_tier">Select Tier</Label>
                <Input type="select" name="user_tier" id="user_tier" value={this.state.data.user_tier} onChange={this.handleChange}>
                    {TIER}
                </Input>
                <FormText className="muted"><small>{this.state.data.user_tier}</small></FormText>
            </FormGroup>
    
            let TYPESELECT =
            <FormGroup>
                <Label for="user_type">Select Business Type</Label>
                <Input type="select" name="user_type" id="user_type" value={this.state.data.user_type} onChange={this.handleChange}>
                    {TYPE}
                </Input>
                <FormText className="muted"><small>{this.state.data.user_type}</small></FormText>
            </FormGroup>

            let DESCRIPTION =
            <FormGroup>
                <Label for='body'>Promo Code Description</Label>
                <Input type='text' name='body' maxLength={70} id='body' onChange={this.handleChange} value={this.state.data.description !== undefined ? this.state.data.description.body : ''}/>
                <FormText className="muted">
                    This is the description that will appear in the 'Perks' section of the SERV User app. <span style={{color:'red', fontWeight:'bold'}}>{this.state.data.description !== undefined ? 70 - this.state.data.description.body.length : 70} characters left.</span>
                </FormText>
            </FormGroup>
            let options = keywords.map((data,index) => (
                <option key={index} value={data.keyword}>{data.desc}</option>
            ))
            // options.unshift(
            //     <option key={options.length+1} value={''}>--Select--</option>
            // )
            let KEYWORD =
            <FormGroup>
                <Label for='keyword'>Promo Code Keyword</Label>
                <Input type='select' name='keyword' id='keyword' onChange={this.handleChange} value={this.state.data.description !== undefined ? this.state.data.description.keyword : ''}>{options}</Input>
            </FormGroup>

            display =
            <div>
                {TYPESELECT}
                {TIERSELECT}

                <FormGroup>
                    <Label for="deduct_percent">Percentage Deducted</Label>
                    <Input type="number" name="deduct_percent" id="deduct_percent" onChange={this.handleChange} value={this.state.data.deduct_percent}/>
                </FormGroup>

                {DESCRIPTION}
                {KEYWORD}
            </div>
        }
        
        return display;
    }
    render() {
        return (
            <div>
                <Button color="dark" onClick={() => {
                    this.toggleModal('modalEdit')
                }}>Edit</Button>

                <Modal fade={false} className='modal-default' isOpen={this.state.modalEdit} toggle={() => {this.toggleModal('modalEdit')}}>
                    <ModalHeader toggle={() => {this.toggleModal('modalEdit')}}>Edit promo code</ModalHeader>
                    <ModalBody>
                        OLD : {this.props.data.promo_code}
                        <FormGroup className="mt-3">
                            <Label for="promo_code">Promo Code</Label>
                            <Input type="text" name="promo_code" id="promo_code" onChange={this.handleChangePromo} value={this.state.data.promo_code}/>
                        </FormGroup>

                        {this._renderByType()}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={this._save}>Save</Button>{' '}
                        <Button className='ml-auto' color='secondary' onClick={() => {this.toggleModal('modalEdit')}}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

EditPromotions.propTypes = {
    data: PropTypes.object,
    type_data: PropTypes.object,
    codeCheck: PropTypes.array,
    fetchParent: PropTypes.func,
};

export default EditPromotions;
EditPromotions = inject('mobx_auth')(observer(EditPromotions))