export default () => {
      // eslint-disable-next-line
    self.addEventListener('message', e => {
        if(!e) return;
        var DATA = e.data
        let WORKSHOP = DATA.workshop
        let WORKSHOP_MAIN = DATA.workshop_main
        let RESPONSE = {}
        let RETAIL_MAIN = []
        let COUNT_VERIFIED = 0, COUNT = 0
        for (const group in WORKSHOP_MAIN) {
            let a = WORKSHOP_MAIN[group]

            // console.log("retail", a)
            a.group_id = group
            let OUTLETS = []
            // console.log(group)

            // 2. iterate through each outlet in workshop and store into outlet array
            for (const workshop_id in WORKSHOP[group]) {
                let CURRENT = WORKSHOP[group][workshop_id]
            

                // console.log('aaaa', CURRENT);
                if (CURRENT.display === "3") {
                    COUNT_VERIFIED = COUNT_VERIFIED + 1
                }
                CURRENT.workshop_id = workshop_id

                if(CURRENT.preferred !== undefined && CURRENT.preferred === true) {
                    COUNT = COUNT + 1
                }

                OUTLETS.push(CURRENT)
               
            }

            // 3. push array of outlets into the main object 
            // console.log("OUTLET", OUTLETS)
            a.OUTLETS = OUTLETS

            RETAIL_MAIN.unshift(a)
        }
        RESPONSE.retail_main = RETAIL_MAIN
        RESPONSE.count = COUNT
        postMessage(RESPONSE)
    });
};