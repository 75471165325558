import React, { Component } from 'react'
import { Card, Row, Col, CardBody, CardHeader, Button, Badge, } from "reactstrap";

import { serv_user_development, serv_user_production } from 'configs/firebaseCF.js'

import { inject, observer } from 'mobx-react';
import AddAdmin from 'components/AddAdmin';
import VerifyWorkshop from 'pages/MerchantVerify';

import RETAIL from 'static_data/retail.json'
import RETAIL_MAIN from 'static_data/retail_main.json'

import STAMP_PLACEHOLDER from 'assets/img/car.svg'
import PLACEHOLDER from 'assets/img/logo-placeholder.png'
import BANNER_PLACEHOLDER from 'assets/img/bg5.jpg'
import VERIFIED from 'assets/img/verified.svg'
import UNVERIFIED from 'assets/img/unverified.svg'

import { createHashHistory } from 'history';
import ErrorAlert from 'components/ErrorAlert';
const history = createHashHistory();

export default class AnalyticsIndividual extends Component {
    state = { data: {}, load: '1', screen: 0, toggle: false, success: false, reload: false }
    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
        this.fetchWorkshopIndividual()
        // this.fetchStatic()
    }
    resize = () => {
        this.setState({ screen: window.innerWidth });
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    fetchWorkshopIndividual = () => {
        let URL, csrfTokenU, csrfSecretU, headerToken;
        const { browser } = this.props.mobx_config

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.workshopFetchIndividual
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.workshopFetchIndividual
        }
        let GROUP = 'group=' + this.props.id
        let FINAL_URL = URL + GROUP
        let OPTIONS = { credentials: 'include', headers: headerToken }

        // console.log(GROUP)

        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                let WORKSHOPS = []
                let retail = response.retail
                let retail_main = response.retail_main
                // console.log(response)
                // console.log(response.retail_main)

                if (response.retail_main !== null && response.retail !== null) {
                    let DATA = retail_main
                    // console.log(DATA);
                    DATA.group_id = this.props.id
                    // 1. iterate through each outlet
                    for (const retail_id in retail) {
                        let a = retail[retail_id]
                        a.retail_id = retail_id

                        WORKSHOPS.push(a)
                    }

                    DATA.OUTLETS = WORKSHOPS

                    this.setState({ load: '2', data: DATA }, () => { console.log(this.state.data) })
                }
            } else {
                this.setState({ load: '3' })
            }
        })
    }
    fetchStatic = () => {
        let WORKSHOPS = []
        let retail = RETAIL
        let retail_main = RETAIL_MAIN

        let DATA = retail_main[this.props.id]
        // console.log(retail);
        DATA.group_id = this.props.id
        // 1. iterate through each outlet
        for (const retail_id in retail[this.props.id]) {
            let a = retail[this.props.id][retail_id]
            a.retail_id = retail_id

            WORKSHOPS.push(a)
        }

        DATA.OUTLETS = WORKSHOPS

        this.setState({ load: '2', data: DATA }, () => { console.log(this.state.data) })
    }
    _back = () => {
        history.push('/user/Merchant-Analytics')
    }
    _renderInfo = () => {
        let display
        const { data } = this.state

        display =
            <Card>
                <CardHeader>
                    <h5 className="m-0 font-weight-bold">Retail Info</h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={2} style={{ display: this.state.screen <= 425 ? 'none' : null }}>
                            <img src={data.logo !== '' ? data.logo : PLACEHOLDER} style={{ width: '100%' }} />
                        </Col>
                        <Col md={10}>
                            <div>
                                <div className="font-weight-bold" style={{ fontSize: '1.2em' }}>{data.name}</div>
                                <small>{data.address}</small>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        return display
    }
    _renderRate = (rate, justify_content) => {
        let display
        let RATE = rate

        if (RATE === 0) {
            display =
                <div className={"d-flex align-items-center " + justify_content}>
                    <i
                        className="fas fa-star mr-2"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                    <i
                        className="fas fa-star mr-2"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                    <i
                        className="fas fa-star mr-2"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                    <i
                        className="fas fa-star mr-2"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                    <i
                        className="fas fa-star"
                        style={{ color: '#ccc', fontSize: '1.7em' }}
                    />
                </div>

            return display
        }

        display =
            <div className={"d-flex align-items-center " + justify_content}>
                <i
                    className={RATE < 0 || RATE >= 1 ? "fas fa-star mr-2" : "fas fa-star-half mr-2"}
                    style={{ color: RATE === 1 || RATE > 0 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
                <i
                    className={RATE < 1 || RATE >= 2 ? "fas fa-star mr-2" : "fas fa-star-half mr-2"}
                    style={{ color: RATE === 2 || RATE > 1 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
                <i
                    className={RATE < 2 || RATE >= 3 ? "fas fa-star mr-2" : "fas fa-star-half mr-2"}
                    style={{ color: RATE === 3 || RATE > 2 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
                <i
                    className={RATE < 3 || RATE >= 4 ? "fas fa-star mr-2" : "fas fa-star-half mr-2"}
                    style={{ color: RATE === 4 || RATE > 3 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
                <i
                    className={RATE < 4 || RATE >= 5 ? "fas fa-star" : "fas fa-star-half"}
                    style={{ color: RATE === 5 || RATE > 4 ? '#fdc115' : 'transparent', fontSize: '1.7em' }}
                />
            </div>

        return display
    }
    _renderLoyalty = () => {
        let display
        const { data } = this.state

        if (this.state.data.loyalty !== undefined && this.state.loyalty !== '') {
            let STAMPS = []

            for (let i = 1; i <= data.loyalty.max; i++) {
                let a = i
                STAMPS.push(a)
            }

            display =
                <Card>
                    <CardHeader
                        style={{
                            background: `linear-gradient( rgba(255,255,255, 0.2),  rgba(255,255,255, 0.2) ), url(${data.loyalty !== undefined ? data.loyalty.banner : BANNER_PLACEHOLDER})`,
                            backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100px', borderRadius: '1rem 1rem 0 0'
                        }}
                    >
                        <h5 className="m-0 font-weight-bold">Loyalty Info</h5>
                    </CardHeader>
                    <CardBody>
                        <h6 className="m-0 mb-2">{data.loyalty.title}</h6>
                        <div>
                            {data.loyalty.description}
                        </div>

                        <h6 className="m-0 mt-4">Stamps ({data.loyalty.max})</h6>
                        <div className="d-flex justify-content-between align-self-center flex-wrap" style={{ margin: '0 -5px' }}>
                            {STAMPS.map((data, i) => (
                                <div style={{ fontSize: '1.1em', fontWeight: 'bold', width: 'calc(20% - 10px)', paddingTop: 'calc(20% - 10px)', margin: '5px', background: `url(${STAMP_PLACEHOLDER})`, borderRadius: '50%', backgroundPosition: 'center', backgroundSize: 'contain' }} key={i}></div>
                            ))}
                        </div>
                    </CardBody>
                </Card>
        } else {
            display =
                <Card body style={{ padding: '45px 15px' }} className="text-center">
                    No data available
            </Card>
        }

        return display
    }
    _renderOutlets = () => {
        let display
        const { data } = this.state

        display =
            <Card>
                <CardHeader>
                    <h5 className="m-0 font-weight-bold">Outlets</h5>
                </CardHeader>
                <CardBody style={{ paddingRight: '10px' }}>
                    <div className="custom-scrollbar" style={{ maxHeight: '600px', overflowY: 'scroll', paddingRight: '5px' }}>
                        {data.OUTLETS !== undefined ? data.OUTLETS.map((data, index) => (
                            <Card body key={index} style={{ boxShadow: 'none', border: '1px solid', height: '300px' }} className="d-flex flex-column justify-content-between">
                                <div>
                                    <div className="m-0 mb-2 d-flex justify-content-end">
                                        {data.display !== undefined && data.display === '3' ?
                                            <img src={VERIFIED} style={{ width: '20px', marginLeft: '10px' }} /> :
                                            data.display !== '3' && data.display === '1' ?
                                                <div>
                                                    <Badge pill color="danger">Unlisted</Badge> <VerifyWorkshop id={this.props.id} data={data} parentRefresh={this.fetchWorkshopIndividual} />
                                                </div> :
                                                <div>
                                                    <Badge pill color="info">Unverified</Badge> <VerifyWorkshop id={this.props.id} data={data} parentRefresh={this.fetchWorkshopIndividual} />
                                                </div>
                                        }
                                    </div>

                                    <h6 className="m-0 mb-2">
                                        {data.name}
                                        <Badge pill className="ml-1" color="warning">{data.role}</Badge>
                                    </h6>

                                    <div>Address : {data.address}</div>
                                    <div>Description : {data.description}</div>
                                    <div>Phone : {data.phone}</div>
                                    {data.retail_admin !== undefined ?
                                        <div>{Object.keys(data.retail_admin).length} admin(s)</div> : <div>No Admin</div>
                                    }
                                    <Badge pill color="light">{data.type}</Badge>
                                </div>

                                {data.reviews_overall !== undefined ?
                                    <div>
                                        <div style={{ fontSize: '2em', fontWeight: 'bold' }}>{data.reviews_overall.rate.toFixed(1)}</div>

                                        <div className="d-flex justify-content-start align-items-center">
                                            {this._renderRate(data.reviews_overall.rate, 'justify-content-start')}
                                            <span className="ml-1">({data.reviews_overall.count})</span>
                                        </div>
                                    </div> :
                                    <div>
                                        <div style={{ fontSize: '2em', fontWeight: 'bold' }}>0</div>

                                        <div className="d-flex justify-content-start align-items-center">
                                            {this._renderRate(0, 'justify-content-start')}
                                            <span className="ml-1">(0)</span>
                                        </div>
                                    </div>
                                }
                            </Card>
                        )) :
                            <div style={{ padding: '45px 25px' }}>
                                No data available
                        </div>
                        }
                    </div>
                </CardBody>
            </Card>

        return display
    }
    _renderContent = () => {
        let display
        const { load, data } = this.state

        if (load == '1') {
            display = <Card body><div className="loader"></div></Card>
        }
        if (load == '2') {
            display =
                <>
                    {this._renderInfo()}
                    <Row>
                        <Col md={8}>
                            {this._renderOutlets()}
                        </Col>
                        <Col md={4}>
                            {this._renderLoyalty()}
                        </Col>
                    </Row>
                </>
        }
        if (load == '3') {
            display = <Card body className="text-center">Nothing to display here.</Card>
        }

        return display
    }
    render() {
        return (
            <>
                <div className="position-relative d-flex justify-content-between">
                    <Button color="dark" onClick={this._back}>Back</Button>
                    <AddAdmin data={this.state.data} id={this.props.id} />
                </div>

                {this._renderContent()}
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </>
        )
    }
}

AnalyticsIndividual = inject('mobx_config')(observer(AnalyticsIndividual))