import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { serv_user_development, serv_provider_development, serv_user_production, serv_provider_production } from 'configs/firebaseCF.js';
import { inject, observer } from 'mobx-react'
import LOGO from "assets/img/logo-placeholder.jpg"
import { options } from 'yargs';
import ErrorAlert from './ErrorAlert';

class BusinessAddTiers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            user_tier: '',

            PreviewLogo: LOGO,
            canUploadLogo: false,
            toggle: false,
            success: false,
            reload: false
        };
    }
    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    }
    childToggle = () => this.setState({ toggle: false }, () => {
        if (this.state.reload === true) {
            sessionStorage.removeItem('CT')
            document.location.reload()
        }
    })
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    //HANDLE THE CHOOSE FILE BUTTON
    handleLogoChange = (evt) => {
        let that = this;
        const file = document.querySelector('#upload_logo').files[0];

        if (file) {
            var checker = new Image();

            checker.src = window.URL.createObjectURL(file);

            checker.onload = function () {
                let fileSize = file.size;
                // var width = checker.naturalWidth,height = checker.naturalHeight;
                window.URL.revokeObjectURL(checker.src);
                if (fileSize < 100000) {
                    that.setState({ canUploadLogo: true, PreviewLogo: URL.createObjectURL(file) })
                } else {
                    that.setState({ canUploadLogo: false })
                }
            }
        } else {
            console.log("Image is empty");
        }

    }
    _save = () => {
        let ID = this.props.type_id
        let TIER = this.state.user_tier
        const { browser } = this.props.mobx_config

        // console.log('save', TIER, ID)

        if (this.state.user_tier === '') {
            alert('Please enter Tier name!');
            return;
        }

        let URL, csrfTokenU, csrfSecretU, headerToken;
        if (this.props.mobx_config.config === 0) {
            URL = serv_user_development.addUserTier
        }
        if (this.props.mobx_config.config === 1) {
            URL = serv_user_production.addUserTier
        }

        if (browser === 'safari') {
            csrfTokenU = sessionStorage.getItem('CSRFTokenU')
            csrfSecretU = sessionStorage.getItem('CSRFSecretU')
            headerToken = { csrftoken: csrfTokenU, csrfsecret: csrfSecretU }
        }

        let USER_TIER = 'user_tier=' + TIER
        let TYPE_ID = '&type_id=' + ID

        let FINAL_URL = URL + USER_TIER + TYPE_ID;
        let OPTIONS = { credentials: 'include', headers: headerToken }

        fetch(FINAL_URL, OPTIONS).then(response => {
            if (response.status !== 200) {
                this.setState({ toggle: true, success: false, reload: true })
                return response.status
            }
            return response.json()
        }).then(response => {
            if (response !== 401) {
                // console.log(response.data);
                this.setState({ collapse: !this.state.collapse, user_tier: '' })
                this.props.parentRefresh();
            }
        })
    }
    render() {
        return (
            <Row className="mb-3">
                <Col md={2}>
                    <Button className="m-0 mr-3" style={{ width: '100%' }} color="dark" onClick={this.toggle}>Add Tier</Button>
                </Col>
                <Col md={10}>
                    <Collapse isOpen={this.state.collapse}>
                        <div style={{ border: '1px solid', borderRadius: '5px', padding: '15px' }}>
                            <FormGroup row className="align-items-center m-0">
                                <Label for='user_tier' className="m-0">Tier Name</Label>
                                <Col md={10}>
                                    <Input type='text' name='user_tier' id='user_tier' onChange={this.handleChange} value={this.state.user_tier} />
                                </Col>
                            </FormGroup>

                            <Label for="upload_logo">Upload Logo</Label>
                            <Input type="file" accept="image/*" name="upload_logo" id="upload_logo" onChange={this.handleLogoChange} />
                            <FormText color="danger">
                                Logo (Make sure the the image size is less than 100kb!)
                            </FormText>
                            <div>
                                <img style={{ width: 250 }} src={this.state.PreviewLogo} />
                            </div>

                            <Button color="light" onClick={this._save}>Save</Button>
                        </div>
                    </Collapse>
                </Col>
                <ErrorAlert toggle={this.state.toggle} parentToggle={this.childToggle} success={this.state.success} />
            </Row>
        );
    }
}

BusinessAddTiers.propTypes = {
    type_id: PropTypes.string.isRequired,
    parentRefresh: PropTypes.func,
};
BusinessAddTiers = inject('mobx_config')(observer(BusinessAddTiers));
export default BusinessAddTiers;