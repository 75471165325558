import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CarPartRatings extends Component {
    _renderChecklist = () => {
        let display;
        display = this.props.condition

        if(this.props.condition === 0 || this.props.condition === '0') {
            display =
            <div className="rating">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        }
        if(this.props.condition === 1 || this.props.condition === '1') {
            display =
            <div className="rating">
                <span style={{background:'#dc3545', borderColor: '#dc3545'}}></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        }
        if(this.props.condition === 2 || this.props.condition === '2') {
            display =
            <div className="rating">
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        }
        if(this.props.condition === 3 || this.props.condition === 'worn' || this.props.condition === '3') {
            display =
            <div className="rating">
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span></span>
                <span></span>
            </div>
        }
        if(this.props.condition === 4 || this.props.condition === '4') {
            display =
            <div className="rating">
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span style={{background:'#ffc107', borderColor: '#ffc107'}}></span>
                <span></span>
            </div>
        }
        if(this.props.condition === 5 || this.props.condition === 'good' || this.props.condition === '5') {
            display =
            <div className="rating">
                <span style={{background:'#28a745', borderColor: '#28a745'}}></span>
                <span style={{background:'#28a745', borderColor: '#28a745'}}></span>
                <span style={{background:'#28a745', borderColor: '#28a745'}}></span>
                <span style={{background:'#28a745', borderColor: '#28a745'}}></span>
                <span style={{background:'#28a745', borderColor: '#28a745'}}></span>
            </div>
        }

        return display;
    }
    _renderName = () => {
        let display;
        let NAME = this.props.name.split('_')
        
        
        display =
        <p style={{margin:'0'}}>
            {NAME.map((data, index) => (
                <span key={index}>{data.charAt(0).toUpperCase() + data.substr(1) + ' '} </span>
            ))} :
        </p>

        return display;
    }

    render() {
        let { condition, name } = this.props;
        return (
            <div className="rating parent" style={{borderBottom: this.props.noBorder ? 'none' :'1px solid rgba(0,0,0,0.4)'}}>
                <div>{this._renderName()}</div> <div>{this._renderChecklist()}</div>
            </div>
        );
    }
}

CarPartRatings.propTypes = {
    condition: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    noBorder: PropTypes.bool,
};

export default CarPartRatings;