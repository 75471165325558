import { observable, action, decorate } from "mobx";
//import { computed, toJS } from "mobx";
export default class Quote {
  load_table = false;
  setLoadTable(load_table) {
    this.load_table = load_table;
  }

  jobKey = '';
  setJobKey(jobKey) {
    this.jobKey = jobKey;
  }

  quotationItems = [];
  setQuotationItems(quotationItems) {
    this.quotationItems = quotationItems;
  }

  servproviderUID = '';
  setSERVID(servproviderUID) {
    this.servproviderUID = servproviderUID;
  }

  chat_loading = false;
  setChatLoad(chat_loading) {
    this.chat_loading = chat_loading;
  }

  providerName = 'false';
  setProviderName(providerName) {
    this.providerName = providerName;
  }

  assign_id = '';
  setAssignID(assign_id) {
    this.assign_id = assign_id;
  }
  assign_name = '';
  setAssignName(assign_name) {
    this.assign_name = assign_name;
  }

  check_assign = false;
  setCheckAssign(check_assign) {
    this.check_assign = check_assign;
  }

  basicORpremium = '';
  setBasiPremium(basicORpremium) {
    this.basicORpremium = basicORpremium
  }

  inspection_quote = false;
  setInspectionQuote(inspection_quote) {
    this.inspection_quote = inspection_quote
  }

  selectData = {};
  setSelectData(selectData) {
    this.selectData = selectData;
  }
}

decorate(Quote, {
  _tabA: observable,
  setTabA: action,

  load_table: observable,
  setLoadTable: action,
  jobKey: observable,
  setJobKey: action,
  quotationItems: observable,
  setQuotationItems: action,

  servproviderUID: observable,
  setSERVID: action,
  chat_loading: observable,
  setChatLoad: action,
  providerName: observable,
  setProviderName: action,

  assign_id: observable,
  setAssignID: action,
  assign_name: observable,
  setAssignName: action,
  check_assign: observable,
  setCheckAssign: action,

  basicORpremium:observable,
  setBasiPremium:action,
  inspection_quote:observable,
  setInspectionQuote:action,

  selectData: observable,
  setSelectData: action,
});
