import React, { Component } from "react"
import firebase from 'firebase';
import { Row, Col } from 'reactstrap';
import ChecklistDisplay from "../components/ChecklistDisplay";
import CarPartRatings from 'components/CarPartRatings.jsx';

export default class UserVehicleDisplay extends Component {
    state = {
        vehicle: '',
        vehicle_details: ''
    }

    _renderCarHealth = () => {
        const { selectedVehicle } = this.props
        let vehicle = selectedVehicle.car_details
        let display, LEFT = [], RIGHT = [];
        let keys = Object.keys(vehicle.car_health)
        
        keys.forEach((carHealth_item, index) => {
            if (carHealth_item !== 'VIN_number' &&
                carHealth_item !== 'customer_car_mileage' &&
                carHealth_item !== 'customer_car_mileage_next_due' &&
                carHealth_item !== 'next_service_date' &&
                carHealth_item !== 'roadtax_expiry_date') {
                if (index <= 16) {
                    LEFT.push(
                        <CarPartRatings key={index} condition={vehicle.car_health[carHealth_item]} name={carHealth_item} />
                    )
                }
                if (index > 16) {
                    RIGHT.push(
                        <CarPartRatings key={index} condition={vehicle.car_health[carHealth_item]} name={carHealth_item} />
                    )
                }
            }
        })

        display =
            <Row>
                <Col md={6}>{LEFT}</Col>
                <Col md={6}>{RIGHT}</Col>
            </Row>

        return display;
    }
    _renderVehicleDetails = () => {
        const { selectedVehicle } = this.props
        let vehicle = selectedVehicle.car_details
        let display
        display =
            <div>
                <Row>
                    <Col md={6}>
                        <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Make : </span>{vehicle.make}
                        </div>
                        <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Model : </span>{vehicle.model}
                        </div>
                        <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>CC : </span>{vehicle.carcc}
                        </div>
                        <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Year : </span>{vehicle.year}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Road Tax Expiry : </span>
                            {vehicle.car_health && vehicle.car_health.roadtax_expiry_date ? vehicle.car_health.roadtax_expiry_date : 'Not available'}
                        </div>
                        <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>VIN Number : </span>
                            {vehicle.car_health && vehicle.car_health.VIN_number ? vehicle.car_health.VIN_number : 'Not available'}
                        </div>
                        <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Car Mileage : </span>
                            {vehicle.car_health && vehicle.car_health.customer_car_mileage ? vehicle.car_health.customer_car_mileage : 'Not available'}
                        </div>
                        <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Next recommended Service : </span>
                            {vehicle.car_health && vehicle.car_health.next_service_date ? vehicle.car_health.next_service_date : 'Not available'}
                        </div>
                    </Col>
                </Row>

                <h5 style={{ paddingTop: '30px' }}>Car Health</h5>
                {vehicle.car_health ? this._renderCarHealth() :
                    <div className="text-center" style={{ padding: "10px 25px" }}>
                        This vehicle has no car health
                    </div>}
               
            </div>


        return display
    }

    render() {
        const { selectedVehicle } = this.props
        return (
            <div>
                <h3 style={{ fontWeight: 'bold' }}>{selectedVehicle.vehicle_id}</h3>
                <h5 >Vehicle Details </h5>
                {this._renderVehicleDetails()}
            </div>
        )
    }
}

