import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop';
import LOGO from 'assets/img/placeholder-popup.png'
import getCroppedImg from 'components/cropImage';
import Compressor from 'compressorjs';
import ErrorAlert from 'components/ErrorAlert';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Progress, Label, Input, FormText, Card, Form, FormGroup, Row, Col  } from 'reactstrap';
import firebase from 'firebase';
import { inject, observer } from 'mobx-react';
export default class MarketingPopupAdd extends Component {
    static propTypes = {
        // prop: PropTypes
    }
    state = {
        modal : false, modalEdit : false,
        link : '', compressedFile : null,
        toggle : false, text : '', displayButt : true,
        canUpload : false, previewUpload : LOGO,
        croppedImage: null, croppedAreaPixels: { x: 0, y: 0 },
        crop: { x: 0, y: 0 }, zoom: 1, aspect: 1 / 1.5,
        progress: { text : '', percentage : 0 },
        edit : null,
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    }
    onCropChange = (crop) => {
        this.setState({ crop })
    }
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }
    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }
    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    handleImage = () => {
        const file = document.querySelector('#upload_popup').files[0]
        if(file){
            if(!file.type.includes("image/")) {
                console.log('not an image');
            }

            var checker = new Image();
            checker.src = window.URL.createObjectURL(file)

            checker.onload = () => {
                let fileSize = file.size;
                console.log(fileSize, file.type);
                window.URL.revokeObjectURL(checker.src)
                if(fileSize < 500000) {
                    this.setState({canUpload:true, previewUpload: URL.createObjectURL(file), croppedImage: URL.createObjectURL(file)})
                } else {
                    document.querySelector('#upload_popup').value = ""
                    this.setState({ previewUpload: LOGO, canUpload:false, toggle:true,
                        text: <span>File size too large! File size has to be less than 100KB.<br/>You can compress your image <a href="https://resizeimage.net/" target="_blank" style={{textDecoration:'underline'}}>here</a></span> })
                }
            }
        } else {
            this.setState({ previewUpload: LOGO, canUpload:false, toggle:true, text: <span>Please select an image to upload!</span> })
        }
    }
    errorHandler = () => {
        const file = document.querySelector('#upload_popup').files[0]
        const { name, link, canUpload } = this.state
        if(name === ""){
            this.setState({ previewUpload: LOGO, canUpload:false, toggle:true, text: <span>Please fill in the name of the campaign.</span> })
            return;
        }
        if(link === ""){
            this.setState({ previewUpload: LOGO, canUpload:false, toggle:true, text: <span>Please fill in the link to open the webview for the campaign.</span> })
            return;
        }
        if(!file || !canUpload){
            this.setState({ previewUpload: LOGO, canUpload:false, toggle:true, text: <span>Please select an image to upload!</span> })
            return;
        }
        this.fetchCroppedImage();
    }
    fetchCroppedImage = async () => {
        try {
            let progress = {text:'compressing image', percentage:15}
            this.setState({ progress  })

            const croppedImage = await getCroppedImg(this.state.previewUpload, this.state.croppedAreaPixels)
            console.log('donee', croppedImage, this.state.progress)

            let compressedImage = await new Compressor(croppedImage, {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                success: async (compressedResult) => {
                    console.log('compressed result', compressedResult);
    
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.        
                    this.setState({ croppedImage : compressedResult })
                    progress = {text:'saving data', percentage:35}
                    console.log(this.state.croppedImage)
                    this.setState({ progress  }, () => {
                        console.log('can upload', this.state.progress);
                        this._savePopup()
                    })
                },
            })
        } catch (e) {
            console.error(e)
            console.log("You're catched")
        }

    }
    _savePopup = async () => {
        let TEMP = null
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        // 1. check if there is current popup existing
        let CHECK = await firebase.app(COUNTRY_DB).database().ref(`marketing_popup`).once('value')
        if(CHECK.exists()) {
            // 2a. if exist, store in a temp variable
            TEMP = CHECK.val()
        }
        // 2b. if no current popup, proceed to number 3
        // else console.log('tak ada la');
        // 3. overwrite/update firebase branch for marketing_popup
        this._savePopupData(TEMP)
    }
    _savePopupData = (TEMP) => {
        const { name, link, id, croppedImage } = this.state
        let blob = croppedImage, that = this
        let progress = { text:'saving data', percentage:50 }
        
        this.setState({ progress })
        let DATA = {
            link : link,
            croppedImage : croppedImage
        }
        // console.log(DATA);
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let KEY = firebase.app(COUNTRY_DB).database().ref('marketing_popup/').push().getKey();
        const storageRef = firebase.storage().ref('marketing_popup');
        let uploadBanner = storageRef.child(KEY).put(blob);
        
        uploadBanner.on('state_changed', (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                // that.setState({progressColor:'warning', text : 'PAUSED'})
                break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                // that.setState({progressColor:'success', text : 'UPLOADING'})
                break;
            }
        }, (error) => {
            // IF IMAGE UPLOAD FAILED
            alert('Upload failed, please try again!\n', error.message);
            that.setState({
                displayButt: true, modalError:true,
                text: <span>Error uploading image!<br/>Check your internet connection and try again.</span>,
                percentage:70 
            })
        }, () => {
            // IF IMAGE UPLOAD SUCCEED
            uploadBanner.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // Save new popup data into marketing_popup
                firebase.app(COUNTRY_DB).database().ref(`marketing_popup/`).update({
                    link : link,
                    banner_image: downloadURL,
                    timestamp: new Date().getTime()
                }).then(() => {
                    // 4. push old popup into marketing_popup_archives (will generate a key)
                    if(TEMP !== null) firebase.app(COUNTRY_DB).database().ref(`marketing_popup_archives/`).push(TEMP);

                    that.setState({
                        text:'saving data', percentage:100,
                        displayButt:true,
                        canUpload: false, previewUpload: LOGO, text: '',
                    }, () => {
                        alert('Succesfully uploaded banner!')
                        // this.props.parentRefresh()
                        that.setState({
                            text:'', percentage:0,
                            displayButt:true, modal: false,
                            canUpload: false, previewUpload: LOGO,
                            link : '', compressedFile : null,
                            progress: { text : '', percentage : 0 },
                        })
                    })
                })
            })
        })
    }
    render() {
        const { displayButt, progress, minZoom } = this.state
        return (
            <div>
                <Button className="m-0" color="dark" onClick={() => {this.toggleModal('modal')}}>Add new Popup</Button>

                <Modal isOpen={this.state.modal} toggle={() => {this.toggleModal('modal')}} className="modal-dialog-centered modal-sm">
                    <ModalHeader toggle={() => {this.toggleModal('modal')}}>Add new banner campaign</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="link">Popup Link</Label>
                            <Input type="text" name="link" id="link" onChange={this.handleChange} placeholder="Webview link" />
                            <FormText color="danger">* This is the link for the webview.</FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label>Upload Popup Image</Label><br />
                            <button id='upload_popup_button' onClick={this.attachFileInputToButton} className='mb-1 m-0' style={{ fontSize: '0.7rem', borderRadius: '5px', display: 'block' }}>Upload Banner</button>
                            <Input type="file" accept="image/*" name="upload_popup" id="upload_popup" style={{marginBottom:'5px'}} onChange={this.handleImage}/>
                            <FormText color="danger">* Image upload guide : Recommended file size less than 150kb. Image dimensions do not matter as long as it has an aspect ratio of 1:1.25!</FormText>
                        </FormGroup>
                        <Card style={{ height:'500px'}}>
                            <Cropper
                                minZoom={minZoom}
                                image={this.state.previewUpload}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={this.state.aspect}
                                restrictPosition={true}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                                zoomSpeed={0.2}
                                objectFit="contain"
                            />
                        </Card>
                        {progress.percentage !== 0 && <Progress bar animated color="success" value={progress.percentage}>{progress.text}</Progress>}
                    </ModalBody>
                    <ModalFooter>
                        {displayButt ? <Button color="dark" onClick={this.errorHandler} className="m-0">Save</Button> : <Button color="dark" className="m-0 d-flex align-items-center justify-content-center" disabled><div>Saving</div><div className="btnloader"></div></Button>}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

MarketingPopupAdd = inject('mobx_auth')(observer(MarketingPopupAdd))