import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button, Card, Collapse } from 'reactstrap'
import firebase from 'firebase'

import MarketingCategoryManagement from 'pages/MarketingCategoryManagement.jsx'
import MarketingSectionManagement from 'pages/MarketingSectionManagement'
import MarketingSectionTile from './MarketingSectionTile'
import { inject, observer } from 'mobx-react'

export default class MarketingCategoryCard extends Component {
   static propTypes = {
      data: PropTypes.object,          // consists of selected category data including sections
      fetchCategory: PropTypes.func    // trigger and fetch updated data from 'marketing_services'
   }

   state = {
      isCollapse: false,         // handles open/close collapsible card
      isDeleteTrigger: false     // handles delete button behavior
   }

   /* toggle collapse card */
   toggleCollapse = state => this.setState({ [state]:!this.state[state] })

   /* delete selected category from db */
   deleteCategory = async (e, data) => {
      let CATEGORY, SECTION
      let COUNTRY_DB = this.props.mobx_auth.COUNTRY
      e.stopPropagation()
      this.setState({ isDeleteTrigger:true })

      let categoryDB = await firebase.app(COUNTRY_DB).database().ref(`marketing_categories/${data.category_id}`).once('value')
      if(categoryDB.exists()) CATEGORY = categoryDB.val()

      let sectionDB = await firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${data.category_id}`).once('value')
      if(sectionDB.exists()) SECTION = sectionDB.val()

      console.log(SECTION);
      
      firebase.app(COUNTRY_DB).database().ref(`marketing_categories_archive/${data.category_id}`).update(CATEGORY)
      SECTION !== undefined && firebase.app(COUNTRY_DB).database().ref(`marketing_categories_archive/${data.category_id}/sections/`).update(SECTION)
      SECTION !== undefined && firebase.app(COUNTRY_DB).database().ref(`marketing_sections/${data.category_id}`).remove()
      firebase.app(COUNTRY_DB).database().ref(`marketing_categories/${data.category_id}`).remove().then(() => {
         alert('Successfully deleted category!')
         this.setState({ isDeleteTrigger:false })
         this.props.fetchCategory()
      })
   }

   /* to trigger delete button */
   _renderDeleteButton = () => {
      const { data } = this.props
      const { isDeleteTrigger } = this.state

      if (!isDeleteTrigger) return <Button color='danger' className='m-0 ml-2' style={{ padding:'7px 15px' }} onClick={e => this.deleteCategory(e, data)}>Delete</Button>

      if (!!isDeleteTrigger) return <Button color='danger' className='m-0 ml-2 d-flex align-items-center justify-content-center' style={{ padding:'7px 15px' }} disabled><div>Deleting</div><div className='ml-1 btnloader'></div></Button>
   }

   /* displays list of sections */
   _renderCollapse = () => {
      const { data } = this.props
      const { isCollapse } = this.state
      let display
      
      if (data.sections !== undefined) {
         display = 
            data.sections.map((tile, index) => 
               <MarketingSectionTile key={index} tile={tile} categoryID={data.category_id} fetchCategory={this.props.fetchCategory} />
            )
      }
      else {
         display = 
         <Card className='m-0'>
            <div className='text-center p-5' body><div>No tiles added.<br />Click on 'Add Tile' to add a new tile.</div> </div>
         </Card>
      }

      return (
         <Collapse isOpen={isCollapse} className={data.sections !== undefined ? 'custom-scrollbar' : ''} style={{ backgroundColor:'transparent', padding:data.sections !== undefined ? '1rem 1rem 0 1rem' : '1rem', position: 'relative', overflowY: 'scroll', maxHeight: '450px' }}>
            {display}
         </Collapse>
      )
   }

   render() {
      const { data } = this.props
      const { isCollapse } = this.state

      return (
         <Card style={{ backgroundColor:'#202020' }}>
            <div 
               className='d-flex justify-content-between' 
               style={{ borderRadius:!isCollapse ? '1rem' : '1rem 1rem 0 0', backgroundColor:'#202020', color:'white', padding:'1rem' }} 
               onClick={() => this.toggleCollapse('isCollapse')}
            >
               <h5 className='m-0'>{data.name}</h5>

               <div className='d-flex justify-content-between'>
                  <MarketingSectionManagement type='add' categoryID={data.category_id} data={data.sections} fetchCategory={this.props.fetchCategory} />

                  <MarketingCategoryManagement type='edit' data={data} fetchCategory={this.props.fetchCategory} />

                  {this._renderDeleteButton()}

                  {!isCollapse ? 
                     <i className='ml-3 mt-2 fas fa-chevron-right text-white'/> :
                     <i className='ml-3 mt-2 fas fa-chevron-down text-white'/>
                  }
               </div>
            </div>

            {this._renderCollapse()}
         </Card>
      )
   }
}

MarketingCategoryCard = inject('mobx_auth')(observer(MarketingCategoryCard))