import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Label, Input, FormGroup, Row, Col } from 'reactstrap'
import firebase from 'firebase'

import ErrorAlert from 'components/ErrorAlert'
import { inject, observer } from 'mobx-react'

export default class MarketingCategoryManagement extends Component {
   static propTypes = {
      type: PropTypes.string,          // type of modal
      data: PropTypes.object,          // consists of selected category data including sections
      fetchCategory: PropTypes.func    // trigger and fetch updated data from 'marketing_services'
   }

   state = {
      isModalOpen: false,     // handles category modal
      isSaveTrigger: false,   // handles save button behavior

      errorText: '',          // handles error text
      errorToggle: false,     // handles error alert modal

      category: ''            // category name
   }

   /* to handle input change then, update state function */
   handleChange = evt => this.setState({ [evt.target.name]:evt.target.value })

   /* toggle error alert */
   childToggle = () => this.setState({ errorToggle:!this.state.errorToggle })

   /* toggle category modal */
   toggleModal = (e, state) => {
      const { data, type } = this.props
      e.stopPropagation()

      this.setState({ [state]:!this.state[state], category:type === 'add' ? '' : data.name })
   }

   /* saves category in database */
   saveCategory = () => {
      const { category } = this.state
      const { data, type } = this.props
      let categoryID, text
      let COUNTRY_DB = this.props.mobx_auth.COUNTRY
      if (category !== '') {
         categoryID = type === 'add' ? firebase.app(COUNTRY_DB).database().ref('marketing_categories/').push().getKey() : data.category_id
         text = type === 'add' ? 'uploaded' : 'updated'

         firebase.app(COUNTRY_DB).database().ref(`marketing_categories/${categoryID}`).update({ name:category }).then(() => {
            alert(`Successfully ${text} category!`)
            this.setState({ isModalOpen:false , isSaveTrigger:false })
            this.props.fetchCategory()
         })
      }
      else {
         return this.setState({ isSaveTrigger:false, errorText:'Category name cannot be empty!', errorToggle:true })
      }
   }

   /* to trigger save button */
   _renderSaveButton = () => {
      const { isSaveTrigger } = this.state

      if (!isSaveTrigger) return <Button className='m-0' color='dark' onClick={() => this.setState({ isSaveTrigger:true }, () => this.saveCategory())}>Save</Button>

      if (!!isSaveTrigger) return <Button className='m-0 d-flex align-items-center justify-content-center' color='dark' disabled><div>Saving</div><div className='ml-1 btnloader'></div></Button>
   }

   /* input field for adding/editing category */
   _renderInputFields = () => {
      const { category } = this.state

      return (
         <Col>
            <FormGroup>
               <Label>Category Name</Label>
               <Input name='category' value={category} onChange={this.handleChange} placeholder='Name of category' />
            </FormGroup>
         </Col>
      )
   }

   /* displays add/edit category modal content */
   _renderModalCategory = () => {
      const { isModalOpen } = this.state
      const { type } = this.props
      let text
      
      text = type === 'add' ? 'Add new' : 'Edit'

      return (
         <Modal className='modal-dialog-centered modal-sm' isOpen={isModalOpen} toggle={e => this.toggleModal(e, 'isModalOpen')}>
            <ModalHeader toggle={e => this.toggleModal(e, 'isModalOpen')}>{`${text} category`}</ModalHeader>
            <ModalBody>
               <Row>
                  {this._renderInputFields()}
               </Row>
            </ModalBody>
            <ModalFooter>
               {this._renderSaveButton()}
            </ModalFooter>
         </Modal> 
      )
   }

   render() {
      const { errorText, errorToggle } = this.state
      const { type } = this.props
      let text, btnColor

      text = type === 'add' ? 'Add Category' : 'Edit'
      btnColor = type === 'add' ? 'dark' : 'light'

      return (
         <>
         <Button className='m-0' style={{ padding:type === 'edit' && '7px 15px' }} color={btnColor} onClick={e => this.toggleModal(e, 'isModalOpen')}>{text}</Button>

         {this._renderModalCategory()}

         <ErrorAlert text={errorText} toggle={errorToggle} parentToggle={this.childToggle} />
         </>
        )
    }
}

MarketingCategoryManagement = inject('mobx_auth')(observer(MarketingCategoryManagement))