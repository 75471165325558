import React, { Component } from 'react'
import firebase, { database } from 'firebase'
import PropTypes from 'prop-types'

import workerDL from 'workers/workerDL.js';
import WebWorker from 'workers/workerSetup.js';
import { Button, Card, CardHeader, Row, Col, Table, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
import PageHeader from '../components/PageHeader';

import ReactExport from "react-export-excel";
import { inject, observer } from 'mobx-react';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Styles = {
    CardHeader : { fontSize : '1rem', fontWeight : 'bold' },
    CardContent : { fontSize : '2.5rem', fontWeight : 'bold', textAlign : 'right' }
}


class DisplayVehicle extends Component {
    static propTypes = {
        data: PropTypes.array,
    }

    state = {
        modalView : false,
    }
    toggleModal = state => {
        this.setState({ [state]: !this.state[state] });
    };
    render() {
        const { data } = this.props
        return (
            <>
                <Button className="m-0" size="sm" color="light" onClick={() => this.toggleModal('modalView')}>Vehicles</Button>

                <Modal isOpen={this.state.modalView} toggle={() => this.toggleModal('modalView')} className='modal-dialog-centered modal-danger' contentClassName='bg-gradient-danger'>
                    <ModalHeader toggle={() => this.toggleModal('modalView')}>Vehicle Detail</ModalHeader>
                    <ModalBody>
                        <Row>
                            {data.map((data, index) => (
                                <Col md={4} key={index}>
                                    <Card body className="no-shadow" style={{border:'1px solid'}}>
                                        <h4 className="m-0 mb-1">{data.plate}</h4>
                                        <div>Brand : {data.brand}</div>
                                        <div>Model : {data.model}</div>
                                        <div>CC : {data.cc}</div>
                                        <div className="d-block">
                                            <Badge color={data.roadtax === "Yes" ? 'success' : 'danger'}>{data.roadtax} Roadtax</Badge>
                                            <Badge color={data.vehicle_registration === "Yes" ? 'success' : 'danger'}>{data.vehicle_registration} Vehicle Registration</Badge>
                                            <Badge color={data.insurance === "Yes" ? 'success' : 'danger'}>{data.insurance} Insurance</Badge>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='m-0 ml-auto' color='dark' onClick={() => this.toggleModal('modalView')}>Close</Button>
                    </ModalFooter>
                </Modal>
                
            </>
        )
    }
}

export default class Dashboard_DirectLending extends Component {
    state = {
        load: '1',
        data: [],
        vehicles: 0
    }
    componentDidMount() {
        this.workerDL = new WebWorker(workerDL);
        this.fetchDirectLending()
    }
    fetchDirectLending = async () => {
        let COUNTRY_DB = this.props.mobx_auth.COUNTRY
        let SNAP = await firebase.app(COUNTRY_DB).database().ref(`direct_lending`).once('value')
        if(SNAP.exists()) {
            await this.workerDL.postMessage(SNAP.val());
            await this.workerDL.addEventListener('message', event => {
                this.setState({ data : event.data.DATA, vehicles : event.data.VEHICLES, load : '2' }, () => {
                    // console.log(this.state.data)
                })
            });
        } else this.setState({ load : '3' })
    }
    // EXPORT DATA INTO EXCEL FOR CUSTOMER AND USER
    _renderExcel = () => {
        const { data, load } = this.state
        // console.log(data);
        let display, TEMP = []

        data.forEach(data => {
            let a = data
            // a.timestamp = new Date(data.timestamp).toLocaleDateString('en-MY')
            // a.register_timestamp = new Date(data.register_timestamp).toLocaleDateString('en-MY')

            if(data.vehicles){
                data.vehicles.forEach(vehicle => {
                    let VEHICLES = vehicle
                    a.brand = VEHICLES.brand
                    a.cc = VEHICLES.cc
                    a.insurance = VEHICLES.insurance
                    a.model = VEHICLES.model
                    a.vehicle_registration = VEHICLES.vehicle_registration
                    a.roadtax = VEHICLES.roadtax
                    a.year = VEHICLES.year
                    a.plate = VEHICLES.plate

                    TEMP.push(a)
                })
            } else {
                a.brand = "NIL"
                a.cc = "NIL"
                a.insurance = "NIL"
                a.model = "NIL"
                a.vehicle_registration = "NIL"
                a.roadtax = "NIL"
                a.year = "NIL"
                a.plate = "NIL"
                TEMP.push(a)
            }
        })

        display =
        <ExcelFile filename='SERV X Direct Lending' element={<Button color="dark" className="m-0" size="sm">Export Report</Button>}>
            <ExcelSheet color='danger' data={TEMP} name='Users' >
                <ExcelColumn label='Interest' value='get_updated' />
                <ExcelColumn label='Platform' value='platform' />
                <ExcelColumn label='Name' value='name' />
                <ExcelColumn label='Phone Number' value='phone' />
                <ExcelColumn label='City' value='city' />
                <ExcelColumn label='State' value='state' />
                <ExcelColumn label='Registration Date' value={(col) => new Date(col.register_timestamp).toLocaleDateString('en-MY')} />

                <ExcelColumn label='Plate Number' value='plate' />
                <ExcelColumn label='Year' value='year' />
                <ExcelColumn label='Brand' value='brand' />
                <ExcelColumn label='Model' value='model' />
                <ExcelColumn label='CC' value='cc' />
                <ExcelColumn label='Vehicle added' value='vehicle_registration' />
                <ExcelColumn label='Roadtax' value='roadtax' />
                <ExcelColumn label='Insurance' value='insurance' />

                <ExcelColumn label='# Transactions' value='transactions_total' />
                <ExcelColumn label='Transaction Type' value='transactions_type' />
                <ExcelColumn label='Avg Transaction (RM)' value='transactions_average' />
                <ExcelColumn label='Timestamp' value={(col) => new Date(col.timestamp).toLocaleDateString('en-MY')} />

                <ExcelColumn label='Referred Workshop' value='workshop_refer' />
                <ExcelColumn label='Workshop Name' value='workshop_name' />
                <ExcelColumn label='Workshop Phone' value='workshop_phone' />
            </ExcelSheet>
        </ExcelFile>

        return display
    }
    _renderTable = () => {
        let display
        const { data, load } = this.state

        display = <Card className='shadow'>
            <CardHeader className='border-0 d-flex align-items-center justify-content-between pb-3'>
                <h3 className='mb-0'>User Interest</h3>
                {load !== '1' && this._renderExcel()}
            </CardHeader>
            <Table className='align-items-center' responsive>
                <thead className='thead-light'>
                    <tr>
                        <th scope='col'>Name</th>
                        <th>Phone</th>
                        <th>Platform</th>
                        <th>Registered</th>
                        <th>Txn</th>
                        <th>Avg Txn</th>
                        <th>Txn Type</th>
                        <th>Get Updated</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Vehicles</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((data,index) => (
                        <tr key={index}>
                            <td>{data.name}</td>
                            <td>{data.phone}</td>
                            <td>{data.platform}</td>
                            <td>{new Date(data.register_timestamp).toLocaleDateString('en-MY')}</td>
                            <td>{data.transactions_total}</td>
                            <td>{data.transactions_average}</td>
                            <td>{data.transactions_type}</td>
                            <td>{data.get_updated}</td>
                            <td>{data.state}</td>
                            <td>{data.city}</td>
                            <td>
                                <div>
                                    <div>{data.vehicles ? data.vehicles.length : '0'} Vehicles</div>
                                    {data.vehicles ? <DisplayVehicle data={data.vehicles} /> : null}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Card>

        return display
    }
    render() {
        const { load, data, vehicles } = this.state

        if(load === '1') return <Card body style={{ padding:'45px 25px' }}><div className="loader"></div></Card>
        return (
            <div className="content">
                <PageHeader title="SERV X Direct Lending" text="Phase 1 : Data collection" image={1} />
                <Row>
                    <Col md={3}>
                        <Card body>
                            <div style={{...Styles.CardHeader}}># Interested Users</div>
                            <div style={{...Styles.CardContent}}>{data.length}</div>
                        </Card>
                    </Col>

                    <Col md={3}>
                        <Card body>
                            <div style={{...Styles.CardHeader}}># Vehicles</div>
                            <div style={{...Styles.CardContent}}>{vehicles}</div>
                        </Card>
                    </Col>
                </Row>
                {this._renderTable()}
            </div>
        )
    }
}

Dashboard_DirectLending = inject('mobx_auth')(observer(Dashboard_DirectLending))